import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Button, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import twitterIcon from "../../assets/landingPageImg/social_Icons/kb_twitter.png";
import facebookIcon from "../../assets/landingPageImg/social_Icons/kb_facebook.png";
import linkedinIcon from "../../assets/landingPageImg/social_Icons/kb_linkedIn.png";
import instagramIcon from "../../assets/landingPageImg/social_Icons/kb_instagram.png";

import lingologo from "../../assets/landingPageImg/kb_logo.png";
import lsplogo from "../../assets/landingPageImg/lspcloud_logo-wide.png";

function LingoletFooter() {
	const current_logo = window.location.hostname.includes("lspcloud") ? lsplogo : lingologo;

	return (
		<Box mt="12" bg="#000">
			<Box
				w={{
					base: "50%",
					md: "90%",
					xl: "50%",
					sm: "90%",
				}}
				mx="auto"
				py="8"
			>
				<Flex justifyContent={"space-between"}>
					<Box>
						<Box>
							<Image w="30%" src={current_logo}></Image>
						</Box>
						<Box color="#fff" ml="5rem">
							<ChakraLink as={ReactRouterLink} isExternal to={"/privacy-policy"}>
								<Text mt="4" fontSize="14px">
									Privacy Policy
								</Text>
							</ChakraLink>
							<ChakraLink as={ReactRouterLink} isExternal to={"/terms-of-use"}>
								<Text mt="4" fontSize="14px">
									Terms of use
								</Text>
							</ChakraLink>
							<ChakraLink as={ReactRouterLink} isExternal to={"/dmca-policy"}>
								<Text mt="4" fontSize="14px">
									DMCA Policy
								</Text>
							</ChakraLink>
							<ChakraLink as={ReactRouterLink} isExternal to={"/gpl-compliance"}>
								<Text mt="4" fontSize="14px">
									GPL Compliance
								</Text>
							</ChakraLink>
							<ChakraLink as={ReactRouterLink} isExternal to={"/eula"}>
								<Text mt="4" fontSize="14px">
									EULA
								</Text>
							</ChakraLink>
							<ChakraLink as={ReactRouterLink} isExternal to={"/cookie-policy"}>
								<Text mt="4" fontSize="14px">
									Cookies
								</Text>
							</ChakraLink>
						</Box>
					</Box>
					<Box>
						<Button
							_hover={{ boxShadow: "none", background: "none" }}
							size="sm"
							variant={"outline"}
							color="#fff"
							fontWeight={"normal"}
							fontSize="14px"
							//   onClick={() => {
							//     scrollToDiv("form");
							//   }}
						>
							Talk With Sales
						</Button>
						<Flex mt="4">
							<Link target="_blank" href="https://www.facebook.com/lingolet.inc/" w="35px" mr="1">
								<Image src={facebookIcon} />
							</Link>
							<Link mr="1" target="_blank" href="https://www.linkedin.com/company/lingolet" w="35px">
								<Image src={linkedinIcon} />
							</Link>
							<Link mr="1" target="_blank" href="https://www.instagram.com/lingolet.inc/" w="35px">
								<Image src={instagramIcon} />
							</Link>
							<Link target="_blank" href="https://twitter.com/Lingolet_Inc" w="35px">
								<Image mr="2" src={twitterIcon} />
							</Link>
						</Flex>
						<ChakraLink as={ReactRouterLink} isExternal to={"/team"}>
							<Text mt="4" color={"gray.100"} fontSize="14px">
								Team
							</Text>
						</ChakraLink>
					</Box>
				</Flex>
				<Box mt={5} borderBottomWidth={"0.5px"} borderBottomColor={"red"}></Box>
			</Box>
		</Box>
	);
}

export default LingoletFooter;
