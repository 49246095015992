// React + Chakra Imports
import React, { useState, useEffect } from "react";
import { Flex, Button, Input, Text, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useColorModeValue, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createBookingServices, updateBookingServices } from "../../../../../../services/accountServices";

const AddUpdateBookingService = React.memo(({ bookingId, isOpen, onOpen, onClose, editData, onUpdateComplete, onEdit }) => {
	const toast = useToast();
	const btnRef = React.useRef();
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

	const initialFormData = {
		booking_service_type: "",
		booking_service_location: "",
		booking_service_sub_location: "",
		booking_service_venue: "",
		booking_service_contact: "",
		booking_service_instruction_internal: "",
		booking_service_instruction_customer: "",
		booking_service_instruction_interpreter: "",
		booking_service_instruction_general: "",
	};
	const [formData, setFormData] = useState(initialFormData);

	useEffect(() => {
		if (editData) {
			setFormData(editData);
			onOpen();
		} else {
			setFormData(initialFormData);
		}
	}, [editData]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;

		if (!formData.booking_service_type) {
			formIsValid = false;
			errors["booking_service_type"] = "*Service Type.";
		}

		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			formData["booking_uuid"] = bookingId;
			if (editData?.booking_service_uuid) {
				formData["booking_service_uuid"] = editData?.booking_service_uuid;
				updateBookingServices(formData)
					.then(res => {
						setLoading(false);
						onUpdateComplete();
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createBookingServices(formData)
					.then(res => {
						setLoading(false);
						onUpdateComplete();
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setFormData(initialFormData);
		setError({});
		onEdit(null);
		onClose();
	}

	// Todo:Reset Form Data

	return (
		<>
			<Button
				ref={btnRef}
				w="100%"
				onClick={onOpen}
				justifyContent={"start"}
				px="12px"
				fontWeight={"400"}
				_hover={{ bg: "blue.100", _active: "blue.100" }}
				sx={{
					bg: "blue.100",
					borderStyle: "dashed",
					borderWidth: "1px",
					borderColor: "black",
					color: "black",
					rounded: "sm",
				}}
			>
				Booking Services
			</Button>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{editData?.booking_uuid ? "Update" : "Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Booking Service
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* Service Type */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Service Type
								</FormLabel>
								<Input placeholder="Enter Booking Service" type="text" name="booking_service_type" value={formData.booking_service_type} onChange={handleChange} color={textColorPrimary} />
								{error.booking_service_type ? (
									<Text color="red" mb="2">
										{error.booking_service_type}
									</Text>
								) : null}
							</FormControl>
							{/* Location */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Location
								</FormLabel>
								<Input placeholder="Enter Location" type="text" name="booking_service_location" value={formData.booking_service_location} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* Sub Location */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Sub Location
								</FormLabel>
								<Input placeholder="Enter Sub Location" type="text" name="booking_service_sub_location" value={formData.booking_service_sub_location} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* Service Venue */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Service Venue
								</FormLabel>
								<Input placeholder="Enter Service Venue" type="text" name="booking_service_venue" value={formData.booking_service_venue} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* Service COntact */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Service Contact
								</FormLabel>
								<Input placeholder="Enter Service Contact" type="text" name="booking_service_contact" value={formData.booking_service_contact} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Service Instruction */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Service Instruction
								</FormLabel>
								<Input placeholder="Enter Service Instruction" type="text" name="booking_service_instruction_internal" value={formData.booking_service_instruction_internal} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Customer Instruction */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Customer Instruction
								</FormLabel>
								<Input placeholder="Enter Customer Instruction" type="text" name="booking_service_instruction_customer" value={formData.booking_service_instruction_customer} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Instruction Interpreter*/}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Instruction Interpreter
								</FormLabel>
								<Input placeholder="Enter Instruction Interpreter" type="text" name="booking_service_instruction_interpreter" value={formData.booking_service_instruction_interpreter} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Instruction General*/}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Instruction General
								</FormLabel>
								<Input placeholder="Enter Instruction General" type="text" name="booking_service_instruction_general" value={formData.booking_service_instruction_general} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{editData?.booking_uuid ? "Update" : "Create"}
							</Button>
							<Button ml="20px" colorScheme="gray" variant="outline" onClick={handleCloseDrawer}>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
});
export default AddUpdateBookingService;
