import { FaCheckCircle, FaClock, FaEnvelope, FaTimesCircle } from "react-icons/fa";

export const EXTERNAL_STORAGE = {
    STORAGE_TYPES: [
        {
            type: 'S3',
            value: "awss3",
            name: "AWS S3",
            credentials: [
                { field: "access_key_id", label: "Access Key ID", type: "text", required: true },
                { field: "secret_access_key", label: "Secret Access Key", type: "password", required: true }
            ],
            config: [
                { field: "bucket_name", label: "Bucket Name", type: "text", required: true },
                { field: "region", label: "Region", type: "text", required: true }
            ]
        },
        {
            type: 'GOOGLE_DRIVE',
            value: "googledrive",
            name: "Google Drive",
            credentials: [
                { field: "client_id", label: "Client ID", type: "text", required: true },
                { field: "client_secret", label: "Client Secret", type: "password", required: true },
            ],
            config: [
                { field: "folder_id", label: "Folder Id", type: "text", required: false }
            ],
            jsonAllowed:true
        },
        {
            type: 'BOX',
            value: "box",
            name: "Box",
            credentials: [
                { field: "client_id", label: "Client ID", type: "text", required: true },
                { field: "client_secret", label: "Client Secret", type: "password", required: true },
                { field: "access_token", label: "Access Token", type: "password", required: true }
            ],
            config: [
                { field: "folder_id", label: "Folder Id", type: "text", required: true }
            ]
        },
        {
            type: 'DROP_BOX',
            value: "dropbox",
            name: "Dropbox",
            credentials: [
                { field: "access_token", label: "Access Token", type: "password", required: true }
            ],
            config: [
                { field: "path", label: "path", type: "text", required: false }
            ]
        },
        {
            type: 'AZURE_BLOB',
            value: "azureblob",
            name: "Azure Blob Storage",
            credentials: [
                { field: "account_name", label: "Account Name", type: "text", required: true },
                { field: "account_key", label: "Account Key", type: "password", required: true }
            ],
            config: [
                { field: "container_name", label: "Container Name", type: "text", required: true },
                ]
        }
    ],
    INPUT_TYPES:
        {
            JSON_INPUT:"json",
            MANUAL_INPUT:"manual"
        },
    
};

export const DOC_AI_WORKER_MANAGEMENT = {
    METRICS_CONFIG : [
        { key: "pending_workflows", label: "Pending Workflows", icon: FaClock, color: "yellow.500" },
        { key: "pending_emails", label: "Pending Emails", icon: FaClock, color: "yellow.500" },
        { key: "total_email_read", label: "Total Emails Read", icon: FaEnvelope, color: "blue.500" },
        { key: "total_email_acted_on", label: "Total Emails Acted On", icon: FaCheckCircle, color: "green.500" },
        { key: "total_email_action_failure", label: "Total Email Failures", icon: FaTimesCircle, color: "red.500" },
        { key: "email_read_last_hour", label: "Emails Read (Last Hour)", icon: FaEnvelope, color: "blue.300" },
        { key: "email_acted_on_last_hour", label: "Emails Acted (Last Hour)", icon: FaCheckCircle, color: "green.300" },
        { key: "email_action_failure_last_hour", label: "Email Failures (Last Hour)", icon: FaTimesCircle, color: "red.300" },
        { key: "email_read_last_24h", label: "Emails Read (Last 24h)", icon: FaEnvelope, color: "blue.400" },
        { key: "email_acted_on_last_24h", label: "Emails Acted (Last 24h)", icon: FaCheckCircle, color: "green.400" },
        { key: "email_action_failure_last_24h", label: "Email Failures (Last 24h)", icon: FaTimesCircle, color: "red.400" }
      ],
      TABS:{
        EMAIL_SYNC_LIST: "email_sync_list",
        METRICS:"metrics"
    },
};

export const PROJECTS_CONTENT = {
    CONTENT_SOURCE : [
        {name:"Lingolet Storage", value:"internal"},
        {name:"External Storage", value:"external"}
    ]
}
