// React + Chakra Imports
import React, { useState, useEffect } from "react";
import { TbTriangleInverted } from "react-icons/tb";
import { Flex, Button, Input, Box, Text, MenuItem, MenuList, ButtonGroup, MenuButton, Menu, VStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, Select, useToast, FormLabel, SimpleGrid, FormControl, RadioGroup, Radio, Checkbox } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { createLingoletIdentity, updateLingoletIdentity } from "../../../../../services/accountServices";

function AddUpdateClientDetails({ passClientData, editStatus, handlerDrawer, updateClientDataCounter }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [counter, setCounter] = useState(0);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedIdentity, setSelectedIdentity] = useState("");
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const [formData, setFormData] = useState({
		id_first_name: "",
		id_last_name: "",
		id_salutation: "",
		id_middle_name: "",
		id_nick_name: "",
		id_suffix: "",
		id_time_zone: "",
		id_gender: "male",
		id_dob: "",
		id_ethnicity: "",
		id_birth_country: "",
		id_nationality: "",
		id_employment_status: "",
		id_employed: false,
		id_retired: false,
		id_residential_country: "",
		id_marital_status: "",
	});

	// Todo:Update Form Details On Edit
	useEffect(() => {
		if (editStatus && passClientData) {
			setFormData({
				id_first_name: passClientData.id_first_name || "",
				id_last_name: passClientData.id_last_name || "",
				id_salutation: passClientData.id_salutation || "",
				id_middle_name: passClientData.id_middle_name || "",
				id_nick_name: passClientData.id_nick_name || "",
				id_suffix: passClientData.id_suffix || "",
				id_time_zone: passClientData.id_time_zone || "",
				id_gender: passClientData.id_gender || "",
				id_dob: passClientData.id_dob || "",
				id_ethnicity: passClientData.id_ethnicity || "",
				id_birth_country: passClientData.id_birth_country || "",
				id_nationality: passClientData.id_nationality || "",
				id_employment_status: passClientData.id_employment_status || "",
				id_employed: passClientData.id_employed || false,
				id_retired: passClientData.id_retired || false,
				id_residential_country: passClientData.id_residential_country || "",
				id_marital_status: passClientData.id_marital_status || "",
			});
			setSelectedIdentity(passClientData.identity_type || "client"); // Assuming identity type is part of passClientData
			onOpen();
		}
	}, [editStatus, passClientData]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};
	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		if (!formData.id_first_name) {
			formIsValid = false;
			errors["firstname"] = "*Please enter your first name.";
		}

		if (!formData.id_last_name) {
			formIsValid = false;
			errors["lastname"] = "*Please enter your last name.";
		}
		if (!formData.id_dob) {
			formIsValid = false;
			errors["id_dob"] = "*Please select your birth date.";
		}
		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			formData["identity_type"] = selectedIdentity;
			if (passClientData?.identity_uuid && editStatus) {
				formData["identity_uuid"] = passClientData?.identity_uuid;
				updateLingoletIdentity(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createLingoletIdentity(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	useEffect(() => {
		updateClientDataCounter(counter);
	}, [counter]);
	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		handlerDrawer(null);
		setFormData({
			id_first_name: "",
			id_last_name: "",
			id_salutation: "",
			id_middle_name: "",
			id_nick_name: "",
			id_suffix: "",
			id_time_zone: "",
			id_gender: "",
			id_dob: "",
			id_ethnicity: "",
			id_birth_country: "",
			id_nationality: "",
			id_employment_status: "",
			id_employed: false,
			id_retired: false,
			id_residential_country: "",
			id_marital_status: "",
		});
		setError({});
		onClose();
	}

	// Todo:Reset Form Data

	return (
		<>
			<Menu>
				<MenuButton as={Box}>
					<ButtonGroup
						isAttached
						spacing={0}
						borderRadius={"10px"}
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
					>
						<Button borderColor="black" bg="blue.100" rounded={"sm"} borderTopLeftRadius="10px" borderBottomLeftRadius="10px" fontWeight={"500"} mb="-1px">
							+ New
						</Button>
						<Button borderTopRightRadius="10px" borderBottomRightRadius="10px" borderStyle="dashed" borderLeftWidth="1px" borderColor="black" bg="blue.100" rounded={"sm"} cursor={"pointer"}>
							<TbTriangleInverted />
						</Button>
					</ButtonGroup>
				</MenuButton>
				<MenuList
					sx={{
						p: 0,
						m: 0,
					}}
				>
					<MenuItem
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
						onClick={() => {
							onOpen();
							setSelectedIdentity("client");
						}}
					>
						Client
					</MenuItem>
					<MenuItem
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderTop: "none",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
						onClick={() => {
							onOpen();
							setSelectedIdentity("customer");
						}}
					>
						Customer
					</MenuItem>
					<MenuItem
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderTop: "none",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
						onClick={() => {
							onOpen();
							setSelectedIdentity("consumer");
						}}
					>
						Consumer
					</MenuItem>
				</MenuList>
			</Menu>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{editStatus ? "Update" : "Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							{selectedIdentity}
						</Text>{" "}
						Details
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* Salutation */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Salutation
								</FormLabel>
								<Input placeholder="Mr./Mrs." type="text" name="id_salutation" value={formData.id_salutation} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* First Name */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									First Name
								</FormLabel>
								<Input type="text" name="id_first_name" value={formData.id_first_name} onChange={handleChange} color={textColorPrimary} />
								{error.firstname ? (
									<Text color="red" mb="2">
										{error.firstname}
									</Text>
								) : null}
							</FormControl>

							{/* Last Name */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Last Name
								</FormLabel>
								<Input type="text" name="id_last_name" value={formData.id_last_name} onChange={handleChange} color={textColorPrimary} />
								{error.lastname ? (
									<Text color="red" mb="2">
										{error.lastname}
									</Text>
								) : null}
							</FormControl>

							{/* Middle Name */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Middle Name
								</FormLabel>
								<Input type="text" name="id_middle_name" value={formData.id_middle_name} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Nick Name */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Nick Name
								</FormLabel>
								<Input type="text" name="id_nick_name" value={formData.id_nick_name} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Gender */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Gender
								</FormLabel>
								<RadioGroup name="id_gender" onChange={value => setFormData({ ...formData, id_gender: value })} value={formData.id_gender}>
									<VStack align="start">
										<Radio value="male">Male</Radio>
										<Radio value="female">Female</Radio>
									</VStack>
								</RadioGroup>
							</FormControl>

							{/* Date of Birth */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Date of Birth
								</FormLabel>
								<Input type="date" name="id_dob" value={formData.id_dob} onChange={handleChange} color={textColorPrimary} />
								{error.id_dob ? (
									<Text color="red" mb="2">
										{error.id_dob}
									</Text>
								) : null}
							</FormControl>

							{editStatus && passClientData ? (
								<>
									{/* Suffix */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Suffix
										</FormLabel>
										<Input placeholder="Jr." type="text" name="id_suffix" value={formData.id_suffix} onChange={handleChange} color={textColorPrimary} />
									</FormControl>

									{/* Time Zone */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Time Zone
										</FormLabel>
										<Input type="text" name="id_time_zone" value={formData.id_time_zone} onChange={handleChange} color={textColorPrimary} />
									</FormControl>

									{/* Ethnicity */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Ethnicity
										</FormLabel>
										<Input type="text" name="id_ethnicity" value={formData.id_ethnicity} onChange={handleChange} color={textColorPrimary} />
									</FormControl>

									{/* Birth Country */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Birth Country
										</FormLabel>
										<Input type="text" name="id_birth_country" value={formData.id_birth_country} color={textColorPrimary} onChange={handleChange} />
									</FormControl>

									{/* Nationality */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Nationality
										</FormLabel>
										<Input type="text" name="id_nationality" value={formData.id_nationality} color={textColorPrimary} onChange={handleChange} />
									</FormControl>

									{/* Employment Status */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Employment Status
										</FormLabel>
										<Input type="text" name="id_employment_status" value={formData.id_employment_status} onChange={handleChange} color={textColorPrimary} />
									</FormControl>

									{/* Employed */}
									<FormControl>
										<Checkbox name="id_employed" isChecked={formData.id_employed} onChange={handleChange}>
											Employed
										</Checkbox>
									</FormControl>

									{/* Retired */}
									<FormControl>
										<Checkbox name="id_retired" isChecked={formData.id_retired} onChange={handleChange}>
											Retired
										</Checkbox>
									</FormControl>

									{/* Residential Country */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Residential Country
										</FormLabel>
										<Input type="text" name="id_residential_country" value={formData.id_residential_country} onChange={handleChange} color={textColorPrimary} />
									</FormControl>

									{/* Marital Status */}
									<FormControl>
										<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
											Marital Status
										</FormLabel>
										<Select name="id_marital_status" value={formData.id_marital_status} onChange={handleChange}>
											<option value="Single">Single</option>
											<option value="Married">Married</option>
											<option value="Divorced">Divorced</option>
										</Select>
									</FormControl>
								</>
							) : null}
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{editStatus ? "Update" : "Create"}
							</Button>
							<Button
								variant="outline"
								colorScheme="red"
								ml={3}
								onClick={() => {
									handleCloseDrawer();
								}}
							>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateClientDetails;
