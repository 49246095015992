// Chakra React Imports
import React, { useEffect, useState } from "react";
import { Text, Box, Flex, useDisclosure, useColorModeValue, useToast, InputGroup, InputLeftAddon, Input, Icon, Button, Spacer, Spinner, Alert, AlertIcon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Tooltip, DrawerFooter } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle } from "react-icons/ai";

// Component Imports
import { useBrandBg, useGreenBg, usePillButtonBg, usePillButtonHighLight, useRedButton, useTextColor } from "../../../../../theme/globalColorTheme";
import { LINK_SHARING_TAB } from "../constants";
import { subscriberSharingDetails, subscriberSharingEmailDetails } from "../../../../../services/accountServices";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import Card from "../../../../../components/card/Card";
import { CiSearch } from "react-icons/ci";
import SharingCardDetails from "./SharingCardDetails";
import Pagination from "../../../../../components/pagination/Pagination";
import QRCode from "react-qr-code";


function LinkSharingTab() {
    const brandBg = useBrandBg();
    const [cardFilter, setCardFilter] = useState(LINK_SHARING_TAB.CARD_FILTER.ACTIVE)
    const [cardtype, setCardType] = useState(LINK_SHARING_TAB.CARD_TYPE.LINGO_LINK)
    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [data, setData] = useState([])
    const [email, setEmail] = useState(null)
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(false);

    const [activeLinksSharedData, setActiveLinksSharedData] = useState([]);

    const [sharedEmailsData, setSharedEmailsData] = useState([])
    const [sharedEmailsDataLoading, setSharedEmailsDataLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [modalQrCode, setModalQrCode] = useState(null);
    const [drawerEmailSearch, setDrawerEmailSearch] = useState(null)
    const blackWhiteColor = useTextColor()
    const cardBg = useColorModeValue("white", "#151117")

    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();
    const qrRef = React.useRef();
    const greenBg = useGreenBg()
    const redBg = useRedButton()

    const port_value = window.location.port ? ":" + window.location.port : '';
    const currentHost = window.location.hostname.includes("localhost")
        ? "https://lingolet.ai/"
        : window.location.protocol + "//" + window.location.hostname + port_value + "/";

    function handleChangeCardType(type) {
        setCardType(type)
    }

    function getFilters() {
        if (cardtype?.length) {
            return [cardFilter, cardtype]
        }
        return cardFilter
    }

    function getDataGroupedByContentType(records) {
        let data = {}
        records.map(record => {
            const refUuid = record.dynamic_content_reference_uuid;
            if (!data[refUuid]) {
                data[refUuid] = {
                    ...record,
                    emails: [{ email: record?.dynamic_content_shared_with, shareCount: record?.share_count }]
                }
            } else {
                data[refUuid].emails.push({ email: record?.dynamic_content_shared_with, shareCount: record?.share_count })
            }
        })
        return Object.values(data)
    }

    function fetchSharingDetails(showLoader = true) {
        showLoader && setDataLoading(true)
        const paramObj = {
            "filter_key": email ? 'email' : '',
            "filter_value": email ? email : "",
            "filter_type": getFilters(),
            "records_per_page": recordsPerPage,
            "page_id": currPage
        }
        subscriberSharingDetails(paramObj).then(resp => {
            if(resp?.data.length===0 || resp?.data[0]?.records?.length === 0){
                setTotalPages(0)
                setData([])
                toast(toastFunctionToaster(resp?.message, "success"))
                return
            }
            if (resp?.data[0]?.records) {
                const groupedByContent = getDataGroupedByContentType(resp?.data[0]?.records)
                setData(groupedByContent)
                setTotalPages(resp?.data[0]?.total_pages)
            }
            if (resp?.data[0]?.error) {
                toast(toastFunctionToaster(resp?.data[0]?.error, "error"))
                setData([])
                setTotalPages(0)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get Sharing data", "error"))
            setData([])
            setTotalPages(0)

        }).finally(() => {
            showLoader && setDataLoading(false)
        })
    }

    function fetchEmailSharedList(filter_value_changed = false) {
        if (filter_value_changed || !sharedEmailsData.length) {
            !filter_value_changed && setSharedEmailsDataLoading(true)
            const paramObj = {
                "filter_key": "email",
                "filter_value": drawerEmailSearch ? drawerEmailSearch : "",
                "status": "active",
                "records_per_page": 1000,
                "page_id": 1
            }
            subscriberSharingEmailDetails(paramObj).then(resp => {
                if (resp?.data?.records) {
                    setSharedEmailsData(resp?.data?.records)
                }
                if (resp?.data[0]?.error) {
                    setSharedEmailsData([])
                    toast(toastFunctionToaster(resp?.data[0]?.error, "error"))

                }
            }).catch(err => {
                setSharedEmailsData([])
                toast(toastFunctionToaster("Failed to get Sharing data", "error"))


            }).finally(() => {
                !filter_value_changed && setSharedEmailsDataLoading(false)
            })
        }
    }

    useEffect(() => {
        fetchSharingDetails()
    }, [currPage, recordsPerPage, cardFilter, cardtype])

    useEffect(() => {
        setCurrPage(1)
    }, [recordsPerPage])

    useEffect(() => {
        if (email !== null) {
            const delay = setTimeout(() => {
                fetchSharingDetails(false)
            }, 200)
            return () => clearTimeout(delay)
        }
    }, [email])


    useEffect(() => {
        const delay = setTimeout(() => {
            fetchEmailSharedList(true)
        }, 200)
        return () => clearTimeout(delay)
    }, [drawerEmailSearch])

    return (
        <Flex h='full' w="full">
            <Box
                w={!configurationCollapseFlag ? "95%" : "80%"}
                bg={brandBg}
                borderRadius={"10px"}
                h="full"
                p={1}
            >
                <Card w={'100%'} mt={2} p={2} h='99%'>
                    <Flex h="full" overflowY="hidden" bg={cardBg} p={2} borderRadius="md">
                        {
                            dataLoading ? (<Flex alignItems="center" justifyContent="center" h="full" w="full">
                                <Spinner size="xl" />
                            </Flex>) : data.length > 0 ? <Flex flexDir="column" gap="2" w="full" h="full" overflow="hidden" p="2" >
                                <InputGroup w="30%" >
                                    <InputLeftAddon><Icon as={CiSearch} fontSize="smaller" /></InputLeftAddon>
                                    <Input fontSize="smaller" type='text' placeholder='Search specific email' value={email} onChange={e => setEmail(e.target.value)} color={blackWhiteColor} />
                                </InputGroup>
                                <Flex flexDir="column" gap="2" w="full" h="full" overflowY="auto" className='custom-scrollbar'>
                                    {
                                        data?.map(item => (
                                            <SharingCardDetails
                                                data={item}
                                                // activeLinks={activeLinksSharedData.filter}
                                                onOpenQr={onOpenQr}
                                                setModalQrCode={setModalQrCode}
                                                key={item?.dynamic_content_uuid} />
                                        ))
                                    }
                                </Flex>
                            </Flex> : <Flex w="full" h="fit-content"><Alert status="warning" mt="4">
                                <AlertIcon />
                                No Data Found
                            </Alert>
                            </Flex>
                        }
                    </Flex>
                    {data.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
                </Card>

                <Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>QR Code for the LingoLink</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {modalQrCode != null ? (
                                <Flex w={"100%"} direction={"column"}>
                                    <QRCode
                                        title={currentHost + modalQrCode}
                                        size={60}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={currentHost + modalQrCode}
                                        viewBox={`0 0 90 90`}
                                    />
                                    <Text p={1} bg={"blue.100"}>
                                        {currentHost + modalQrCode}
                                    </Text>
                                </Flex>
                            ) : (
                                <Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                size={"sm"}
                                colorScheme="blue"
                                onClick={() => {
                                    setModalQrCode(null);
                                    onCloseQr();
                                }}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                    size="md"
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Emails List</DrawerHeader>

                        <DrawerBody>
                            {
                                sharedEmailsDataLoading ?
                                    <Flex alignItems="center" justifyContent="center" h="full" w="full">
                                        <Spinner size="xl" />
                                    </Flex> :
                                    sharedEmailsData?.length ? <Flex flexDir="column" gap={2}>
                                        <InputGroup w="80%" mb="4">
                                            <InputLeftAddon><Text as="span" fontSize="smaller">Search</Text></InputLeftAddon>
                                            <Input type='text' placeholder='Search specific email' value={drawerEmailSearch} onChange={e => setDrawerEmailSearch(e.target.value)} color={blackWhiteColor} />
                                        </InputGroup>
                                        {
                                            sharedEmailsData.map(data => {
                                                let lingoLinkCount = 0
                                                let lingoCardCount = 0

                                                data?.shared_content?.length && data?.shared_content.map(content => {
                                                    lingoCardCount = content?.dynamic_content_type === 'lingocard' ? lingoCardCount + content?.share_count : lingoCardCount
                                                    lingoLinkCount = content?.dynamic_content_type === "lingolink" ? lingoLinkCount + content?.share_count : lingoLinkCount
                                                })
                                                return (<Flex alignItems="center" justifyContent="flex-start" gap='2' bg="blue.100" rounded="md" p={2} w="full">
                                                    <Tooltip label={data?.email}>
                                                        <Text w={"50%"} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" color="black">
                                                            {data?.email}
                                                        </Text>
                                                    </Tooltip>
                                                    <Flex gap={2} w="50%">
                                                        {lingoCardCount ? <Flex
                                                            alignItems={"center"}
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedLeft={"lg"}
                                                            roundedRight={"lg"}
                                                            bg='green.600'
                                                            p={0}
                                                            color='white'
                                                            size="sm"
                                                            fontSize="smaller"
                                                            onClick={() => { setCardFilter(LINK_SHARING_TAB.CARD_FILTER.ACTIVE) }}>
                                                            <Flex borderRight="1px" h="full" alignItems="center" justifyContent="center" p={2}>
                                                                <Text>
                                                                    {lingoCardCount}
                                                                </Text>
                                                            </Flex>
                                                            <Box p={2}>LingoCards</Box>
                                                        </Flex> : null}
                                                        {lingoLinkCount ? <Flex
                                                            alignItems={"center"}
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedLeft={"lg"}
                                                            roundedRight={"lg"}
                                                            bg='green.600'
                                                            p={0}
                                                            color='white'
                                                            size="sm"
                                                            fontSize="smaller"
                                                            onClick={() => { setCardFilter(LINK_SHARING_TAB.CARD_FILTER.ACTIVE) }}>
                                                            <Flex borderRight="1px" h="full" alignItems="center" justifyContent="center" p={2}>
                                                                <Text>
                                                                    {lingoLinkCount}
                                                                </Text>
                                                            </Flex>
                                                            <Box p={2}>LingoLinks</Box>
                                                        </Flex> : null}
                                                    </Flex>
                                                </Flex>)
                                            })
                                        }
                                    </Flex> : <Flex w="full" h="fit-content"><Alert status="warning" mt="4">
                                        <AlertIcon />
                                        No Data Found
                                    </Alert>
                                    </Flex>

                            }

                        </DrawerBody>

                        <DrawerFooter>
                            <Button variant='outline' mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue'>Save</Button>
                        </DrawerFooter>

                    </DrawerContent>
                </Drawer>
            </Box>
            {configurationCollapseFlag ? (
                <Box
                    w="20%"
                    position="relative"
                    p="1"
                    borderRadius="15px"
                    bg={brandBg}
                    ml="2"
                    h="full"
                >
                    <Box
                        position="absolute"
                        top="0"
                        right="0"
                        mr="2"
                        mt="3"
                        zIndex="999"
                    >
                        <AiFillRightCircle
                            cursor="pointer"
                            size="20px"
                            onClick={() => {
                                setConfiguationCollapseFlag(
                                    !configurationCollapseFlag
                                );
                            }}
                        />
                    </Box>
                    <Box w="100%" h="full" bg={brandBg}>
                        <Card h="full" w="full" p={1}>
                            <Flex direction="column" pt={4} gap={4} alignItems="center">
                                <Flex w={"220px"} align={"center"} justifyContent="center">
                                    <Box
                                        p={1}
                                        align={"center"}
                                        w={"100px"}
                                        _hover={{ cursor: "pointer" }}
                                        roundedLeft={"lg"}
                                        bg={cardFilter === LINK_SHARING_TAB.CARD_FILTER.ACTIVE ? 'blue.300' : 'blue.100'}
                                        color={cardFilter === LINK_SHARING_TAB.CARD_FILTER.ACTIVE ? 'gray.200' : 'gray.700'}
                                        size="sm"
                                        onClick={() => { setCardFilter(LINK_SHARING_TAB.CARD_FILTER.ACTIVE) }}>
                                        {cardFilter === LINK_SHARING_TAB.CARD_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                        Active
                                    </Box>
                                    <Box
                                        p={1}
                                        bg={cardFilter === LINK_SHARING_TAB.CARD_FILTER.DISABLE ? 'blue.300' : 'blue.100'}
                                        color={cardFilter === LINK_SHARING_TAB.CARD_FILTER.DISABLE ? 'gray.200' : 'gray.700'}
                                        align={"center"}
                                        w={"100px"}
                                        size="sm"
                                        _hover={{ cursor: "pointer" }}
                                        roundedRight={"lg"}
                                        onClick={() => { setCardFilter(LINK_SHARING_TAB.CARD_FILTER.DISABLE) }}>
                                        {cardFilter === LINK_SHARING_TAB.CARD_FILTER.DISABLE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                        Closed
                                    </Box>
                                </Flex>
                                <Flex w={"220px"} align={"center"} justifyContent="center">
                                    <Box
                                        p={1}
                                        align={"center"}
                                        w={"100px"}
                                        _hover={{ cursor: "pointer" }}
                                        roundedLeft={"lg"}
                                        bg={cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_LINK ? 'blue.300' : 'blue.100'}
                                        color={cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_LINK ? 'gray.200' : 'gray.700'}
                                        size="sm"
                                        onClick={() => { handleChangeCardType(LINK_SHARING_TAB.CARD_TYPE.LINGO_LINK) }}>
                                        {cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_LINK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                        LingoLink
                                    </Box>
                                    <Box
                                        p={1}
                                        align={"center"}
                                        w={"100px"}
                                        _hover={{ cursor: "pointer" }}
                                        roundedRight={"lg"}
                                        bg={cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_CARD ? 'blue.300' : 'blue.100'}
                                        color={cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_CARD ? 'gray.200' : 'gray.700'}
                                        size="sm"
                                        onClick={() => { handleChangeCardType(LINK_SHARING_TAB.CARD_TYPE.LINGO_CARD) }}>
                                        {cardtype === LINK_SHARING_TAB.CARD_TYPE.LINGO_CARD ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                        LingoCard
                                    </Box>
                                </Flex>
                                <Flex justifyContent="center" w="full">
                                    <Button _hover={{ bg: usePillButtonHighLight, color: "black" }}
                                        color={useTextColor}
                                        bg={usePillButtonBg} rounded={'md'} size="sm" onClick={() => {
                                            onOpen()
                                            fetchEmailSharedList()

                                        }}>Show Email/SMS only</Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </Box>
                </Box>
            ) : (
                <Box
                    w="5%"
                    p="3"
                    borderRadius="10px"
                    bg={brandBg}
                    h="100%"
                    ml="2"
                    pl="5"
                >
                    <Box w={"100%"} h="100%">
                        <AiFillLeftCircle
                            cursor="pointer"
                            onClick={() => {
                                setConfiguationCollapseFlag(
                                    !configurationCollapseFlag
                                );
                            }}
                            size="20px"
                        />
                        <Text
                            fontSize="16px"
                            mt="2"
                            css={{
                                writingMode: "vertical-lr",
                                textOrientation: "mixed",
                            }}
                        >
                            Expand Report Settings
                        </Text>
                    </Box>
                </Box>
            )}
        </Flex>
    );
}

export default LinkSharingTab;
