import { Box, Icon, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useState } from "react";
import { fetchFormData } from "../../../../services/adminServices";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useEffect } from "react";
import { MdErrorOutline } from "react-icons/md";

const DynamicTable = ({ data }) => {

    const tabColorScheme = useColorModeValue("blue", "teal");
    const [formData, setFormData] = useState([]);
    const toast = useToast();
  
  
    const { form_uuid, form_key } = data;
    const params = {
      form_uuid: form_uuid,
      form_key: form_key
    }
  
  
    const handleFormDataFetch = () => {
      fetchFormData(params).then((res) => {
        if (res?.status !== 'failure') {
          setFormData(res?.data[0]?.data[0]?.records)
          toast(toastFunctionToaster(res?.message, "success"));
  
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      }
      ).catch((err) => {
        toast(toastFunctionToaster("Failed to fetch form data", "error"));
      });
    }
  
    useEffect(() => {
      setFormData([]);
      handleFormDataFetch();
    }, [data]);
  
  
    if (!formData || formData.length === 0) {
      return (
        <Box p={4} textAlign="center">
          <Icon as={MdErrorOutline} boxSize={12} color="gray.400" mb={4} />
          <Text fontSize="lg" color="gray.500">
            No data available
          </Text>
        </Box>
      );
    }
  
    const fieldsList = formData[0].fields_list;
  
    return (
  
      <Box p={6} borderWidth={1} borderRadius="md" boxShadow="md" bg="white">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Dynamic Data Table
        </Text>
        <TableContainer
          css={{
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '10px',
              transition: 'background-color 0.3s ease',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            'scroll-behavior': 'smooth',
          }}
          maxHeight="500px"
          overflow="auto"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          bg="gray.50"
          p={2}
        >
          <Table variant="striped" colorScheme={tabColorScheme} size="md">
            <Thead>
              <Tr>
                {fieldsList.map((field, index) => (
                  <Th
                    key={index}
                    textTransform="capitalize"
                    fontWeight="semibold"
                    fontSize="md"
                    paddingX={4}
                    borderStart="2px solid"
                    borderEnd="2px solid"
                    borderColor="gray.200"
                  >
                    {field.replace(/_/g, ' ')}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {formData.map((row, rowIndex) => (
                <Tr key={row.form_uuid}>
                  {fieldsList.map((field, colIndex) => (
                    <Td key={colIndex}>
                      {row.data_raw[field] !== undefined
                        ? typeof row.data_raw[field] === 'boolean'
                          ? 'Yes'
                          : row.data_raw[field]
                        : 'N/A'}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

export default DynamicTable