export const serviceAPIs = (urlVar = null) => {
	return {
		loginApi: `/v1/api/user/login`,
		tokenApi: `/v1/api/token`,
		dummyInfo: `/v1/api/utils/info`,
		updateInfo: `/v1/api/user/update_info`,
		changePassword: `/v1/api/user/forgot_password`,
		updatePassword: `/v1/api/user/update_password`,
		domainConfiguration: `/v1/api/business/get_domain_conf`,
		updateDefaultPassword: `/v1/api/update_default_password`,
		logoutApi: `/v1/api/user/logout`,
		resetEmailPassword: `/v1/api/reset_password`,
		googleLogin: `/v1/api/user/login_google`,
		googleRegister: `/v1/api/business/register_with_google`,
		verifyEmail: "v1/api/business/verify_email_code",
		regenerateVerifiedCode: `v1/api/business/regenerate_email_code`,
		registerBusinessUserApi: `/v1/api/business/register_business_user`,
		inviteAssociate: `/v1/api/business/invite_associate`,
		userInfoEx: `/v1/api/user/personal_info_ex`,
		userInfo: `/v1/api/user/personal_info`,
		uploadHeadshot: `/v1/api/user/update_headshot`,

		// Associate API
		associateStatus: `/v1/api/business/manage_status`,
		associateLevel: `/v1/api/business/business_level`,
		pageinatedAssociatesList: `/v1/api/business/all_business_users`,
		resetResendCode: `/v1/api/business/resend_code`,

		// Business API
		addBusiness: `/v1/api/business/add_business`,
		updateBusiness: `/v1/api/business/update_business`,
		fetchBusinessDetails: `/v1/api/business/details`,
		uploadBusinessLogo: `/v1/api/business/upload_business_config`,

		// Module API
		moduleListingAll: `/v1/api/module/listing`,
		createModuleAdmin: `/v1/api/admin/module/create`,
		getModuleListAdmin: `/v1/api/admin/module/modules`,

		specificAdminModule: `/v1/api/admin/module/specific`,
		specificBusinessModule: `/v1/api/business/module/specific`,

		createModuleBusiness: `/v1/api/business/module/create`,
		getModuleListBusiness: `/v1/api/business/module/modules`,

		// Assign modules to associate
		storeAssoiateModuleData: "/v1/api/business/assignModules",

		// updateModuleDetails: `/v1/api/module/update`,
		updateAdminModuleDetails: `/v1/api/admin/module/update`,
		updateBusinessModuleDetails: `/v1/api/business/module/update`,
		managePublishedStatus: `/v1/api/module/manage_status`,

		// Topic In Workshop
		wsSpecificModuleTopic: `/v1/api/module/topic/specific`,
		wsModuleAssessmentFullData: `/v1/api/assessment/specific`,
		wsModuleProgressData: `/v1/api/module/progress`,
		wsModuleForTraining: `/v1/api/module/training`,

		// Topic API
		topicList: `/v1/api/module/topics`,
		editTopicDetails: `/v1/api/module/topic/update`,
		createTopicDetails: `/v1/api/module/topic/create`,
		manageTopicReadnessStatus: `/v1/api/module/topic/manage_status`,

		// Assesment API
		assessmentList: `/v1/api/assessment/list`,
		createAssesmentDetails: `/v1/api/assessment/create`,
		editAssesmentDetails: `/v1/api/assessment/update`,
		manageAssesmentStatus: `/v1/api/assessment/status`,
		listAssesmentQuestion: `/v1/api/assessment/questions`,
		addAssesmentQuestion: `/v1/api/assessment/question/create`,
		updateAssesmentQuestion: `/v1/api/assessment/question/update`,

		// Training API,
		trainingCommunicate: `/v1/api/communicate/letschat`,
		trainingContentSetup: `/v1/api/communicate/setup_training_content`,

		// Configuration API,
		addGoal: "/v1/api/business/addGoals",
		updateGoal: "/v1/api/business/updateGoals",
		addRoles: "/v1/api/business/addRoles",
		updateRoles: "/v1/api/business/updateRoles",
		fetchRoleDetails: "/v1/api/business/getAssociateRoles",
		fetchBusinessGoals: "/v1/api/business/module/goals",

		// Associate
		associateAssignedModule: "/v1/api/associate/assignedModules",
		associateSelfModule: "/v1/api/associate/modules",
		associateSpecificModule: "/v1/api/associate/specific_module",

		// Project
		createProject: "/v1/api/project/create",
		fetchProject: "/v1/api/project/list",
		updateProject: "/v1/api/project/update",
		updateProjectStatus: "/v1/api/project/status",
		updateProjectAccCode: "/v1/api/project/accesscode",
		moduleProjectAllotment: "v1/api/project/module/create",

		//document access control

		createDocAccessControl: "/v1/api/business/account/documentai/control/create",
		getdocAccessControlList: "/v1/api/business/account/documentai/control/listing",
		updateAccessControlList: "/v1/api/business/account/documentai/control/edit",
		updateAccessControlCode: "/v1/api/business/account/documentai/control/update",
		accessControlDocContent: "/v1/api/business/account/documentai/control/content/listing",

		createDocumentSpaceAccess: "/v1/api/business/account/documentai/control/space/access/create",
		updateDocumentSpaceAccess: "/v1/api/business/account/documentai/control/space/access/update",
		updateDocumentSpaceAccessCode: "/v1/api/business/account/documentai/control/space/access/regenerate_code",

		// LingoLinks for customers
		createCustLingoLink: "/v1/api/lingocustomer/lingolink/create",
		resetCustLingoLink: "/v1/api/lingocustomer/lingolink/reset",
		fetchAllCustLinks: "/v1/api/lingocustomer/lingolink/listing",
		disableCustLingoLink: "/v1/api/lingocustomer/lingolink/disable",
		shareCustLingoLink: "/v1/api/lingocustomer/lingolink/share",

		// LingoLinks
		fetchAllLinks: "/v1/api/dynamic/links",
		fetchOneLink: "/v1/api/dynamic/link",
		fetchAllSessions: "/v1/api/dynamic/sessions",
		fetchAllUserCalls: "/v1/api/dynamic/client_calls",
		calculateUserLinks: "/v1/api/dynamic/calculate_client",
		createDynamicLink: "/v1/api/dynamic/create",
		disableDynamicLink: "/v1/api/dynamic/disable",
		shareDynamicLinkViaEmail: "/v1/api/dynamic/link/share/email",
		shareDynamicLinkViaSms: "/v1/api/dynamic/link/share/sms",
		refreshDynamicLink: "/v1/api/dynamic/refresh",
		refreshLeaderCodeLink: "/v1/api/dynamic/leader_code_reset",
		shareLeaderCode: "/v1/api/dynamic/leader_code_share",
		updateDynamicFeatures: "/v1/api/dynamic/appupdate",
		getTranscript: "/v1/api/business/account/subscriber/lingolink/transcripts",
		getTranscriptData: "/v1/api/business/account/subscriber/lingolink/get_transcript",
		storeAccountAppData: "/v1/api/business/account/apps",
		updateAccountAdminApps: "/v1/api/business/account/apps", //Api needs to be chnaged
		addTagToDynamicLink: "/v1/api/dynamic/keywords",
		updateLingoLink: "/v1/api/dynamic/update_details",
		getCallTranscript: "/v1/api/dynamic/get_transcript",
		shareCallTranscript: "/v1/api/dynamic/share_transcript",

		// ----- Quota Call
		fetchAccountQuotaList: "/v1/api/business/account/location/quota_full",
		updateAccountQuota: "/v1/api/business/account/location/quota_create",
		fetchQuotaDetails: "/v1/api/business/account/location/quota",

		// ----- Account Billing Call
		fetchAccountBillList: "/v1/api/account/billing/listing",
		fetchAccountSubsBillList: "/v1/api/account/subscriber/billing/listing",

		// ----- Getting a list of customer
		fetchLinkCustomers: "/v1/api/dynamic/customer/list",
		fetchLinkCustomer: "/v1/api/dynamic/customer/selected",
		fetchCustomerLinks: "/v1/api/dynamic/customer/links",
		fetchEnabledCustomers: "/v1/api/dynamic/customer/enabled",
		addUpLinkCustomer: "/v1/api/dynamic/customer/addupdate",
		moreUpLinkCustomer: "/v1/api/dynamic/customer/updatemore",
		mangLinkCustomer: "/v1/api/dynamic/customer/manage",
		shareLnkwithCustomer: "/v1/api/dynamic/customer/linkshare",
		fetchLinkDesign: "/v1/api/dynamic/design/get_style",
		manageLinkDesign: "/v1/api/dynamic/design/manage",
		uploadLinkDesign: "/v1/api/dynamic/design/upload_image",

		// --------------------
		// Client Dashboard API
		// --------------------
		dbClientLinkCnt: "/v1/api/dashboard/client/lingolink/count",
		dbClientProjCnt: "/v1/api/dashboard/client/project/count",

		// ----- External Launch API End
		// updateLinks: "/v1/api/project/update",
		// updateProjectStatus: "/v1/api/project/status",
		// moduleProjectAllotment: "v1/api/project/module/create",

		// Project Content
		addUpdateContent: `/v1/api/project/content/add_update`,
		getAllContent: `/v1/api/project/content/list`,
		getProcessContentUrl: `/v1/api/project/content/process_url`,
		getAudioFileUrl: `/v1/api/project/content/audio`,
		getAllActiveApi: `/v1/api/project/jobs/active`,
		getContentData: `/v1/api/project/content/content_text`,
		uploadContentData: `/v1/api/project/content/upload`,
		contentViewData: `/v1/api/project/content/view`,

		// Project Content Workflow
		createContentWorkflow: `/v1/api/project/content/workflow/create`,
		listingContentWorkflow: `/v1/api/project/content/workflow/listing`,
		contentWorkflowResultApi: `/v1/api/project/content/workflow/result`,
		contentResultMailApi: `/v1/api/project/content/workflow/mail_result`,

		// Admin Workflow Management
		adminWorkflowJobsApi: `/v1/api/admin/workflow/jobs`,
		adminWorkflowDataApi: `/v1/api/admin/workflow/job_data`,
		adminWorkflowPerformApi: `/v1/api/admin/workflow/perform`,
		adminWorkflowCollectApi: `/v1/api/admin/workflow/collect`,
		// - Automation Admin
		adminAutoJobsApi: `/v1/api/admin/workflow/auto/list`,
		adminAutoReportApi: `/v1/api/admin/workflow/auto/report`,

		// Devices (Admin)
		addDeviceApi: "/v1/api/admin/device/add_new",
		getDeviceApi: "/v1/api/admin/device/get",
		getDevBusinessApi: "/v1/api/admin/device/getbiz",
		getDevTeamApi: "/v1/api/admin/device/getteam",
		assignDeviceApi: "/v1/api/admin/device/assign",
		updateDeviceApi: "/v1/api/admin/device/update",
		manageAdmDevApi: "/v1/api/admin/device/manage",
		addDevicesApi: "/v1/api/admin/device/bulk_add",
		listDevicesApi: "/v1/api/admin/device/master_list",
		adminAddNoteToDeviceAssign: "/v1/api/business/admin/device/assign_notes",

		// Devices (Client)
		getBizDevicesApi: "/v1/api/business/device/get_all",
		getBizDevTeamApi: "/v1/api/business/device/get_team",
		assignBizDevApi: "/v1/api/business/device/assign",
		bizDevConnLogs: "/v1/api/business/device/connlogs",
		assingDeviceToAccount: "/v1/api/business/device/assign/account",
		assingNotesToAccount: "/v1/api/business/device/assign/notes",
		assignDeviceToAccAdmin: "/v1/api/business/device/assign-account-admin",
		assignLinkToDevice: "/v1/api/business/account/admin/device/assign_lingolink",
		assignNotesToDevice: "/v1/api/business/account/admin/device/assign_notes",

		// Topic Workshop & Resource Management
		uploadResource: `/v1/api/resource/upload_resource`,
		addUpdateResource: `/v1/api/resource/add_update_resource`,
		allResources: `/v1/api/resource/resources`,
		getResourceData: `/v1/api/resource/get_resource_text`,
		addUpdateTrainingContent: `/v1/api/resource/add_update_content`,
		getTrainingContent: `/v1/api/resource/get_training_content`,
		prepareTrainingContent: `/v1/api/resource/prep_training_content`,

		// Admin Business List
		businessList: `/v1/api/admin/businesses_list`,
		businessUserList: `/v1/api/admin/business_users`,
		teamList: `v1/api/admin/team_listing`,
		moduleList: `/v1/api/admin/module_listing`,
		adminInviteAssociate: `/v1/api/admin/invite_associate`,

		// Admin Billing List
		adminBillingSettings: `/v1/api/admin/billing/settings`,
		adminBillingAdd: `/v1/api/admin/billing/add`,
		adminBillingUpdate: `/v1/api/admin/billing/update`,
		adminBillingManage: `/v1/api/admin/billing/manage`,
		adminBillingPricing: `/v1/api/admin/billing/pricing`,
		adminBillingEntries: `/v1/api/admin/billing/entries`,

		// Admin Lingo Calls List
		lingolinkCallsList: `/v1/api/admin/lingolink/calls`,

		// Business Billing List : Note:- api are admin not yet changed to business
		businessBillingSettings: `/v1/api/client/billing/settings`,
		businessBillingEntries: `/v1/api/client/billing/entries`,
		adjustBillingPricing: `/v1/api/admin/billing/pricing`, //TodoChange In Future For Adjust Pricing
		// businessBillingAdd: `/v1/api/admin/billing/add`,
		// businessBillingUpdate: `/v1/api/admin/billing/update`,
		// businessBillingManage: `/v1/api/admin/billing/manage`,

		// LingoCard Api
		lingoCardList: `v1/api/lingocard/list`,
		lingoCardDetails: `v1/api/lingocard/details`,
		addUpdateLingoCard: `v1/api/lingocard/addupdate`,
		shareCrdwithCustomer: `v1/api/lingocard/assign_customer`,
		emailCrdwithCustomer: `v1/api/lingocard/email_customer`,
		manageCardStatus: `v1/api/lingocard/manage`,
		disableCardStatus: `v1/api/lingocard/disable`,
		businessCallListing: `v1/api/lingocard/call/listing`, //business admin card call listing

		// Subdomain Service Api
		addSubdomain: `v1/api/business/add_subdomain`,
		delSubdomain: `v1/api/business/remove_subdomain`,
		addSubDomainAccountAdmin: `v1/api/business/account/account_add_subdomain`,
		delSubDomainAccountAdmin: `v1/api/business/account/account_remove_subdomain`,
		getBusinessDetailsForAccountAdmin: "v1/api/business/account/business_details",

		// Common Feedback Service Api
		feedback: `v1/api/common/feedback`,

		// Language Associate (Account Admin)
		pageinatedLanguageAssociatesList: `/v1/api/business/account/lang_associate_listing`,
		inviteLanguageAssociate: `/v1/api/business/account/lang_associate_invite`,
		languageassociateStatus: `/v1/api/business/account/lang_associate_manage`,
		languageassociateLevel: `/v1/api/business/account/business_level`,

		// Todo:Change the End Point's
		// Link Design Customer
		fetchCustomerLinkDesignData: "/v1/api/dynamic/design/get_style",
		manageCustomerLinkDesign: "/v1/api/dynamic/design/manage",

		// Customer Card Details
		addUpdateCustomerCard: `v1/api/lingocard/addupdate`,
		getCustomerCardList: `v1/api/lingocard/list`,
		disableCustomerCardStatus: `v1/api/lingocard/disable`,
		emailCardDetails: `v1/api/lingocard/email_customer`,

		// -----  (External) --------------
		// ----- LingoLink Launch API Start
		externalPageLaunch: "/v1/api/lingolink/launch",
		verifyPageLaunch: "/v1/api/lingolink/verify",
		verifyCallAccessibility: "/v1/api/lingolink/accessibility",
		piCallSetupApi: "/v1/api/lingolink/comm/pisetup",
		biCallSetupApi: "/v1/api/lingolink/comm/bisetup",
		callStartedApi: "/v1/api/lingolink/comm/add",
		callCompletedApi: "/v1/api/lingolink/comm/update",
		addlingocallFeedBack: "/v1/api/lingolink/submit/feedback",

		// -----  (External) --------------
		// ----- LingoLink Management API Start
		manageLinkStarter: "/v1/api/lingolink/manage/letmein",
		manageLinkVerifier: "/v1/api/lingolink/manage/verify",

		// ----- Account Management API -------------
		getAccountList: "/v1/api/business/account/listing",
		getAccountDesc: "/v1/api/business/account/description",
		getAccountInfo: "/v1/api/business/account/info",
		getAccountQuota: "/v1/api/business/account/quota",
		createNewAccount: "/v1/api/business/account/create",
		createNewQuota: "/v1/api/business/account/quota_create",
		updateAccount: "/v1/api/business/account/update",
		manageAccountStatus: "/v1/api/business/account/manage",
		accountImageUpdate: "/v1/api/business/account/design",
		updateDesignForAccountAdmin: "/v1/api/business/account/update_design",

		manageDocumnetAi: "/v1/api/business/account/admin/translation_manage",
		manageDeviceSupport: "/v1/api/business/account/admin/device_manage",
		manageScheduleSupport: "/v1/api/business/account/admin/schedule_manage",
		manageAssociateSupport: "/v1/api/business/account/admin/manage_associate_support",
		manageLingoFormSupport: "/v1/api/business/account/admin/lingo_form_manage",
		manageAccAdminLingoFormSupport: "/v1/api/business/account/admin/acc_admin/lingo_form_manage",

		adminAccountList: "/v1/api/business/account/admin/listing",
		manageAccountAdmStatus: "/v1/api/business/account/admin/manage_status",
		resetAccountAdmPwd: "/v1/api/business/account/admin/manage_pwd",
		inviteAccountAdmin: "/v1/api/business/account/admin/invite",
		inviteTeamMember: "/v1/api/business/account/request_team_member",
		updateAccountAdmin: "/v1/api/business/account/admin/update",
		adminAccountManageState: "/v1/api/business/account/admin/manage_status",
		getTeamMembers: "/v1/api/business/account/admin/team_members",

		createAccLangPref: "/v1/api/business/account/admin/lang_preference/create",
		updateAccLangPref: "/v1/api/business/account/admin/lang_preference/update",
		manageAccLangPref: "/v1/api/business/account/admin/lang_preference/manage",
		applyAccLangPref: "/v1/api/business/account/admin/lang_preference/apply",
		accLangPaginated: "/v1/api/business/account/admin/lang_preference/paginated_list",
		accAssignedDeviceList: "/v1/api/business/account/devices/assigned_list",
		accAssignedDeviceConnLogs: "/v1/api/business/account/devices/connect_logs",
		accSharingDetails: "/v1/api/business/account/admin/share/listing",
		accSharingEmailsList: "/v1/api/business/account/admin/share/email_list",

		// ----- Account Location and Subscriber Management API -------------
		getAccLocInfo: "/v1/api/business/account/location/subscriber/parent_info",
		getSubPersonalInfo: "/v1/api/business/account/location/subscriber/personal_info",

		//Application settings api

		getSettingsdata: "/v1/api/app/setting/data",
		createSetting: "/v1/api/app/setting/create",
		updateSetting: "/v1/api/app/setting/update",
		manageSetting: "v1/api/app/setting/manage",
		getSetting: "v1/api/app/get_setting",

		// ----------Location Api
		getLocationInfo: "/v1/api/business/account/location/info",
		getLocationList: "/v1/api/business/account/location/listing",
		getLocListWithSubs: "/v1/api/business/account/location/listwithsubs",
		manageLocationStatus: "/v1/api/business/account/location/manage",
		createNewLocation: "/v1/api/business/account/location/create",
		updateExisingLocation: "/v1/api/business/account/location/update",
		uploadLocationAvtar: "/v1/api/business/account/location/design",
		getSubscriberList: "/v1/api/business/account/location/subscriber/listing",
		getSubscriberLingoLinkList: "/v1/api/business/account/location/subscriber/lingolinks",
		getSubscriberLingoLinkManage: "/v1/api/business/account/location/subscriber/lingolink/manage",
		manageSubscriberStatus: "/v1/api/business/account/location/subscriber/manage",
		addSubscriber: "/v1/api/business/account/location/subscriber/invite",
		updateSubscriber: "/v1/api/business/account/location/subscriber/update",

		// Updated by Avkash Aug 1 for Location Language settings
		getLocLanguageList: "/v1/api/business/account/location/languages/get_list", //to get all the language list for location
		enableDiableSubLingoCard: "/v1/api/business/account/location/subscriber/manage/lingocard",
		getLanguageList: "/v1/api/business/account/languages/get_list", //to get all the language lists
		updateLanguageList: "/v1/api/business/account/location/language_update", //update language list
		getLanguageListForLocation: "/v1/api/business/account/location/language_list", //valid language lists for location

		// -----------Subscriber Api
		createSubLingoLink: "v1/api/business/account/subscriber/lingolink/create",
		getSubLingoList: "v1/api/business/account/subscriber/lingolink/listing",
		getSubLingoMiniList: "v1/api/business/account/subscriber/lingolink/mini_listing",
		subLingoKey: "/v1/api/business/account/subscriber/lingolink/keywords",
		disableSubLingoLink: "/v1/api/business/account/subscriber/lingolink/disable",
		resetSubLingoLinkCode: "/v1/api/business/account/subscriber/lingolink/reset",
		shareSubLingoLink: "/v1/api/business/account/subscriber/lingolink/share",
		getSubCallHistory: "/v1/api/business/account/subscriber/lingolink/call_history",
		calSubCalls: "/v1/api/business/account/subscriber/lingolink/calculate_calls",
		uploadSubscriberLinkDesign: "v1/api/business/account/subscriber/lingolink/design",
		fetchSubLinkDesignData: "/v1/api/business/account/subscriber/lingolink/get_design",
		manageSubscriberLinkDesign: "/v1/api/business/account/subscriber/lingolink/manage_design",
		addUpdateFeatures: "/v1/api/business/account/subscriber/lingolink/appupdate",
		getSubsriberTranscript: "/v1/api/business/account/subscriber/lingolink/transcripts",
		getSubsriberTranscriptData: "/v1/api/business/account/subscriber/lingolink/get_transcript",
		sendSubscriberTranscriptMail: "/v1/api/business/account/subscriber/lingolink/email_transcript",
		resetSubscriberPassword: "/v1/api/business/account/location/subscriber/reset_pwd",
		getsubscriberLinks: "/v1/api/business/account/billing/lingolinks",
		getsubscriberLinksCallHistory: "v1/api/business/account/billing/call_history",
		getresetedLeaderCode: "/v1/api/business/account/subscriber/lingolink/leader_code_reset",
		mailLeaderCode: "/v1/api/business/account/subscriber/lingolink/leader_code_share",
		updateLingoLinkSubs: "/v1/api/business/account/subscriber/lingolink/update_details",

		createSubLingoCard: "/v1/api/business/account/subscriber/lingocard/create",
		updateSubLingoCard: "/v1/api/business/account/subscriber/lingocard/update",
		listSubLingoCards: "/v1/api/business/account/subscriber/lingocard/listing",
		manageSubLingoCard: "/v1/api/business/account/subscriber/lingocard/manage",
		shareSubLingoCard: "/v1/api/business/account/subscriber/lingocard/share",
		checkLingoCardEnabled: "/v1/api/business/account/subscriber/lingocard/validate/access",
		refreshSubLingoCard: "/v1/api/business/account/subscriber/lingocard/refresh",
		cardUsageDetails: "/v1/api/business/account/subscriber/lingocard/usages",
		commonDetailsLingoCard: "/v1/api/business/account/subscriber/lingocard/common_details",
		getAcountAdminEventsForSubscriber: "/v1/api/business/account/subscriber/events",
		getAccountEvents: "/v1/api/business/account/account_events",
		shareLingoCardSms: "/v1/api/business/account/subscriber/lingocard/share/sms",

		// Subscriber Lingo Card Call listing
		subLingoCardCallListing: "v1/api/business/account/subscriber/lingocard/call/listing",
		subscriberTestCall: "/v1/api/business/account/subscriber/lingocard/test_call",

		createSchedule: "/v1/api/business/account/subscriber/schedule/create",
		updateSchedule: "/v1/api/business/account/subscriber/schedule/update",
		getScheduleList: "/v1/api/business/account/subscriber/schedule/listing",
		cancelSchedule: "/v1/api/business/account/subscriber/schedule/cancel",
		shareSchedule: "/v1/api/business/account/subscriber/schedule/share",

		// ---------- Events API
		getAllAdminEvents: "/v1/api/business/account/admin_events",
		getEventList: "/v1/api/business/account/all_events",

		// --------------------
		// Subscriber Dashboard API
		// --------------------
		dbSubLingoLinksCount: "/v1/api/business/account/dashboard/subscriber/lingolinks/count",
		dbSubClientEventsCnt: "/v1/api/business/account/dashboard/subscriber/events/count",
		saveAccountSubApp: "/v1/api/business/account/location/subscriber/apps",
		disableAllSubApp: "/v1/api/business/account/location/subscriber/disable/all/apps",
		fetchSubQuotaInfo: "/v1/api/business/account/location/subscriber/quota",
		// --------------------
		// Account Dashboard API
		// --------------------
		dbAccountLocationsCount: "/v1/api/business/account/dashboard/metrics",
		dbAccountEventsCount: "/v1/api/business/account/dashboard/lingolinks",
		// -------------------
		// Lingolet Common
		// -------------------
		lingoEmailTranscript: "/v1/api/lingolink/comm/email_transcript",
		saveLingoTranscript: "/v1/api/lingolink/comm/save_transcript",

		//language-preference

		createLanguagePreference: "/v1/api/business/account/subscriber/lang_preference/create",
		updateLanguagePreference: "/v1/api/business/account/subscriber/lang_preference/update",
		manageLanguagePreference: "/v1/api/business/account/subscriber/lang_preference/manage",
		listLangPreferences: "/v1/api/business/account/subscriber/lang_preference/get_list_paginated",
		applyLangPrefrence: "/v1/api/business/account/subscriber/lang_preference/apply",
		getPrefrence: "/v1/api/business/account/subscriber/lang_preference/get",

		sharingDetails: "/v1/api/business/account/subscriber/share/listing",
		sharingEmailsDetails: "/v1/api/business/account/subscriber/share/email_list",

		//LingoCard for account admin
		createAdminLingoCard: "/v1/api/business/account/admin/lingocard/create",
		updateAdminLingoCard: "/v1/api/business/account/admin/lingocard/update",
		listAdminLingoCards: "/v1/api/business/account/admin/lingocard/listing",
		manageAdminLingoCard: "/v1/api/business/account/admin/lingocard/manage",
		shareAdminLingoCard: "/v1/api/business/account/admin/lingocard/share",
		checkAdminLingoCardEnabled: "/v1/api/business/account/admin/lingocard/validate/access",
		refreshAdminLingoCard: "/v1/api/business/account/admin/lingocard/refresh",
		AdmincardUsageDetails: "/v1/api/business/account/admin/lingocard/usages",
		commonDetailsAdminLingoCard: "/v1/api/business/account/admin/lingocard/common_details",
		shareAdminLingoCardSms: "/v1/api/business/account/admin/lingocard/share/sms",
		accAdminCallListing: "/v1/api/business/account/admin/lingocard/call/listing", // account admin lingo card call listing
		accAdminTestCall: "/v1/api/business/account/admin/lingocard/test_call",

		//schedule for account admin
		createAccAdminSchedule: "/v1/api/business/account/admin/schedule/create",
		updateAccAdminSchedule: "/v1/api/business/account/admin/schedule/update",
		getAccAdminScheduleList: "/v1/api/business/account/admin/schedule/listing",
		cancelAccAdminSchedule: "/v1/api/business/account/admin/schedule/cancel",
		shareAccAdminSchedule: "/v1/api/business/account/admin/schedule/share",

		getAccLinkCallHistory: "/v1/api/business/account/billing/acc_link_call_history",

		manageAccLingoCard: "/v1/api/business/account/admin/lingocard_manage",
		updateAccountType: "/v1/api/business/account/admin/account_access_manage",
		updateAccountSecureCall: "/v1/api/business/account/admin/account_secure_call_manage",

		getAppConf: "/v1/api/app/config",
		getToc: "/v1/api/user/help/toc",
		getHelpFileContent: "/v1/api/user/help/file/get_md",

		//mobile phone exception apis
		addPhoneException: "/v1/api/public/exception/add",
		verifyPhone: "/v1/api/public/exception/verify",
		managePhoneException: "v1/api/public/exception/manage",

		//---------------------------
		// Reporting Setup api for account admin
		//---------------------------

		addReportSetupAccAdmin: "v1/api/business/account/reporting/setup/create",
		updateReportSetupAccAdmin: "v1/api/business/account/reporting/setup/update",
		manageReportSetupAccAdmin: "v1/api/business/account/reporting/setup/manage",
		listReportSetupAccAdmin: "v1/api/business/account/reporting/setup/get",
		fetchReportExecutionListAccAdmin: "v1/api/business/account/reporting/exec/get",

		//---------------------------
		// Reporting Setup api for account subscriber
		//---------------------------

		addReportSetupAccSubs: "v1/api/business/account/subscriber/reporting/setup/create",
		updateReportSetupAccSubs: "v1/api/business/account/subscriber/reporting/setup/update",
		manageReportSetupAccSubs: "v1/api/business/account/subscriber/reporting/setup/manage",
		listReportSetupAccSubs: "v1/api/business/account/subscriber/reporting/setup/get",
		fetchReportExecutionListAccSubs: "v1/api/business/account/subscriber/reporting/exec/get",

		//---------------------------
		// Idenity Management APIs
		//---------------------------

		//lingolet identity
		createIdentity: "/v1/api/business/account/identity/create",
		updateIdentity: "/v1/api/business/account/identity/update",
		manageIdentity: "/v1/api/business/account/identity/manage",
		selectIdentity: "/v1/api/business/account/identity/select",
		listIdentity: "/v1/api/business/account/identity/listing",
		uploadIdentityLogo: "/v1/api/business/account/identity/update_image",

		//lingolet identity contact
		createIdContact: "/v1/api/business/account/identity/contact/create",
		updateIdContact: "/v1/api/business/account/identity/contact/update",
		fetchIdContact: "/v1/api/business/account/identity/contact/get",

		//lingolet identity address
		createIdAddress: "/v1/api/business/account/identity/address/create",
		updateIdAddress: "/v1/api/business/account/identity/address/update",
		manageIdAddress: "/v1/api/business/account/identity/address/manage",
		fetchIdAddress: "/v1/api/business/account/identity/address/get",

		//lingolet identity banking
		createIdBank: "/v1/api/business/account/identity/bank/create",
		updateIdBank: "/v1/api/business/account/identity/bank/update",
		manageIdBank: "/v1/api/business/account/identity/bank/manage",
		fetchIdBank: "/v1/api/business/account/identity/bank/get",

		//lingolet identity tax
		createIdTax: "/v1/api/business/account/identity/tax/create",
		updateIdTax: "/v1/api/business/account/identity/tax/update",
		manageIdTax: "/v1/api/business/account/identity/tax/manage",
		fetchIdTax: "/v1/api/business/account/identity/tax/get",

		//lingolet identity company
		createIdCompany: "/v1/api/business/account/identity/company/create",
		updateIdCompany: "/v1/api/business/account/identity/company/update",
		manageIdCompany: "/v1/api/business/account/identity/company/manage",
		fetchIdCompany: "/v1/api/business/account/identity/company/get",

		//account reporting
		getSubsLinksMetrics: "/v1/api/business/account/reporting/subscriber/lingolinks/count",
		getSubsEventsMetrics: "/v1/api/business/account/reporting/subscriber/events/count",
		getSubsLingoCards: "/v1/api/business/accountreporting/lingocard/listing",

		verifySpaceAccess: "/v1/api/public/documentai/verify/control_access_code",
		controlSpaceAccessContentList: "/v1/api/public/documentai/space/contents/list",
		getAllSubscribers: "/v1/api/business/account/list/subscribers",

		//documentai management
		DocAiWorkerHeartbeat: "/v1/api/documentai/management/heartbeat",
		DocAiMetrics: "/v1/api/documentai/management/metrics",
		DocAiEmailSyncList: "/v1/api/documentai/management/email_sync_list",
		DocAiEmailActivity: "/v1/api/documentai/management/email_activity",
		DocAiEmailActivityList: "/v1/api/documentai/management/email_activity_list",
		DocAiRework: "/v1/api/documentai/management/email_rework",

		// ---------------------------------------------------------------------------
		//                           Dynamic Forms apis
		// ---------------------------------------------------------------------------

		// Form Meta Data
		createFormMetaData: "/v1/api/dynamic/forms/metadata/create",
		updateFormMetaData: "/v1/api/dynamic/forms/metadata/update",
		getFormMetaData: "/v1/api/dynamic/forms/metadata/list",

		//Form Data
		createFormData: "/v1/api/dynamic/forms/data/create",
		updateFormData: "/v1/api/dynamic/forms/data/update",
		getFormData: "/v1/api/dynamic/forms/data/list",

		//External storage
		addExternalStorage: "/v1/api/documentai/storage/add",
		updateExternalStorage: "/v1/api/documentai/storage/update",
		manageExternalStorage: "/v1/api/documentai/storage/manage",
		listExternalStorage: "/v1/api/documentai/storage/list",
		selectExternalStorage: "/v1/api/documentai/storage/select",
		authorizeGoogleExternalStorage: "/v1/api/docai/storage/auth",
		listGdriveStorageFiles: "/v1/api/docai/storage/files/list",
		disconnectStorage: "/v1/api/documentai/storage/disconnect",

		//newsletter, demo, webinar signup
		addSignUpType: "/v1/api/public/sign_up",
		verifyValidationCode: "/v1/api/public/sign_up/validate",

		generatePinFotPrefrencesList: "/v1/api/public/preferences",
		validatePinToListPrefrences: "/v1/api/public/preferences/validate",
		removePrefrences: "/v1/api/public/sign_up/remove",
		updatePrefrenceList: "/v1/api/public/preferences/update",
		getWebsiteRegistrations: "/v1/api/business/admin/business/signup/listing",

		//scheduler > booking
		addBooking: "/v1/api/business/account/booking/create",
		listBooking: "/v1/api/business/account/booking/master/listing",
		createBookingService: "/v1/api/business/account/booking/service/create",
		updateBookingService: "/v1/api/business/account/booking/service/update",
		listBookingService: "/v1/api/business/account/booking/service/listing",
		addBookingAppoinmentDetails: "/v1/api/business/account/booking/appointment/create",
		updateBookingAppoinmentDetails: "/v1/api/business/account/booking/appointment/update",
		listBookingApoinmentDetails: "/v1/api/business/account/booking/appointment/listing",
	};
};
