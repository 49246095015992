import { Alert, AlertIcon, Box, Button, Card, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useBrandBg } from '../../../theme/globalColorTheme'
import { useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { REGISTRATIONS_TYPE } from './constants'
import { FcApproval } from 'react-icons/fc'
import { useEffect } from 'react'
import { getWebsiteRegistrations } from '../../../services/adminServices'
import Pagination from '../../../components/pagination/Pagination'
import { TfiReload } from "react-icons/tfi";


const WebsiteRegistrations = () => {
    const [registrationsList, setRegistrationsList] = useState([])
    const [btnLoading, setBtnLoading] = useState({})
    const [filter, setFilter] = useState(REGISTRATIONS_TYPE[0])
    const [pageId, setPageId] = useState(0)
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        setPageId(1)
    }, [recordsPerPage])

    useEffect(() => {
        handleGetRegistrations()
    }, [filter, pageId, recordsPerPage])

    function handleGetRegistrations() {
        if (!filter) return
        const paramObj = {
            filter_type: filter?.value,
            page_id: pageId,
            records_per_page: recordsPerPage,
            is_enabled: true
        }
        getWebsiteRegistrations(paramObj).then((resp) => {
            if (resp?.data[0]?.status) {
                setTotalPages(resp?.data[0]?.total_pages)
                setRegistrationsList(resp?.data[0]?.data?.data)
            }
        }).catch(() => {

        }).finally(() => {

        })
    }

    return (
        <Flex direction="column" pt={{ sm: "130px", lg: "80px" }} flex={1}>
            <Flex p="1" gap="2">
                <Spacer />
                <IconButton icon={<TfiReload/>} colorScheme='yellow' onClick={handleGetRegistrations} color="black" aria-label='Reload Data'/>
                <Flex alignItems="center" gap="2">
                    <Text fontWeight="bold">Registration type:</Text>
                    <Menu placement='bottom'>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme='telegram'>
                            {filter?.name || 'Select Filter'}
                        </MenuButton>
                        <MenuList>
                            {
                                REGISTRATIONS_TYPE.map(item => (
                                    <MenuItem onClick={() => setFilter(item)}>{item.name}</MenuItem>
                                ))
                            }
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
            <Card p="1" flex={1}>
                <Flex minH="full" overflow="hidden" flex={1}>
                    <Box
                        w={"full"}
                        bg={useBrandBg}
                        p="2"
                        borderRadius={"10px"}
                        minH="full"

                    >
                        <Flex h="91%" w="full" className="custom-scrollbar"
                            overflowY="auto">
                            {
                                btnLoading?.listStorage ? <Flex h="full" justify-content="center" align-items="center" w="full">
                                    <Box>
                                        <Spinner size="xl" />
                                    </Box>
                                </Flex>
                                    : <Flex flexDir="column" gap="2" w="full">
                                        {
                                            registrationsList.length > 0 ?
                                                        <Flex gap="2" w="full">
                                                            <Table variant="simple">
                                                                <Thead>
                                                                    <Tr bg="gray.300">
                                                                        <Th>Email</Th>
                                                                        <Th>Demo</Th>
                                                                        <Th>Newsletter</Th>
                                                                        <Th>Events</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {registrationsList.map((user, index) => (
                                                                        <Tr key={user.signup_email} bg="gray.100">
                                                                            <Td>{user.signup_email}</Td>
                                                                            <Td>
                                                                                {user?.is_demo && <Icon as={FcApproval} fontSize="md" />}
                                                                            </Td>
                                                                            <Td>
                                                                                {user?.is_newsletter && <Icon as={FcApproval} fontSize="md" />}
                                                                            </Td>
                                                                            <Td>
                                                                                {user?.is_webinar && <Icon as={FcApproval} fontSize="md" />}
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                            </Table>
                                                        </Flex>
                                                :
                                                <Alert status='info'>
                                                    <AlertIcon />
                                                    No Registrations Found!!
                                                </Alert>
                                        }
                                    </Flex>
                            }
                        </Flex>
                        {
                            registrationsList &&
                            <Pagination currentPage={pageId} recordsPerPage={recordsPerPage} setCurrentPage={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} />
                        }
                    </Box>
                </Flex>
            </Card>
        </Flex>
    )
}

export default WebsiteRegistrations