export const REGISTRATIONS_TYPE = [
    {
        name:"Demo",
        value:"demo"
    },
    {
        name:"Event",
        value:"webinar"
    },
    {
        name:"Newsletter",
        value:"newsletter"
    },
    {
        name:"All",
        value:"all"
    },
]