import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";

const JsonInput = ({ text, setText }) => {
  const [highlightedJson, setHighlightedJson] = useState("");
  const [isFocused, setIsFocused] = useState(false)

  useEffect(()=>{
    if(text){
      handleTextChange(text)
    }
  },[])

  function handleTextChange(newText) {
    try {
      const jsonData = JSON.parse(newText);
      setText(newText);
      setHighlightedJson(syntaxHighlight(JSON.stringify(jsonData, null, 2)));
    } catch {
    }
  }

  function getColorForType(type) {
    switch (type) {
      case "key":
        return "brown";
      case "string":
        return "green";
      case "number":
        return "blue";
      case "boolean":
        return "purple";
      case "null":
        return "gray";
      default:
        return "black";
    }
  }

  function syntaxHighlight(json) {
    if (typeof json !== "string") {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");

    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        let type = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            type = "key";
          } else {
            type = "string";
          }
        } else if (/true|false/.test(match)) {
          type = "boolean";
        } else if (/null/.test(match)) {
          type = "null";
        }
        return `<span style="color: ${getColorForType(type)}">${match}</span>`;
      }
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      maxH="600px"
      p="2"
      borderColor={isFocused ? "blue.300" : "gray.300"}
      borderWidth={isFocused ? "2px" : "1px"}
      borderRadius="md"
      fontSize="md"
      fontFamily="monospace"
      whiteSpace="pre-wrap"
      wordBreak="break-word"
      overflow="auto"
      contentEditable
      onInput={(e)=>handleTextChange(e.target.innerText)}
      onFocus={() => setIsFocused(true)} 
      onBlur={() => setIsFocused(false)} 
      outline="none"
      dangerouslySetInnerHTML={{ __html: highlightedJson }}
    />
  );
};

export default JsonInput;
