// React + Chakra Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Flex, Text, Alert, AlertIcon, Spinner, useToast, Icon, useColorModeValue } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getLingoletIdBankList } from "../../../../../../services/accountServices";

function BankList({ identity_uuid, setclientData, reRenderContactList, idx, setIdxValue }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [bankDetailsList, setBankDetailsList] = useState([]);
	const shadowBlack = useColorModeValue("0px 3px 5px rgb(107 111 110)", "0px 3px 5px rgb(107 111 110);");

	const fields = [
		{ label: "Account Type", key: "identity_bank_account_type" },
		{ label: "Account Number", key: "identity_bank_account_number" },
		{ label: "Bank Name", key: "identity_bank_name" },
		{ label: "Bank Location", key: "identity_bank_location" },
		{ label: "Bank Branch", key: "identity_bank_branch" },
		{ label: "Bank Address", key: "identity_bank_address" },
		{ label: "Account Description", key: "identity_bank_account_description" },
		{ label: "Account Reference", key: "identity_bank_account_reference" },
		{ label: "Routing Transit", key: "identity_bank_sft_routing_transit" },
		{ label: "SWIFT Code", key: "identity_bank_account_swift" },
		{ label: "IBAN", key: "identity_bank_account_iban" },
		{ label: "Currency Code", key: "identity_bank_currency_code" },
		{ label: "Currency Symbol", key: "identity_bank_currency_symbol" },
		{ label: "Account Active", key: "identity_bank_active" },
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchBankList();
	}, []);

	useEffect(() => {
		if (reRenderContactList !== null) {
			//After update couter flags and function triggers
			fetchBankList();
		}
	}, [reRenderContactList]);

	// Todo:Fetch Client Details
	const fetchBankList = () => {
		isLoading(true);
		const obj = {
			identity_uuid: identity_uuid,
			identity_bank_active: "active",
		};

		getLingoletIdBankList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setBankDetailsList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setBankDetailsList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderBankDetails =
		bankDetailsList?.length > 0 ? (
			bankDetailsList.map((data, index) => (
				<Flex p="4" borderWidth="1px" fontSize="15px" alignItems={"start"} mt="-5px" flexWrap={"wrap"} key={index} position="relative" mb="4" borderRadius={"10px"} borderColor={"#a5a5a5"} boxShadow={shadowBlack}>
					{fields.map(({ label, key }) => (
						<Flex mb="3" mr="3" key={key} w="32%">
							<Text fontWeight="bold" w="50%">
								{label}:
							</Text>
							<Text w="50%" ml="2">
								{data[key] !== undefined ? data[key]?.toString() : "No data found"}
							</Text>
						</Flex>
					))}
					<Flex
						rounded={"md"}
						p={2}
						alignItems={"center"}
						borderWidth={"1px"}
						borderColor={"gray.400"}
						align={"end"}
						cursor={"pointer"}
						onClick={() => {
							setclientData(data);
							setIdxValue(idx);
						}}
						borderRadius={"50px"}
						position={"absolute"}
						right="8px"
					>
						<Icon as={AiFillEdit} rounded={"md"} fontSize={"14px"} cursor={"pointer"} />
					</Flex>
				</Flex>
			))
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} borderWidth={"1px"} p="4" flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderBankDetails}</>
			)}
		</Flex>
	);
}

export default BankList;
