// Chakra Imports
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import {
	Box,
	Flex,
	Button,
	Icon,
    Image,
    Spacer,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
} from "@chakra-ui/react";
import AWS from "aws-sdk";

// Component
import TagsInput from "react-tagsinput";
import AudioPlayer from "../AudioPlayer";
import "react-tagsinput/react-tagsinput.css";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import KioskTranslateSelector from "./KioskTranslateSelector";
import { RiSplitCellsHorizontal, RiSplitCellsVertical } from "react-icons/ri";
import {
	AiOutlineAudio,
	AiOutlineCaretRight,
	AiOutlineAudioMuted,
	AiOutlineSave,
	AiOutlineClear,
	AiFillClockCircle,
	AiOutlineHome,
	AiOutlineMail,
	AiOutlineUserAdd,
    AiOutlineAppstore,
    AiOutlineInfoCircle
} from "react-icons/ai";

// API
import { basicAiCallSetupEx, callCompletedEx } from "../../../../services/dynamicPageSvc";
import { lingoEmailTranscript, saveEmailTranscript } from "../../../../services/accountServices";
import { setHomeRouteFlag } from "../../../../store/actions/topNotificationActions";
import logo from "../../../../assets/landingPageImg/kb_logo.png";

const KioskConsumerUI = () => {
	const toast = useToast();
    const { state } = useLocation();
	const dispatch = useDispatch();
    const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const launchKey = state.launchKey ? state.launchKey : null; // this is key for dynamic_link
	const launchCode = state.launchCode ? state.launchCode : null; // code for LingoLink
	const sessionKey = state.sessionKey ? state.sessionKey : null;

	const sourceLanguageCode = state?.selectedSettings?.sourceLangCode ? state?.selectedSettings?.sourceLangCode : null;
	const targetLanguageCode = state?.selectedSettings?.targetLangCode ? state?.selectedSettings?.targetLangCode : null;
	let socket = null;

    const [bgImage, setBgImage] = useState("");
	const [dynamicLogo, setDynamicLogo] = useState(logo);

	// ----- Deepgram + Anthropic -------- //
	const [interimTranscript, setInterimTranscript] = useState("");
	const [interimConfidence, setInterimConfidence] = useState(0);
	const [recordingState, setRecordingState] = useState(null);
	const [micDisabled, setMicDisabled] = useState(true);
	const [translatedTranscript, setTranslatedTranscript] = useState([]);
	const location = useLocation();
	const [isTabActive, setIsTabActive] = useState(true);
	const [callCompleted, setCallCompleted] = useState(false);
	const sttSocketRef = useRef(null);
	const micRef = useRef(null);
	const targetLanguageRef = useRef("");
	const [spitFlag, setSplitFlag] = useState("vertical");
	// THIS ONE VARIABLE CONTROLS WHO SPEAKS
	const [activeLanguage, setActiveLanguage] = useState("source"); // 'source' or 'target' SOURCE IS SPEAKER 1, AND TARGET IS SPEAKER 2
	const activeLanguageRef = useRef(activeLanguage);
	const [skillsKeyword, setSkillsKeyword] = useState([]);
	const [invalidEmail, setInvalidEmail] = useState([]);

	const [selectedLanguage, setSelectedLanguage] = useState('Hindi');
	const [phoneNumber, setPhoneNumber] = useState('+16478855924');
	const mediaFormatRef = useRef({
		mimeType: '',
		audioEncoding: '',
		sampleRate: 0
	});

	useEffect(() => {
		activeLanguageRef.current = activeLanguage;
	}, [activeLanguage]);

	const [sourceLanguageTranscript, setSourceLanguageTranscript] = useState([]);
	const [targetLanguageTranscript, setTargetLanguageTranscript] = useState([]);
	const [communicationFragments, setCommunicationFragments] = useState([]);
	const [participants, setParticipants] = useState([]);
	const [autoSwitchLanguage, setAutoSwitchLanguage] = useState(false);
	const autoLanguageSpeakerRef = useRef(autoSwitchLanguage);

	useEffect(() => {
		autoLanguageSpeakerRef.current = autoSwitchLanguage;
	}, [autoSwitchLanguage]);

	const [targetLanguage, setTargetLanguage] = useState("");
	const [targetLanguageDisplay, setTargetLanguageDisplay] = useState(state?.selectedSettings?.targetLangCode);
	const translateInput = useRef("");
	const [buttonConnectedLoad, setButtonConnectedLoad] = useState(false);
	const intervalRef = useRef();
	const [time, setTime] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const [demoHistoryTag, setDemoHistoryTag] = useState(true);
	const [pauseFlag, setPauseFlag] = useState(true);
	const [basicAiCallUuid, setBasicAiCallUuid] = useState(null);

	// ----- Deepgram + Anthropic -------- //

	useEffect(() => {
		if (state?.selectedSettings?.sourceLangCode === "Demo" || state?.selectedSettings?.targetLangCode === "Demo") {
			setDemoHistoryTag(false);
		}
	}, [state?.selectedSettings]);

	useEffect(() => {
		if (demoHistoryTag) {
			if (targetLanguageDisplay) {
				targetLanguageRef.current = targetLanguageDisplay;
				setTargetLanguage(targetLanguageDisplay);
				setTargetLanguageDisplay(targetLanguageDisplay);
			}
			// if (sourceLanguageCode != null && callProviderCode == 21) {
			//     callSetupProcess();
			// }
		}
	}, [targetLanguageDisplay]);

	// CALL SETUP AND FINISH IS NOW DONE AT SERVER SIDE
	// const callSetupProcess = () => {
	// 	if (launchKey != null && launchCode != null && sessionKey != null && callProviderCode != null) {
	// 		let paramObj = {
	// 			feature: "bi",
	// 			provider: callProviderCode,
	// 			launchSession: sessionKey,
	// 			launchKey: launchKey,
	// 			launchCode: launchCode,
	// 			source_language: sourceLanguageCode,
	// 			target_language: targetLanguageCode,
	// 		};
	// 		basicAiCallSetupEx(paramObj)
	// 			.then(res => {
	// 				if (res?.data[0]?.status) {
	// 					setBasicAiCallUuid(res?.data[0]?.uuid);
	// 					setRecordingState(true);
	// 					console.log(recordingState);
	// 					// launch2PartySocket(res?.data[0]?.uuid);
	// 				}
	// 			})
	// 			.catch(err => {
	// 				// updateLogDetails("Unable to get call started working...");
	// 			});
	// 	}
	// };

	// const callCompletedProcess = () => {
	// 	if (launchKey != null && sessionKey != null && basicAiCallUuid != null) {
	// 		let paramObj = {
	// 			feature: "hi",
	// 			provider: callProviderCode,
	// 			callUuid: basicAiCallUuid,
	// 			launchKey: launchKey,
	// 			launchSession: sessionKey,
	// 			callLog: "none",
	// 			agentInfo: !demoHistoryTag ? "Demo Call" : "{'agent': 'Lingolet AI'}",
	// 		};
	// 		callCompletedEx(paramObj)
	// 			.then(res => {
	// 				if (res?.data[0]?.status) {
	// 					setBasicAiCallUuid(null);
	// 					setCallCompleted(true);
	// 					//Toast -> "You have completed Secure AI voice call with total call time: " + formatTime(time) + "."
	// 				}
	// 			})
	// 			.catch(err => {
	// 				// updateLogDetails("Unable to get call stopped correctly...");
	// 			});
	// 	}
	// };

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
		region: process.env.REACT_APP_AWS_REGION,
	});

	const polly = new AWS.Polly();
	const [audioFile, setAudioFile] = useState();
	const [playNowState, setPlayNowState] = useState(false);

	const convertTextToSpeech = (local_text, voiceName) => {
		if (polly != null && polly != undefined) {
			polly.synthesizeSpeech(
				{
					Text: local_text,
					OutputFormat: "mp3",
					VoiceId: voiceName ? voiceName : "Salli",
				},
				(error, data) => {
					if (error) {
						//console.log(error);
						toast(toastFunctionToaster("Something went wrong!", "error"));
					} else {
						setPlayNowState(true);
						setAudioFile(data);
						// Show toast notification on successful conversation
						// toast(toastFunctionToaster('Text converted to speech successfully!', "success"));
					}
				}
			);
		}
	};

	async function getMicrophone() {
        try {
            const supportedFormats = [
                'audio/webm;codecs=opus',
                'audio/webm',
                'audio/ogg;codecs=opus',
                'audio/ogg',
                'audio/mp4;codecs=mp4a',
                'audio/mp4',
                'audio/mpeg',
                'audio/wav',
                'audio/aac'
            ];

            console.log("Supported audio formats:");
            let selectedMimeType = '';
            for (const format of supportedFormats) {
                const isSupported = MediaRecorder.isTypeSupported(format);
                console.log(`${format}: ${isSupported}`);
                if (isSupported && !selectedMimeType) {
                    selectedMimeType = format;
                }
            }

            if (!selectedMimeType) {
                throw new Error('No supported audio format found');
            }

            console.log(`Selected MIME type: ${selectedMimeType}`);

            const audioEncoding = selectedMimeType.split(';')[0].split('/')[1];

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });

            // Create AudioContext to get sample rate
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            const sampleRate = audioContext.sampleRate;

            mediaFormatRef.current = {
                mimeType: selectedMimeType,
                audioEncoding: audioEncoding,
                sampleRate: sampleRate
            };

            console.log(`Audio encoding: ${audioEncoding}`);
            console.log(`Sample rate: ${sampleRate} Hz`);

            return new MediaRecorder(stream, { mimeType: selectedMimeType });
        } catch (error) {
            console.error("Error accessing microphone:", error);
            throw error;
        }
    }


	async function openMicrophone(microphone, socket) {
		return new Promise(resolve => {
			microphone.onstart = () => {
				console.log("client: microphone opened");
				document.body.classList.add("recording");
				resolve();
			};

			microphone.onstop = () => {
				console.log("client: microphone closed");
				if (socket.readyState === WebSocket.OPEN) {
					console.log("client: microphone stop sent");
				}
				document.body.classList.remove("recording");
			};

			microphone.ondataavailable = event => {
				// console.log("client: microphone data received");
				if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
					socket.send(event.data);
					// console.log("client: microphone data sent");
				}
			};
			microphone.start(1000);
		});
	}

	async function toggleRecording() {
		if (recordingState == null || !recordingState) {
			setRecordingState(true);
			startTimer();
			// callSetupProcess();
			launch2PartySocket();
		} else {
			setRecordingState(false);
			stopTimer();
			// callCompletedProcess();
			setParticipants([]);
			closeSocket();
			micRef.current.stop();
		}
	}

	const socketAddListener_open = async socket => {
		setButtonConnectedLoad(true);
		socket.addEventListener("open", async () => {
			setButtonConnectedLoad(false);
			console.log("client: connected to server");

			// socketRef.current.setTimeout // HOW TO SET Socket timeout if there is no Data for 1 minute
			try {
				micRef.current = await getMicrophone();
				await openMicrophone(micRef.current, sttSocketRef.current);
				socket.send(JSON.stringify({ 
                    type: "start_transcribing",
                    media_format: mediaFormatRef.current
                }));
				socket.send(
					JSON.stringify({
						type: "auto_switch_language",
						value: autoLanguageSpeakerRef.current,
					})
				);
				micRef.current.resume();
				setMicDisabled(false);
			} catch (error) {
				console.error("error opening microphone:", error);
			}
		});
	};

	useEffect(() => {
		// send the message to server if socket is open, send the current mode of autoSwitchSpeaker
		if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN) {
			sttSocketRef.current.send(
				JSON.stringify({
					type: "auto_switch_speaker",
					autoSwitchSpeaker: autoLanguageSpeakerRef.current,
				})
			);
		}
	}, [autoSwitchLanguage]);

	const add_communication_fragment = data => {
		if (data.text != undefined && data.text.trim() !== "") {
			setCommunicationFragments(t => [...t, data]);
		}
	};

	// NOTE: below unused for now, but will be used when we add chat messages
	const add_communication_polyglot = data => {
		// iterate over communication fragments and find the matching entry with communication_fragment_uuid
		// if found, update the text with the new text
		for (let i = communicationFragments.length - 1; i >= 0; i--) {
			if (communicationFragments[i].communication_fragment_uuid === data.communication_fragment_uuid) {
				if (!communicationFragments[i].polyglots) {
					communicationFragments[i].polyglots = [];
				}
				communicationFragments[i].polyglots.push(data);
			}
		}
	};

	const socketAddListener_message = async socket => {
		socket.addEventListener("message", async event => {
			const data = JSON.parse(event.data);
			if (data.type === "UtteranceEnd") {
				console.log(data.type, data["language"]);
				setInterimTranscript("");
				setInterimConfidence(0);
			} else if (data.type === "Results" && data.transcript !== "") {
				console.log(data.type, data["confidence"], data["language"], data.transcript);
				if (data.is_final) {
					console.log("Final transcript:", data);
					add_communication_fragment(data);
					if (data.participant_session && data.participant_session === sessionKey) {
						console.log("self spoken transcript", data.text);
					} else {
						// convertTextToSpeech(data.communication_polyglots[0].text);
						const audioContent = data.communication_polyglots[0].audio_content;
						const audioUrl = `data:audio/mp3;base64,${audioContent}`;
						const audio = new Audio(audioUrl);
						audio.oncanplaythrough = function () {
							console.log('Can play through audio without stopping');
							audio.play();
						};
						audio.onerror = function (e) {
							console.error('Error in playing audio', e);
						};
					}
					setInterimTranscript("");
					setInterimConfidence(0);
				} else {
					setInterimTranscript(data.text);
					setInterimConfidence(data.confidence);
				}
			} else if (data.type === "participants_info") {
				console.log("Participants info", data);
				setParticipants(data.participants);
			}
		});
	};

	const socketAddListener_close = async socket => {
		socket.addEventListener("close", () => {
			console.log("client: disconnected from server");
			setRecordingState(false);
			setMicDisabled(true);
			if (micRef != null && micRef != undefined) {
				if (micRef.current != null && micRef.current != undefined) {
					// send socket message to stop-transcribing
					socket.send(JSON.stringify({ type: "stop_transcribing" }));
					micRef.current.stop();
					micRef.current = null;
				}
			}
		});
	};

	const makeCall = (phoneNumber, selectedLanguage) => {
		
		if (sttSocketRef.current) {
			sttSocketRef.current.send(JSON.stringify({ type: "add_call_participant_request", 'phone_number': phoneNumber, 'language': selectedLanguage }));
			setSelectedLanguage('');
			setPhoneNumber('');
		}
	};

	const launch2PartySocket = callUuid => {
		console.log("Launch 2 Party Socket");
		var localCallUuid = null;
		if (basicAiCallUuid != null) {
			localCallUuid = basicAiCallUuid;
		} else {
			localCallUuid = callUuid;
		}
		console.log(
			"launch key",
			launchKey,
			"launch code",
			launchCode,
			"local call uuid",
			localCallUuid,
			"session key",
			sessionKey,
			"source language code",
			sourceLanguageCode,
			"demo history tag",
			demoHistoryTag
		);
		if (
			launchKey != null &&
			launchCode != null &&
			// && localCallUuid != null
			sessionKey != null &&
			sourceLanguageCode != null &&
			demoHistoryTag
		) {
			let socket_url = "";
			if (window.location.hostname.includes("localhost")) {
				// Suraj > "wss://lingolet.ai/api/stt";
				// socket_url = "wss://lingolet.ai/api/stt?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&langCode=" + sourceLanguageCode;
				socket_url =
					"ws://localhost:9110/api/stt_multiparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode;
				console.log("Localhost URL: ", socket_url);
			} else {
				// stt > single Party
				socket_url =
					"wss://" +
					window.location.hostname +
					"/api/stt_multiparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode;
			}
			socket = new WebSocket(socket_url);
			sttSocketRef.current = socket;
			setActiveLanguage("source");
			socketAddListener_open(socket);
			socketAddListener_message(socket);
			socketAddListener_close(socket);
		} else {
			console.log("Error in launch2PartySocket");
		}
	};

	const handleSelectedAudio = data => {
		if (data) {
			const translatedLocalText = translatedTranscript[translatedTranscript?.length - 1];
			convertTextToSpeech(translatedLocalText, data);
		}
	};

	// Todo:When Browser tab is changed close socket
	useEffect(() => {
		const handleEvent = () => {
			// const isTabActive = !document.hidden;
			// setIsTabActive(isTabActive);
			// if (!isTabActive) {
			// 	closeSocket();
			// }
		};
		const handleBeforeUnload = event => {
			closeSocket();
			event.returnValue = "";
		};
		document.addEventListener("visibilitychange", handleEvent);
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			document.removeEventListener("visibilitychange", handleEvent);
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	// Todo:When Url is changes socket is closed
	// useEffect(() => {
	//     closeSocket()
	// }, [location]);

	const closeSocket = () => {
		console.log("CLosed Socket");
		if (sttSocketRef?.current != null && sttSocketRef?.current !== undefined) {
			sttSocketRef.current.close();
		}
	};

	// Step 2: Create a function that toggles the active language
	const toggleActiveLanguage = () => {
		setActiveLanguage(prevLanguage => (prevLanguage === "source" ? "target" : "source"));
	};
	const getActiveSourceLanguageCode = () => {
		return activeLanguageRef.current === "source" ? sourceLanguageCode : targetLanguageCode;
	};
	const getActiveTargetLanguageCode = () => {
		return activeLanguageRef.current === "target" ? sourceLanguageCode : targetLanguageCode;
	};
	useEffect(() => {
		// Check if the socket is defined and open
		if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN) {
			// Send a message to the socket
			sttSocketRef.current.send(
				JSON.stringify({
					type: "activate_language",
					language: getActiveSourceLanguageCode(),
				})
			);
		}
	}, [activeLanguage]);

	// ------ Code main Starts here

	// Time Start Stop Function
	const startTimer = () => {
		resetTimer();
		if (!isRunning) {
			setIsRunning(true);
			intervalRef.current = setInterval(() => {
				setTime(prevTime => prevTime + 1);
			}, 1000);
		}
	};

	const stopTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		// Todo:Once call is hangup reset timer
	};

	const resetTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		setTime(0);
	};

	const formatTime = timeInSeconds => {
		const minutes = Math.floor(timeInSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
		return `${minutes}:${seconds}`;
	};
	// ------ Code main Ends here

	//   Todo:Set home button route to primary page by updating redux value to dynamic home
	const handleHomeRouteFlag = () => {
		dispatch(setHomeRouteFlag(true));
	};

	//   Todo:Play Pause function
	const pauseTranslationCall = () => {
		if (pauseFlag) {
			micRef.current.pause();
			setPauseFlag(!pauseFlag);
		} else {
			setMicDisabled(true);
			micRef.current.resume();
			setPauseFlag(!pauseFlag);
		}
	};

	const saveTranscript = () => {
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode}${targetLanguageCode}`,
			transcript_text: JSON.stringify(communicationFragments),
			call_uuid: "",
		};
		saveEmailTranscript(letObj)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const emailTranscript = () => {
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode}${targetLanguageCode}`,
			transcript_text: JSON.stringify(communicationFragments),
			call_uuid: "",
			emails: skillsKeyword.join(", "),
		};
		lingoEmailTranscript(letObj)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const onTagChange = tags => {
		setSkillsKeyword(tags);
	};

	const validateEmails = emails => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emails.filter(email => !emailRegex.test(email));
	};

    const handleHomeClick = () => {
		navigate("/", {
            state: {
                route: 'kisokai'
            },
        });
	}


	useEffect(() => {
		setInvalidEmail(validateEmails(skillsKeyword));
	}, [skillsKeyword]);

	useEffect(() => {
		const transformedData = communicationFragments.map(item => {
			const englishText = item.text;
			const sourceLang = item.communication_polyglots[0].language;
			const hindiText = item.communication_polyglots[0].text;
			return { [item.language]: englishText, sourceLang, hindiText };
		});
		console.log(transformedData, "transformedData");
	}, [communicationFragments]);


	return (
		<>
        <Box w={"100%"} h="100%" overflow={"auto"}>
            {launchKey == null || sessionKey == null  || launchCode == null ? (
                <>
                    <Flex w="100%" h="100%" flexWrap={"wrap"}>
                        <Text>Error</Text>
                    </Flex>
                </>
            ) : (
                <Flex
						w={"100%"}
						h="100%"
						direction={"column"}
						align={"center"}
						backgroundImage={bgImage}
						backgroundPosition="center"
						backgroundRepeat="no-repeat"
						backgroundSize={"100% 100%"}
					>
						<Flex w={"100%"} borderBottomWidth={"2px"} bg={"gray.700"} py={2} align={"center"}>
							<Image
								maxH={"50px"}
								w={{
									base: "100px",
									md: "100px",
									xl: "100px",
									sm: "100px",
								}}
								src={dynamicLogo}
							/>
							<Spacer />
							{launchKey != null && launchCode != null && sessionKey != null ? (
								<Icon
									onClick={() => {
										navigate("/lingolink/management", {
											state: {
												linkKey: launchKey,
												linkCode: launchCode,
												linkSession: sessionKey,
											},
										});
									}}
									ml={2}
									mr={2}
									h={5}
									w={5}
									as={AiOutlineAppstore}
									color={"white"}
								/>
							) : null}
							<Icon onClick={handleHomeClick} ml={2} mr={2} h={5} w={5} as={AiOutlineHome} color="white" />
							<Icon onClick={onOpen} ml={2} h={5} w={5} as={AiOutlineInfoCircle} color={"white"} />
						</Flex>
						<Flex w="100%" h="100%" alignItems={"center"} justifyContent={"center"}>
                            <Flex w="100%" justifyContent={"center"}>
                                <Flex borderRadius={"10px"} color="#fff" alignItems={"center"} bg="#000" p="2">
                                    {time > 0 ? (
                                        <>
                                            <Flex
                                                py={1}
                                                ml={1}
                                                rounded={"md"}
                                                minW={"80px"}
                                                maxW={"80px"}
                                                bg={"#bee3f8"}
                                                color="#000"
                                                align={"center"}>
                                                <Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
                                                {formatTime(time)}
                                            </Flex>
                                        </>
                                    ) : null}
                                    <Flex w={'100%'}>
                                        <Box p={1}>
                                            <KioskTranslateSelector
                                                targetLanguage={targetLanguage}
                                                returnSelectedAudioType={handleSelectedAudio}
                                            />
                                        </Box>
                                    </Flex>
                                    {recordingState && (
                                        <>
                                            <Button
                                                size={{
                                                    base: "xs",
                                                    md: "sm",
                                                    lg: "sm",
                                                    xl: "sm",
                                                }}
                                                p="10px"
                                                borderRadius={"5px"}
                                                mr="1"
                                                // onClick={toggleActiveLanguage}
                                                color="#000">
                                                {sourceLanguageCode}
                                            </Button>
                                            <Flex w={'50px'} align={'center'} px={2} m={1} bg="gray.100" color="gray.700" rounded={'lg'}>
                                                <Icon as={AiOutlineUserAdd} h={5} w={5} />
                                                <Text fontSize={'xl'}>
                                                    {participants.length}
                                                </Text>
                                            </Flex>
                                        </>
                                    )}
                                    <Flex w={'100%'} borderWidth={"0.5px"} borderRadius={"5px"} bg="#000" mr="1px">
                                        {spitFlag === "horizontal" ? (
                                            <Icon
                                                w="40px"
                                                cursor={"pointer"}
                                                fontSize="30px"
                                                color="#42aa42"
                                                as={RiSplitCellsHorizontal}
                                                onClick={() => {
                                                    setSplitFlag("vertical");
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                w="40px"
                                                cursor={"pointer"}
                                                fontSize="30px"
                                                color="#42aa42"
                                                as={RiSplitCellsVertical}
                                                onClick={() => {
                                                    setSplitFlag("horizontal");
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Box w={'100%'} overflow={"hidden"} bgColor="#252628">
                            <Box w={'100%'} bg="#fff" h="calc(100vh - 240px)">
                                <AudioPlayer playNow={playNowState} audioFile={audioFile} />
                                <Flex justifyContent={"space-between"} flexWrap="wrap" h="100%" border="1px solid #efefef">
                                    <Box
                                        w={{
                                            base: spitFlag === "vertical" ? "49%" : "100%",
                                            md: spitFlag === "vertical" ? "49%" : "100%",
                                            lg: spitFlag === "vertical" ? "49%" : "100%",
                                            xl: spitFlag === "vertical" ? "49%" : "100%",
                                        }}
                                        borderRightWidth={"1px"}
                                        p="10px"
                                        borderColor="#efefef"
                                        borderBottomWidth={spitFlag === "horizontal" ? "1px" : "0px"}>
                                        <Box
                                            h="80%"
                                            overflow="auto"
                                            className="custom-scrollbar"
                                            mt="3">
                                            <Text color="#000" fontSize={"14px"}>
                                                {communicationFragments?.map((item, index) => (
                                                    <Flex key={index} direction={spitFlag === "horizontal" ? "column" : "row"}>
                                                        {(item.language === sourceLanguageCode || spitFlag === "horizontal") && (
                                                            <Text>
                                                                <Icon mr="1" as={AiOutlineCaretRight} />
                                                                {item.language}: {item.text} 
                                                                {item.confidence && typeof item.confidence === 'number' ? 
                                                                    ` (${(item.confidence * 100).toFixed(2)}%)` : 
                                                                    ''
                                                                }
                                                            </Text>
                                                        )}
                                                        {(item.language === sourceLanguageCode || spitFlag === "horizontal") &&
                                                            item.communication_polyglots?.map(
                                                                (polyglot, index) =>
                                                                    (polyglot.language !== sourceLanguageCode ||
                                                                        spitFlag === "horizontal") && (
                                                                        <Text key={index} direction="row">
                                                                            {spitFlag !== "vertical" && (
                                                                                <span>
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                                                                    <Icon mr="1" as={AiOutlineCaretRight} />
                                                                                </span>
                                                                            )}
                                                                            {polyglot.language}: {polyglot.text}
                                                                        </Text>
                                                                    )
                                                            )}
                                                    </Flex>
                                                ))}
                                            </Text>
                                            {(activeLanguage === "source" || spitFlag === "horizontal") && interimTranscript ? (
                                                <Text color="#c6d704" fontSize={"14px"}>
                                                    {interimTranscript} - {interimConfidence}
                                                </Text>
                                            ) : null}
                                        </Box>
                                    </Box>
                                    <Box
                                        w={{
                                            base: spitFlag === "vertical" ? "49%" : "100%",
                                            md: spitFlag === "vertical" ? "49%" : "100%",
                                            lg: spitFlag === "vertical" ? "49%" : "100%",
                                            xl: spitFlag === "vertical" ? "49%" : "100%",
                                        }}
                                        p="10px">
                                        <Box
                                            // h="calc(100vh - 175px)"
                                            h="80%"
                                            overflow="auto"
                                            className="custom-scrollbar"
                                            mt="3">
                                            {spitFlag == "vertical" && (
                                                <Text color="#000" fontSize={"14px"}>
                                                    {communicationFragments?.map((item, index) => (
                                                        <Flex key={index} direction={spitFlag === "vertical" ? "column" : "row"}>
                                                            {(item.language !== sourceLanguageCode ||
                                                                spitFlag === "horizontal") && (
                                                                    <Text>
                                                                        <Icon mr="1" as={AiOutlineCaretRight} />
                                                                        {item.language}: {item.text}
                                                                        {item.confidence && typeof item.confidence === 'number' ? 
                                                                            ` (${(item.confidence * 100).toFixed(2)}%)` : 
                                                                            ''
                                                                        }
                                                                </Text>
                                                            )}
                                                            {item.language !== sourceLanguageCode &&
                                                                item.communication_polyglots?.map(
                                                                    (polyglot, index) =>
                                                                        (polyglot.language === sourceLanguageCode ||
                                                                            spitFlag === "horizontal") && (
                                                                            <Text key={index} direction="row">
                                                                                {spitFlag === "vertical" && (
                                                                                    <span>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                                                                        <Icon mr="1" as={AiOutlineCaretRight} />
                                                                                    </span>
                                                                                )}
                                                                                {polyglot.language}: {polyglot.text}
                                                                            </Text>
                                                                        )
                                                                )}
                                                        </Flex>
                                                    ))}
                                                </Text>
                                            )}
                                            {activeLanguage === "target" && spitFlag !== "horizontal" && interimTranscript ? (
                                                <Text color="#c6d704" fontSize={"14px"}>
                                                    {interimTranscript} - {interimConfidence}
                                                </Text>
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        {callCompleted ? (
                            <Flex alignItems={"center"} justifyContent={"center"} bg={"red.50"} p={1}>
                                <Button
                                    size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
                                    p="10px"
                                    borderRadius={"5px"}
                                    mr="2"
                                    onClick={e => {
                                        saveTranscript();
                                    }}
                                    colorScheme="green"
                                    leftIcon={<Icon as={AiOutlineSave} />}>
                                    <Text
                                        as="span"
                                        display={{
                                            base: "none",
                                            md: "block",
                                            lg: "block",
                                            xl: "block",
                                        }}>
                                        Save
                                    </Text>
                                </Button>

                                <Button
                                    size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
                                    p="10px"
                                    borderRadius={"5px"}
                                    mr="2"
                                    onClick={e => {
                                        onOpen();
                                    }}
                                    colorScheme="cyan"
                                    leftIcon={<Icon as={AiOutlineMail} />}>
                                    <Text
                                        as="span"
                                        display={{
                                            base: "none",
                                            md: "block",
                                            lg: "block",
                                            xl: "block",
                                        }}>
                                        Email
                                    </Text>
                                </Button>
                                <Button
                                    size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
                                    p="10px"
                                    borderRadius={"5px"}
                                    mr="2"
                                    onClick={e => {
                                        setCommunicationFragments([]);
                                        setInterimTranscript("");
                                        setInterimConfidence(0);
                                    }}
                                    colorScheme="teal"
                                    leftIcon={<Icon as={AiOutlineClear} />}>
                                    <Text
                                        as="span"
                                        display={{
                                            base: "none",
                                            md: "block",
                                            lg: "block",
                                            xl: "block",
                                        }}>
                                        Clear
                                    </Text>
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleHomeRouteFlag();
                                    }}
                                    borderRadius={"5px"}
                                    size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
                                    p="10px"
                                    colorScheme="pink">
                                    <Icon as={AiOutlineHome} />
                                </Button>
                            </Flex>
                            ) : (
                            <Flex alignItems={"center"} justifyContent={"center"} bg={"red.50"} p={1}>
                                {/* {basicAiCallUuid != null */}
                                <Button
                                    size={{ base: "xs", md: "sm", lg: "sm", xl: "sm" }}
                                    p="10px"
                                    mr="3"
                                    borderRadius={"5px"}
                                    onClick={() => {
                                        setRecordingState(true);
                                        toggleRecording();
                                    }}
                                    colorScheme={recordingState ? "red" : "green"}
                                    // isDisabled={micDisabled || !targetLanguageRef.current}
                                    isDisabled={buttonConnectedLoad}
                                    leftIcon={<Icon as={recordingState ? AiOutlineAudioMuted : AiOutlineAudio} />}>
                                    <Text
                                        as="span"
                                        display={{
                                            base: "none",
                                            md: "block",
                                            lg: "block",
                                            xl: "block",
                                        }}>
                                        {recordingState ? "Stop " : "Start "} Call
                                    </Text>
                                </Button>
                                {/* :<Text p={2}>Call Completed</Text>} */}
                                {recordingState && (

                                    <>
                                        <Button
                                            size={{
                                                base: "xs",
                                                md: "sm",
                                                lg: "sm",
                                                xl: "sm",
                                            }}
                                            p="10px"
                                            borderRadius={"5px"}
                                            mr="3"
                                            colorScheme={pauseFlag ? "red" : "green"}
                                            leftIcon={<Icon as={pauseFlag ? AiOutlineAudioMuted : AiOutlineAudio} />}
                                            onClick={() => {
                                                pauseTranslationCall();
                                            }}>
                                            {pauseFlag ? "Pause" : "Resume"}
                                        </Button>
                                        <Flex direction="row" mt={2}>
                                            <Input
                                                placeholder="Enter language code"
                                                value={selectedLanguage}
                                                onChange={(e) => setSelectedLanguage(e.target.value)}
                                                mb={2}
                                            />
                                            <Input
                                                placeholder="Enter phone number"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                mb={2}
                                            />
                                            <Button
                                                colorScheme="blue"
                                                onClick={() => {
                                                    if (selectedLanguage && phoneNumber) {
                                                        // Add your call logic here
                                                        makeCall(phoneNumber, selectedLanguage);
                                                    }
                                                }}>
                                                Make Call
                                            </Button>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        )}
                    </Box>
                </Flex>
            )}
            </Box>
            {/* Popup Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Send Email</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TagsInput
                            value={skillsKeyword}
                            onChange={onTagChange}
                            inputProps={{ placeholder: "Enter Email" }}
                        />
                        <Flex flexWrap="wrap" alignItems={"center"} mt="2">
                            {invalidEmail.map((email, index) => (
                                <Text p="1" mr="1" bg="red.100" color="#000" size="sm" key={index}>
                                    {email}
                                </Text>
                            ))}
                            {invalidEmail?.length ? <Text>Not Valid Email </Text> : null}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="green"
                            mr={3}
                            onClick={() => {
                                emailTranscript();
                            }}
                            isDisabled={invalidEmail?.length && !skillsKeyword?.length ? true : false}>
                            Send Mail
                        </Button>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {recordingState && (
                <Flex direction="row">
                    <Box p={1} m={1} bg="#000" color="#fff" borderRadius="5px">
                        Participants: {participants.length}
                    </Box>
                    <Flex direction="row">
                        {participants.map((participant, index) => {
                            return (
                                <Box key={index} p={1} m={1} bg="#000" color="#fff" borderRadius="5px">
                                    | &nbsp;&nbsp;	{participant.is_phone_call_participant 
                                        ? `${participant.phone_number} (${participant.call_status}) - ${participant.language}`
                                        : `${participant.participant_session.slice(-5)} - ${participant.language}`
                                    }
                                </Box>
                            );
                        })}
                    </Flex>
                </Flex>
            )}
		</>
	);
};

export default KioskConsumerUI;
