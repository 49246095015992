import React, { useEffect, useState } from "react";

import { Heading, Button, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
// import theme from "./theme";
// import HeroCarousel from "./HeroCarausel";

import lsplogo from "../../assets/landingPageImg/lspcloud_logo-wide.png";

import lspLanding from "../../assets/landingPageImg/lspLanding.jpg";
import Footer from "../../components/footer/FooterAdmin";

function LspLandingPage() {
	const current_logo = lsplogo;

	// const currentHost =  window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";

	const [scrollPosition, setScrollPosition] = useState(0);

	//   Todo:On Scroll Change Backgrounf
	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			setScrollPosition(position);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const pageContent = () => {
		return "LSP Cloud";
	};

	const backgroundColor = scrollPosition > window.innerHeight ? "white" : "gray.900";
	const boxShadow = scrollPosition > window.innerHeight ? "0px 3px 9px 1px #a6a6a6" : "";

	return (
		<Box bgImage={lspLanding} bgPosition="center" bgRepeat="no-repeat" bgSize="cover" h="100vh" fontFamily={"DM Sans"}>
			<Box mx="auto" p="4" position={"fixed"} w="100%" zIndex={"4"} boxShadow={boxShadow} bg={backgroundColor} transition="background-color 0.5s ease" display={{ base: "none", md: "none", lg: "block", sm: "none" }}>
				<Flex className="header" justifyContent={"space-between"} alignItems={"center"}>
					<Box w={{ base: "20%", md: "20%", lg: "10%", sm: "20%" }}>
						<Image w="100%" src={current_logo} />
					</Box>
					<Box w={{ base: "30%", md: "30%", lg: "65%", sm: "30%" }} textAlign={"end"}></Box>
					<Link href="/login">
						<Button bg={"blue.200"}>Parter Login</Button>
					</Link>
				</Flex>
			</Box>
			<Box mx="auto" p="4" w="100%" bg={backgroundColor} zIndex={"4"} display={{ base: "block", md: "block", lg: "none", sm: "block" }}>
				<Flex className="header" justifyContent={"space-between"} alignItems={"center"}>
					<Box w={{ base: "30%", md: "20%", lg: "10%", sm: "30%" }}>
						<Image w="100%" src={current_logo} />
					</Box>
					<Box w={{ base: "70%", md: "40%", lg: "25%", sm: "70%" }}>
						<Flex w="100%" justifyContent={"space-between"} alignItems={"center"}></Flex>
					</Box>
					<Box w={{ base: "0%", md: "40%", lg: "40%", sm: "0%" }} textAlign={"end"} display={{ base: "none", md: "block", lg: "none", sm: "none" }}></Box>
				</Flex>
			</Box>
			<Box w={{ base: "90%", md: "90%", lg: "60%", sm: "90%" }} mx="auto" pt={{ base: "1rem", md: "1rem", lg: "7rem", sm: "1rem" }} textAlign="center">
				<Flex className="heading" alignItems={"center"} direction={"column"} mt="5rem">
					<Heading color={"white"} fontWeight={"500"} fontSize={{ base: "2rem", md: "4rem", lg: "6rem", sm: "2rem" }}>
						{pageContent()}
					</Heading>
					<Heading fontWeight={"500"} fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}>
						<Text fontWeight={"400"} as="span" color={"white"} fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}>
							Technology Partner for
						</Text>
					</Heading>
					<Heading color={"white"} fontWeight={"400"} fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}>
						Language Services Delivery Worldwide
					</Heading>
					<Button w="100px" borderRadius={"10px"} colorScheme="orange" mt="4" size="md">
						Contact
					</Button>
				</Flex>
			</Box>
			<Box position="absolute" w="100%" bottom="0" bg="#000">
				<Footer padding={"10px"} />
			</Box>
		</Box>
	);
}

export default LspLandingPage;
