// React Chakra Imports
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { Box, Flex, Text, Menu, Grid, GridItem, MenuButton, MenuList, Button } from "@chakra-ui/react";

const AIPlatform = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Menu isOpen={isMenuOpen} onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
			<MenuButton as={Button} width="100%" bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }} fontSize={"14px"} pr="0">
				<Box 
					fontSize={'md'}
					display={"inline-flex"} alignItems={"center"} gap={1} cursor="pointer" color={isMenuOpen ? "#259edc" : "#000"}>
					AI Platform {isMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
				</Box>
			</MenuButton>
			<MenuList bg={"white"} 
				//ml="-8%" 
				borderRadius={"0"}>
				<Flex h="20vh" w={"100vw"} p="5px" 
					//</MenuList>alignItems="center" justifyContent={"center"}
					>
					<Flex w={'100%'} direction={'column'} mx={10}>
						<Grid
							templateColumns={{
								sm: "repeat(1, 1fr)",
								md: "repeat(4, 1fr)",
								lg: "repeat(4, 1fr)",
							}}
							gap={10}
						>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Scalable SaaS Platform</Text>
								<Text fontSize={'sm'}>
									Lingolet AI platform is designed to run in cloud along with various scalable services 
									to support small to large LSP customer requerement.  
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Cloud Agnostic Deployment</Text>
								<Text fontSize={'sm'}>
									Lingolet AI platform is designed to run on any cloud (AWS, Azure, Google) with a little
									configuration changes. It can be deployed under a week for a very large LSP rquirement. 
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>LSPCloud.ai SaaS Platform</Text>
								<Text fontSize={'sm'}>
									While scalable deployment is a feature for large LSP customer, small and medium size customers
									can be part of LSPcloud.ai SaaS platform to manage overall business.
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Businesses & Customers</Text>
								<Text fontSize={'sm'}>
									Lingolet AI platform can be used by business directly to provide support for their team and customers 
									without a need of LSP in the middle. Please contact Lingolet Sales for more details.
								</Text>
							</GridItem>

						</Grid>
					</Flex>
				</Flex>
			</MenuList>
		</Menu>
	);
};

export default AIPlatform;
