// React Chakra Imports
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { Box, Flex, Text, Menu, MenuButton, MenuList, Button } from "@chakra-ui/react";

const Resources = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Menu isOpen={isMenuOpen} onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
			<MenuButton as={Button} width="100%" bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }} fontSize={"14px"}>
				<Box 					
					fontSize={'md'}
					display={"inline-flex"} alignItems={"center"} gap={1} cursor="pointer" color={isMenuOpen ? "#259edc" : "#000"}>
					Resources {isMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
				</Box>
			</MenuButton>
			<MenuList bg={"white"} 
				//ml="-8%" 
				borderRadius={"0"}>
				<Flex h="20vh" w={"100vw"} p="20px" alignItems="center" justifyContent={"center"}>
					<Text fontWeight="500">Resources</Text>
				</Flex>
			</MenuList>
		</Menu>
	);
};

export default Resources;
