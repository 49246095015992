import React from "react";
import { Route } from "react-router-dom";
import OthersError from "../views/admin/main/others/404";
import "../assets/css/App.css";

import ChangeDummyPassword from "../komponents/userauth/changepassword/ChangeDummyPassword";
import ForgotPassword from "../views/auth/forgotPassword/ForgotPasswordCentered";
import CookiePolicy from "../komponents/landinghome/CookiePolicy";
import TermsOfUse from "../komponents/landinghome/TermsOfUse";
import EULA from "../komponents/landinghome/EULA";
import PrivacyPolicy from "../komponents/landinghome/PrivacyPolicy";
import DMCAPolicy from "../komponents/landinghome/DMCAPolicy";
import GPLCompliance from "../komponents/landinghome/GPLCompliance";
import VerificationCentered from "../komponents/userauth/verification/VerificationCentered";
import RegisterBusinessUser from "../komponents/userauth/register/RegisterBizUser";
import LingoletOneMetaDemo from "../komponents/admin/demo/LingoletOneMetaDemo";

// Layout and Menu
import AdminLayouts from "../sideBarRoutes/AdminLayouts";
import BusinessLayouts from "../sideBarRoutes/BusinessLayouts";
import LanguageAssociateLayouts from "../sideBarRoutes/LanguageAssociateLayouts";
import AccountLayouts from "../sideBarRoutes/AccountLayouts";
import SubscriberLayouts from "../sideBarRoutes/SubscriberLayouts";

// Other Pages
import HybridAppPage from "../komponents/landinghome/HybridAppPage";
import PhoneException from "../komponents/common/exception/PhoneException";
import TeamPage from "../komponents/landinghome/Team";
import DummyAppDemo from "../komponents/admin/demo/DummyAppDemo";
import EventFeedback from "../komponents/admin/demo/EventFeedback";
import WorkflowError from "../komponents/landinghome/WorkflowError";

// LingoLink Home
import DynamicLauncher from "../komponents/DynamicLink/DynamicLauncher";
import DynamicHome from "../komponents/DynamicLink/DynamicHome";
import LinkManagVerify from "../komponents/DynamicLink/management/LinkManageVerify";
import KioskLauncher from "../komponents/DynamicLink/KioskLauncher";
import KioskConsumerUI from "../komponents/DynamicLink/components/KioskAI/KioskConsumerUI";

// Media Kit
import CESMediaKit from "../komponents/mediakit/CESMediaKit";
import HomeLandingPage from "../komponents/landinghome/HomeLandingPage";
import Countdown from "../komponents/landinghome/Countdown";
import LspLogin from "../komponents/userauth/signIn/LspLogin";
import LspLandingPage from "../komponents/landinghome/LspLandingPage";
import DocumentControlAccess from "../komponents/common/DocumentControlAccess/DocumentControlAccess";
import LspLandingPageNew from "../komponents/landinghome/LspLandingPageNew";
import UpdatePrefrences from "../komponents/common/prefrence/UpdatePrefrences";

const useLingoletRoutes = (currentTheme, setCurrentTheme) => {
	return (
		<>
			<Route path="admin/*" element={<AdminLayouts theme={currentTheme} setTheme={setCurrentTheme} />} />
			<Route path="business/*" element={<BusinessLayouts theme={currentTheme} setTheme={setCurrentTheme} />}></Route>
			<Route path="languageassociate/*" element={<LanguageAssociateLayouts theme={currentTheme} setTheme={setCurrentTheme} />} />
			<Route path="account/*" element={<AccountLayouts theme={currentTheme} setTheme={setCurrentTheme} />} />
			<Route path="subscriber/*" element={<SubscriberLayouts theme={currentTheme} setTheme={setCurrentTheme} />} />
			{/* <Route path="/module" element={<ModuleWidget />} /> */}
			<Route index path="/dmca-policy" element={<DMCAPolicy />} />
			<Route index path="/cookie-policy" element={<CookiePolicy />} />
			<Route index path="/terms-of-use" element={<TermsOfUse />} />
			<Route index path="/gpl-compliance" element={<GPLCompliance />} />
			<Route index path="/eula" element={<EULA />} />
			<Route index path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/cesdemo" index element={<LingoletOneMetaDemo />} />
			<Route path="/aiapp" index element={<DummyAppDemo />} />
			<Route path="/eventfeedback" index element={<EventFeedback />} />
			<Route path="/WorkflowFailed" index element={<WorkflowError />} />
			<Route path="/lingolink" index element={<DynamicLauncher />} />
			<Route path="/lingolink/apps" index element={<DynamicHome />} />
			<Route path="/lingolink/management" index element={<LinkManagVerify />} />
			<Route path="/kioskai" index element={<KioskLauncher />} />
			<Route path="/kioskai/consumer" index element={<KioskConsumerUI />} />

			<Route path="/exception/phone" index element={<PhoneException />} />
			<Route path="/hybridapp" index element={<HybridAppPage />} />
			<Route path="/team" index element={<TeamPage />} />
			<Route path="/cesmediakit" index element={<CESMediaKit />} />
			{/* <Route path="/" index element={<LandingPage />} /> */}
			<Route path="/newpage" index element={<HomeLandingPage />} />
			<Route path="*" element={<OthersError />} />

			{/* Lingolet Route */}
			<Route
				path="/"
				index
				element={
					window.location.hostname === "lspcloud.ai" ? (
						<LspLandingPage />
					) : window.location.hostname === "lingolet.ai" ? (
						<LspLandingPageNew />
						// <Countdown />
					) : (
						// <LspLandingPage />
						<LspLandingPageNew />
					)
				}
			/>

			{/* LspCloud Route */}
			{/* <Route path="/" element={<LspLogin />} /> */}
			<Route path="/login" element={<LspLogin />} />
			{/* <Route path="/login" element={<SignInCentered />} /> */}
			<Route path="/forgot-password" element={<ForgotPassword />} />
			{/* Blocked for now */}
			<Route path="/registerbizuser" element={<RegisterBusinessUser />} />
			<Route path="/verifyEmail" element={<VerificationCentered />} />
			<Route path="/changeDefaultPassword" element={<ChangeDummyPassword />} />

			<Route path="/translation/documents/*" element={<DocumentControlAccess />} />
			<Route path="/public/preference/update" element={<UpdatePrefrences/>}/>
		</>
	);
};

export default useLingoletRoutes;
