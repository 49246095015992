// React + Chakra Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Flex, Text, Alert, AlertIcon, Spinner, useToast, Icon, useColorModeValue } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getLingoletIdCompanyList } from "../../../../../../services/accountServices";
import AddressList from "../address/AddressList";
import AddUpdateAdressDetails from "../address/AddUpdateAdressDetails";

function CompanyList({ identity_uuid, setclientData, reRenderContactList, idx, setIdxValue }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [taxDetailsList, setTaxDetailsList] = useState([]);
	const [addressDetails, setAddressDetails] = useState(null);
	const [updateAddCounter, setUpdateAddCounter] = useState(null);
	const [selectedAddIndex, setSelectedAddIndex] = useState(null);

	const shadowBlack = useColorModeValue("0px 3px 5px rgb(107 111 110)", "0px 3px 5px rgb(107 111 110);");

	const fields = [
		{ label: "Company Name", key: "identity_company_name" },
		{ label: "Company Type", key: "identity_company_type" },
		{ label: "URL", key: "identity_company_url" },
		{ label: "Functions", key: "identity_company_function" },
		{ label: "Industry", key: "identity_company_industry" },
		{ label: "Phone Number", key: "identity_company_phone" },
		{ label: "Email", key: "identity_company_email" },
		{ label: "Contact", key: "identity_company_contact" },
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchCompanyDetails();
	}, []);

	useEffect(() => {
		if (reRenderContactList !== null) {
			//After update couter flags and function triggers
			fetchCompanyDetails();
		}
	}, [reRenderContactList]);

	// Todo:Fetch Client Details
	const fetchCompanyDetails = () => {
		isLoading(true);
		const obj = {
			identity_uuid: identity_uuid,
			identity_company_active: "active",
		};

		getLingoletIdCompanyList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setTaxDetailsList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setTaxDetailsList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderCompanyDetails =
		taxDetailsList?.length > 0 ? (
			taxDetailsList.map((data, index) => (
				<>
					<Flex flexWrap={"wrap"} p="4" key={data?.identity_uuid + index} borderWidth="1px" alignItems={"start"} fontSize="15px" mb="4" borderRadius={"10px"} borderColor={"#a5a5a5"} position={"relative"} boxShadow={shadowBlack}>
						{fields.map(({ label, key }) => (
							<Flex mb="3" mr="3" key={key} w="45%">
								<Text fontWeight="bold" w="50%">
									{label}:
								</Text>
								<Text w="50%" ml="2">
									{data[key] !== undefined ? data[key]?.toString() : "No data found"}
								</Text>
							</Flex>
						))}
						<Flex rounded={"md"} p={2} alignItems={"center"} align={"end"} cursor={"pointer"} position={"absolute"} right="8px" pr="0">
							<AddUpdateAdressDetails identity_uuid={identity_uuid} companyuUId={data?.identity_company_uuid} passClientData={addressDetails} setAddressData={setAddressDetails} updateClientDataCounter={setUpdateAddCounter} idValue={selectedAddIndex} indexValue={index} />
						</Flex>
						<Flex
							rounded={"md"}
							p={"6px"}
							alignItems={"center"}
							borderWidth={"1px"}
							borderColor={"gray.400"}
							align={"end"}
							cursor={"pointer"}
							onClick={() => {
								setclientData(data);
								setIdxValue(idx);
							}}
							borderRadius={"50px"}
							position={"absolute"}
							right="8px"
							top="60px"
						>
							<Icon as={AiFillEdit} rounded={"md"} fontSize={"14px"} cursor={"pointer"} />
						</Flex>
						<AddressList identity_uuid={identity_uuid} companyData={data} setclientData={setAddressDetails} idx={index} setIdxValue={setSelectedAddIndex} reRenderContactList={updateAddCounter} />
					</Flex>
				</>
			))
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} borderWidth={"1px"} p="4" flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderCompanyDetails}</>
			)}
		</Flex>
	);
}

export default CompanyList;
