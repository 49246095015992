// Chakra Imports
import Marquee from "react-fast-marquee";
import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Heading,
  Img,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { AiFillAppstore, AiOutlineDown } from "react-icons/ai";

// Component Import
import lingologo from "../../assets/landingPageImg/kb_logo.png";
import lsplogo from "../../assets/landingPageImg/lspcloud_logo-wide.png";

import demo1Image from "../../assets/landingPageImg/new_landing_img/lingolet-platform.png";
import LandingBgImage from "../../assets/landingPageImg/new_landing_img/demoBg_Img.png";
import Hubspot from "../../assets/landingPageImg/new_landing_img/partners/hubspot.svg";
import Clearbit from "../../assets/landingPageImg/new_landing_img/partners/clearbit.png";
import Salesforce from "../../assets/landingPageImg/new_landing_img/partners/salesforce-01.svg";
import Download from "../../assets/landingPageImg/new_landing_img/partners/download.png";
import Bombara from "../../assets/landingPageImg/new_landing_img/bombora.svg";

const HomeLandingPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const current_logo = window.location.hostname.includes('lspcloud') ? lsplogo : lingologo;

  const images = [
    Hubspot,
    Clearbit,
    Download,
    Bombara,
    Salesforce,
    Hubspot,
    Clearbit,
    Download,
    Bombara,
    Salesforce,
  ];

  //   Todo:Parter Marquee Component
  const renderPartnerSlider = () => {
    return (
      <>
        <Marquee gradient="true">
          {images.map((image, index) => (
            <Box h="100px" w="100px" mr="2rem">
              <Image
                key={index}
                src={image}
                w="100%"
                h="100%"
                objectFit="contain"
              />
            </Box>
          ))}
        </Marquee>
      </>
    );
  };

  //   Todo:AI Power Tab Component
  const renderTabData = () => {
    return (
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList
          justifyContent={"center"}
          background="#fff"
          width="fit-content"
          mx="auto"
          p="5px"
          borderRadius="50px"
          flexWrap={"wrap"}
        >
          <Tab>Discover</Tab>
          <Tab>Enrich</Tab>
          <Tab>Intent </Tab>
          <Tab>Outreach </Tab>
          <Tab>Predict </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex
              mt={{ base: "1.5rem", md: "4rem", lg: "4rem", sm: "1.5rem" }}
              flexWrap={"wrap"}
            >
              <Box w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}>
                <Text
                  fontSize={{
                    base: "1.5rem",
                    md: "2rem",
                    lg: "2rem",
                    sm: "1.5rem",
                  }}
                  fontWeight="600"
                >
                  Discover
                </Text>
                <Text
                  fontSize={{
                    base: "16px",
                    md: "18px",
                    lg: "18px",
                    sm: "16px",
                  }}
                  w={{ base: "90%", md: "70%", lg: "70%", sm: "90%" }}
                  fontWeight={"500"}
                  my="2rem"
                >
                  Sprouts AI/ML engine helps you discover advanced Ideal
                  Customer Profile (ICP) variables and create a hyper-precise
                  target account list.
                </Text>
                <Button
                  size={{ base: "md", md: "lg", lg: "lg", sm: "md" }}
                  borderRadius={"10px"}
                  colorScheme="purple"
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}
                mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box>
                  <Img src={demo1Image} />
                </Box>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              mt={{ base: "1.5rem", md: "4rem", lg: "4rem", sm: "1.5rem" }}
              flexWrap={"wrap"}
            >
              <Box w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}>
                <Text
                  fontSize={{
                    base: "1.5rem",
                    md: "2rem",
                    lg: "2rem",
                    sm: "1.5rem",
                  }}
                  fontWeight="600"
                >
                  Enrich
                </Text>
                <Text
                  fontSize={{
                    base: "16px",
                    md: "18px",
                    lg: "18px",
                    sm: "16px",
                  }}
                  w={{ base: "90%", md: "70%", lg: "70%", sm: "90%" }}
                  fontWeight={"500"}
                  my="2rem"
                >
                  Sprouts provides effortless access to multiple databases in
                  one intuitive platform, including CRM integration.
                </Text>
                <Button
                  size={{ base: "md", md: "lg", lg: "lg", sm: "md" }}
                  borderRadius={"10px"}
                  colorScheme="purple"
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}
                mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box>
                  <Img src={demo1Image} />
                </Box>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              mt={{ base: "1.5rem", md: "4rem", lg: "4rem", sm: "1.5rem" }}
              flexWrap={"wrap"}
            >
              <Box w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}>
                <Text
                  fontSize={{
                    base: "1.5rem",
                    md: "2rem",
                    lg: "2rem",
                    sm: "1.5rem",
                  }}
                  fontWeight="600"
                >
                  Intent
                </Text>
                <Text
                  fontSize={{
                    base: "16px",
                    md: "18px",
                    lg: "18px",
                    sm: "16px",
                  }}
                  w={{ base: "90%", md: "70%", lg: "70%", sm: "90%" }}
                  fontWeight={"500"}
                  my="2rem"
                >
                  Identify demand signals from potential customers, even the
                  sneaky anonymous visitors, and target in-market accounts with
                  precise contact intelligence.
                </Text>
                <Button
                  size={{ base: "md", md: "lg", lg: "lg", sm: "md" }}
                  borderRadius={"10px"}
                  colorScheme="purple"
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}
                mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box>
                  <Img src={demo1Image} />
                </Box>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              mt={{ base: "1.5rem", md: "4rem", lg: "4rem", sm: "1.5rem" }}
              flexWrap={"wrap"}
            >
              <Box w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}>
                <Text
                  fontSize={{
                    base: "1.5rem",
                    md: "2rem",
                    lg: "2rem",
                    sm: "1.5rem",
                  }}
                  fontWeight="600"
                >
                  Outreach
                </Text>
                <Text
                  fontSize={{
                    base: "16px",
                    md: "18px",
                    lg: "18px",
                    sm: "16px",
                  }}
                  w={{ base: "90%", md: "70%", lg: "70%", sm: "90%" }}
                  fontWeight={"500"}
                  my="2rem"
                >
                  Maximize engagement and spread your reach across multiple
                  touchpoints using emails, LinkedIn, phone calls, SMS,
                  Whatsapp, and targeted ads.
                </Text>
                <Button
                  size={{ base: "md", md: "lg", lg: "lg", sm: "md" }}
                  borderRadius={"10px"}
                  colorScheme="purple"
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}
                mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box>
                  <Img src={demo1Image} />
                </Box>
              </Box>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              mt={{ base: "1.5rem", md: "4rem", lg: "4rem", sm: "1.5rem" }}
              flexWrap={"wrap"}
            >
              <Box w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}>
                <Text
                  fontSize={{
                    base: "1.5rem",
                    md: "2rem",
                    lg: "2rem",
                    sm: "1.5rem",
                  }}
                  fontWeight="600"
                >
                  Predict
                </Text>
                <Text
                  fontSize={{
                    base: "16px",
                    md: "18px",
                    lg: "18px",
                    sm: "16px",
                  }}
                  w={{ base: "90%", md: "70%", lg: "70%", sm: "90%" }}
                  fontWeight={"500"}
                  my="2rem"
                >
                  Machine Learning model to power account purchase prediction.
                  Know exactly when a customer is ready to make a purchase.
                </Text>
                <Button
                  size={{ base: "md", md: "lg", lg: "lg", sm: "md" }}
                  borderRadius={"10px"}
                  colorScheme="purple"
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w={{ base: "90%", md: "90%", lg: "50%", sm: "90%" }}
                mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box>
                  <Img src={demo1Image} />
                </Box>
              </Box>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };

  const renderCardUI = () => {
    return (
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
        gap={{ base: 0, md: 3, lg: 6, sm: 0 }}
      >
        <GridItem
          w="100%"
          bg="#f5f5fe"
          border="1px"
          borderColor={"green"}
          p="2rem"
          borderRadius={"10px"}
          mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
        >
          <Text fontSize={"1.5rem"} mb="1rem" fontWeight={"bold"}>
            Increase Conversations
          </Text>
          <Text
            fontSize={{ base: "2rem", md: "2rem", lg: "6rem", sm: "2rem" }}
            mb="1rem"
            bgGradient="linear-gradient(236deg, #8D7DFD 8.16%, #5341D4 89.22%)"
            bgClip="text"
          >
            {" "}
            200%
          </Text>
          <Text fontSize={"18px"} fontWeight="500" color="#6E6F93">
            Increase in MQL & SQL
          </Text>
        </GridItem>
        <GridItem
          w="100%"
          bg="#f5f5fe"
          border="1px"
          borderColor={"green"}
          p="2rem"
          borderRadius={"10px"}
          mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
        >
          <Text fontSize={"1.5rem"} mb="1rem" fontWeight={"bold"}>
            Reduce time & effort
          </Text>
          <Text
            fontSize={{ base: "2rem", md: "2rem", lg: "6rem", sm: "2rem" }}
            mb="1rem"
            bgGradient="linear-gradient(236deg, #8D7DFD 8.16%, #5341D4 89.22%)"
            bgClip="text"
          >
            {" "}
            3X
          </Text>
          <Text fontSize={"18px"} fontWeight="500" color="#6E6F93">
            Increase in BDR& SDR efficiency
          </Text>
        </GridItem>
        <GridItem
          w="100%"
          bg="#f5f5fe"
          border="1px"
          borderColor={"green"}
          p="2rem"
          borderRadius={"10px"}
          mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
        >
          <Text fontSize={"1.5rem"} mb="1rem" fontWeight={"bold"}>
            Reduce costs
          </Text>
          <Text
            fontSize={{ base: "2rem", md: "2rem", lg: "6rem", sm: "2rem" }}
            mb="1rem"
            bgGradient="linear-gradient(236deg, #8D7DFD 8.16%, #5341D4 89.22%)"
            bgClip="text"
          >
            {" "}
            37%
          </Text>
          <Text fontSize={"18px"} fontWeight="500" color="#6E6F93">
            Reduction in costs against comparable martech stack
          </Text>
        </GridItem>
      </Grid>
    );
  };

  //   Todo:On Scroll Change Backgrounf
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColor = scrollPosition > window.innerHeight ? "white" : "";
  const boxShadow =
    scrollPosition > window.innerHeight ? "0px 3px 9px 1px #a6a6a6" : "";

  return (
    <Box
      bgImage={LandingBgImage}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      h={{ base: "60vh", md: "60vh", lg: "100vh", sm: "60vh" }}
      fontFamily={"DM Sans"}
    >
      <Box
        mx="auto"
        p="4"
        position={"fixed"}
        w="100%"
        zIndex={"4"}
        boxShadow={boxShadow}
        bg={backgroundColor}
        transition="background-color 0.5s ease"
        display={{ base: "none", md: "none", lg: "block", sm: "none" }}
      >
        <Flex
          className="header"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box w={{ base: "20%", md: "20%", lg: "10%", sm: "20%" }}>
            <Image w="100%" src={current_logo} />
          </Box>
          <Box
            w={{ base: "50%", md: "40%", lg: "25%", sm: "50%" }}
            display={{ base: "none", md: "block", lg: "block", sm: "none" }}
          >
            <Flex
              w="100%"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<AiOutlineDown />}
                  bg="none"
                  _hover={{ bg: "none" }}
                >
                  Resources
                </MenuButton>
                <MenuList>
                  <MenuItem>Playbooks & blogs</MenuItem>
                  <MenuItem>Glossaries</MenuItem>
                  <MenuItem>People Search</MenuItem>
                </MenuList>
              </Menu>
              <Link href="#" fontWeight={"600"}>
                Contact Us
              </Link>
              <Link href="#" fontWeight={"600"}>
                Events
              </Link>
            </Flex>
          </Box>
          <Box
            w={{ base: "30%", md: "30%", lg: "65%", sm: "30%" }}
            textAlign={"end"}
          >
            <Button size="md" borderRadius="5px" colorScheme="purple">
              Book a free GTM audit
            </Button>
          </Box>
        </Flex>
      </Box>
      <Box
        mx="auto"
        p="4"
        w="100%"
        zIndex={"4"}
        display={{ base: "block", md: "block", lg: "none", sm: "block" }}
      >
        <Flex
          className="header"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box w={{ base: "30%", md: "20%", lg: "10%", sm: "30%" }}>
            <Image w="100%" src={current_logo} />
          </Box>
          <Box w={{ base: "70%", md: "40%", lg: "25%", sm: "70%" }}>
            <Flex
              w="100%"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<AiOutlineDown />}
                  bg="none"
                  _hover={{ bg: "none" }}
                  fontSize={"12px"}
                >
                  Resources
                </MenuButton>
                <MenuList>
                  <MenuItem>Playbooks & blogs</MenuItem>
                  <MenuItem>Glossaries</MenuItem>
                  <MenuItem>People Search</MenuItem>
                </MenuList>
              </Menu>
              <Link href="#" fontWeight={"600"} fontSize={"12px"}>
                Contact Us
              </Link>
              <Link href="#" fontWeight={"600"} fontSize={"12px"}>
                Events
              </Link>
            </Flex>
          </Box>
          <Box
            w={{ base: "0%", md: "40%", lg: "40%", sm: "0%" }}
            textAlign={"end"}
            display={{ base: "none", md: "block", lg: "none", sm: "none" }}
          >
            <Button size="sm" borderRadius="5px" colorScheme="purple">
              Book a free GTM audit
            </Button>
          </Box>
        </Flex>
      </Box>

      <Box
        w={{ base: "90%", md: "90%", lg: "60%", sm: "90%" }}
        mx="auto"
        pt={{ base: "1rem", md: "1rem", lg: "7rem", sm: "1rem" }}
        textAlign="center"
      >
        <Box className="heading">
          <Heading
            fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}
          >
            AI powered{" "}
            <Text
              as="span"
              bgGradient="linear(to-l, #06976A, #6A47E6)"
              bgClip="text"
              fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}
            >
              ABM
            </Text>
          </Heading>
          <Heading
            fontSize={{ base: "2rem", md: "3rem", lg: "3rem", sm: "2rem" }}
          >
            intelligence platform
          </Heading>
        </Box>
        <Flex
          className="navigator_button"
          my={{ base: "2rem", md: "4rem", lg: "4rem", sm: "2rem" }}
          justifyContent={{
            base: "center",
            md: "center",
            lg: "space-between",
            sm: "center",
          }}
          w={{ base: "90%", md: "90%", lg: "70%", sm: "90%" }}
          mx="auto"
          flexWrap={"wrap"}
        >
          <Button
            size="sm"
            leftIcon={<AiFillAppstore />}
            bg="#fff"
            color="#1c1c1c"
            borderRadius="10px"
            m={{ base: "5px", md: "5px", lg: "0px", sm: "5px" }}
          >
            Discover
          </Button>
          <Button
            size="sm"
            leftIcon={<AiFillAppstore />}
            bg="#fff"
            color="#1c1c1c"
            borderRadius="10px"
            m={{ base: "5px", md: "5px", lg: "0px", sm: "5px" }}
          >
            Enrich
          </Button>
          <Button
            size="sm"
            leftIcon={<AiFillAppstore />}
            bg="#fff"
            color="#1c1c1c"
            borderRadius="10px"
            m={{ base: "5px", md: "5px", lg: "0px", sm: "5px" }}
          >
            Intent
          </Button>
          <Button
            size="sm"
            leftIcon={<AiFillAppstore />}
            bg="#fff"
            color="#1c1c1c"
            borderRadius="10px"
            m={{ base: "5px", md: "5px", lg: "0px", sm: "5px" }}
          >
            Outreach
          </Button>
          <Button
            size="sm"
            leftIcon={<AiFillAppstore />}
            bg="#fff"
            color="#1c1c1c"
            borderRadius="10px"
            m={{ base: "5px", md: "5px", lg: "0px", sm: "5px" }}
          >
            Predict
          </Button>
        </Flex>
        <Box className="demo_button" mb="3rem">
          <Button
            size={{ base: "md", md: "md", lg: "lg", sm: "md" }}
            colorScheme="purple"
            borderRadius="10px"
          >
            Free GTM Audit
          </Button>
        </Box>
        <Box className="dummy_image">
          <Img src={demo1Image} />
        </Box>
      </Box>
      <Box w="80%" mx="auto" textAlign={"center"}>
        <Text
          className="partner_header"
          color="#6e6f93"
          fontSize="1.5rem"
          my={{ base: "1rem", md: "2rem", lg: "3rem", sm: "1rem" }}
        >
          All-in-one demand gen platform
        </Text>
        <Box
          className="partner_slider"
          mb={{ base: "2rem", md: "3rem", lg: "4rem", sm: "2rem" }}
        >
          {renderPartnerSlider()}
        </Box>
      </Box>
      <Box
        w={{ base: "95%", md: "80%", lg: "80%", sm: "95%" }}
        my={{ base: "1rem", md: "2rem", lg: "3rem", sm: "1rem" }}
        mx="auto"
        bg="#f9f9f9"
        borderRadius="10px"
        p={{ base: "1rem", md: "3rem", lg: "4rem", sm: "1rem" }}
      >
        <Text
          textAlign={"center"}
          mt={{ base: "1rem", md: "2rem", lg: "3rem", sm: "1rem" }}
          mb={{ base: "1rem", md: "2rem", lg: "2rem", sm: "1rem" }}
          fontSize={{ base: "1.5rem", md: "2rem", lg: "2rem", sm: "1.5rem" }}
          fontWeight={"600"}
        >
          AI powered GTM intellegence
        </Text>
        <Box className="tab_data">{renderTabData()}</Box>
      </Box>
      <Box
        w={{ base: "95%", md: "80%", lg: "80%", sm: "95%" }}
        mx="auto"
        my={{ base: "1", md: "3", lg: "3", sm: "1" }}
        textAlign={"center"}
      >
        <Text
          fontSize={{
            base: "1.5rem",
            md: "2.5rem",
            lg: "2.5rem",
            sm: "1.5rem",
          }}
          fontWeight={"700"}
          my={{ base: "1rem", md: "4rem", lg: "4rem", sm: "1rem" }}
        >
          The fastest and most uncompromising AI platform!
        </Text>
        <Box p="4">
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
            gap={{ base: 0, md: 3, lg: 6, sm: 0 }}
          >
            <GridItem
              w="100%"
              bg="#f3f4f6"
              borderRadius="20px"
              textAlign="left"
              p={{ base: "1rem", md: "1rem", lg: "2rem", sm: "1rem" }}
              mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
            >
              <Box
                h={{ base: "150px", md: "150px", lg: "300px", sm: "150px" }}
                w="100%"
                bg="#dfdfdf"
                borderRadius="20px"
                boxShadow="normal"
              ></Box>
              <Text fontWeight="600" fontSize={"18px"} mt="2rem">
                Industry Leading Performance
              </Text>
              <Text>
                Independently benchmarked to have the top speed of all inference
                providers
              </Text>
            </GridItem>
            <GridItem
              w="100%"
              bg="#f3f4f6"
              p={{ base: "1rem", md: "1rem", lg: "2rem", sm: "1rem" }}
              borderRadius="20px"
              textAlign="left"
            >
              <Box
                h={{ base: "150px", md: "150px", lg: "300px", sm: "150px" }}
                w="100%"
                bg="#dfdfdf"
                borderRadius="20px"
                boxShadow="normal"
              ></Box>
              <Text fontWeight="600" fontSize={"18px"} mt="2rem">
                Enterprise Scale Throughput
              </Text>
              <Text>
                Our proprietary stack blows open source options out of the water
                (see blog)
              </Text>
            </GridItem>
          </Grid>

          <Box mt="6">
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
              gap={{ base: 0, md: 3, lg: 6, sm: 0 }}
            >
              <GridItem
                w="100%"
                bg="#f3f4f6"
                borderRadius="20px"
                textAlign="left"
                p={{ base: "1rem", md: "1rem", lg: "2rem", sm: "1rem" }}
                mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box
                  h={{ base: "150px", md: "150px", lg: "300px", sm: "150px" }}
                  w="100%"
                  bg="#dfdfdf"
                  borderRadius="20px"
                  boxShadow="normal"
                ></Box>
                <Text fontWeight="600" fontSize={"18px"} mt="2rem">
                  State-of-the-art Models
                </Text>
                <Text>
                  Use powerful models curated by Fireworks or our in-house
                  trained multi-modal and function-calling models
                </Text>
              </GridItem>
              <GridItem
                w="100%"
                bg="#f3f4f6"
                borderRadius="20px"
                textAlign="left"
                p={{ base: "1rem", md: "1rem", lg: "2rem", sm: "1rem" }}
                mb={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
              >
                <Box
                  h={{ base: "150px", md: "150px", lg: "300px", sm: "150px" }}
                  w="100%"
                  bg="#dfdfdf"
                  borderRadius="20px"
                  boxShadow="normal"
                ></Box>
                <Text fontWeight="600" fontSize={"18px"} mt="2rem">
                  Battle Tested for Reliability
                </Text>
                <Text>
                  Fireworks is the 2nd most used open-source model provider and
                  also generates over 1M images/day
                </Text>
              </GridItem>
              <GridItem
                w="100%"
                bg="#f3f4f6"
                p="2rem"
                borderRadius="20px"
                textAlign="left"
              >
                <Box
                  h={{ base: "150px", md: "150px", lg: "300px", sm: "150px" }}
                  w="100%"
                  bg="#dfdfdf"
                  borderRadius="20px"
                  boxShadow="normal"
                ></Box>
                <Text fontWeight="600" fontSize={"18px"} mt="2rem">
                  {" "}
                  Built for Developers
                </Text>
                <Text>
                  Our OpenAI-compatible API makes it easy to start building with
                  Fireworks!
                </Text>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        w={{ base: "95%", md: "80%", lg: "80%", sm: "95%" }}
        mx="auto"
        textAlign={"center"}
        p={{ base: "4", md: "4", lg: "0", sm: "4" }}
      >
        <Text
          fontSize={{ base: "1.5rem", md: "2rem", lg: "2.5rem", sm: "1.5rem" }}
          fontWeight={"700"}
          my={{ base: "2rem", md: "3rem", lg: "4rem", sm: "2rem" }}
        >
          Amplify growth with less resources
        </Text>
        {renderCardUI()}
        <Box
          fontSize={{ base: "1rem", md: "1rem", lg: "2rem", sm: "1rem" }}
          fontWeight={"700"}
          mt={{ base: "2rem", md: "3rem", lg: "4rem", sm: "2rem" }}
        >
          <Text>You have scrolled so far don't stop now!</Text>
          <Text>Sign up for a free GMT audit</Text>
        </Box>
        <Box
          className="demo_button"
          my={{ base: "3rem", md: "3rem", lg: "5rem", sm: "3rem" }}
        >
          <Button
            size={{ base: "md", md: "md", lg: "lg", sm: "md" }}
            colorScheme="purple"
            borderRadius="10px"
          >
            Book Now
          </Button>
        </Box>
      </Box>
      <Flex
        w="100%"
        py="2rem"
        px="4"
        textAlign={"center"}
        alignItems={"center"}
        bg="#090729"
        flexWrap={"wrap"}
      >
        <Flex
          w={{ base: "100%", md: "100%", lg: "60%", sm: "100%" }}
          mx="auto"
          justifyContent={{
            base: "space-around",
            md: "space-around",
            lg: "space-between",
            sm: "space-around",
          }}
          alignItems={"center"}
          fontSize={{ base: "14px", md: "14px", lg: "18px%", sm: "14px" }}
          fontWeight={"600"}
          flexWrap={"wrap"}
        >
          <Text color="#999">Sprouts</Text>
          <Text color="#999">Contact US</Text>
          <Text color="#999">Resources</Text>
          <Text color="#999">People Search</Text>
          <Text color="#999">Terms & Condition</Text>
          <Text color="#999">Privacy Policy</Text>
        </Flex>
        <Text
          w={{ base: "100%", md: "100%", lg: "20%", sm: "100%" }}
          color="#999"
          ontSize={{ base: "14px", md: "14px", lg: "18px%", sm: "14px" }}
          fontWeight={"600"}
          mt={{ base: "1rem", md: "1rem", lg: "0", sm: "1rem" }}
        >
          copyright @ 2024 <Text as="span">sprouts.ai</Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default HomeLandingPage;
