import React, { useState, useEffect } from "react";
import { ChakraProvider, Box, Heading, Button, useToast, Flex, Text, Spinner, Alert, AlertIcon } from "@chakra-ui/react";
import ReportingForm from "./components/ReportingForm";
import ReportingTable from "./components/ReportingTable";
import {
  addReportSetupAccSubs,
  updateReportSetupAccSubs,
  manageReportSetupAccSubs,
  fetchReportExecutionListAccSubs,
  BillingReportSheduleList,
  listReportSetupAccSubs
} from "../../../services/accountServices"
import Card from "../../../components/card/Card";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { useBrandBg } from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function ReportingSetup() {
  const [editData, setEditData] = useState(null);
  const [listData, setListData] = useState([])
  const [loading, setLoading] = useState(false)
  const toast = useToast();
  const brandBg = useBrandBg();
  const [configurationCollapseFlag, setConfiguationCollapseFlag] =
    useState(true);

  useEffect(() => {
    getScheduleList()
  }, []);

  function getScheduleList() {
    setLoading(true)
    listReportSetupAccSubs({}).then(resp => {
      if (resp.data[0]?.status) {
        setListData(resp?.data[0]?.data)
        console.log(resp?.data[0]?.data)
        console.log(resp?.data[0]?.data.length)
      } else {
        setListData([])
        toast(toastFunctionToaster("Failed to get list Data", "error"))
      }
    }).catch(e => {
      setListData([])

    }).finally(() => {
      setLoading(false)
    })
  }

  const handleCreateOrUpdate = async (formData) => {
    try {
      if (editData) {
        await updateReportSetupAccSubs(editData.reporting_uuid, formData);
        toast({ title: "Report updated successfully.", status: "success" });
      } else {
        await addReportSetupAccSubs(formData);
        toast({ title: "Report created successfully.", status: "success" });
      }
      setEditData(null);
      getScheduleList();
    } catch (error) {
      toast({
        title: "Error creating/updating report.",
        description: "Operation failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex h="full" overflow="hidden" w="full">
      <Card p="1" h="full" >
        <Flex h="full" overflow="hidden" >
          <Box
            w={!configurationCollapseFlag ? "95%" : "78%"}
            bg={brandBg}
            p="2"
            borderRadius={"10px"}
            h="full"
            className="custom-scrollbar"
            overflowY="auto"
          >
            <Card h="full" p={1}>
              {loading ? <Spinner size="xl" /> : (listData.length<1 ? <Alert status='info'>
                <AlertIcon />
                List is empty!
              </Alert> : <ReportingTable onEdit={setEditData} listData={listData}/>)}
            </Card>
          </Box>
          {configurationCollapseFlag ? (
            <Box
              w="22%"
              position="relative"
              p="2"
              h="full"
              borderRadius="15px"
              bg={brandBg}
              ml="2"
              overflow="hidden"
            >
              <Box
                position="absolute"
                top="0"
                right="0"
                mr="2"
                mt="3"
                zIndex="999"
              >
                <AiFillRightCircle
                  cursor="pointer"
                  size="20px"
                  onClick={() => {
                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                  }}
                />
              </Box>
              <Box w="100%" h="full" overflow="hidden">
                <Card h="full" overflow="auto" className="custom-scrollbar" p="2">
                  <Flex direction="column" pt="5" gap="2" h="full" pb={2}>
                    <ReportingForm
                      onSubmit={handleCreateOrUpdate}
                      editData={editData}
                    />
                  </Flex>
                </Card>
              </Box>
            </Box>

          ) : (
            <Box
              w="5%"
              p="3"
              borderRadius="10px"
              bg={brandBg}
              minH="100%"
              ml="2"
              pl="5"
            >
              <Box w={"100%"} h="100%">
                <AiFillLeftCircle
                  cursor="pointer"
                  onClick={() => {
                    setConfiguationCollapseFlag(
                      !configurationCollapseFlag
                    );
                  }}
                  size="20px"
                />
                <Text
                  fontSize="16px"
                  mt="2"
                  css={{
                    writingMode: "vertical-lr",
                    textOrientation: "mixed",
                  }}
                >
                  Expand Management Settings
                </Text>
              </Box>
            </Box>
          )}
        </Flex>
      </Card>
    </Flex>
  );
}

export default ReportingSetup;
