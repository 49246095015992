// Chakra + React Imports
import React, { useState, useEffect } from "react";
import { TbTriangleInverted } from "react-icons/tb";
import { Flex, Button, Input, Box, Text, MenuItem, MenuList, ButtonGroup, MenuButton, Menu, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, Select, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { addBookingServices, getLingoletIdentityList } from "../../../../services/accountServices";

function AddUpdateBooking({ onAddSuccess }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const [email, setEmail] = useState("");

	const [clientData, setClientData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [clientValue, selectedClientValue] = useState("");
	const [customerValue, selectedCustomerValue] = useState("");

	useEffect(() => {
		fetchClientDetails("client", setClientData);
		fetchClientDetails("customer", setCustomerData);
	}, []);

	const fetchClientDetails = (value, setter) => {
		const obj = {
			filter_key: "identity_type",
			filter_value: value,
			pageId: 1,
			records_per_page: 1000,
		};

		getLingoletIdentityList(obj)
			.then(res => {
				if (res?.result) {
					setter(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
				} else {
					setter([]);
				}
			})
			.catch(err => {
				setter([]);
			});
	};

	const validateFields = () => {
		const errors = {};
		if (!clientValue) errors.client = "Client is required";
		if (!customerValue) errors.customer = "Customer is required";
		if (!email || !/\S+@\S+\.\S+/.test(email)) errors.email = "Valid email is required";
		setError(errors);
		return Object.keys(errors).length === 0;
	};

	const handleSubmit = () => {
		if (!validateFields()) return;
		let obj = {
			booking_client_uuid: clientValue,
			booking_customer_uuid: customerValue,
			booking_other_emails: email,
			booking_billing_party_uuid: "",
		};
		setLoading(true);
		addBookingServices(obj)
			.then(res => {
				setLoading(false);
				onAddSuccess();
				handleCloseDrawer();
				toast(toastFunctionToaster(res?.message, "success"));
			})
			.catch(err => {
				setLoading(false);
				handleCloseDrawer();
				toast(toastFunctionToaster("Something went wrong", "error"));
			});
	};

	function handleCloseDrawer() {
		setEmail("");
		onClose();
	}

	return (
		<>
			<Menu>
				<MenuButton as={Box}>
					<ButtonGroup
						isAttached
						spacing={0}
						borderRadius={"10px"}
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
					>
						<Button borderColor="black" bg="blue.100" rounded={"sm"} borderTopLeftRadius="10px" borderBottomLeftRadius="10px" fontWeight={"500"} mb="-1px">
							+ New
						</Button>
						<Button borderTopRightRadius="10px" borderBottomRightRadius="10px" borderStyle="dashed" borderLeftWidth="1px" borderColor="black" bg="blue.100" rounded={"sm"} cursor={"pointer"}>
							<TbTriangleInverted />
						</Button>
					</ButtonGroup>
				</MenuButton>
				<MenuList sx={{ p: 0, m: 0 }}>
					<MenuItem
						sx={{
							bg: "blue.100",
							borderStyle: "dashed",
							borderWidth: "1px",
							borderColor: "black",
							color: "black",
							rounded: "sm",
						}}
						onClick={() => {
							onOpen();
						}}
					>
						Booking
					</MenuItem>
				</MenuList>
			</Menu>

			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{"Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Booking
						</Text>{" "}
						Details
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Select Client
								</FormLabel>
								<Select zIndex={1} value={clientValue} onChange={e => selectedClientValue(e.target.value)}>
									<option value="">Select Client</option>
									{clientData.map(option => (
										<option key={option.identity_uuid} value={option.identity_uuid}>
											{option.id_first_name} {option.id_last_name}
										</option>
									))}
								</Select>
								{error.client && (
									<Text color="red.500" fontSize="sm">
										{error.client}
									</Text>
								)}
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Select Customer
								</FormLabel>
								<Select zIndex={1} value={customerValue} onChange={e => selectedCustomerValue(e.target.value)}>
									<option value="">Select Customer</option>
									{customerData.map(option => (
										<option key={option.identity_uuid} value={option.identity_uuid}>
											{option.id_first_name} {option.id_last_name}
										</option>
									))}
								</Select>
								{error.customer && (
									<Text color="red.500" fontSize="sm">
										{error.customer}
									</Text>
								)}
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Booking Email
								</FormLabel>
								<Input
									type="text"
									name="id_first_name"
									value={email}
									onChange={e => {
										setEmail(e.target.value);
									}}
									color={textColorPrimary}
								/>
								{error.email && (
									<Text color="red.500" fontSize="sm">
										{error.email}
									</Text>
								)}
							</FormControl>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{"Add"}
							</Button>
							<Button variant="outline" colorScheme="red" ml={3} onClick={handleCloseDrawer}>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateBooking;
