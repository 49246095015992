import React from "react";
import { Icon } from "@chakra-ui/react";
import { AiOutlineSetting, AiOutlineDashboard } from "react-icons/ai";

// Business Componenr Imports
import LADashboard from "../komponents/languageassociate/LADashboard";

// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";

const languageAssociateRoutes = [
	{
		name: "Dashboard",
		path: "/languageassociate",
		icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Statistics",
				layout: "/languageassociate",
				path: "/stats",
				component: <LADashboard />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
			},
		],
	},
	{
		name: "Help",
		path: "/languageassociate",
		icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
		collapse: true,
		items: [
			{
				name: "Get Help",
				layout: "/languageassociate",
				path: "/help",
				component: <Helper />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			},
			{
				name: "Compliance",
				layout: "/languageassociate",
				path: "/trust",
				component: <PlatformTrust />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
			},
		],
	},
];

export default languageAssociateRoutes;
