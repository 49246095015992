import React, { useState, useEffect, useRef } from "react";
import "react-responsive-pagination/themes/classic.css";
import { useNavigate, } from "react-router-dom";
import QRCode from "react-qr-code";
import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
// Chakra Import
import {
	Box,
	Flex,
	Text,
	Link,
	Button,
	Icon,
	Spacer,
	Alert,
	Drawer,
	Spinner,
	Image,
	Tooltip,
	CheckboxGroup,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	AlertIcon,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
	Input,
	Checkbox,
	useColorModeValue,
	SimpleGrid,
	Badge,
	FormControl,
	Select,
	RadioGroup,
	Radio,
	background,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../../components/card/Card";
import {
	AiOutlineCheckCircle,
	AiOutlineCopy,
	AiOutlineLink,
	AiOutlineStop,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
	AiOutlinePlus,
} from "react-icons/ai";
// Api Call Imports
import {
	addNewLink,
	getAllLinks,
} from "../../../../services/businessServices";
import { formatSeconds } from "../../../../utils/TimeFunctions";
// Component Imports
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/SecureAI.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";
import KioskAiBg from '../../../../assets/communicator/kiosk-ai.png';
import LiveAiBg from '../../../../assets/communicator/live-ai.png';
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";


import { toastFunctionToaster } from "../../../../utils/toastFunction";
import {
	useBlackWhiteBg, useBrandBg, useGray500TextColor,
	useGradientColor, useBlueButton, useTextColor, usePillButtonTextColorHover,
	usePillButtonTextColorSelected, usePillButtonBgHover,
	usePillButtonBg, usePillButtonHighLight,
	usePillButtonTextColor, useGrayTextColor,
	useCardHeaderNonActiveBg, useCardHeaderActiveBg, useLinkColor,
	useBoxHoverHighlight,
	useGrayShadeCardBg, useGrayShadeCardBorderBg
} from "../../../../theme/globalColorTheme";
import LinkApp from "./LinkApp";
import Pagination from "../../../../components/pagination/Pagination";
import { LINGOLINK_TYPES, MAX_LINK_DURATION_VALUE,
	INIT_CALL_TIME_VALUE, INIT_CURRENT_SELECTION, INIT_DURATION_VALUE, INIT_LINK_CHOICE, INIT_PAGE, INIT_SELECTED_VALUE, INIT_VIDEO_CALL_VALUE, INIT_VOICE_CALL_VALUE, INIT_RECORDS_PER_PAGE } from "./constants";
import LinkPreferences from "./LinkLanguagePreferences";
import { getAllLocLanguages, fetchLocationInfo, accLangPrefPaginated } from "../../../../services/accountServices";
import LinkTags from "./LinkTags";

function LinkListing(props) {
	const toast = useToast();
	const navigate = useNavigate();
	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const type = props?.type
	const port_value = window.location.port ? ":" + window.location.port : '';
	const currentHost = window.location.hostname.includes("localhost")
		? "https://lingolet.ai/"
		: window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const currentUseType = window.location.href.includes('/business/') ? "businessadmin" : "accountadmin";

	// const currentHost = window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();
	const qrRef = React.useRef();

	const [currentSelection, setCurrentSelection] = useState(INIT_CURRENT_SELECTION);
	const [dynamicLinks, setDynamicLinks] = useState([]);
	const [linkChoice, setLinkChoice] = useState(INIT_LINK_CHOICE);
	const [selectedValues, setSelectedValues] = useState(INIT_SELECTED_VALUE);
	const [linkTitle, setLinkTitle] = useState(null);
	const [durationValue, setDurationValue] = useState(INIT_DURATION_VALUE);
	const [showTooltip, setShowTooltip] = useState(false);
	const [linkTitleFlag, setLinkTitleFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
	const [linkTrial, setLinkTrial] = useState(false);
	const [voiceCallValue, setVoiceCallValue] = useState(INIT_VOICE_CALL_VALUE);
	const [videoCallValue, setVideoCallValue] = useState(INIT_VIDEO_CALL_VALUE);
	const [callTimeValue, setCallTimeValue] = useState(INIT_CALL_TIME_VALUE);
	const [modalQrCode, setModalQrCode] = useState(null);
	const [currentPage, setCurrentPage] = useState(INIT_PAGE);
	const [totalPages, setTotalPages] = useState(0);
	const [recordsPerPage, setRecordsPerPage] = useState(INIT_RECORDS_PER_PAGE)
	const [linkMultichannel, setLinkMultichannel] = useState(false);
	const [isCallDurationUnlimited, setIsCallDurationUnlimited] = useState(false)
	const [linkStartDateTime, setLinkStartDatetime] = useState(null)
	const [linkEndDateTime, setLinkEndDateTime] = useState(null)
	const [newLingoLinkType, setNewLingoLinkType] = useState(LINGOLINK_TYPES.NO_LIMIT)
	const [errors, setErrors] = useState({})
	const [langPrefList, setLangPrefList] = useState([])
	const touched = useRef({})
	const [availableLangs, setAvailableLangs] = useState([])

	const [accountInfo, setAccountInfo] = useState([]);
	const [subAppDetails, setSubAppDetails] = useState([]);
	const [selectedLangPref, setSelectedLangPref] = useState("none")

	const pillButtonTextColorHover = usePillButtonTextColorHover()
	const pillButtonText = useTextColor()
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blackWhiteBg = useBlackWhiteBg()
	const blueButtonBg = useBlueButton()

	const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonBg = usePillButtonBg()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonBgHighlight = usePillButtonHighLight()
	const textColor = usePillButtonTextColor()
	const localCardHeaderNonActiveBg = useCardHeaderNonActiveBg();
	const localCardHeaderActiveBg = useCardHeaderActiveBg();
	const localGrayTextColor = useGrayTextColor();
	const localGray500TextColor = useGray500TextColor();

	const linkColor = useLinkColor();

	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};
	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	const DatetimePickerStyles =
	{
		height: "38.5px",
		padding: "0 16px",
		width: "100%",
		border: ".8px solid #cbd5e0",
		borderRadius: "5px",
		background: "transparent",
		color: pillButtonText
	}

	useEffect(() => { resetProjectDetails(false, false) }, [newLingoLinkType])

	function getLanguagePreferencesList() {
		const paramObj = {
			pageId: 1,
			records_per_page: 100,
			status: 'active'
		}
		accLangPrefPaginated(paramObj).then(resp => {
			if (resp.data[0]?.status) {
				setLangPrefList(resp.data[0]?.status)
			} else {
				setLangPrefList(null)
				toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
			}
		}).catch(err => {
			setLangPrefList(null)
			toast(toastFunctionToaster("Failed to fetch Preference list", "error"))
		})
	}

	function calculateMinutes() {
		const differenceInMs = new Date(linkEndDateTime) - new Date(linkStartDateTime);
		const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
		console.log(differenceInMinutes)
		return differenceInMinutes;
	}

	//Fetch Parent Account details
	useEffect(() => {
		if (currentUseType === 'accountadmin') {
			collectLocationInfo();
		} else {
			setSubAppDetails(['hi', 'bi', 'mi', 'di', 'li', 'ki']);
		}
		getLanguagePreferencesList()
	}, []);

	const collectLocationInfo = () => {
		isLoading(true);
		let paramObj = {
			key: 'location',
		};
		fetchLocationInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setAccountInfo(res?.data[0]?.info);
					// console.log(res?.data[0]?.info[0]?.apps);
					setSubAppDetails(res?.data[0]?.info[0]?.apps?.length ? res?.data[0]?.info[0]?.apps?.split(',') : []);
					// console.log(subAppDetails);
				} else {
					isLoading(false);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	// Todo:Differentiate based on type
	useEffect(() => {
		const buttonType = props?.button
		if (buttonType?.length) {
			switch (buttonType) {
				case 'active':
				case 'closed':
					getAllDynamicLinks(buttonType);
					break;
				case 'add':
					onOpen();
					break;
				default:
					break;
			}
			props?.resetButton('reset')
		}

	}, [props?.button])
	//   Todo:Fetch all projects
	useEffect(() => {
		getAllDynamicLinks(linkChoice);
	}, [currentPage, recordsPerPage]);

	const getAllDynamicLinks = choice => {
		isLoading(true);
		let paramObj = {
			filter_key: "string",
			filter_value: choice,
			records_per_page: recordsPerPage,
			page_id: currentPage,
		};
		setLinkChoice(choice);
		getAllLinks(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.records) {
					setDynamicLinks(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.total_pages);
				} else {
					setDynamicLinks([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	function validate() {
		if (linkTitle?.length && selectedValues.length > 0) {
			setLinkTitleFLag(false)
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				if (!linkStartDateTime || !linkEndDateTime) {
					setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
					return false
				}
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return false
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > MAX_LINK_DURATION_VALUE) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 120 days" }))
					return false
				}
			}
			return true
		}
		else {
			setLinkTitleFLag(true);
			return false
		}
	}
	function getAvailableLangs() {
		const paramObj = {
			type: "translation",
			org: "aws"
		}
		getAllLocLanguages(paramObj).then(resp => {
			if (resp?.result) {
				setAvailableLangs(resp?.data)
			} else {
				setAvailableLangs([])
			}
		}).catch(err => {
			setAvailableLangs([])
		})
	}

	useEffect(() => {
		getAvailableLangs()
	}, [])

	useEffect(() => {
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	}, [linkTitle])

	useEffect(() => {
		if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
			if (touched.current['link_start_date_time'] && touched.current['link_end_date_time']) {
				if (new Date(linkEndDateTime) < new Date() || new Date(linkStartDateTime) < new Date()) {
					setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
					return
				}
				let durationInMinutes = calculateMinutes()
				if (durationInMinutes < 60 || durationInMinutes > MAX_LINK_DURATION_VALUE) {
					setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 120 days" }))
					return
				}
			}
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
	}, [linkStartDateTime, linkEndDateTime, newLingoLinkType])

	function handleBlur(name) {
		touched.current = { ...touched.current, [name]: true }
		if ((touched.current['link_start_date_time'] && !linkStartDateTime) || (touched.current['link_end_date_time'] && !linkEndDateTime)) {
			setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
		}
		else {
			setErrors(errors => ({ ...errors, durationError: "" }))
		}
		touched.current['link_title'] && !linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
	}

	//   Todo:API to create new project
	const createDynaLink = () => {
		if (validate()) {
			let locDurationValue = durationValue;
			let locVideoCallValue = videoCallValue
			let locVoiceCallValue = voiceCallValue
			let locCallTimeValue = callTimeValue;
			let linkStartTime = ''
			let isLinkTrial = newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL || newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION
			if (newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT) {
				locDurationValue = 8760;
				locVideoCallValue = 1000;
				locVoiceCallValue = 10000;
				locCallTimeValue = 2920;
			}
			if (newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION) {
				let durationInMinutes = calculateMinutes()
				locDurationValue = durationInMinutes / 60
				locVideoCallValue = 9999;
				locVoiceCallValue = 9999;
				locCallTimeValue = isCallDurationUnlimited ? 9999 : callTimeValue
				linkStartTime = moment(new Date(linkStartDateTime)).utc().format('YYYY-MM-DD HH:mm:ss')
			}
			debugger;
			setLinkTitleFLag(false);
			let objBody = {
				link_title: linkTitle,
				is_trail: isLinkTrial,
				active_hours: locDurationValue,
				videoCount: locVideoCallValue,
				voiceCount: locVoiceCallValue,
				callTime: locCallTimeValue,
				features: selectedValues.join(","),
				multichannel: linkMultichannel,
				start_time: linkStartTime,
				lang_pref: selectedLangPref
			};
			setSaveDetailsLoader(true);
			addNewLink(objBody)
				.then(res => {
					setSaveDetailsLoader(false);
					if (res?.result) {
						closeDrawer()
						getAllDynamicLinks("active");
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					setSaveDetailsLoader(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		}
	};

	const launchManageLink = () => {
		if (window.location.pathname.includes('/account')) {
			return '/account/linkmanager';
		} else if (window.location.pathname.includes('/business')) {
			return '/business/linkmanager';
		}
		return null;
	}

	// Todo:Re-render list after updating the features
	const handleLink = (data) => {
		getAllDynamicLinks("active")
	}

	const renderDynamicLinks =
		dynamicLinks !== null && dynamicLinks?.length > 0
			? dynamicLinks?.map((item, index) => (
				<Card
					//bg={brandBg}
					boxShadow={'base'}
					key={index}
					w={"100%"}
					mb={2}
					p="0px"
					borderRadius='10px'
				>
					<Flex w={"100%"} direction={"column"} borderWidth={'0.5px'} borderColor={gradientColor} borderRadius="10px" className="abc">
						<Flex w={'100%'} p={2}
							bg={item?.dead ? localCardHeaderNonActiveBg : localCardHeaderActiveBg}
							alignItems={'center'}
							justifyContent={'space-between'}
							roundedTop={'md'} borderBottomWidth={'0.5px'} borderColor={gradientColor}>
							<Text ml={1} fontSize={'lg'} mr={1}>{item?.title}</Text>
							<Flex ml={5} w={'150px'} bg={item?.active ? 'green.100' : 'red.100'} rounded={'md'}>
								{item?.active === false || item?.dead
									? <Box w={'150px'} color="black" align='center' rounded={'md'} borderWidth={'0.05px'} borderColor={'red.200'}>
										{item?.class === 1 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} /> Unlimited Link</Text> : null}
										{item?.class === 2 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Trial Link</Text> : null}
										{item?.class === 3 ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Fixed Link</Text> : null}

										{item?.multichannel ? <Text><Icon as={AiOutlineStop} color={"red.600"} mt={1} mr={1} />Multichannel</Text> : null}
									</Box>
									: <Box w={'150px'} color="black" align='center' rounded={'md'} borderWidth={'0.05px'} borderColor={'green.200'}>
										{item?.class === 1 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Unlimited Link</Text> : null}
										{item?.class === 2 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Trial Link</Text> : null}
										{item?.class === 3 ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Fixed Link</Text> : null}
										{item?.multichannel ? <Text><Icon as={AiOutlineCheckCircle} color={"gray.600"} mt={1} mr={1} />Multichannel</Text> : null}
									</Box>}
							</Flex>
							{(item?.trial && item?.voiceCall !== 9999 && item?.videoCall !== 9999)
								? <Badge ml={2} size={'xs'} color={localGrayTextColor} bg={'blue.100'}>Trial LingoLink</Badge> : null}
							<Spacer />
							{type === 'lingoCustomer' ? <Button size="sm" bg={blueButtonBg} borderRadius="5px"> Send Email </Button> : (
								<Button
									_hover={{ bg: pillButtonBgHighlight, color: "black" }}
									color={pillButtonText}
									bg={pillButtonBg}
									onClick={() => {
										navigate(launchManageLink(), {
											state: {
												linkUuid: item?.uuid,
												linkCode: item?.code,
											},
										});
									}}
									size={"sm"}
									borderRadius={'10px'}>
									Manage Link
								</Button>
							)}
						</Flex>
						<Flex w={'100%'} >
							<Flex w={"100%"} direction={"column"} p={'10px'}>
								{(item?.trial && (item?.class === 2 || item?.class === 3))
									? <Flex w={'100%'} direction={'column'}>
										<Flex w={"100%"} >
											<Text w={'200px'} color={localGray500TextColor}>
												Link Start Time (UTC):
											</Text>
											<Text w={'300px'}>
												{moment.utc(item?.starttime).local().format("MMM DD,YYYY,HH:mm a")}
											</Text>
											<Text w={'200px'} ml={2} color={localGray500TextColor}>
												Link End Time (UTC):
											</Text>
											<Text w={'300px'}>
												{moment.utc(item?.endtime).local().format("MMM DD,YYYY,HH:mm a")}
											</Text>
										</Flex>
										<Flex w={"100%"} mb="10px">
											<Text w={'200px'} color={localGray500TextColor}>
												Link Assigned Time:
											</Text>
											<Text w={'300px'}>
												{item?.hours} Hours
											</Text>
											<Text w={'200px'} ml={2} color={localGray500TextColor}>
												Time Left:
											</Text>
											<Text>
												{item?.time_left}
											</Text>
										</Flex>
									</Flex> : null}
								<Flex
									w={"100%"}
									p={1}
									direction={"column"}
									bg={blackWhiteBg}
									rounded={"md"}>
									<Flex w={"100%"} direction={"column"}>
										<Flex w={"100%"} mt={1} mb={1}>
											<Flex w={"100%"}>
												<Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
													{item?.url ? currentHost + item?.url : "No Url"}
												</Text>
											</Flex>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Copy Url">
												<Box w={"35px"} ml={1}
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer",
														color: 'black'
													}}
													color={pillButtonText}
													bg={pillButtonBg}
													pr={2} rounded={"md"}
													mr={2}>
													<Icon
														onClick={() =>
															navigator.clipboard.writeText(currentHost + item?.url)
														}
														ml={2}
														mt={1}
														h={5}
														w={5}
														as={AiOutlineCopy}
													/>
												</Box>
											</Tooltip>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Open Url in new Tab">
												<Link
													p={1}
													w={"35px"}
													rounded={"md"}
													mr={2}
													align={"center"}
													href={currentHost + item?.url}
													target="_blank"
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer",
														color: "black"
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineLink}
													/>
												</Link>
											</Tooltip>
										</Flex>
										<Flex
											w={"100%"}
											mt={1}
											mb={1}
											borderTopColor={gradientColor}
											borderTopWidth={"0.5px"}>
											<Flex w={"100%"} mt={1}>
												<Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
													{item?.fullurl ? currentHost + item?.fullurl : "No Url"}
												</Text>
											</Flex>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Copy Url">
												<Box
													mt={1}
													w={"35px"}
													ml={1}
													pr={2}
													rounded={"md"}
													mr={2}
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer",
														color: 'black'
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														onClick={() =>
															navigator.clipboard.writeText(currentHost + item?.fullurl)
														}
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineCopy}
													/>
												</Box>
											</Tooltip>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Open Url in new Tab">
												<Link
													mt={1}
													p={1}
													w={"35px"}
													rounded={"md"}
													mr={2}
													align={"center"}
													href={currentHost + item?.fullurl}
													target="_blank"
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer",
														color: "black"
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineLink}
													/>
												</Link>
											</Tooltip>
										</Flex>
										<Flex w={"100%"}>
											{(item?.trial && (item?.class === 2 || item?.class === 3)) ? (
												item?.calls?.expired ? (
													<Flex
														w={"100%"}
														// bg={"red.100"}
														bg={brandBg}
														align={"center"}
														rounded={"md"}
														p={1}
														borderWidth={"0.05px"}
														borderColor={"gray.400"}>
														<Box w={"250px"}>
															<Text color={"red.700"}>Expired Trial Link</Text>
														</Box>
														<Flex w={"100%"} direction={"column"}>
															<Box w={"100%"}>
																<Icon
																	as={AiOutlineClockCircle}
																	mr={1}
																	mt={1}
																	h={5}
																	w={5}
																/>{" "}
																<Text mr={1}>Time:</Text>
																<Text mr={1}>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</Text>
																<Text mr={1}>| Used:</Text>
																<Text mr={1}>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</Text>
																<Text mr={1}>| Remaining:</Text>
																<Text mr={1}>
																	{item?.calls?.calls[8] > 0
																		? formatSeconds(
																			item?.calls?.calls[8].toFixed(2)
																		)
																		: "00:00:00"}
																</Text>
															</Box>
															<Flex w={"100%"}>
																<Box w={"100%"}>
																	<Icon
																		as={AiTwotoneAudio}
																		mr={1}
																		mt={1}
																		h={5}
																		w={5}
																	/>{" "}
																	<Text>
																		Calls:{" "}
																		{item?.calls?.calls[0]} | Made:{" "}
																		{item?.calls?.calls[1]} | Remaining:{" "}
																		{item?.calls?.calls[2]}
																	</Text>
																</Box>
																<Box w={"100%"}>
																	<Icon
																		as={AiOutlineVideoCamera}
																		mr={1}
																		mt={1}
																		h={5}
																		w={5}
																	/>{" "}
																	<Text>
																		Calls:{" "}
																		{item?.calls?.calls[3]} | Made:{" "}
																		{item?.calls?.calls[4]} | Remaining:{" "}
																		{item?.calls?.calls[5]}
																	</Text>
																</Box>
															</Flex>
														</Flex>
													</Flex>
												) : (
													<Flex
														w={"100%"}
														bg={brandBg}
														align={"center"}
														rounded={"md"}
														p={1}
													>
														<Box w={"250px"}>
															<Text color={"green.700"}>Active Trial Link</Text>
														</Box>
														<Flex w={"100%"} direction={"column"} alignItems={'center'}>
															<Flex w={"100%"} alignItems={'center'}>
																<Icon
																	as={AiOutlineClockCircle}
																	mr={1}
																	mt={1}
																	h={5}
																	w={5}
																/>
																<Text mr={1}>Time:</Text>
																<Text mr={1}>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</Text>
																<Text mr={1}>| Used:</Text>
																<Text mr={1}>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</Text>
																<Text mr={1}>| Remaining:</Text>
																<Text mr={1}>
																	{item?.calls?.calls[8] > 0
																		? formatSeconds(
																			item?.calls?.calls[8].toFixed(2)
																		)
																		: "00:00:00"}
																</Text>
															</Flex>
															{/* <Flex w={"100%"} alignItems={'center'}>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiTwotoneAudio}
																		mr={1}
																		h={5}
																		w={5}
																	/>{" "}
																	<Text mr={1}>Calls:</Text>
																	<Text mr={1}>{item?.calls?.calls[0]} | Made:</Text>
																	<Text mr={1}>{item?.calls?.calls[1]} | Remaining:</Text>
																	<Text mr={1}>{item?.calls?.calls[2]}</Text>
																</Flex>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiOutlineVideoCamera}
																		mr={1}
																		h={5}
																		w={5}
																	/>
																	<Text mr={1}>Calls:</Text>
																	<Text mr={1}>{item?.calls?.calls[3]} | Made:</Text>
																	<Text mr={1}>{item?.calls?.calls[4]} | Remaining:</Text>
																	<Text mr={1}>{item?.calls?.calls[5]}</Text>
																</Flex>
															</Flex> */}
														</Flex>
													</Flex>
												)
											) : (
												<Flex
													w={"100%"}
													bg={brandBg}
													align={"center"}
													rounded={"md"}
													px={2}
												>
													{/* {item?.multichannel === false
													?<Text fontSize={"md"} color={"green.700"} fontWeight={'500'}>
														Not a trial/demo link.
													</Text>:null} */}
												</Flex>
											)}
										</Flex>
									</Flex>
								</Flex>
								<Flex w={'100%'} p={1}>
									<Flex w={'100%'} direction={'column'} bg={useGrayShadeCardBg} rounded={'lg'} p={1}
										borderWidth={'0.5px'} borderColor={useGrayShadeCardBorderBg}>
										<Flex>
											<LinkTags linkItem={item} accountId={props?.accountId} locationId={props?.locationId} reListLink={handleLink} />
										</Flex>
										{item?.multichannel
											? <Flex w={'100%'} align={'center'} py={1}>
												<Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
												<Text ml={1} fontSize={'xl'}>Multichannel Lingolink</Text>
											</Flex>
											: <LinkApp
												linkItem={item}
												supportedApps={subAppDetails}
												reListLink={handleLink} />}
										<LinkPreferences linkItem={item} reListLink={handleLink} availableLangs={availableLangs} langPrefList={langPrefList} />
									</Flex>
									<Flex minW={'300px'} maxW={'300px'} ml={1}
										rounded={'lg'} p={1} bg={useGrayShadeCardBg}
										borderWidth={'0.5px'} borderColor={useGrayShadeCardBorderBg}>
										<Flex maxW={"150px"}>
											<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
												<Box
													w={"100%"}
													onClick={() => {
														setModalQrCode(item?.url);
														onOpenQr();
													}}
													_hover={{ cursor: "pointer", boxShadow: useBoxHoverHighlight, borderColor: "blue.600", borderWidth: "1px" }}
													p={1}
													borderColor={"gray.400"}
													borderWidth={"1px"}
													rounded={"md"}>
													<QRCode
														size={90}
														style={{ height: "auto", maxWidth: "100", width: "100" }}
														value={item?.url}
														viewBox={`0 0 90 90`}
													/>
												</Box>
												<Text>QR(Url)</Text>
											</Flex>
										</Flex>
										<Flex maxW={"150px"}>
											<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
												<Box
													w={"100%"}
													onClick={() => {
														setModalQrCode(item?.fullurl);
														onOpenQr();
													}}
													_hover={{ cursor: "pointer", boxShadow: useBoxHoverHighlight, borderColor: "blue.600", borderWidth: "1px" }}
													p={1}
													borderColor={"gray.400"}
													borderWidth={"1px"}
													rounded={"md"}>
													<QRCode
														size={90}
														style={{ height: "auto", maxWidth: "100", width: "100" }}
														value={item?.fullurl}
														viewBox={`0 0 90 90`}
													/>
												</Box>
												<Text>QR(Url+Code)</Text>
											</Flex>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Card>
			))
			: null;

	// Todo:Reset Project Details
	const resetProjectDetails = (resetLingoLinkType = true, resetLingoLinkTitle = true) => {
		resetLingoLinkTitle && setLinkTitle("");
		setDurationValue(48);
		setVoiceCallValue(5);
		setVoiceCallValue(5);
		setLinkTitleFLag(false);
		resetLingoLinkType && setNewLingoLinkType(LINGOLINK_TYPES.NO_LIMIT)
		setIsCallDurationUnlimited(false)
		setErrors({})
		setLinkStartDatetime(null)
		setLinkEndDateTime(null)
		setSelectedValues(INIT_SELECTED_VALUE)
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetProjectDetails();
	};

	// Function to handle changes in the CheckboxGroup
	const handleChange = (values) => {
		setSelectedValues(values);
	};

	const renderFeatureName = (name) => {
		if (name === 'hi') {
			return 'Human Interpretation';
		} else if (name === 'bi') {
			return 'Secure AI';
			// } else if (name === 'pi') {
			//     return 'Premium AI';
		} else if (name === 'di') {
			return 'Document AI';
		} else if (name === 'mi') {
			return 'Multichannel AI';
		} else if (name === 'li') {
			return 'Live AI';
		} else if (name === 'ki') {
			return 'Kiosk AI';
		}
	}

	// Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "li") {
			return LiveAiBg;
		} else if (name === "ki") {
			return KioskAiBg;
		} else return;
	};

	return (
		<>
			<Box direction="column" pt={
				type === 'lingoCustomer' ? { sm: "0px", lg: "0px" } : { sm: '125px', lg: '75px' }}
				mt={type === 'lingoCustomer' ? '0' : 4}>
				{type === 'lingoCustomer' ? 'null' : (
					<Card p="3" alignItems="center" rounded={'md'}>
						<Flex w={'100%'} direction={'row'} >
							<Text fontWeight={"100"} fontSize={"18px"}>
								LingoLink {dynamicLinks?.length > 0 ? dynamicLinks?.length : 0} ({linkChoice})
							</Text>
							<Spacer />
							<Flex minW={'440px'} maxW={'440px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
								<Text
									minW={'70px'} maxW={'70px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('Active');
										getAllDynamicLinks("active")
									}}
									p={2} roundedLeft={'md'}
									color={currentSelection === 'Active' ? 'white' : textColor}
									bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
									Active
								</Text>
								<Text
									minW={'80px'} maxW={'80px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('closed');
										getAllDynamicLinks("closed")
									}}
									color={currentSelection === 'closed' ? 'white' : textColor}
									p={2}
									bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
									Closed
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										getAllDynamicLinks("active");
										setCurrentSelection('refresh');
									}}
									color={currentSelection === 'refresh' ? 'white' : textColor}
									p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>Refresh
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										onOpen();
										// setCurrentSelection('new');
									}}
									roundedRight={'md'}
									color={currentSelection === 'new' ? 'white' : textColor}
									p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>
									<Icon pt={1} as={AiOutlinePlus} mr={1} />
									Create New Link
								</Text>
							</Flex>
						</Flex>
					</Card>)}
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h={type === 'lingoCustomer' ? '10vh' : '50vh'}>
						<Spinner size="xl" />
					</Flex>
				) : dynamicLinks?.length ? (
					<Card w={"100%"} borderRadius='10px' mt={3} p={type === 'lingoCustomer' ? '0px' : '10px'}>
						<Flex w={"100%"} rounded={"md"} direction={"column"}
							height='calc(100vh - 300px)' p={2}
							overflowX="auto">
							{renderDynamicLinks}
						</Flex>
					</Card>
				) : (
					<Alert status="warning" mt="4">
						<AlertIcon />
						<Text>You dont have any LingoLink yet... </Text>
						<Text ml={5}>
							<b>CREATE NEW LINGOLINK!!</b>
						</Text>
					</Alert>
				)}
			</Box>
			{dynamicLinks?.length ? (
				<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} recordsPerPage={recordsPerPage} />
			) : null}
			{/* // Add Project Drawer */}
			<Drawer
				size="lg"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>{"Create New LingoLink"}</DrawerHeader>
					<DrawerBody overflow="hidden" overflowY="scroll" mt="5">
						<Flex w={"100%"} direction={"column"}>
							<Flex w={'100%'} rounded={'md'}
								mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
								<InputField
									mb="0"
									me="30px"
									id="link_title"
									label="LingoLink Name"
									value={linkTitle}
									name="link_title"
									required="true"
									borderRadius="5px"
									bg="inherit"
									onChange={e => {
										setLinkTitle(e.target.value);
									}}
									onBlur={e => handleBlur(e.target.name)}
									placeholder="Enter Link Name"
									w="100%"
									maxlength={100}
								/>
								<Flex alignItems={"center"} justifyContent={"space-between"}>
									{linkTitleFlag ? (
										<Text fontSize="sm" color={"red"} mt="1">
											LingoLink Name is Required
										</Text>
									) : null}
									<Spacer />
									<Text textAlign={"right"} fontWeight={"100"} fontSize={"sm"} mt="2">
										{linkTitle?.length}/100
									</Text>
								</Flex>
							</Flex>
							<Flex w={'100%'} direction={'column'}>
								<Flex w={'100%'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
									<Text
										minW={'50%'} maxW={'50%'}
										_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
										onClick={() => {
											setLinkMultichannel(false);
											setSelectedValues(INIT_SELECTED_VALUE);
										}}
										p={2} roundedLeft={'md'}
										color={linkMultichannel === false ? 'white' : textColor}
										bg={linkMultichannel === false ? pillButtonBgSelected : pillButtonBg}>
										Multipurpose LingoLink
									</Text>
									{subAppDetails?.length && subAppDetails?.includes('mi') ? (
										<Text
											minW={'50%'} maxW={'50%'}
											_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
											onClick={() => {
												setLinkMultichannel(true);
												setSelectedValues(['mi']);
											}}
											roundedRight={'md'}
											color={linkMultichannel === true ? 'white' : textColor}
											p={2}
											bg={linkMultichannel === true ? pillButtonBgSelected : pillButtonBg}>
											Multichannel LingoLink
											{/* {JSON.stringify(subAppDetails)} */}
										</Text>
									) : null}
								</Flex>
							</Flex>
							{linkMultichannel === true
								? <Flex w={'100%'} bg={'red.50'} rounded={'lg'} mt={1} direction={'column'} p={2}>
									<Flex alignItem='center'>
										<Image h="100px" w="100px" mx="1" borderRadius="50px" src={MultichannelBg} />
										<Flex w={'100%'} direction={'column'} color="black">
											<Text as="span">
												MultiChannel AI
											</Text>
											<Text as="span">
												Main Language:
											</Text>
											<Text as="span">
												<b>English</b>
											</Text>
											<Text as="span">
												Other Language:
											</Text>
											<Text as="span">
												<b>Selected at Connecting Time</b>
											</Text>
										</Flex>
									</Flex>
								</Flex>
								: <Flex w={'100%'} rounded={'lg'} mt={1} direction={'column'}>
									<Flex
										w={"100%"}
										direction={"column"}
										p={1}
										rounded={"md"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"} mt="3">
										<Flex w={"100%"} align={"center"} p={2} rounded={'md'} color="black">
											<RadioGroup defaultValue={newLingoLinkType} onChange={value => setNewLingoLinkType(value)}>
												<Stack spacing={4} direction='row' color={pillButtonText}>
													<Radio variant="contained" colorScheme="gray" value={LINGOLINK_TYPES.NO_LIMIT}>Unlimited LingoLink</Radio>
													{/* <Radio value={LINGOLINK_TYPES.SEVEN_DAYS_TRIAL}>7 Days Trial LingoLink</Radio> */}
													<Radio variant="contained" value={LINGOLINK_TYPES.FIXED_DURATION}>Fixed Day or Duration LingoLink</Radio>
												</Stack>
											</RadioGroup>
											<Spacer />
										</Flex>
										{newLingoLinkType === LINGOLINK_TYPES.SEVEN_DAYS_TRIAL && (
											<Flex w={"100%"} direction={"column"}>
												<FormLabel my="3" ml="2" required>
													<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
														Link Activity Duration (Hours)
													</Text>
													<Flex
														mt={5}
														h={"100px"}
														w={"100%"}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Slider
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={2}
															max={168}
															step={1}
															defaultValue={48}
															onChange={v => setDurationValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={24} {...labelStyles}>
																24
															</SliderMark>
															<SliderMark value={48} {...labelStyles}>
																48
															</SliderMark>
															<SliderMark value={72} {...labelStyles}>
																72
															</SliderMark>
															<SliderMark value={96} {...labelStyles}>
																96
															</SliderMark>
															<SliderMark value={120} {...labelStyles}>
																120
															</SliderMark>
															<SliderMark value={144} {...labelStyles}>
																144
															</SliderMark>
															<SliderMark value={168} {...labelStyles}>
																168
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${durationValue} Hours`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
												</FormLabel>
												<Flex w={"100%"} align={"center"}>
													<Flex
														w={"50%"}
														h={"100px"}
														direction={"column"}
														py={1}
														px={5}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Text>
															Voice Call Count: <b>{voiceCallValue}</b>
														</Text>
														<Slider
															mt={8}
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={1}
															max={50}
															step={1}
															defaultValue={5}
															onChange={v => setVoiceCallValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={1} {...labelStylesVoice}>
																1
															</SliderMark>
															<SliderMark value={25} {...labelStylesVoice}>
																25
															</SliderMark>
															<SliderMark value={50} {...labelStylesVoice}>
																50
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${voiceCallValue}`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
													<Flex
														ml={1}
														w={"50%"}
														h={"100px"}
														direction={"column"}
														py={1}
														px={5}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Text>
															Video Call Count: <b>{videoCallValue}</b>
														</Text>
														<Slider
															mt={8}
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={1}
															max={50}
															step={1}
															defaultValue={5}
															onChange={v => setVideoCallValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={1} {...labelStylesVoice}>
																1
															</SliderMark>
															<SliderMark value={25} {...labelStylesVoice}>
																25
															</SliderMark>
															<SliderMark value={50} {...labelStylesVoice}>
																50
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${videoCallValue}`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
												</Flex>
												<Flex
													mt="3"
													w={"100%"}
													h={"100px"}
													direction={"column"}
													py={1}
													px={5}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"}>
													<Text>
														Maximum minutes available in this Trial call: <b>{callTimeValue}</b>{" "}
														minutes.
													</Text>
													<Slider
														mt={10}
														aria-label="slider-ex-1"
														colorScheme="teal"
														min={5}
														max={600}
														step={5}
														defaultValue={5}
														onChange={v => setCallTimeValue(v)}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={5} {...labelStylesVoice}>
															5
														</SliderMark>
														<SliderMark value={100} {...labelStylesVoice}>
															100
														</SliderMark>
														<SliderMark value={200} {...labelStylesVoice}>
															200
														</SliderMark>
														<SliderMark value={300} {...labelStylesVoice}>
															300
														</SliderMark>
														<SliderMark value={400} {...labelStylesVoice}>
															400
														</SliderMark>
														<SliderMark value={500} {...labelStylesVoice}>
															500
														</SliderMark>
														<SliderMark value={600} {...labelStylesVoice}>
															600
														</SliderMark>
														<SliderTrack>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${callTimeValue}`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
												</Flex>
											</Flex>
										)}
										{
											newLingoLinkType === LINGOLINK_TYPES.NO_LIMIT && (
												<Box w="full" p={2} border="1px" borderColor="green.200" bg="green.100" borderRadius="lg" mt="4">
													<Text color="black">This LingoLink Type has no restrictions. It will be active as long as you do not disable it.</Text>
												</Box>
											)
										}
										{
											newLingoLinkType === LINGOLINK_TYPES.FIXED_DURATION && (
												<Flex w="full" p={3} mt={3} direction="column">
													<Flex w="full" direction="column">
														<Flex w="full" justifyContent="space-between">
															<FormControl w="45%">
																<FormLabel htmlFor="startDate" >Start Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																<Flatpickr options={{
																	enableTime: true,
																	dateFormat: "Y-m-d H:i",
																	minuteIncrement: 15,
																	minDate: 'today',
																}}
																	altInputClass="flat-picker"
																	value={linkStartDateTime}
																	name="link_start_date_time"
																	onBlur={(e) => {
																		handleBlur(e.target.name)
																	}}
																	onChange={value => setLinkStartDatetime(value[0])}
																	style={DatetimePickerStyles}
																	placeholder="Select Date Range"
																	id="startDate"
																/>
															</FormControl>
															<FormControl w="45%">
																<FormLabel htmlFor="endDate" >End Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
																{/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
																<Flatpickr options={{
																	enableTime: true,
																	dateFormat: "Y-m-d H:i",
																	minuteIncrement: 15,
																	minDate: 'today',
																}}
																	altInputClass="flat-picker"
																	onBlur={e => handleBlur(e.target.name)}
																	name="link_end_date_time"
																	value={linkEndDateTime}
																	onChange={value => setLinkEndDateTime(value[0])}
																	style={DatetimePickerStyles}
																	placeholder="Select Date Range"
																	id="endDate"
																/>
															</FormControl>
														</Flex>


														{errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
													</Flex>
													<Flex
														mt="3"
														w={"100%"}
														h={"140px"}
														direction={"column"}
														py={1}
														px={5}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Text>
															Maximum minutes available in this call: <b>{isCallDurationUnlimited ? 'unlimited' : callTimeValue}</b>{" "}
															minutes.
														</Text>
														<Checkbox mt='2' isChecked={isCallDurationUnlimited} onChange={e => setIsCallDurationUnlimited(!isCallDurationUnlimited)}>Make it unlimited</Checkbox>
														<Slider
															isDisabled={isCallDurationUnlimited}
															mt={5}
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={5}
															max={600}
															step={5}
															defaultValue={5}
															onChange={v => setCallTimeValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={5} {...labelStylesVoice}>
																5
															</SliderMark>
															<SliderMark value={100} {...labelStylesVoice}>
																100
															</SliderMark>
															<SliderMark value={200} {...labelStylesVoice}>
																200
															</SliderMark>
															<SliderMark value={300} {...labelStylesVoice}>
																300
															</SliderMark>
															<SliderMark value={400} {...labelStylesVoice}>
																400
															</SliderMark>
															<SliderMark value={500} {...labelStylesVoice}>
																500
															</SliderMark>
															<SliderMark value={600} {...labelStylesVoice}>
																600
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${callTimeValue}`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
												</Flex>
											)
										}
									</Flex>
									<Flex w={'100%'} rounded={'md'}
										mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
										<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
										{
											subAppDetails.length > 0 ? <CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
												<SimpleGrid columns={2} gap="2px" mt="2" >
													{
														subAppDetails?.map(app => (
															<Checkbox value={app} isChecked={selectedValues.indexOf(app) > -1} key={app}>
																<Flex alignItem='center'>
																	<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={renderFeatureImage(app)} />
																	<Text as="span" >
																		{renderFeatureName(app)}
																	</Text>
																</Flex>
															</Checkbox>
														))
													}
												</SimpleGrid>
											</CheckboxGroup> :
												<Alert status='info'>
													<AlertIcon />
													No Apps Found!!
												</Alert>
										}

									</Flex>
									{langPrefList?.length ? <Flex w={'100%'} rounded={'md'}
										mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
										<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select Language Preference</Text>
										<Select defaultValue={"none"} value={selectedLangPref} onChange={e => setSelectedLangPref(e.target.value)}>
											<option style={{
												color: pillButtonText
											}} value={"none"} >Select language preference</option>
											{
												langPrefList.map(pref => (
													<option style={{
														color: pillButtonText
													}} value={pref.language_preference_uuid} key={pref?.language_preference_uuid}>{pref?.language_preference_name}</option>
												))
											}
										</Select>

									</Flex> : null}
								</Flex>}
						</Flex>
					</DrawerBody>
					<DrawerFooter>
						<Flex w={"100%"} direction={"row"}>
							<Button
								variant="outline"
								mr={3}
								onClick={() => {
									onClose();
									resetProjectDetails();
								}}>
								Cancel
							</Button>
							<Button
								colorScheme="blue"
								onClick={() => {
									// resetProjectDetails();
									createDynaLink();
								}}
								isDisabled={
									!linkTitle || !selectedValues.length || saveDetailsLoader
								}
								isLoading={saveDetailsLoader ? true : false}>
								{"Create New Link"}
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
			<Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>QR Code for the LingoLink</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{modalQrCode != null ? (
							<Flex w={"100%"} direction={"column"}>
								<QRCode
									title={currentHost + modalQrCode}
									size={90}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={currentHost + modalQrCode}
									viewBox={`0 0 90 90`}
								/>
								<Text p={1} bg={"blue.100"}>
									{currentHost + modalQrCode}
								</Text>
							</Flex>
						) : (
							<Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							size={"sm"}
							colorScheme="blue"
							onClick={() => {
								setModalQrCode(null);
								onCloseQr();
							}}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default LinkListing;