import React, { useState, useEffect } from 'react';
import { Box, Heading, Select, Button, VStack, Text, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const AudioDeviceSelector = ({inputDevice, setInputDevice, outputDevice, setOutputDevice }) => {
    const [audioInputs, setAudioInputs] = useState([]);
    const [audioOutputs, setAudioOutputs] = useState([]);
    const [mediaStream, setMediaStream] = useState(null);

    useEffect(() => {
      requestPermissions();
      fetchMediaDevices();
    }, []);

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (err) {
      console.error('Permission denied for accessing devices:', err);
    }
  };

  const fetchMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
console.log("devices",devices);

      const inputs = devices.filter((device) => device.kind === 'audioinput');
      const outputs = devices.filter((device) => device.kind === 'audiooutput');

      setAudioInputs(inputs);
      setAudioOutputs(outputs);

      // Set default devices if not already selected
      if (!inputDevice && inputs.length > 0) {
        setInputDevice(inputs[0].deviceId);
      }
      if (!outputDevice && outputs.length > 0) {
        setOutputDevice(outputs[0].deviceId);
      }
    } catch (error) {
      console.error('Error getting media devices:', error);
    }
  };

  const handleStartStream = async () => {
    if (inputDevice) {
      const stream = await getMediaStream(inputDevice);
      setMediaStream(stream);

      const audioElement = document.querySelector('audio');
      if (audioElement && stream) {
        audioElement.srcObject = stream;
      }

      if (audioElement && outputDevice) {
        setAudioOutput(audioElement, outputDevice);
      }
    }
  };

  const handleStopStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  const getMediaStream = async (audioDeviceId) => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioDeviceId },
      });
      return mediaStream;
    } catch (error) {
      console.error('Error getting media stream:', error);
      return null;
    }
  };

  const setAudioOutput = (audioElement, outputDeviceId) => {
    if (audioElement?.setSinkId) {
      audioElement
        .setSinkId(outputDeviceId)
        .then(() => {
          console.log('Audio output device set successfully.');
        })
        .catch((error) => {
          console.error('Error setting audio output device:', error);
        });
    } else {
      console.error('setSinkId not supported in this browser.');
    }
  };

  return (
    <VStack spacing={6} align="center" p={6} bg="gray.100" borderRadius="lg" boxShadow="lg">
      <MotionBox
        as={Heading}
        size="lg"
        fontWeight="bold"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        Audio Device Selector
      </MotionBox>

      <Flex direction="column" align="center" width="100%" p={1}>
        {/* Audio Input (Microphone) Selection */}
        <Box mb={4} p={1}>
          <Text>Select Microphone:</Text>
          <Select
            value={inputDevice || ''}
            onChange={(e) => setInputDevice(e.target.value)}
            placeholder="Select Microphone"
            width="300px"
            transition="all 0.3s ease"
            mb={4}
          >
            {audioInputs.map((input) => (
              <option key={input.deviceId} value={input.deviceId}>
                {input.label || `Microphone ${input.deviceId}`}
              </option>
            ))}
          </Select>
        </Box>

        {/* Audio Output (Speakers/Headphones) Selection */}
        <Box mb={4}>
          <Text>Select Speaker/Headphones:</Text>
          <Select
            value={outputDevice || ''}
            onChange={(e) => setOutputDevice(e.target.value)}
            placeholder="Select Speaker/Headphones"
            width="300px"
            transition="all 0.3s ease"
            mb={4}
          >
            {audioOutputs.map((output) => (
              <option key={output.deviceId} value={output.deviceId}>
                {output.label || `Speaker ${output.deviceId}`}
              </option>
            ))}
          </Select>
        </Box>

        {/* Buttons to Start and Stop Audio Stream */}
        <Flex justifyContent="space-between" width="300px">
          <Button
            colorScheme="teal"
            onClick={handleStartStream}
            isDisabled={!inputDevice || !outputDevice}
            transition="all 0.3s ease"
          >
            Start Stream
          </Button>
          <Button
            colorScheme="red"
            onClick={handleStopStream}
            isDisabled={!mediaStream}
            transition="all 0.3s ease"
          >
            Stop Stream
          </Button>
        </Flex>

        {/* Audio Element for Playing Stream */}
        <audio controls autoPlay />

        {/* Refresh Devices Button */}
        <Button
          mt={4}
          colorScheme="blue"
          onClick={fetchMediaDevices}
          transition="all 0.3s ease"
        >
          Refresh Devices
        </Button>

        {/* Display selected devices */}
        <MotionBox
          as={Text}
          animate={{ opacity: mediaStream ? 1 : 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p>Selected Input Device: {inputDevice}</p>
          <p>Selected Output Device: {outputDevice}</p>
        </MotionBox>
      </Flex>
    </VStack>
  );
};

export default AudioDeviceSelector;
