import React from "react";
import { Flex } from "@chakra-ui/react";
import Card from "../../../components/card/Card";

function AdminDashboard() {
	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
			<Card px="0px" p={10}>
				Admin Dashboard
			</Card>
		</Flex>
	);
}

export default AdminDashboard;
