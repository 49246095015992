import { 
    Button, Checkbox, Flex, HStack, Image, Input, PinInput, 
    PinInputField, Text, useToast 
} from '@chakra-ui/react'
import React, { useState } from 'react'
import Card from '../../../components/card/Card'
import { useBrandBg } from '../../../theme/globalColorTheme'
import lingoletImage from '../../../assets/landingPageImg/kb_logo.png'
import lspCloudLogin from '../../../assets/landingPageImg/lspcloud_logo-wide.png'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import { generatePinFotPrefrencesList, updatePrefrenceList, validatePinToListPrefrences } from '../../../services/sharedServices'

const UpdatePrefrences = () => {
    const logo = window.location.hostname.includes('lspcloud') ? lspCloudLogin : lingoletImage
    const [btnLoading, setBtnLoading] = useState({})
    const [email, setEmail] = useState("")
    const toast = useToast()
    const [pin, setPin] = useState("")
    const [showPinInput, setShowPinInput] = useState(false)
    const [userValidated, setUserValidated] = useState(false)
    const [preferenceList, setPreferenceList] = useState([])
    const [jwt, setjwt] = useState(null)

    const ALL_PREFERENCE = ['webinar', "newsletter", "demo"]
    const pageBackgroundImages = [
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
        'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
    ]

    function handleGeneratePin(e) {
        e.preventDefault()
        if (!email) {
            toast(toastFunctionToaster("Email is required", "error"))
            return
        }
        setBtnLoading(prev => ({ ...prev, getPin: true }))
        generatePinFotPrefrencesList({ signup_email: email })
            .then(resp => {
                if (resp?.data[0]?.status) {
                    toast(toastFunctionToaster(resp?.message, "success"))
                    if (resp?.data[0]?.email_sent) {
                        setShowPinInput(true)
                    }
                } else {
                    toast(toastFunctionToaster(resp?.message, "error"))
                }
            })
            .catch(() => toast(toastFunctionToaster("Failed to validate email", "error")))
            .finally(() => setBtnLoading(prev => ({ ...prev, getPin: false })))
    }

    function validatePin() {
        if (pin?.length !== 4) {
            toast(toastFunctionToaster("Invalid Pin", "error"))
            return
        }
        setBtnLoading(prev => ({ ...prev, verify: true }))
        validatePinToListPrefrences({ signup_email: email, code: pin })
            .then(resp => {
                if (resp?.data[0]?.status && resp?.data[0]?.access_verified) {
                    setUserValidated(true)
                    setPreferenceList(resp?.data[0]?.list)
                    setjwt(resp?.data[0]?.token)
                    toast(toastFunctionToaster(resp?.message, "success"))
                } else {
                    toast(toastFunctionToaster(resp?.message, "error"))
                }
            })
            .catch(() => toast(toastFunctionToaster("Failed to verify code", "error")))
            .finally(() => setBtnLoading(prev => ({ ...prev, verify: false })))
    }

    const updatePreferenceList = (e) => {
        const value = e.target.value;
        setPreferenceList(prevList =>
            prevList.includes(value) ? prevList.filter(item => item !== value) : [...prevList, value]
        );
    };

    function handleUpdateUserPrefrences(update_type) {
        setBtnLoading(prev => ({
            ...prev,
            [update_type]: true
        }));

        updatePrefrenceList({
            signup_email: email,
            preferences: preferenceList.join(","),
            update_type,
            token: jwt
        })
            .then(resp => {
                if (resp?.data[0]?.status) {
                    setPreferenceList(resp?.data[0]?.list)
                    toast(toastFunctionToaster(resp?.message, "success"))
                } else {
                    toast(toastFunctionToaster(resp?.message, "error"))
                }
            })
            .catch(() => toast(toastFunctionToaster("Failed to update preferences", "error")))
            .finally(() => setBtnLoading(prev => ({ ...prev, [update_type]: false })))
    }

    return (
        <Flex 
            w="100vw" 
            h="100vh" 
            justifyContent="center" 
            alignItems="center" 
            px={{ base: 4, md: 0 }} 
            backgroundImage={pageBackgroundImages[3]} 
            bgPosition="center" 
            bgRepeat="no-repeat" 
            bgSize="cover"
        >
            <Card 
                w={{ base: "90%", sm: "80%", md: "50%", lg: "40%", xl: "40%" }} 
                minH="300px"
                maxW="500px" 
                bg={useBrandBg} 
                p={6} 
                borderColor="green.100" 
                borderWidth="0.5px"
            >
                <Flex justifyContent="center" alignItems="center" flexDir="column" w="full">
                    <Image w="50%" mb={3} src={logo} />
                    <Text fontSize="xl" fontWeight="bold">Update Preferences</Text>
                    
                    {!userValidated ? (
                        showPinInput ? (
                            <Flex w="full" gap={2} alignItems="center" justifyContent="center" flexDir="column">
                                <HStack>
                                    <PinInput otp onChange={setPin} size="lg">
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                                <Button colorScheme="green" size="md" isLoading={btnLoading.verify} onClick={validatePin}>Verify</Button>
                            </Flex>
                        ) : (
                            <form style={{ width: "100%" }} onSubmit={handleGeneratePin}>
                                <Flex gap={2} w="full" justifyContent="center" alignItems="center">
                                    <Input 
                                        w="70%" 
                                        type="email" 
                                        isRequired 
                                        placeholder="Enter Email Address" 
                                        value={email} 
                                        onInput={e => setEmail(e.target.value)} 
                                    />
                                    <Button size="md" colorScheme="green" type="submit" isLoading={btnLoading.getPin}>Get Code</Button>
                                </Flex>
                            </form>
                        )
                    ) : (
                        <Flex flexDir="column" w="full">
                            <Flex flexDir="column" w="full">
                                {ALL_PREFERENCE.map(item => (
                                    <Checkbox key={item} isChecked={preferenceList.includes(item)} size="lg" fontWeight="bold" onChange={updatePreferenceList} value={item} textTransform="capitalize">
                                        {item}
                                    </Checkbox>
                                ))}
                            </Flex>
                            <Flex mt={4}>
                                <Button colorScheme="blue" onClick={() => handleUpdateUserPrefrences("update")} isLoading={btnLoading.update}>Update Preferences</Button>
                            </Flex>
                            <Flex w="full" gap={2} flexWrap="wrap" mt={2}>
                                <Button colorScheme="red" onClick={() => handleUpdateUserPrefrences("unsubscribe_all")} isLoading={btnLoading.unsubscribe_all}>Unsubscribe All</Button>
                                <Button colorScheme="green" onClick={() => handleUpdateUserPrefrences("subscribe_all")} isLoading={btnLoading.subscribe_all}>Subscribe All</Button>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Card>
        </Flex>
    )
}

export default UpdatePrefrences
