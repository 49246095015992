// React + Chakra Imports
import React, { useState } from "react";
import { MdUpload } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Text, Flex, Box, Input, Stack, Icon, Img, FormControl, useColorModeValue, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useToast } from "@chakra-ui/react";

// Component + Services Import
import axios from "../../../../../../services/axios";
import Card from "../../../../../../components/card/Card.js";
import { uploadLingoletIdentityLogo } from "../../../../../../services/accountServices.js";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction.js";

export default function UploadLogo(props) {
	const toast = useToast();
	const btnRef = React.useRef();
	const { used, total, identity_uuid, refreshData, ...rest } = props;
	const navigate = useNavigate();
	const { state } = useLocation();
	const textColorSecondary = "gray.400";
	const [loading, isLoading] = useState(true);
	const [uploadFile, setUploadFile] = useState();
	const [uploadStatus, setUploadStatus] = useState();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [uploadFileUrl, setUploadFileUrl] = useState();
	const brandColor = useColorModeValue("brand.500", "white");
	const [uploadStatusLoading, setUploadStatusLoading] = useState(false);
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

	function postObjectData(s3Data, selectedFile) {
		const { url, fields } = s3Data;
		const payload = new FormData();

		// add all the other fields
		Object.entries(fields).forEach(([key, val]) => {
			payload.append(key, val);
		});
		// payload.append("Content-Type", "image/jpeg");
		// This must be the last line in the payload otherwise 412 error may occur
		// this is the file blob, eg from <input type="file">
		payload.append("file", selectedFile);

		// No need to manually set content-type header, your browser knows what to do
		// const { data: result } = axios.post(url, payload);
		// https://stackoverflow.com/questions/46656474/axios-remove-headers-authorization-in-1-call-only
		var ax_instance = axios.create();
		delete ax_instance.defaults.headers.common["Authorization"];
		const { data: result } = ax_instance
			.post(url, payload)
			.then(res => {
				isLoading(false);
				if (res?.data?.length > 0) {
					toast(toastFunctionToaster("File is uploaded successfully", "success"));
					setUploadStatus("File is uploaded successfully");
					refreshData();
					handleCloseDrawer();
				} else {
					setUploadStatus("File is uploaded successfully");
					toast(toastFunctionToaster("File is uploaded successfully", "success"));
					refreshData();
					handleCloseDrawer();
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster('Upload failed with Error :" + err', "error"));
				setUploadStatus("Upload failed with Error :" + err);
				handleCloseDrawer();
			});
	}

	const handleFileChange = e => {
		if (e.target.files) {
			setUploadFile(e.target.files[0]);
			setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
		}
	};
	const handleSubmitNew = async () => {
		setUploadStatusLoading(true);
		if (uploadFile) {
			let objData = {
				identity_uuid: identity_uuid,
				content_choice: "logo",
				file_name: uploadFile.name,
				file_type: uploadFile.type,
			};
			setUploadStatus("Getting upload url for the selected file..");
			const response = await uploadLingoletIdentityLogo(objData);
			setUploadStatusLoading(false);
			if (response && response.data && response.data.length > 0) {
				setUploadStatusLoading(false);
				const finalUrl = response.data[0].signedPackage.signedUrl;
				setUploadStatus("Trying to upload the selected file..");
				const s3UploadResult = await postObjectData(finalUrl, uploadFile);
			} else {
				setUploadStatusLoading(false);
				setUploadStatus("Unable to get the upload signed url..");
			}
		}
	};

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setUploadFile("");
		setUploadFileUrl("");
		setUploadStatus("");
		onClose();
	}

	return (
		<>
			<Button
				ref={btnRef}
				w="100%"
				onClick={onOpen}
				justifyContent={"start"}
				px="12px"
				fontWeight={"400"}
				_hover={{ bg: "blue.100", _active: "blue.100" }}
				sx={{
					bg: "blue.100",
					borderStyle: "dashed",
					borderTop: "none",
					borderWidth: "1px",
					borderColor: "black",
					color: "black",
					rounded: "sm",
				}}
			>
				Upload Logo
			</Button>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Upload Logo
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<Card {...rest} mb="20px" align="center" p="20px">
							<Box w={"100%"}>
								<FormControl cursor="pointer">
									<Text fontSize={"12px"}>{uploadFileUrl}</Text>
									<Text mb={4} fontSize={"12px"}>
										{uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
									</Text>
									<Box position="relative" height="200px" width="100%" borderWidth="2px" borderColor={brandColor} borderRadius={"10px"} cursor="pointer">
										<Box position="absolute" top="0" left="0" height="100%" width="100%" display="flex" flexDirection="column" cursor="pointer">
											<Stack height="100%" width="100%" display="flex" alignItems="center" justify="center" spacing="4" cursor="pointer">
												<Box textAlign="center">
													<Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
													<Flex justify="center" mx="auto" mb="12px">
														<Text fontSize="xl" fontWeight="700" color={brandColor}>
															Upload Files
														</Text>
													</Flex>
													<Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
														PNG, JPG and GIF files are allowed
													</Text>
												</Box>
											</Stack>
										</Box>
										<Input
											type="file"
											height="100%"
											width="100%"
											position="absolute"
											top="0"
											left="0"
											opacity="0"
											aria-hidden="true"
											accept=".png, .jpg, .jpeg, .pdf" // Include .pdf here to accept PDF files
											cursor="pointer"
											onChange={handleFileChange}
										/>
									</Box>
								</FormControl>
							</Box>

							{uploadFile ? (
								<>
									<Flex w="100%" mb="30px" mt="20px" alignItems="center" justifyContent={"space-between"}>
										<Img src={uploadFileUrl} w="200px" />
										<Button me="auto" w="60%" ml="2" variant="brand" fontWeight="500" onClick={handleSubmitNew} isDisabled={uploadStatusLoading ? true : false} isLoading={uploadStatusLoading ? true : false}>
											Upload Logo
										</Button>
									</Flex>
									<Box w={"100%"}>
										<Text color="green">{uploadStatus}</Text>
									</Box>
								</>
							) : null}
						</Card>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
