import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineRobot, AiOutlineClose } from "react-icons/ai";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Flex,
    FormLabel,
    Heading,
    Icon,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/react";

import BasicAiLanguageList from "../../../../utils/BasicAILanguageList";
import BasicAiSourceLanguageList from "../../../../utils/BasicAiSourceLanguageList";
import KioskAgentUI from "./KioskAgentUI";

import { MdQrCode2 } from "react-icons/md";
import QRCode from "react-qr-code";


const KioskAISettings = props => {
    const navigate = useNavigate();
    const langArr = BasicAiLanguageList;
    const sourceLanguageArr = BasicAiSourceLanguageList;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

    const currentHost =  window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";

    const launchKey = props.launchKey ? props.launchKey : null;
    const launchCode = props.launchCode ? props.launchCode : null;
    const sessionKey = props.sessionKey ? props.sessionKey : null;

    // Todo:Store Dropdown Data
    const [basicAIFlag, setBasicAIFlag] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedSettings, setSelectedSettings] = useState({});
    const [sourceLanguage, setSourceLanguage] = useState("English");
    const [targetLanguage, setTargetLanguage] = useState("Hindi");

    useEffect(() => {
        if (selectedValue?.length) {
            renderSelectedValue();
        }
    }, [selectedValue]);

    const renderSelectedValue = () => {
        if (selectedValue === "Source") {
            renderLangugageData();
        } else {
            renderLangugageData()
        }
    };

    useEffect(() => {
        let obj = {
            sourceLangCode: sourceLanguage,
            targetLangCode: targetLanguage,
        };
        setSelectedSettings(obj);
    }, [sourceLanguage, targetLanguage]);

    const renderBasicAISelectUI = () => {
        return renderSelectionFormData();
    };

    function handleStartKioskAI() {
        props?.verifyAccessCode().then(resp=>{
            if(resp){
                setBasicAIFlag(true);
            }
        })
    }

    const renderSelectionFormData = () => {
        return (
            <Flex direction="column" gap="4" w="100%">
                <Flex w={'100%'} align={'center'} justify={'center'}>
                    <Text px="2" bg={'gray.200'} rounded={'lg'} fontSize={'xl'}>Kiosk AI</Text>
                </Flex>
                <Box p="2" borderWidth={"1px"} bg="#fff" borderRadius={"10px"} w="100%">
                    <Text fontWeight="bold" fontSize="large" mb="2">Client Language</Text>
                    <Flex w="100%" mb="4" alignItems={"center"} borderWidth={"1px"} borderRadius={"10px"} justifyContent={"space-between"} p="2">
                        <FormLabel mb="0px" w="25%" color="gray.800">
                            Target Language{" "}
                        </FormLabel>
                        <Text w="50%" bg="#e2e8f0" borderWidth="1px" p="2" borderRadius={"10px"} mr="3" color="gray.800">
                            {targetLanguage}
                        </Text>
                        <Button
                            size="md"
                            bg="#e2e8f0"
                            ml="2"
                            color="gray.600"
                            borderRadius={"10px"}
                            onClick={() => {
                                onOpen();
                                setSelectedValue("Target");
                            }}
                        >
                            ...
                        </Button>
                    </Flex>
                    <Flex justifyContent={"end"}>
                        {targetLanguage && <Button
                            type="button"
                            bg="blue.200"
                            borderRadius={"10px"}
                            onClick={() => {
                                onModalOpen()
                            }}
                            _hover={{ bg: "blue.200" }}
                            mr="2"
                        >
                            <Icon as={MdQrCode2} mr={1} />
                            Show QR Code
                        </Button>}
                    </Flex>
                </Box>

                <Flex mt={5} w={'100%'} 
                    direction={'column'}
                    rounded={'lg'}
                    p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                    <Box p="2" borderWidth={"1px"} bg="#fff" borderRadius={"10px"} w="100%">
                        <Text fontWeight="bold" fontSize="large" mb="2">Agent Language</Text>
                        <Flex w="100%" alignItems={"center"} borderWidth={"1px"} borderRadius={"10px"} justifyContent={"space-between"} p="2">
                            <FormLabel mb="0px" w="25%" color="gray.800">
                                Source Language{" "}
                            </FormLabel>
                            <Text w="50%" bg="#e2e8f0" borderWidth="1px" p="2" borderRadius={"10px"} mr="3" color="gray.800">
                                {sourceLanguage}
                            </Text>
                            <Button
                                size="md"
                                bg="#e2e8f0"
                                ml="2"
                                color="gray.600"
                                borderRadius={"10px"}
                                onClick={() => {
                                    onOpen();
                                    setSelectedValue("Source");
                                }}
                            >
                                ...
                            </Button>
                        </Flex>
                    </Box>

                    <Flex justifyContent={"end"} gap="2" mt="3">
                        <Button
                            type="button"
                            colorScheme="red"
                            borderRadius={"10px"}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Back
                        </Button>
                        {targetLanguage && <Button
                            type="button"
                            bg="blue.200"
                            borderRadius={"10px"}
                            // onClick={() => {
                            // 	setBasicAIFlag(true);
                            // }}
                            onClick={handleStartKioskAI}
                            _hover={{ bg: "blue.200" }}

                        >
                            <Icon as={AiOutlineRobot} mr={1} />
                            Start Communication
                        </Button>}
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    const OptionItem = ({ data, onClick, isSelected, text }) => (
        <Text
            key={data.name}
            value={data.name}
            color="#000"
            w="31%"
            borderWidth="1px"
            p="2"
            borderRadius="10px"
            m="1%"
            onClick={() => onClick(data.name)}
            bg={isSelected ? "green.200" : "#e2e8f0"}
            cursor="pointer"
        >
            {text || data.name}
        </Text>
    );

    const renderOptions = (dataArray, onClickHandler, selectedItem) => {
        return dataArray.map((data, index) => (
            <OptionItem
                data={data}
                key={index}
                onClick={onClickHandler}
                isSelected={Array.isArray(selectedItem) ? selectedItem.includes(data.name) : data.name === selectedItem}
            />
        ));
    };

    const renderLangugageData = () => {
        return renderOptions(sourceLanguageArr, setSourceLanguage, sourceLanguage);
    };

    const renderToLanguage = lang => {
        return renderOptions(sourceLanguageArr, setTargetLanguage, targetLanguage)
    };

    const handlerCloser = () => {
        onClose();
    };

    return (
        <>
            <Box w={"100%"} h="100%" overflow={"auto"}>
                {launchKey != null && sessionKey != null ? (
                    <>
                        <Flex w="100%" h="100%" flexWrap={"wrap"}>
                            {!basicAIFlag ? (
                                <Flex
                                    w={{
                                        base: "100%",
                                        md: "100%",
                                        lg: "50%",
                                    }}
                                    p="10px"
                                    mx="auto"
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    {renderBasicAISelectUI()}
                                </Flex>
                            ) : (
                                <Box w="100%" borderLeftWidth={"0px"}>
                                    <KioskAgentUI
										launchKey={launchKey}
										launchCode={launchCode}
										sessionKey={sessionKey}
										selectedSettings={selectedSettings}
									></KioskAgentUI>
                                </Box>
                            )}
                        </Flex>
                    </>
                ) : (
                    <Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
                        <Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
                            Hmm....{" "}
                            <Text as="span" fontWeight={"600"} mr="1">
                                Something is not right!!
                            </Text>
                        </Heading>
                        <Text mt="3" fontSize={"17px"} color="gray.700">
                            Looks like, there is some issues with Human Translation...
                        </Text>
                    </Flex>
                )}
            </Box>
            {/* Drawer Ui */}
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    handlerCloser();
                }}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        Select {selectedValue} {selectedValue === "Service" ? "Type" : "Language"}{" "}
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex flexWrap={"wrap"}>
                            {selectedValue === "Source" ? renderLangugageData() : selectedValue === "Target" ? renderToLanguage() : null}
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            variant="outline"
                            size="sm"
                            borderRadius="10px"
                            mr={3}
                            onClick={() => {
                                handlerCloser();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme="green"
                            size="sm"
                            borderRadius="10px"
                            onClick={() => {
                                handlerCloser();
                            }}
                        >
                            Save
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>QR Code</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex p={2} direction="column" gap="2" alignItems="center">
                            <QRCode
                                title={"Qr code"}
                                size={60}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={currentHost + 'kioskai?key=' +  launchKey + '&code=' + launchCode + '&source=' + targetLanguage + '&target=' + sourceLanguage}
                                viewBox={`0 0 90 90`}
                            />
                            <Text fontSize="md" fontWeight="bold">{`Client Language: ${targetLanguage}`}</Text>
                            <Text fontSize="xs" p={2} fontWeight="bold">{`Target URL: ${currentHost + 'kioskai?key=' +  launchKey + '&code=' + launchCode + '&source=' + sourceLanguage + '&target=' + targetLanguage}`}</Text>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default KioskAISettings;
