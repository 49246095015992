// React + Chakra Imports
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { Flex, Text, Alert, AlertIcon, Spinner, useToast, Icon, useColorModeValue } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getLingoletIdTaxList } from "../../../../../../services/accountServices";

function TaxList({ identity_uuid, setclientData, reRenderContactList, idx, setIdxValue }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [taxDetailsList, setTaxDetailsList] = useState([]);
	const shadowBlack = useColorModeValue("0px 3px 5px rgb(107 111 110)", "0px 3px 5px rgb(107 111 110);");

	const fields = [
		{ label: "Tax Identifier", key: "identity_tax_identifier" },
		{ label: "Tax Info", key: "identity_tax_identifier_info" },
		{ label: "Tax Rate", key: "identity_tax_identifier_rate" },
		{ label: "Country", key: "identity_tax_identifier_country" },
		{ label: "State", key: "identity_tax_identifier_state" },
		{ label: "Active", key: "identity_tax_active" },
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchContactDetails();
	}, []);

	useEffect(() => {
		if (reRenderContactList !== null) {
			//After update couter flags and function triggers
			fetchContactDetails();
		}
	}, [reRenderContactList]);

	// Todo:Fetch Client Details
	const fetchContactDetails = () => {
		isLoading(true);
		const obj = {
			identity_uuid: identity_uuid,
			identity_tax_active: "active",
		};

		getLingoletIdTaxList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setTaxDetailsList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setTaxDetailsList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderTaxList =
		taxDetailsList?.length > 0 ? (
			taxDetailsList.map((data, index) => (
				<Flex flexWrap={"wrap"} p="4" key={data?.identity_uuid + index} borderWidth="1px" w="49%" alignItems={"start"} fontSize="15px" mb="4" borderRadius={"10px"} borderColor={"#a5a5a5"} position={"relative"} boxShadow={shadowBlack}>
					{fields.map(({ label, key }) => (
						<Flex mb="3" mr="3" key={key} w="45%">
							<Text fontWeight="bold" w="50%">
								{label}:
							</Text>
							<Text w="50%" ml="2">
								{data[key] !== undefined ? data[key]?.toString() : "No data found"}
							</Text>
						</Flex>
					))}
					<Flex
						rounded={"md"}
						p={2}
						alignItems={"center"}
						borderWidth={"1px"}
						borderColor={"gray.400"}
						align={"end"}
						cursor={"pointer"}
						onClick={() => {
							setclientData(data);
							setIdxValue(idx);
						}}
						borderRadius={"50px"}
						position={"absolute"}
						right="8px"
					>
						<Icon as={AiFillEdit} rounded={"md"} fontSize={"14px"} cursor={"pointer"} />
					</Flex>
				</Flex>
			))
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} borderWidth={"1px"} p="4" flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderTaxList}</>
			)}
		</Flex>
	);
}

export default TaxList;
