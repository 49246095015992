// React + Chakra Imports
import React, { useState } from "react";
import { Flex, Text, Tab, Tabs, TabList, TabPanel, TabPanels } from "@chakra-ui/react";

// Component Imports
import Card from "../../../../components/card/Card";
import Active from "./active/Active";
import Completed from "./completed/Completed";
import Pending from "./pending/Pending";
import Closed from "./closed/Closed";
import AddUpdateBooking from "./AddUpdateBooking";

function BookingHome() {
	const [updateCounter, setUpdateCounter] = useState(null);
	const [editFlag, setEdit] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const handleRefresh = () => {
		setRefresh(prev => !prev); // Toggle the refresh state
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w={"100%"} rounded={"md"} mb={2} p="20px">
				<Tabs variant="enclosed" size={"md"}>
					<Flex alignItems="start" justifyContent={"space-between"} borderBottomWidth={"1px"} borderColor={"#a5a5a5"}>
						<AddUpdateBooking onAddSuccess={handleRefresh} />
						<TabList border="0px">
							<Tab
								_selected={{
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#a5a5a5",
									fontWeight: "500",
									color: "black",
									borderBottom: "0px",
									mb: "-4px",
								}}
								sx={{
									bg: "#fce5cd",
									color: "black",
									borderStyle: "solid",
									borderWidth: "1px",
									// borderColor: "grey.500",
									rounded: "md",
									borderBottomLeftRadius: "0px",
									borderBottomRightRadius: "0px",
								}}
								mr="2"
							>
								Active
							</Tab>
							<Tab
								_selected={{
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#a5a5a5",
									fontWeight: "500",
									color: "black",
									borderBottom: "0px",
									mb: "-2px",
								}}
								sx={{
									bg: "#fce5cd",
									color: "black",
									borderStyle: "solid",
									borderWidth: "1px",
									rounded: "md",
									borderBottomLeftRadius: "0px",
									borderBottomRightRadius: "0px",
									borderBottomWidth: "0",
								}}
								mr="2"
							>
								Completed
							</Tab>
							<Tab
								_selected={{
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#a5a5a5",
									fontWeight: "500",
									color: "black",
									borderBottom: "0px",
									mb: "-2px",
								}}
								sx={{
									bg: "#fce5cd",
									color: "black",
									borderStyle: "solid",
									borderWidth: "1px",
									rounded: "md",
									borderBottomLeftRadius: "0px",
									borderBottomRightRadius: "0px",
									borderBottomWidth: "0",
								}}
								mr="2"
							>
								Pending
							</Tab>
							<Tab
								_selected={{
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#a5a5a5",
									fontWeight: "500",
									color: "black",
									borderBottom: "0px",
									mb: "-2px",
								}}
								sx={{
									bg: "#fce5cd",
									color: "black",
									borderStyle: "solid",
									borderWidth: "1px",
									rounded: "md",
									borderBottomLeftRadius: "0px",
									borderBottomRightRadius: "0px",
									borderBottomWidth: "0",
								}}
							>
								Closed Paid
							</Tab>
						</TabList>
					</Flex>
					<TabPanels>
						<TabPanel p="0">
							<Active refresh={refresh} />
						</TabPanel>
						<TabPanel p="0">
							<Completed />
						</TabPanel>
						<TabPanel p="0">
							<Pending />
						</TabPanel>
						<TabPanel p="0">
							<Closed />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default BookingHome;
