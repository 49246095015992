// Chakra Imports
import React from "react";
import { useNavigate } from "react-router-dom";

import { FaAngleDown, FaGlobe } from "react-icons/fa6";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Image, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Stack, useDisclosure } from "@chakra-ui/react";

// Component Imports
import Resources from "./content/Resources";
import AIPlatform from "./content/AIPlatform";
import IndustrySolutions from "./content/IndustrySolutions";
import LogoImg from "../../../assets/landingPageImg/kb_logo_new1.png";

const Navbar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();

	return (
		<Box zIndex={"999"} 
			bg={'gray.200'}
			w={"full"} 
			//bg={"white"} 
			boxShadow="dark-lg" position={"fixed"} p={"15px"}>
			<Flex justify={"space-between"} align={"center"} display={{ base: "none", md: "flex" }}>
				<Box>
					<Stack direction={"row"} align={"center"} gap={2}>
						<Image src={LogoImg} w={"180px"} objectFit={"contain"} mr={3} />
						<AIPlatform />
						<IndustrySolutions />
						{/* <Resources /> */}
					</Stack>
				</Box>

				<Box>
					<Stack gap={2} direction={"row"} align={"center"}>
						<Menu closeOnSelect={false}>
							<MenuButton>
								<Stack direction={"row"} gap={1}>
									<FaGlobe />
									<FaAngleDown />
								</Stack>
							</MenuButton>

							<MenuList mt={"1rem"} minW={"150px"}>
								<MenuOptionGroup defaultValue="asc" title="LANGUAGES" type="radio">
									<MenuItemOption value="en">English</MenuItemOption>
									<MenuItemOption value="por">Japanese</MenuItemOption>
									<MenuItemOption value="por">Spanish</MenuItemOption>
								</MenuOptionGroup>
							</MenuList>
						</Menu>
						<Button 
							variant={"outline"} 
							py={1}
							onClick={() => {
								navigate("/login")
							}}
							mt="1rem" borderColor={"#259edc"} fontWeight={"400"} 
							color="#259edc" borderRadius={"50px"} 
							_hover={{ borderColor: "red.200", color: "#259edc" }} 
							fontSize={"14px"}>
							Login
						</Button>

						{/* <Button mt="1rem" bg={"#259edc"} fontWeight={"400"} color="white" borderRadius={"50px"} _hover={{ bg: "#259edc", color: "white" }} fontSize={"14px"}>
							SUBSCRIBE NOW
						</Button> */}
					</Stack>
				</Box>
			</Flex>

			{/* Mobile Menu Button */}
			<Flex justifyContent={"space-between"} alignItems={"center"} display={{ sm: "flex", md: "none" }}>
				<Image src={LogoImg} w={"75px"} objectFit={"contain"} mr={3} />
				<IconButton size="md" icon={isOpen ? <CloseIcon /> : <HamburgerIcon />} aria-label="Open Menu" onClick={isOpen ? onClose : onOpen} />
			</Flex>

			{/* Mobile Navigation */}
			{isOpen ? (
				<Box pb={4} display={{ md: "none" }}>
					<Stack as="nav" spacing={4}>
						<AIPlatform />
						<IndustrySolutions />
						<Resources />
					</Stack>
				</Box>
			) : null}
		</Box>
	);
};

export default Navbar;
