// React + Chakra Imports
import React, { useState, useEffect } from "react";
import { Flex, Button, Input, Text, Switch, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, Select, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createLingoletIdBank, updateLingoletIdBank } from "../../../../../../services/accountServices";

function AddUpdateBankDetails({ identity_uuid, passClientData, setclientData, updateClientDataCounter, idValue, indexValue }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [counter, setCounter] = useState(0);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

	const initialFormData = {
		identity_bank_account_type: "savings",
		identity_bank_account_number: "",
		identity_bank_name: "",
		identity_bank_location: "",
		identity_bank_branch: "",
		identity_bank_address: "",
		identity_bank_account_description: "",
		identity_bank_account_reference: "",
		identity_bank_sft_routing_transit: "",
		identity_bank_account_swift: "",
		identity_bank_account_iban: "",
		identity_bank_currency_code: "",
		identity_bank_currency_symbol: "",
		identity_bank_active: true,
	};
	const [formData, setFormData] = useState(initialFormData);

	// Todo:Update Form Details On Edit
	useEffect(() => {
		if (passClientData !== null || (passClientData !== undefined && passClientData?.identity_bank_uuid)) {
			setFormData({
				identity_bank_account_type: passClientData.identity_bank_account_type || "",
				identity_bank_account_number: passClientData.identity_bank_account_number || "",
				identity_bank_name: passClientData.identity_bank_name || "",
				identity_bank_location: passClientData.identity_bank_location || "",
				identity_bank_branch: passClientData.identity_bank_branch || "",
				identity_bank_address: passClientData.identity_bank_address || "",
				identity_bank_account_description: passClientData.identity_bank_account_description || "",
				identity_bank_account_reference: passClientData.identity_bank_account_reference || "",
				identity_bank_sft_routing_transit: passClientData.identity_bank_sft_routing_transit || "",
				identity_bank_account_swift: passClientData.identity_bank_account_swift || "",
				identity_bank_account_iban: passClientData.identity_bank_account_iban || "",
				identity_bank_currency_code: passClientData.identity_bank_currency_code || "",
				identity_bank_currency_symbol: passClientData.identity_bank_currency_symbol || "",
				identity_bank_active: passClientData.identity_bank_active || true,
			});
			if (idValue === indexValue) {
				onOpen();
			}
		} else {
			setFormData(initialFormData);
		}
	}, [passClientData, idValue, indexValue]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" || type === "switch" ? checked : value,
		});
	};
	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		if (!formData.identity_bank_account_type) {
			formIsValid = false;
			errors["contactHome"] = "*Please enter your first name.";
		}

		if (!formData.identity_bank_account_number) {
			formIsValid = false;
			errors["contactNumber"] = "*Please enter your last name.";
		}

		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			formData["identity_uuid"] = identity_uuid;
			if (passClientData?.identity_bank_uuid) {
				formData["identity_uuid"] = passClientData?.identity_uuid;
				formData["identity_bank_uuid"] = passClientData?.identity_bank_uuid;
				updateLingoletIdBank(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createLingoletIdBank(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	//Todo:Update Counter when data updated to refresh data
	useEffect(() => {
		updateClientDataCounter(counter);
	}, [counter]);

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setFormData(initialFormData);
		setError({});
		setclientData(null);
		onClose();
	}

	// Todo:Reset Form Data

	return (
		<>
			<Button
				ref={btnRef}
				w="100%"
				onClick={onOpen}
				justifyContent={"start"}
				px="12px"
				fontWeight={"400"}
				_hover={{ bg: "blue.100", _active: "blue.100" }}
				sx={{
					bg: "blue.100",
					borderStyle: "dashed",
					borderTop: "none",
					borderWidth: "1px",
					borderColor: "black",
					color: "black",
					rounded: "sm",
				}}
			>
				Bank
			</Button>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{passClientData?.identity_bank_uuid ? "Update" : "Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Bank
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* Bank Account Type */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Bank Account Type
								</FormLabel>
								<Select name="identity_bank_account_type" value={formData.identity_bank_account_type} onChange={handleChange} color={textColorPrimary}>
									<option value="savings">Savings</option>
									<option value="current">Current</option>
									<option value="fixed">Fixed</option>
								</Select>
								{error.contactHome ? (
									<Text color="red" mb="2">
										{error.contactHome}
									</Text>
								) : null}
							</FormControl>

							{/* Bank Account Number */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Bank Account Number
								</FormLabel>
								<Input disabled={passClientData?.identity_bank_uuid ? true : false} type="text" name="identity_bank_account_number" value={formData.identity_bank_account_number} onChange={handleChange} color={textColorPrimary} />
								{error.contactNumber ? (
									<Text color="red" mb="2">
										{error.contactNumber}
									</Text>
								) : null}
							</FormControl>

							{/* Bank Name */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Bank Name
								</FormLabel>
								<Input type="text" name="identity_bank_name" value={formData.identity_bank_name} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Bank Location */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Bank Location
								</FormLabel>
								<Input type="text" name="identity_bank_location" value={formData.identity_bank_location} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							<>
								{/* Bank Branch */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Branch
									</FormLabel>
									<Input type="text" name="identity_bank_branch" value={formData.identity_bank_branch} onChange={handleChange} color={textColorPrimary} />
								</FormControl>

								{/* Bank Address */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Address
									</FormLabel>
									<Input type="text" name="identity_bank_address" value={formData.identity_bank_address} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Bank Account Description */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Account Description
									</FormLabel>
									<Input type="text" name="identity_bank_account_description" value={formData.identity_bank_account_description} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Bank Account Reference */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Account Reference
									</FormLabel>
									<Input type="text" name="identity_bank_account_reference" value={formData.identity_bank_account_reference} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Bank Account Routing Transit */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Sft Routing Transit
									</FormLabel>
									<Input type="text" name="identity_bank_sft_routing_transit" value={formData.identity_bank_sft_routing_transit} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Bank Account Swift */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Account Swift
									</FormLabel>
									<Input type="text" name="identity_bank_account_swift" value={formData.identity_bank_account_swift} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Bank Account IBan */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Bank Account IBan
									</FormLabel>
									<Input type="text" name="identity_bank_account_iban" value={formData.identity_bank_account_iban} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Currency Code */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Currency Code
									</FormLabel>
									<Input type="text" name="identity_bank_currency_code" value={formData.identity_bank_currency_code} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Currency Code */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Currency Symbol
									</FormLabel>
									<Input type="text" name="identity_bank_currency_symbol" value={formData.identity_bank_currency_symbol} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Active Status */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Status
									</FormLabel>
									<Switch name="identity_bank_active" isChecked={formData.identity_bank_active} onChange={handleChange} />
								</FormControl>
							</>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{passClientData?.identity_bank_uuid ? "Update" : "Create"}
							</Button>
							<Button
								variant="outline"
								colorScheme="red"
								ml={3}
								onClick={() => {
									handleCloseDrawer();
								}}
							>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateBankDetails;
