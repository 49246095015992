import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const DynamicFormCardList = ({ formData,updateFormDetails }) => {
    const navigate = useNavigate()
    const subRoute = window.location.pathname.split('/')[1]
    return (
        <>
            {
                formData && formData?.length > 0 &&(
                    <Flex flexDir="column" gap="2">{
                        formData.map(data => (
                            <Flex
                                justify="space-between"
                                align="center"
                                p="4"
                                bg="gray.100"
                                border="1px solid"
                                borderColor="gray.300"
                                borderRadius="md"
                                w="100%"
                            >
                                <Text fontSize="md" fontWeight="medium">
                                    {data?.form_name}
                                </Text>

                                <Flex gap="2">
                                    <Button size="sm" bg="green.100" color="black" _hover={{ bg: "green.200" }} onClick={()=>{
                                        navigate(`/${subRoute}/lingoforms/launch`,{
                                            state:{
                                                data
                                            }
                                        })
                                    }}>
                                        Test Launch
                                    </Button>
                                    <Button size="sm" bg="green.100" color="black" _hover={{ bg: "green.200" }} onClick={()=>{
                                        navigate(`/${subRoute}/lingoforms/contentList`,{
                                            state:{
                                                data
                                            }
                                        })
                                    }}>
                                        Completed List
                                    </Button>
                                    <Button size="sm" bg="green.100" color="black" _hover={{ bg: "green.200" }} onClick={()=>updateFormDetails(data)}>
                                        Edit
                                    </Button>
                                </Flex>
                            </Flex>
                        ))
                    }</Flex>

                )
            }
        </>
    )
}

export default DynamicFormCardList