// React + Chakra Imports
import { FiRefreshCw } from "react-icons/fi";
import { FaSort, FaFilter } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTriangleInverted } from "react-icons/tb";
import { Flex, Button, Input, HStack, Box, Text, Tab, Tabs, TabList, TabPanel, TabPanels, MenuItem, MenuList, ButtonGroup, MenuButton, Menu, Circle, VStack, useColorModeValue, Alert, AlertIcon, Spinner, IconButton, useToast, Img } from "@chakra-ui/react";

// Component + Services Import
import TaxList from "./tax/TaxList";
import BankList from "./bank/BankList";
import CompanyList from "./company/CompanyList";
import ContactList from "./contact/ContactList";
import UploadLogo from "./uploadLogo/UploadLogo";
import AddUpdateContact from "./contact/AddUpdateContact";
import AddUpdateTaxDetails from "./tax/AddUpdateTaxDetails";
import AddUpdateBankDetails from "./bank/AddUpdateBankDetails";
import Pagination from "../../../../../components/pagination/Pagination";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import AddUpdateCompanyDetails from "./company/AddUpdateCompanyDetails";
import { getLingoletIdentityList } from "../../../../../services/accountServices";

function Client({ setclientData, handlerDrawer, refreshData, type, selectedTab }) {
	const toast = useToast();
	const [currPage, setCurrPage] = useState(1);
	const [loading, isLoading] = useState(false);
	const [clientList, setClientList] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [activeTabs, setActiveTabs] = useState(null);
	const tabBg = useColorModeValue("#eeeeee", "#707070");
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	// Todo:Handle Fields
	const [contactDetails, setContactDetails] = useState(null);
	const [bankDetails, setBankDetails] = useState(null);
	const [taxDetails, setTaxDetails] = useState(null);
	const [companyDetails, setCompanyDetails] = useState(null);
	const [updateCounter, setUpdateCounter] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [sortOrder, setSortOrder] = useState("asc");
	const [quickFilterValue, setQuickFilterValue] = useState("");

	useEffect(() => {
		fetchClientDetails();
	}, [currPage, recordsPerPage, selectedTab, sortOrder, quickFilterValue]);

	useEffect(() => {
		if (refreshData !== null && refreshData > 0) {
			fetchClientDetails();
		}
	}, [refreshData]);

	// Todo:Fetch Client Details
	const fetchClientDetails = () => {
		isLoading(true);
		const obj = {
			filter_key: "identity_type",
			filter_value: type,
			quick_filter_value: quickFilterValue,
			sort_order: sortOrder,
			pageId: currPage,
			records_per_page: recordsPerPage,
		};

		getLingoletIdentityList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setTotalPages(res?.data[0]?.total_pages);
					setClientList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setClientList([]);
					setTotalPages(0);
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const handleTabClick = (clientIndex, tabIndex) => {
		setActiveTabs(prevActiveTabs => ({
			...prevActiveTabs,
			[clientIndex]: prevActiveTabs?.[clientIndex] === tabIndex ? null : tabIndex,
		}));
	};

	// Todo: Render Client Data
	const renderClientListData =
		clientList?.length > 0 ? (
			clientList.map((data, index) => (
				<Tabs key={data?.identity_uuid} variant="enclosed" size="md" borderWidth="1px" borderRadius="10px" mb="3" index={activeTabs?.[index]}>
					<Flex p="10px" justifyContent="space-between">
						<Flex w="98%">
							{data?.identity_image ? (
								<Img src={data?.identity_image} h="90px" w="90px" borderWidth="1px" mr="3" borderRadius="10px" objectFit="cover" />
							) : (
								<Flex alignItems="center" justifyContent="center" h="90px" w="90px" borderWidth="1px" mr="3" borderRadius="10px">
									Logo
								</Flex>
							)}
							<Box w="100%">
								<Flex justifyContent={"space-between"}>
									<Box>
										<Text fontSize="18px" fontWeight="500">
											{data?.id_salutation}
											{data?.id_first_name} {data?.id_last_name}
										</Text>
										{data?.id_nationality ? (
											<Text fontWeight="500" fontSize="15px">
												{/* 1234, Street Name, Suite X, City, State, Zip, Country */}
												{data?.id_nationality}
											</Text>
										) : null}
									</Box>
									<VStack gap={2} align={"flex-start"}>
										<Menu>
											<MenuButton as={Box}>
												<ButtonGroup
													isAttached
													spacing={0}
													borderRadius={"10px"}
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
													size="sm"
												>
													<Button borderColor="black" bg="blue.100" rounded={"sm"} borderTopLeftRadius="10px" borderBottomLeftRadius="10px" fontWeight={"500"}>
														<Circle size={"10px"} bg={"limegreen"} mr={1} /> Action
													</Button>
													<Button borderTopRightRadius="10px" borderBottomRightRadius="10px" borderStyle="dashed" borderLeftWidth="1px" borderColor="black" bg="blue.100" rounded={"sm"} cursor={"pointer"}>
														<TbTriangleInverted />
													</Button>
												</ButtonGroup>
											</MenuButton>
											<MenuList
												sx={{
													p: 0,
													m: 0,
												}}
												zIndex={9}
											>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
													onClick={() => {
														setclientData(data);
														handlerDrawer(true);
													}}
												>
													Edit Details
												</MenuItem>
												<MenuItem p="0">
													<UploadLogo identity_uuid={data?.identity_uuid} refreshData={fetchClientDetails} />
												</MenuItem>

												<MenuItem p="0" key={index}>
													<AddUpdateContact identity_uuid={data?.identity_uuid} passClientData={contactDetails} setclientData={setContactDetails} updateClientDataCounter={setUpdateCounter} idValue={selectedIndex} indexValue={index} />
												</MenuItem>

												<MenuItem p="0">
													<AddUpdateBankDetails identity_uuid={data?.identity_uuid} passClientData={bankDetails} setclientData={setBankDetails} updateClientDataCounter={setUpdateCounter} idValue={selectedIndex} indexValue={index} />
												</MenuItem>

												<MenuItem p="0">
													<AddUpdateTaxDetails identity_uuid={data?.identity_uuid} passClientData={taxDetails} setclientData={setTaxDetails} updateClientDataCounter={setUpdateCounter} idValue={selectedIndex} indexValue={index} />
												</MenuItem>

												<MenuItem p="0">
													<AddUpdateCompanyDetails identity_uuid={data?.identity_uuid} passClientData={companyDetails} companyDetails={setCompanyDetails} updateClientDataCounter={setUpdateCounter} idValue={selectedIndex} indexValue={index} />
												</MenuItem>
											</MenuList>
										</Menu>
										<Menu m="0px">
											<MenuButton as={Box} m="0px !important">
												<ButtonGroup
													isAttached
													spacing={0}
													borderRadius={"10px"}
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
													size="sm"
												>
													<Button borderColor="black" bg="blue.100" rounded={"sm"} borderTopLeftRadius="10px" borderBottomLeftRadius="10px" fontWeight={"500"}>
														<Circle size={"10px"} bg={"limegreen"} mr={1} /> Review
													</Button>
													<Button borderTopRightRadius="10px" borderBottomRightRadius="10px" borderStyle="dashed" borderLeftWidth="1px" borderColor="black" bg="blue.100" rounded={"sm"} cursor={"pointer"}>
														<TbTriangleInverted />
													</Button>
												</ButtonGroup>
											</MenuButton>
											<MenuList
												sx={{
													p: 0,
													m: 0,
												}}
												zIndex={9}
											>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Jobs
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Bookings
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Payments
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Billings
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Activities
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Report
												</MenuItem>
												<MenuItem
													sx={{
														bg: "blue.100",
														borderStyle: "dashed",
														borderTop: "none",
														borderWidth: "1px",
														borderColor: "black",
														color: "black",
														rounded: "sm",
													}}
												>
													Analysis
												</MenuItem>
											</MenuList>
										</Menu>
									</VStack>
								</Flex>

								<TabList border="0px" mt="-20px">
									{["Contact", "Banking", "Tax", "Company"].map((tabName, tabIndex) => (
										<Tab
											key={tabIndex}
											onClick={() => handleTabClick(index, tabIndex)}
											border={"0px"}
											_selected={{
												borderWidth: activeTabs?.[index] === tabIndex ? "1px" : "0px",
												borderStyle: "solid",
												borderColor: "#a5a5a5",
												fontWeight: "500",
												color: "black",
												borderBottom: "0px",
												mb: "-4px",
											}}
											sx={{
												bg: "green.100",
												color: "black",
												rounded: "md",
												borderBottomLeftRadius: "0px",
												borderBottomRightRadius: "0px",
												// No initial border applied here
											}}
											mr={tabName === "Address" ? 0 : "5px"}
										>
											{tabName}
										</Tab>
									))}
								</TabList>

								<TabPanels>
									<TabPanel p="0">{activeTabs?.[index] === 0 && <ContactList identity_uuid={data?.identity_uuid} setclientData={setContactDetails} reRenderContactList={updateCounter} idx={index} setIdxValue={setSelectedIndex} />}</TabPanel>
									<TabPanel p="0">{activeTabs?.[index] === 1 && <BankList identity_uuid={data?.identity_uuid} setclientData={setBankDetails} reRenderContactList={updateCounter} idx={index} setIdxValue={setSelectedIndex} />}</TabPanel>

									<TabPanel p="0">{activeTabs?.[index] === 2 && <TaxList identity_uuid={data?.identity_uuid} setclientData={setTaxDetails} reRenderContactList={updateCounter} idx={index} setIdxValue={setSelectedIndex} />}</TabPanel>
									<TabPanel p="0">{activeTabs?.[index] === 3 && <CompanyList identity_uuid={data?.identity_uuid} setclientData={setCompanyDetails} reRenderContactList={updateCounter} idx={index} setIdxValue={setSelectedIndex} />}</TabPanel>
								</TabPanels>
							</Box>
						</Flex>
					</Flex>
				</Tabs>
			))
		) : (
			<Alert status="warning" mt="4">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	// Todo:Genrate Filter alpabets from a to z
	const alphabets = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

	return (
		<Box my={4}>
			<HStack gap={1} h={"full"} mb="3" justifyContent="space-between">
				<Box>
					<Input size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} w="300px" placeholder="Filter <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
					<Input size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} w="300px" placeholder="Search <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
					<Button size={"sm"} bg={tabBg} fontWeight={"500"} fontSize={"16px"} borderRadius={0} rounded={"none"} borderWidth={"1px"} borderColor={"gray.400"} px={5} mb="1px">
						GO
					</Button>
				</Box>
				<Box alignItems={"center"}>
					<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} as={Button} rightIcon={<FaSort />} mr={2} onClick={() => setSortOrder(sortOrder === "asc" ? "dec" : "asc")}>
						Sort
					</Button>
					<Menu>
						<MenuButton size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} as={Button} rightIcon={<FaFilter fontSize={"12px"} />}>
							Quick Filter
						</MenuButton>
						<MenuList>
							<MenuItem p="2">
								{alphabets.map(letter => (
									<Button
										mr="2"
										key={letter}
										size="sm"
										bg={quickFilterValue === letter ? "green.200" : tabBg}
										borderRadius="5px"
										borderWidth="1px"
										borderColor="gray.800"
										px={0}
										onClick={() => {
											setQuickFilterValue(letter);
										}}
									>
										{letter}
									</Button>
								))}
								<Button
									mr="2"
									size="sm"
									bg={quickFilterValue === null ? "green.200" : tabBg}
									borderRadius="5px"
									borderWidth="1px"
									borderColor="gray.800"
									px={0}
									onClick={() => {
										setQuickFilterValue("");
									}}
								>
									Clear Filter
								</Button>
							</MenuItem>
						</MenuList>
					</Menu>
					<IconButton
						ml={2}
						size={"sm"}
						bg={tabBg}
						borderRadius={"5px"}
						borderWidth={"1px"}
						borderColor={"gray.800"}
						pr={2}
						rightIcon={<FiRefreshCw />}
						onClick={() => {
							fetchClientDetails();
						}}
					></IconButton>
					<Button ml={2} size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3}>
						...
					</Button>
					<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} ml="2 !important">
						...
					</Button>
					<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} ml="2 !important">
						<FaEllipsisVertical />
					</Button>
				</Box>
				{/*  */}
			</HStack>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>
					{renderClientListData}
					{clientList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
				</>
			)}
		</Box>
		// Todo:Drawer Content
	);
}

export default Client;
