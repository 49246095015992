import React, { useState } from 'react';
import { Box, Button, Input, Flex, Text, useToast, ScaleFade, Fade, Spinner } from '@chakra-ui/react';
import { FaPhoneAlt } from 'react-icons/fa';

import { toastFunctionToaster } from '../../toastFunction';

const Dailer = ({ onDial, hangUpCall, onDTMF, disabled, isCallActive }) => {
    const [number, setNumber] = useState('');
    const toast = useToast();

    const handleKeyPress = (key) => {
        if (isCallActive) {
            onDTMF(key);
        } else {
            setNumber((prev) => prev + key);
        }
    };

    const handleBackspace = () => {
        setNumber((prev) => prev.slice(0, -1));
    };

    const isValidNumber = (number) => {
        const sipUriRegex = /^sip:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/; 
        return sipUriRegex.test(number); 
    }

  // Function to handle dialing logic
    const handleDial = async () => {
        if (!number) {
            toast(toastFunctionToaster('Please enter a number to dial.', 'warning'));
            return;
        }

        if (!isValidNumber(number)) {
            toast(toastFunctionToaster('Invalid number format.', 'error'));
            return;
        }

        try {
            await onDial(number);
            setNumber(''); 
        } catch (error) {
            toast(toastFunctionToaster(`Dialing failed: ${error.message}`, 'error'));
        }
    };


    // Dial pad layout
    const dialPad = [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['*', '0', '#'],
    ];

    return (
        <Box
        maxW="400px"
        mx="auto"
        mt={10}
        p={5}
        bg="white"
        borderRadius="lg"
        boxShadow="lg"
        textAlign="center"
        transition="all 0.3s ease"
        >
            {/* Input Field for the Dialer */}
            {!isCallActive && (
                <ScaleFade initialScale={0.9} in={true}>
                    <Input
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="sip:example@domain.com"
                    size="lg"
                    mb={4}
                    fontSize="sm"
                    textAlign="left"
                    isDisabled={disabled || isCallActive}
                    />
                </ScaleFade>
            )}

            {/* Dial Pad */}
            <Box>
                {dialPad.map((row, i) => (
                <Flex key={i} justify="center" mb={2}>
                    {row.map((key) => (
                    <Button
                        key={key}
                        onClick={() => handleKeyPress(key)}
                        size="lg"
                        m={1}
                        w="60px"
                        h="60px"
                        colorScheme="blue"
                        fontSize="2xl"
                        isDisabled={disabled || isCallActive}
                        _hover={{ bg: 'blue.600' }}
                        _active={{ bg: 'blue.700' }}
                        transition="all 0.2s ease"
                    >
                        {key}
                    </Button>
                    ))}
                </Flex>
                ))}
            </Box>

            {/* Backspace and Dial buttons */}
            <Flex justify="center" mt={4}>
            {!isCallActive && (
                <>
                    <Button
                    onClick={handleBackspace}
                    size="lg"
                    colorScheme="red"
                    m={1}
                    w="60px"
                    h="60px"
                    fontSize="2xl"
                    isDisabled={disabled || isCallActive || number.length === 0}
                    _hover={{ bg: 'red.600' }}
                    _active={{ bg: 'red.700' }}
                    transition="all 0.2s ease"
                    >
                    ⌫
                    </Button>

                    <Button
                    onClick={handleDial}
                    colorScheme="green"
                    size="lg"
                    m={1}
                    w="60px"
                    h="60px"
                    fontSize="xl"
                    isDisabled={disabled || !number || isCallActive}
                    _hover={{ bg: 'green.600' }}
                    _active={{ bg: 'green.700' }}
                    transition="all 0.2s ease"
                    >
                    {isCallActive ? <Spinner size="sm" /> : <FaPhoneAlt />}
                    </Button>
                    </>
                )}
                    <Button
                        onClick={hangUpCall}
                        colorScheme="red"
                        size="lg"
                        m={1}
                        w="60px"
                        h="60px"
                        fontSize="xl"
                        mb={2}
                        _hover={{ bg: 'red.600' }}
                        _active={{ bg: 'red.700' }}
                        transition="all 0.2s ease"
                    >
                        <FaPhoneAlt />
                    </Button>
                </Flex>

            {/* Dialing Indicator */}
            <Fade in={isCallActive}>
                <Text mt={4} fontSize="xl" color="green.500">
                Dialing {number}...
                </Text>
            </Fade>
        </Box>
    );
};

export default Dailer;
