import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// --------------------------------------------------------------
// Account and Location API
// --------------------------------------------------------------

export const fetchLocationInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationInfo, data, config);
};

export const fetchLocationDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationList, data, config);
};

export const fetchLocationsAndSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocListWithSubs, data, config);
};

export const manageLocationStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageLocationStatus, data, config);
};

export const addNewLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createNewLocation, data, config);
};

export const updateExistingLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateExisingLocation, data, config);
};

export const uploadLocationAvtar = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadLocationAvtar, data, config);
};

export const fetchSubscriberList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubscriberList, data, config);
};
export const fetchSubscriberLingoLinks = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubscriberLingoLinkList, data, config);
};
export const fetchSubscriberManageLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubscriberLingoLinkManage, data, config);
};

export const manageSubscriberStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberStatus, data, config);
};

export const addSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addSubscriber, data, config);
};

export const updateSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSubscriber, data, config);
};

export const getAllLanguages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLanguageList, data, config);
};

export const getAllLocLanguages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocLanguageList, data, config);
};

export const fetchValidLanguages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLanguageListForLocation, data, config);
};

export const updateLocationLanguageList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateLanguageList, data, config);
};

export const updateDesignForAccountAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateDesignForAccountAdmin, data, config);
};

export const addSubDomainForAccountAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addSubDomainAccountAdmin, data, config);
};

export const delSubDomainForAccountAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().delSubDomainAccountAdmin, data, config);
};

export const createAccLangPref = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createAccLangPref, data, config);
};

export const updateAccLangPref = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccLangPref, data, config);
};

export const manageAccLangPref = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageAccLangPref, data, config);
};

export const applyAccLangPref = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().applyAccLangPref, data, config);
};

export const accLangPrefPaginated = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accLangPaginated, data, config);
};

export const getBusinessDetailsForAccountAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getBusinessDetailsForAccountAdmin, data, config);
};

export const createAccAdminSchedule = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createAccAdminSchedule, data, config);
};

export const updateAccAdminSchedule = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccAdminSchedule, data, config);
};

export const getAccAdminScheduleList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccAdminScheduleList, data, config);
};

export const cancelAccAdminSchedule = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().cancelAccAdminSchedule, data, config);
};

export const shareAccAdminSchedule = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareAccAdminSchedule, data, config);
};

export const getAccLinkHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccLinkCallHistory, data, config);
};

// export const getAccAssignedDevices = (data=null, config=null, axiosInstance=axios) =>{
// 	return axiosInstance.get(serviceAPIs().accAssignedDeviceList+`?page_id=${data?.page_id}&records_per_page=${data?.records_per_page}`, config)
// }
export const getAccAssignedDevices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accAssignedDeviceList, data, config);
};
export const getAccAssignedDeviceLogs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accAssignedDeviceConnLogs, data, config);
};

export const getAccSharingData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accSharingDetails, data, config);
};

export const getAccSharingEmailsData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accSharingEmailsList, data, config);
};

// lingolet identity api
export const getLingoletIdentityList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listIdentity, data, config);
};

export const createLingoletIdentity = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdentity, data, config);
};

export const updateLingoletIdentity = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdentity, data, config);
};

export const manageLingoletIdentity = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageIdentity, data, config);
};

export const selectLingoletIdentity = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().selectIdentity, data, config);
};

// lingolet identity contact api
export const getLingoletIdContactList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchIdContact, data, config);
};

export const createLingoletIdContact = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdContact, data, config);
};

export const updateLingoletIdContact = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdContact, data, config);
};

// lingolet identity address api
export const getLingoletIdAddressList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchIdAddress, data, config);
};

export const createLingoletIdAddress = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdAddress, data, config);
};

export const updateLingoletIdAddress = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdAddress, data, config);
};

export const manageLingoletIdAddress = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageIdAddress, data, config);
};

// lingolet identity bank api
export const getLingoletIdBankList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchIdBank, data, config);
};

export const createLingoletIdBank = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdBank, data, config);
};

export const updateLingoletIdBank = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdBank, data, config);
};

export const manageLingoletIdBank = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageIdBank, data, config);
};

// lingolet identity tax api
export const getLingoletIdTaxList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchIdTax, data, config);
};

export const createLingoletIdTax = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdTax, data, config);
};

export const updateLingoletIdTax = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdTax, data, config);
};

export const manageLingoletIdTax = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageIdTax, data, config);
};

// lingolet identity company api
export const getLingoletIdCompanyList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchIdCompany, data, config);
};

export const createLingoletIdCompany = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createIdCompany, data, config);
};

export const updateLingoletIdCompany = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateIdCompany, data, config);
};

export const manageLingoletIdCompany = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageIdCompany, data, config);
};

// --------------------------------------------------------------
// Subscriber API
// --------------------------------------------------------------
export const fetchAccLocInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccLocInfo, data, config);
};
export const fetchSubPersonalInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubPersonalInfo, data, config);
};

export const createSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSubLingoLink, data, config);
};

export const fetchSubLingoList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoList, data, config);
};
export const fetchSubLingoMiniList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoMiniList, data, config);
};

export const disableSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disableSubLingoLink, data, config);
};

export const resetSubLingoLinkCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().resetSubLingoLinkCode, data, config);
};

export const shareSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareSubLingoLink, data, config);
};

export const getLinkSubscriberDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchSubLinkDesignData, data, config);
};

export const setupSubscriberLinkDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberLinkDesign, data, config);
};

export const uploadSubscriberLinkDesignImages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadSubscriberLinkDesign, data, config);
};

export const getSubscriberCallHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubCallHistory, data, config);
};

export const calculateSubscriberCalls = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().calSubCalls, data, config);
};

export const subLingoKeyword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().subLingoKey, data, config);
};

export const updateLinkFeatures = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addUpdateFeatures, data, config);
};

export const fetchSubscriberTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscript, data, config);
};

export const fetchSubscriberTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscriptData, data, config);
};

export const mailSubscriberTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().sendSubscriberTranscriptMail, data, config);
};

export const resetSubscriberPassword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().resetSubscriberPassword, data, config);
};

export const fetchResetedLeaderCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getresetedLeaderCode, data, config);
};

export const shareLeaderCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().mailLeaderCode, data, config);
};

export const createSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSubLingoCard, data, config);
};

export const updateSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSubLingoCard, data, config);
};

export const listSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listSubLingoCards, data, config);
};
export const manageSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubLingoCard, data, config);
};

export const shareSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareSubLingoCard, data, config);
};

export const enableDiableSubLingoCard = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().enableDiableSubLingoCard, data, config);
};

export const checkLingoCardAccess = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().checkLingoCardEnabled, data, config);
};

export const refreshLingoCardCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().refreshSubLingoCard, data, config);
};

export const subLingoCardUsage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().cardUsageDetails, data, config);
};

export const commonDetailsForLingoCards = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.get(serviceAPIs().commonDetailsLingoCard, data, config);
};

export const shareLingoCardVaiSms = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareLingoCardSms, data, config);
};

export const createSubSchedule = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSchedule, data, config);
};

export const updateSubSchedule = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSchedule, data, config);
};

export const getSubScheduleList = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getScheduleList, data, config);
};

export const cancelSubSchedule = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().cancelSchedule, data, config);
};

export const shareSubSchedule = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareSchedule, data, config);
};

export const createLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createLanguagePreference, data, config);
};
export const updateLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateLanguagePreference, data, config);
};
export const listLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listLangPreferences, data, config);
};
export const getLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLanPreference, data, config);
};
export const applyLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().applyLangPrefrence, data, config);
};
export const manageLangPreference = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageLanguagePreference, data, config);
};

export const subscriberSharingDetails = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().sharingDetails, data, config);
};

export const subscriberSharingEmailDetails = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().sharingEmailsDetails, data, config);
};

export const subscriberUpdateLingoLinkDetails = (data = null, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateLingoLinkSubs, data, config);
};

export const subscriberTestCardCall = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().subscriberTestCall, data, config);
};

// --------------------------------------------------------------
// Events API
// --------------------------------------------------------------

export const fetchAccountEvents = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAllAdminEvents, data, config);
};

export const fetchEventData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getEventList, data, config);
};

export const fetchAccAdminEventsForSub = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAcountAdminEventsForSubscriber, data, config);
};

export const fetchAccEvents = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccountEvents, data, config);
};

// --------------------
// Subscriber Dashboard Services
// --------------------
export const getSubDbClientLinkCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubLingoLinksCount, data, config);
};
export const getSubDbClientEventsCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubClientEventsCnt, data, config);
};

export const lingoEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().lingoEmailTranscript, data, config);
};

export const saveEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveLingoTranscript, data, config);
};

export const storeAccountSubApp = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveAccountSubApp, data, config);
};

export const diableAllAccountSubApp = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disableAllSubApp, data, config);
};

export const fetchSubscriberLinks = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getsubscriberLinks, data, config);
};

export const fetchSubscriberLinksCallHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getsubscriberLinksCallHistory, data, config);
};

export const getSubscriberQuotaInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchSubQuotaInfo, data, config);
};

// --------------------
// Account Dashboard Services
// --------------------
export const getAccountDbLocationCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbAccountLocationsCount, data, config);
};
export const getAccountDbEventCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbAccountEventsCount, data, config);
};
// --------------------
// Account Quota Services
// --------------------
export const getAccountQuotaList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchAccountQuotaList, data, config);
};

export const getQuotaData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchQuotaDetails, data, config);
};

export const updateQuotaDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccountQuota, data, config);
};

//Account reporting
export const getSubsLinksMetric = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsLinksMetrics, data, config);
};

export const getSubsEventsMetrics = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsEventsMetrics, data, config);
};

export const getSubsLingoCards = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsLingoCards, data, config);
};

// document access control

export const createDocAccessControl = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createDocAccessControl, data, config);
};

export const getDocControlAccessList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getdocAccessControlList, data, config);
};

export const UpdateDocControlAccessList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccessControlList, data, config);
};

export const updateControlAccessCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateAccessControlCode, data, config);
};

export const accessControlDocContent = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().accessControlDocContent, data, config);
};

export const createDocumentAccessSpace = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createDocumentSpaceAccess, data, config);
};
export const updateDocumentAccessSpace = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateDocumentSpaceAccess, data, config);
};
export const updateDocumentAccessSpaceCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateDocumentSpaceAccessCode, data, config);
};

// --------------------
// Account Billing Services
// --------------------
export const getAccountBillingList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchAccountBillList, data, config);
};

export const getAccountSubscriberBillingList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchAccountSubsBillList, data, config);
};

// --------------------
// Account Admin Report Setup
// --------------------
export const addReportSetupAccAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addReportSetupAccAdmin, data, config);
};

export const updateReportSetupAccAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateReportSetupAccAdmin, data, config);
};

export const manageReportSetupAccAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageReportSetupAccAdmin, data, config);
};

export const listReportSetupAccAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listReportSetupAccAdmin, data, config);
};

export const fetchReportExecutionListAccAdmin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchReportExecutionListAccAdmin, data, config);
};

// --------------------
// Account Subscriber Report Setup
// --------------------
export const addReportSetupAccSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addReportSetupAccSubs, data, config);
};

export const updateReportSetupAccSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateReportSetupAccSubs, data, config);
};

export const manageReportSetupAccSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageReportSetupAccSubs, data, config);
};

export const listReportSetupAccSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listReportSetupAccSubs, data, config);
};

export const fetchReportExecutionListAccSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchReportExecutionListAccSubs, data, config);
};

export const getAllSubscribersList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.get(serviceAPIs().getAllSubscribers, data, config);
};

//document ai management

export const DocAiWorkerHeartbeat = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiWorkerHeartbeat, data, config);
};

export const DocAiEmailSyncList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiEmailSyncList, data, config);
};

export const DocAiMetrics = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiMetrics, data, config);
};

export const DocAiEmailActivity = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiEmailActivity, data, config);
};
export const DocAiEmailActivityList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiEmailActivityList, data, config);
};

export const DocAiRework = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().DocAiRework, data, config);
};

// -------------------- exteral storage services --------------------

export const createExternalStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addExternalStorage, data, config);
};

export const updateExternalStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateExternalStorage, data, config);
};

export const listExternalStorage = (data, config = null, axiosInstance = axios) => {
	const params = new URLSearchParams();
	params.append("storage_type", data.storage_type);
	if (data?.storage_uuid) {
		params.append("storage_uuid", data.storage_uuid);
	}

	return axiosInstance.get(`${serviceAPIs().listExternalStorage}?${params.toString()}`, config);
};

export const selectExternalStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.get(serviceAPIs().selectExternalStorage + `?docai_store_uuid=${data?.docai_store_uuid}`, data, config);
};

export const manageExternalStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageExternalStorage, data, config);
};

export const authorizeGoogleExternalStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().authorizeGoogleExternalStorage, data, config);
};

export const getGdriveFiles = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.get(serviceAPIs().listGdriveStorageFiles + `?provider=${data?.provider}&docai_store_uuid=${data?.docai_store_uuid}&records_per_page=${data?.records_per_page}&page_id=${data?.page_id}&parent_id=${data?.parent_id}`, data, config);
};

export const disconnectStorage = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disconnectStorage, data, config);
};

// identities upload logo
export const uploadLingoletIdentityLogo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadIdentityLogo, data, config);
};

// booking services
export const addBookingServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addBooking, data, config);
};

export const listBookServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listBooking, data, config);
};

export const createBookingServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createBookingService, data, config);
};

export const updateBookingServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateBookingService, data, config);
};

export const getBookingServicesList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listBookingService, data, config);
};

export const createBookingAppoinmentServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addBookingAppoinmentDetails, data, config);
};

export const updateBookingAppoinmentServices = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateBookingAppoinmentDetails, data, config);
};

export const getBookingAppoinmentList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listBookingApoinmentDetails, data, config);
};
