import React, { useState, useEffect } from 'react';
import {
  Icon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Select,
  Checkbox,
  RadioGroup,
  Radio,
  Stack,
  IconButton,
  Text,
  useToast,
  NumberInput,
  NumberInputField,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Tab, TabList, TabPanel, TabPanels, Tabs,
  Drawer,
  Spacer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Alert,
  AlertIcon,
  Spinner
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { MdErrorOutline } from "react-icons/md";

import { createFormMetaData, fetchFormMetaData, createFormData, fetchFormData, updateFormMetaData } from "../../../services/adminServices"

import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useBrandBg } from '../../../theme/globalColorTheme';
import Card from '../../../components/card/Card';
import DynamicFormCardList from './components/DynamicFormCardList';
import { set } from 'date-fns';


const FieldTypeOptions = [
  { value: 'text', label: 'Text' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'numeric', label: 'Numeric' },
  { value: 'date', label: 'Date' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio', label: 'Radio' },
  { value: 'dropdown', label: 'Dropdown' },
];

const DesignOptions = [
  { value: 'mandatory', label: 'Mandatory' },
  { value: 'readonly', label: 'Readonly' },
  { value: 'disabled', label: 'Disabled' },
  { value: 'hidden', label: 'Hidden' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
  { value: 'minLength', label: 'Min Length' },
  { value: 'maxLength', label: 'Max Length' }
];

// ------------------------------------------------------------------
//                   Field Component
// ------------------------------------------------------------------

const FieldComponent = ({ field, index, onRemoveField }) => {
  return (
    <Box key={index} display="flex" alignItems="center" mb={2} borderBottomWidth={1} borderColor="gray.200" p={2} boxShadow={'md'}>
      <Text mr={2} fontWeight="bold">Field:</Text>
      <Text mr={2}>{field.name}</Text>

      <Text ml={4} mr={2} fontWeight="bold" >Type:</Text>
      <Text ml={2} >({field.type})</Text>
      <Flex ml="auto">
        <IconButton size="xs" variant="ghost" icon={<FaTrash />} onClick={() => onRemoveField(index)} />
      </Flex>
    </Box>
  );
};

// ------------------------------------------------------------------
//                   Field Modal Component
// ------------------------------------------------------------------  

const FieldModal = ({ isOpen, onClose, onAddField }) => {
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('text');
  const [designOptions, setDesignOptions] = useState([]);
  const [enumValues, setEnumValues] = useState([]);

  const handleAddField = () => {
    const field = {
      name: fieldName,
      type: fieldType,
      design: {
        mandatory: designOptions.includes('mandatory'),
        readonly: designOptions.includes('readonly'),
        disabled: designOptions.includes('disabled'),
        hidden: designOptions.includes('hidden'),
        min: designOptions.includes('min') ? parseFloat(designOptions.find(option => option.value === 'min')?.customValue) : undefined,
        max: designOptions.includes('max') ? parseFloat(designOptions.find(option => option.value === 'max')?.customValue) : undefined,
        minLength: designOptions.includes('minLength') ? parseInt(designOptions.find(option => option.value === 'minLength')?.customValue) : undefined,
        maxLength: designOptions.includes('maxLength') ? parseInt(designOptions.find(option => option.value === 'maxLength')?.customValue) : undefined,
      },
    };

    if (fieldType === 'checkbox') {
      field.design.is_checkkbox = true;
      field.design.checkbox = {
        default: '',  //can be any of string, integer, boolean, date
        layout: 'inline',
      }
    } else if (fieldType === 'radio') {
      field.design.is_radio = true;
      field.design.enum = enumValues;
      field.design.radio = {
        layout: 'horizontal' // or 'vertical'
      }
    } else if (fieldType === 'dropdown') {
      field.design.is_dropdown = true;
      field.design.enum = enumValues;
      field.design.dropdown = {
        allowsearch: false,
        placeholder: 'Select an option'
      }
    } else if (fieldType === 'date') {
      field.design.format = 'DD-MM-YYYY';
    }

    onAddField(field);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel htmlFor="fieldName">Field Name</FormLabel>
            <Input
              id="fieldName"
              placeholder="Enter field name"
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel htmlFor="fieldType">Field Type</FormLabel>
            <Select
              id="fieldType"
              value={fieldType}
              onChange={(e) => setFieldType(e.target.value)}
            >
              {FieldTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Design Options</FormLabel>
            <Flex direction="row" wrap={"wrap"} spacing={2}>
              {DesignOptions.map((option) => {
                const isDisabled =
                  (option.value === 'min' || option.value === 'max') && fieldType !== 'numeric' ||
                  (option.value === 'minLength' || option.value === 'maxLength') && fieldType !== 'text';
                const isInput = option.value === 'min' || option.value === 'max' || option.value === 'minLength' || option.value === 'maxLength';

                return (
                  <>
                    {isInput && (
                      <FormControl display={fieldType === (option.value.includes('Length') ? 'text' : 'numeric') ? 'block' : 'none'} key={option.value} mr={2}>
                        <FormLabel htmlFor={`${option.value}-input`}>{option.label}</FormLabel>
                        <Input
                          id={`${option.value}-input`}
                          type="number"
                          placeholder={`Enter ${option.label}`}
                          value={designOptions.find((o) => o.value === option.value)?.customValue || ''}
                          onChange={(e) => {
                            const updatedOptions = [...designOptions];
                            const index = updatedOptions.findIndex((o) => o.value === option.value);
                            if (index !== -1) {
                              updatedOptions[index].customValue = e.target.value;
                            } else {
                              updatedOptions.push({ value: option.value, customValue: e.target.value });
                            }
                            setDesignOptions(updatedOptions);
                          }}
                          isDisabled={isDisabled}
                        />
                      </FormControl>
                    )}
                    {!isInput && (
                      <Checkbox mr={2} key={option.value} value={option.value} isChecked={designOptions.includes(option.value)} onChange={(e) => {
                        if (e.target.checked) {
                          setDesignOptions([...designOptions, option.value]);
                        } else {
                          setDesignOptions(designOptions.filter((o) => o !== option.value));
                        }
                      }} isDisabled={isDisabled}>
                        {option.label}
                      </Checkbox>
                    )}
                  </>
                );
              })}
            </Flex>
          </FormControl>

          {(fieldType === 'radio' || fieldType === 'dropdown') && (
            <FormControl mb={3}>
              <FormLabel>Values for {fieldType} (comma-separated)</FormLabel>
              <Input
                type="text"
                placeholder="Enter enum values"
                value={enumValues.join(',')}
                onChange={(e) => setEnumValues(e.target.value.split(','))}
              />
            </FormControl>
          )}

          <Button onClick={handleAddField} colorScheme="blue">
            Add Field
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// ------------------------------------------------------------------
//                   Created form list Component
// ------------------------------------------------------------------

const CreatedFormsList = ({ createdForms, updateFormDetails }) => {

  return (
    <Box mt={4} mb={4}>
      <Text fontSize="xl" fontWeight="bold">
        Created Forms
      </Text>
      <Flex border={"0.1px solid #ccc"} borderRadius="md" overflow="hidden" boxShadow={"md"}>
        <Box
          p={4}
          w="full"
          overflow="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '10px',
              transition: 'background-color 0.3s ease',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            'scroll-behavior': 'smooth',
          }}
        >
          <DynamicFormCardList formData={createdForms} updateFormDetails={updateFormDetails} />
        </Box>
      </Flex>
    </Box>
  );
};

// ------------------------------------------------------------------
//                   Dynamic Form Builder Component
// ------------------------------------------------------------------

const DynamicFormBuilder = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [formName, setFormName] = useState(null);
  const [fields, setFields] = useState([]);
  const [createdForms, setCreatedForms] = useState([])
  const toast = useToast();
  const [selectedForm, setSelectedForm] = useState(null);
  const [btnLoading, setBtnLoading] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getformList()
  }, []);

  function getformList() {
    setLoading(true)
    fetchFormMetaData({})
      .then((res) => {
        if (res?.status !== 'failure') {
          setCreatedForms(res?.data[0]?.data[0]?.records)
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.data[0]?.error, "error"));
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Failed to fetch the created forms", "error"));
      }).finally(() => {
        setLoading(false)
      });
  }

  const handleAddField = (newField) => {
    setFields([...fields, newField]);

  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleLaunch = () => {
    try {
      setBtnLoading(btnLoading => ({ ...btnLoading, launch: true }));
      const requestBody = {
        form_name: formName,
        form_design: fields.length > 0 && { fields: fields }
      }
      createFormMetaData(requestBody).then((res) => {
        if (res?.status !== 'failure') {
          setFormName('');
          setFields([]);
          toast(toastFunctionToaster(res?.message, "success"));
          getformList()
          handleModalClose()
        } else {
          toast(toastFunctionToaster(res?.data[0]?.error, "error"));
        }
      })
        .catch((err) => {
          toast(toastFunctionToaster("Failed to create form", "error"));
        }).finally(() => {
          setBtnLoading(btnLoading => ({ ...btnLoading, launch: false }));
        })
    } catch (error) {
      toast(toastFunctionToaster("Failed to create form", "error"));
    }
  };

  const handleupdateFormMetaData = () => {
    setBtnLoading(btnLoading => ({ ...btnLoading, update: true }));
    const params = {
      form_uuid: selectedForm?.form_uuid,
      form_key: selectedForm?.form_key,
      form_name: formName,
      form_design: fields.length > 0 && { fields: fields }
    }

    updateFormMetaData(params).then((res) => {
      if (res?.status !== 'failure') {
        setFormName('');
        setFields([]);
        toast(toastFunctionToaster(res?.message, "success"));
        handleModalClose()
        getformList()
      } else {
        toast(toastFunctionToaster(res?.data[0]?.error, "error"));
      }
    }
    ).catch((err) => {
      toast(toastFunctionToaster("Failed to update form", "error"));
    }).finally(() => {
      setBtnLoading(btnLoading => ({ ...btnLoading, update: false }));
    });
  }

  function handleModalClose() {
    onModalClose()
    setFormName('')
    setFields([])
    setSelectedForm(null)
  }

  function updateFormDetails(form) {
    setSelectedForm(form)
    setFormName(form?.form_name)
    setFields(form?.form_design?.fields)
    onModalOpen()
  }

  return (
    <Flex direction="column" pt={{ sm: "130px", lg: "80px" }} flex={1}>
      <Flex p="1">
        <Spacer />
        <Button colorScheme='blue' size="sm" onClick={onModalOpen}>Add new Form</Button>
      </Flex>
      <Card p="1" flex={1}>
        <Flex minH="full" overflow="hidden" flex={1}>
          <Box
            w={"full"}
            bg={useBrandBg}
            p="2"
            borderRadius={"10px"}
            minH="full"
            className="custom-scrollbar"
            overflowY="auto"
          >
            <Card h="full">
              {
                loading ? <Spinner size='xl' /> : <>
                  {createdForms.length > 0 ? (
                    <CreatedFormsList createdForms={createdForms} updateFormDetails={updateFormDetails} />
                  ) :
                    <Alert status='info'>
                      <AlertIcon />
                      Form List is empty, Click here to create Forms
                    </Alert>}
                </>
              }

            </Card>
          </Box>
        </Flex>
      </Card>
      <Drawer
        isOpen={isModalOpen}
        placement='right'
        onClose={handleModalClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create Form</DrawerHeader>
          <DrawerBody>
            <Box>

              <FieldModal isOpen={isOpen} onClose={onClose} onAddField={handleAddField} />

              <FormControl mb={3}>
                <FormLabel htmlFor="formName" fontSize="xl" fontWeight="bold">Form Name</FormLabel>
                <Input
                  id="formName"
                  placeholder="Enter form name"
                  value={formName}
                  focusBorderColor='green.500'
                  onChange={(e) => setFormName(e.target.value)}
                />
              </FormControl>

              <Box mt={4}>
                {fields.length > 0 && <Text fontSize="xl" fontWeight="bold" mb={2}>
                  Form Fields
                </Text>}
                {fields.map((field, index) => (
                  <FieldComponent
                    key={index}
                    field={field}
                    index={index}
                    onRemoveField={handleRemoveField}
                  />
                ))}
                <Button onClick={onOpen} >Add Field</Button>
              </Box>

              {selectedForm ? <Button onClick={handleupdateFormMetaData} colorScheme="green" mt={4} isLoading={btnLoading.update}>
                Update Form
              </Button> : <Button onClick={handleLaunch} colorScheme="green" mt={4} isLoading={btnLoading.launch}>
                Add Form
              </Button>}


            </Box>

          </DrawerBody>
          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>


  );
};

export default DynamicFormBuilder;



