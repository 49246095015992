export const ContentListingHeader = [
    {
      Header: "FILE",
      accessor: "title",
    },
    {
      Header:'ADDED BY',
      accessor:"added_by",
    },
    // {
    //   Header: "TYPE",
    //   accessor: "type",
    // },
    // {
    //   Header: "URL",
    //   accessor: "url",
    // },
    // {
    //   Header: "VECTORIZE",
    //   accessor: "vectorize",
    // },
    {
      Header: 'Date',
      accessor: 'added_on'
    },
    {
      Header: "OVERWRITTEN",
      accessor: "overwritten",
    },
    {
      Header: "EVENT",
      accessor: "event",
    },
    {
    Header: "STORED",
    accessor: "stored",
    },
    // {
    // Header: "TEXT",
    // accessor: "text",
    // },
    {
    Header: "ACTION",
    accessor: "uuid",
    },
    {
      Header: "WORKFLOW",
      accessor: "count",
    }
];
  
  