// React Imports
import React, { useState } from "react";
import { Flex, Tab, Tabs, TabList, TabPanel, TabPanels } from "@chakra-ui/react";

// Component Imports
import Client from "./client/Client";
import Card from "../../../../components/card/Card";
import AddUpdateClientDetails from "./client/AddUpdateClient";

function IdentitiesHome() {
	const [clientDetails, setClientDetails] = useState(null);
	const [updateCounter, setUpdateCounter] = useState(null);
	const [editFlag, setEdit] = useState(null);
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	// Handle tab change
	const handleTabChange = index => {
		setActiveTabIndex(index);
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card w="100%" rounded="md" mb={2} p="20px">
				<Tabs
					variant="enclosed"
					size="md"
					key={activeTabIndex}
					index={activeTabIndex} // Control the active tab
					onChange={handleTabChange} // Update the active tab index
				>
					<Flex alignItems="start" justifyContent="space-between" borderBottomWidth="1px" borderColor="#a5a5a5">
						<AddUpdateClientDetails passClientData={clientDetails} editStatus={editFlag} handlerDrawer={setEdit} updateClientDataCounter={setUpdateCounter} />
						<TabList border="0px">
							{["Clients", "Customers", "Consumers", "Relations"].map((label, index) => (
								<Tab
									key={index}
									_selected={{
										borderWidth: "1px",
										borderStyle: "solid",
										borderColor: "#a5a5a5",
										fontWeight: "500",
										color: "black",
										borderBottom: "0px",
										mb: "-4px",
									}}
									sx={{
										bg: "#fce5cd",
										color: "black",
										borderStyle: "solid",
										borderWidth: "1px",
										rounded: "md",
										borderBottomLeftRadius: "0px",
										borderBottomRightRadius: "0px",
									}}
									mr="2"
								>
									{label}
								</Tab>
							))}
						</TabList>
					</Flex>
					<TabPanels>
						{/* Render content only for the active tab */}
						<TabPanel p="0">{activeTabIndex === 0 && <Client setclientData={setClientDetails} handlerDrawer={setEdit} refreshData={updateCounter} type="client" selectedTab={activeTabIndex} />}</TabPanel>
						<TabPanel p="0">{activeTabIndex === 1 && <Client setclientData={setClientDetails} handlerDrawer={setEdit} refreshData={updateCounter} type="customer" selectedTab={activeTabIndex} />}</TabPanel>
						<TabPanel p="0">{activeTabIndex === 2 && <Client setclientData={setClientDetails} handlerDrawer={setEdit} refreshData={updateCounter} type="consumer" selectedTab={activeTabIndex} />}</TabPanel>
						<TabPanel p="4">Relations</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default IdentitiesHome;
