import React from "react";
import Card from "../../components/card/Card";
import Footer from "../../komponents/uilayout/Footer";
import { Box, Text, Center, Flex } from "@chakra-ui/react";

function DMCAPolicy() {
	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
			<Center>
				<Text fontWeight={"bold"} fontSize={"24px"}>
					Lingolet DMCA Privacy Policy
				</Text>
			</Center>
			<Card w={"96%"} px={20} mt={10}>
				<Text>Lingolet Inc. respects the intellectual property rights of others and expects the users of our websites and services to do the same. We reserve the right to delete or disable content that appears to violate these terms or applicable law.</Text>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law (see copyright.gov/legislation/dmca.pdf on the U.S. Copyright Office website).
					<br />
					<br />
					If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, you (or your agent) may send us a notice requesting that the Content be removed or access to it blocked.
				</Text>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					DMCA Notice of Alleged Infringement (“Notice”)
				</Text>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					Provide all the following required information:
				</Text>
				<Box pl={5} mt={5}>
					<ul>
						<li>Requester’s Full Legal Name and physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
						<li> Requester’s Identity (Individual / Company Name) – Provide your company affiliation (if applicable), mailing address, telephone number, and email address;</li>
						<li> Identification of the copyrighted work claimed to have been infringed or, if multiple copyrighted works at a single online site are covered a single notification, a representative list of such works at that site;</li>
						<li> URLs of Offending material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information;</li>
						<li>
							{" "}
							Include both following statements in the body of the Notice:
							<ul>
								<li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).” </li>
								<li> “I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
							</ul>
						</li>
					</ul>
				</Box>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					Send the notification to:
				</Text>
				<Text>
					Lingolet Inc. <br />
					Attn: Legal Department, DMCA
					<br />
					4633 Old Ironsides Drive, Ste. 230
					<br />
					Santa Clara, CA 95054
					<br />
					Email info@lingolet.com
					<br />
					<br />
				</Text>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					Sharing Information
				</Text>
				<Box pl={5}>
					<ul>
						<li>Note: We provide the above contact information for purposes of the DMCA only and reserve the right to respond only to correspondence that is relevant to this purpose.</li>
					</ul>

					<ul>
						<li>We may provide your contact information, including your name and email address, the name of the organization or client who owns the right in question, and/or the contents of your report to the person that posted the content you are reporting.</li>
					</ul>
				</Box>
				<Text>You acknowledge that under 17 U.S.C. section 512(f) any person who knowingly materially misrepresents that material or activity is infringing upon the law and may be subject to liability for damages.</Text>
				<Text fontWeight={"bold"} fontSize={"20px"} mt={5}>
					If you have questions, contact us:
				</Text>
				​
				<Text>
					Lingolet Inc. <br />
					Attn: Legal DMCA Policy <br />
					4633 Old Ironsides Drive, Ste. 230 <br />
					Santa Clara, CA 95054 <br />
					Email info@lingolet.com <br />
				</Text>
				<Center mt={10}>
					Last Updated:{" "}
					<Text pl={1}>
						<b>December 21th, 2023</b>
					</Text>
				</Center>
			</Card>
			<Box w="100%">
				<Footer />
			</Box>
		</Flex>
	);
}

export default DMCAPolicy;
