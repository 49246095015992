// React + Chakra Imports
import { AiFillPlusCircle } from "react-icons/ai";

import React, { useState, useEffect } from "react";
import { Flex, Button, Input, Icon, Text, Switch, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, Select, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createLingoletIdAddress, updateLingoletIdAddress } from "../../../../../../services/accountServices";

function AddUpdateAdressDetails({ identity_uuid, companyuUId, passClientData, setAddressData, updateClientDataCounter, idValue, indexValue }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [counter, setCounter] = useState(0);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const initialFormData = {
		id_address_type: "Commrecial",
		id_address_line1: "",
		id_address_city: "",
		id_address_state: "",
		id_address_zip: "",
		id_address_country: "",
		id_address_line2: "",
		id_address_region: "",
		id_address_active: true,
		id_address_company_uuid: companyuUId,
	};
	const [formData, setFormData] = useState(initialFormData);

	// Todo:Update Form Details On Edit
	useEffect(() => {
		if (passClientData !== null || (passClientData !== undefined && passClientData?.id_address_uuid)) {
			setFormData({
				id_address_type: passClientData.id_address_type || "Commrecial",
				id_address_line1: passClientData.id_address_line1 || "",
				id_address_city: passClientData.id_address_city || "",
				id_address_state: passClientData.id_address_state || "",
				id_address_zip: passClientData.id_address_zip || "",
				id_address_country: passClientData.id_address_country || "",
				id_address_line2: passClientData.id_address_line2 || "",
				id_address_region: passClientData.id_address_region || "",
				id_address_active: passClientData.id_address_active || true,
				id_address_company_uuid: companyuUId,
			});
			if (idValue === indexValue) {
				onOpen();
			}
		} else {
			setFormData(initialFormData);
		}
	}, [passClientData, idValue, indexValue]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" || type === "switch" ? checked : value,
		});
	};
	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		if (!formData.id_address_type) {
			formIsValid = false;
			errors["id_address_type"] = "*Please select type.";
		}

		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			formData["identity_uuid"] = identity_uuid;
			formData["id_address_company_uuid"] = companyuUId;
			if (passClientData?.id_address_uuid) {
				formData["id_address_uuid"] = passClientData?.id_address_uuid;
				updateLingoletIdAddress(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createLingoletIdAddress(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	//Todo:Update Counter wjen data updated to refresh data
	useEffect(() => {
		updateClientDataCounter(counter);
	}, [counter]);

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setFormData(initialFormData);
		setError({});
		onClose();
		setAddressData(null);
	}

	// Todo:Reset Form Data

	return (
		<>
			<Icon onClick={onOpen} as={AiFillPlusCircle} rounded={"md"} fontSize={"28px"} cursor={"pointer"} />
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{passClientData?.id_address_uuid ? "Update" : "Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Address
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* Address Type */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Address Type
								</FormLabel>

								<Select name="id_address_type" value={formData.id_address_type} onChange={handleChange} color={textColorPrimary}>
									<option value="Commrecial">Commrecial</option>
									<option value="Residential">Residential</option>
									<option value="Office Space">Office Space</option>
								</Select>
								{error.id_address_type ? (
									<Text color="red" mb="2">
										{error.id_address_type}
									</Text>
								) : null}
							</FormControl>
							{/* Address Line One */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Address Line One
								</FormLabel>
								<Input type="text" name="id_address_line1" value={formData.id_address_line1} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Address Line Two */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Address Line Two
								</FormLabel>
								<Input type="text" name="id_address_line2" value={formData.id_address_line2} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* City */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									City
								</FormLabel>
								<Input type="text" name="id_address_city" value={formData.id_address_city} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* State */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									State
								</FormLabel>
								<Input type="text" name="id_address_state" value={formData.id_address_state} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							<>
								{/* Zipcode */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Zipcode
									</FormLabel>
									<Input type="text" name="id_address_zip" value={formData.id_address_zip} onChange={handleChange} color={textColorPrimary} />
								</FormControl>

								{/* Country */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Country
									</FormLabel>
									<Input type="text" name="id_address_country" value={formData.id_address_country} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Region */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Region
									</FormLabel>
									<Input type="text" name="id_address_region" value={formData.id_address_region} color={textColorPrimary} onChange={handleChange} />
								</FormControl>

								{/* Active Status */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Status
									</FormLabel>
									<Switch name="id_address_active" isChecked={formData.id_address_active} onChange={handleChange} />
								</FormControl>
							</>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{passClientData?.id_address_uuid ? "Update" : "Create"}
							</Button>
							<Button
								variant="outline"
								colorScheme="red"
								ml={3}
								onClick={() => {
									handleCloseDrawer();
								}}
							>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateAdressDetails;
