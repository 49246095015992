import { Button, Checkbox, CheckboxGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Image, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Spacer, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import InputField from "../../../components/fields/InputField";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import moment from 'moment'
import { toastFunctionToaster } from '../../../utils/toastFunction';
import { useGreenButton, useTextColor } from '../../../theme/globalColorTheme';
import { subscriberUpdateLingoLinkDetails } from '../../../services/accountServices';
import { LINGOLINK_TYPES, MAX_LINK_DURATION_VALUE} from "./constants";



const UpdateLink = ({ linkInfo,supportedApps,accountId, locationId,refreshList,currentSelection }) => {
    const [errors, setErrors] = useState(null)
    const touched = useRef({})
    const btnRef = React.useRef();
    const [updatedValues, setUpdatedValues] = useState({
        title: '',
        linkStartDateTime: new Date(),
        linkEndDateTime: new Date(),
        isCallDurationUnlimited: false,
        callTimeValue: 50,
        selectedApps: []
    })
    const [showTooltip, setShowTooltip] = useState(false);

    const [linkTitleFlag, setLinkTitleFLag] = useState(false)
    const [subAppDetails, setSubAppDetails] = useState([]);
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(true)
    const toast = useToast()
    const greenButtonBg = useGreenButton();
    const blackWhiteColor = useTextColor()

    function getValues(){
        return {
            title: linkInfo?.title,
            linkStartDateTime: moment.utc(linkInfo?.starttime).local().format('YYYY-MM-DD HH:mm'),
            linkEndDateTime: moment.utc(linkInfo?.endtime).local().format("YYYY-MM-DD HH:mm"),
            isCallDurationUnlimited: linkInfo?.max_time_min === 9999,
            callTimeValue: linkInfo?.max_time_min,
            selectedApps: linkInfo?.features?.split(",")

        }
    }

    function valuesUpdated() {
        const newValues = {
            ...updatedValues
        }
        const oldValues = getValues()
        return JSON.stringify(newValues) === JSON.stringify(oldValues)
    }

    useEffect(() => {
        const values = getValues()
        setUpdatedValues(values)
    }, [linkInfo])
    useEffect(() => {
        if (linkInfo?.class === 1) {
            if (!updatedValues.title || linkInfo.title === updatedValues.title || updatedValues?.selectedApps?.length == 0) {
                return setIsUpdateDisabled(true)
            }

        }
        if (linkInfo?.class === 3) {
            if (!updatedValues?.title || !updatedValues?.callTimeValue || !updatedValues?.selectedApps?.length === 0 || !updatedValues?.linkEndDateTime || !updatedValues?.linkStartDateTime) {
                return setIsUpdateDisabled(true)
            }
            if (valuesUpdated()) return setIsUpdateDisabled(true)
        }
        setIsUpdateDisabled(false)
    }, [updatedValues, linkInfo])

    useEffect(()=>{
        setSubAppDetails(supportedApps)
    },[supportedApps])
    const { isOpen: isSideBarOpen, onOpen: onSideBarOpen, onClose: onSideBarClose } = useDisclosure()

    function calculateMinutes() {
        const differenceInMs = new Date(updatedValues.linkEndDateTime) - new Date(updatedValues.linkStartDateTime);
        const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
        return differenceInMinutes;
    }

    function handleUpdateLink() {
        let durationInMinutes = calculateMinutes()
        let locDurationValue = durationInMinutes / 60
        let locCallTimeValue = updatedValues?.isCallDurationUnlimited ? 9999 : updatedValues.callTimeValue
        const paramObj = linkInfo?.class === 3 ? {
            "link_title": updatedValues?.title,
            "start_time": moment(new Date(updatedValues?.linkStartDateTime)).utc().format('YYYY-MM-DD HH:mm:ss'),
            "active_hours": locDurationValue,
            "features": updatedValues?.selectedApps?.join(","),
            "callTime": locCallTimeValue,
            "link_uuid": linkInfo?.uuid,
            "link_account_uuid": accountId,
            "link_location_uuid": locationId
        } : {
            "link_title": updatedValues?.title,
            "start_time": new Date(),
            "active_hours": 0,
            "features": "string",
            "callTime": 0,
            "link_uuid": linkInfo?.uuid,
            "link_account_uuid": accountId,
            "link_location_uuid": locationId
        }

        subscriberUpdateLingoLinkDetails(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                toast(toastFunctionToaster("Link Details updated Successfully", "success"))
                handleSideBarClose()
                refreshList(currentSelection)
            } else {
                toast(toastFunctionToaster("Failed to update link details", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update link details", "error"))
        })
    }

    function handleSideBarClose() {
        onSideBarClose()
        setErrors(null)
        touched.current = {}
        const values = getValues()
        setUpdatedValues(values)
    }

    const DatetimePickerStyles =
	{
		height: "38.5px",
		padding: "0 16px",
		width: "100%",
		border: ".8px solid #cbd5e0",
		borderRadius: "5px",
		background:"transparent",
		color:blackWhiteColor
	}

    function handleBlur(name) {
        touched.current = { ...touched.current, [name]: true }
        if (!updatedValues?.linkStartDateTime || !updatedValues?.linkEndDateTime) {
            setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
        }
        touched.current['link_title'] && !updatedValues?.title?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
    }

    useEffect(() => {
        touched.current['link_title'] && !updatedValues.linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
    }, [updatedValues.linkTitle])

    useEffect(() => {
        let durationInMinutes = calculateMinutes()
        if (new Date(updatedValues.linkEndDateTime) < new Date() || new Date(updatedValues.linkStartDateTime) < new Date()) {
            setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
            return
        }
        if (durationInMinutes < 60 || durationInMinutes > MAX_LINK_DURATION_VALUE) {
            setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 120 days" }))
            return
        }
        setErrors(errors => ({ ...errors, durationError: "" }))
    }, [updatedValues.linkStartDateTime, updatedValues.linkEndDateTime, updatedValues.newLingoLinkType])

    const labelStylesVoice = {
        mt: "1",
        ml: "-1",
        mr: "2.0",
        fontSize: "sm",
    };


    return (
        <>
            <Button justifyContent={'start'} ref={btnRef} w='100%' size="sm" bg={greenButtonBg} borderRadius="0" onClick={onSideBarOpen}>Update Link</Button>
            <Drawer
                isOpen={isSideBarOpen}
                placement='right'
                onClose={handleSideBarClose}
                finalFocusRef={btnRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Edit LingoLink</DrawerHeader>

                    <DrawerBody>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Flex w={'100%'} rounded={'md'}
                                mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                                <InputField
                                    mb="0"
                                    me="30px"
                                    id="link_title"
                                    label="LingoLink Name"
                                    value={updatedValues.title}
                                    name="link_title"
                                    required="true"
                                    borderRadius="5px"
                                    bg="inherit"
                                    onChange={e => {
                                        setUpdatedValues(values => ({ ...values, title: e.target.value }));
                                    }}
                                    onBlur={e => handleBlur(e.target.name)}
                                    placeholder="Enter Link Name"
                                    w="100%"
                                    maxlength={100}
                                />
                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                    {linkTitleFlag ? (
                                        <Text fontSize="sm" color={"red"} mt="1">
                                            LingoLink Name is Required
                                        </Text>
                                    ) : null}
                                    <Spacer />
                                    <Text textAlign={"right"} fontWeight={"100"} fontSize={"sm"} mt="2">
                                        {updatedValues?.title?.length}/100
                                    </Text>
                                </Flex>
                            </Flex>
                            {
                                linkInfo?.class === 3 && (
                                    <>
                                        <Flex w="full" p={3} mt={3} direction="column">
                                            <Flex w="full" direction="column">
                                                <Flex w="full" justifyContent="space-between">
                                                    <FormControl w="45%">
                                                        <FormLabel htmlFor="startDate" >Start Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
                                                        <Flatpickr options={{
                                                            enableTime: true,
                                                            dateFormat: "Y-m-d H:i",
                                                            minuteIncrement: 15,
                                                            minDate: 'today',
                                                        }}
                                                            altInputClass="flat-picker"
                                                            value={updatedValues?.linkStartDateTime}
                                                            name="link_start_date_time"
                                                            onBlur={(e) => {
                                                                handleBlur(e.target.name)
                                                            }}
                                                            onChange={value => setUpdatedValues(values => ({ ...values, linkStartDateTime: value[0] }))}
                                                            style={DatetimePickerStyles}
                                                            placeholder="Select Date Range"
                                                            id="startDate"
                                                        />
                                                    </FormControl>
                                                    <FormControl w="45%">
                                                        <FormLabel htmlFor="endDate" >End Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
                                                        <Flatpickr options={{
                                                            enableTime: true,
                                                            dateFormat: "Y-m-d H:i",
                                                            minuteIncrement: 15,
                                                            minDate: 'today',
                                                        }}
                                                            altInputClass="flat-picker"
                                                            onBlur={e => handleBlur(e.target.name)}
                                                            name="link_end_date_time"
                                                            value={updatedValues?.linkEndDateTime}
                                                            onChange={value => setUpdatedValues(values => ({ ...values, linkEndDateTime: value[0] }))}
                                                            style={DatetimePickerStyles}
                                                            placeholder="Select Date Range"
                                                            id="endDate"
                                                        />
                                                    </FormControl>
                                                </Flex>


                                                {errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
                                            </Flex>
                                            <Flex
                                                mt="3"
                                                w={"100%"}
                                                h={"140px"}
                                                direction={"column"}
                                                py={1}
                                                px={5}
                                                rounded={"md"}
                                                borderWidth={"0.5px"}
                                                borderColor={"gray.200"}>
                                                <Text>
                                                    Maximum minutes available in this call: <b>{updatedValues?.isCallDurationUnlimited ? 'unlimited' : updatedValues?.callTimeValue}</b>{" "}
                                                    minutes.
                                                </Text>
                                                <Checkbox mt='2' isChecked={updatedValues?.isCallDurationUnlimited} onChange={e => setUpdatedValues(values => ({ ...values, isCallDurationUnlimited: !values.isCallDurationUnlimited, callTimeValue: values.isCallDurationUnlimited ? 50 : 9999 }))}>Make it unlimited</Checkbox>

                                                <Slider
                                                    isDisabled={updatedValues?.isCallDurationUnlimited}
                                                    mt={5}
                                                    aria-label="slider-ex-1"
                                                    colorScheme="teal"
                                                    min={5}
                                                    max={600}
                                                    value={updatedValues?.callTimeValue}
                                                    step={5}
                                                    defaultValue={5}
                                                    onChange={v => setUpdatedValues(values => ({ ...values, callTimeValue: v }))}
                                                    onMouseEnter={() => setShowTooltip(true)}
                                                    onMouseLeave={() => setShowTooltip(false)}>
                                                    <SliderMark value={5} {...labelStylesVoice}>
                                                        5
                                                    </SliderMark>
                                                    <SliderMark value={100} {...labelStylesVoice}>
                                                        100
                                                    </SliderMark>
                                                    <SliderMark value={200} {...labelStylesVoice}>
                                                        200
                                                    </SliderMark>
                                                    <SliderMark value={300} {...labelStylesVoice}>
                                                        300
                                                    </SliderMark>
                                                    <SliderMark value={400} {...labelStylesVoice}>
                                                        400
                                                    </SliderMark>
                                                    <SliderMark value={500} {...labelStylesVoice}>
                                                        500
                                                    </SliderMark>
                                                    <SliderMark value={600} {...labelStylesVoice}>
                                                        600
                                                    </SliderMark>
                                                    <SliderTrack>
                                                        <SliderFilledTrack />
                                                    </SliderTrack>
                                                    <Tooltip
                                                        hasArrow
                                                        bg="teal.500"
                                                        color="white"
                                                        placement="top"
                                                        isOpen={showTooltip}
                                                        label={`${updatedValues?.callTimeValue}`}
                                                    >
                                                        <SliderThumb />
                                                    </Tooltip>
                                                </Slider>
                                            </Flex>
                                        </Flex>
                                        <Flex w={'100%'} rounded={'md'}
                                            mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                                            <Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
                                            <CheckboxGroup colorScheme='blue' value={updatedValues?.selectedApps} onChange={values => setUpdatedValues(preValues => ({ ...preValues, selectedApps: values }))}>
                                                <SimpleGrid columns={2} gap="2px" mt="2" >
                                                    <Checkbox value='hi' display={subAppDetails?.includes('hi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('hi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1	" borderRadius="50px" src={HumanAIBg} />
                                                            <Text as="span" >
                                                                Human Interpretation
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                    <Checkbox value='bi' display={subAppDetails?.includes('bi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('bi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1	" borderRadius="50px" src={BasicAIBg} />
                                                            <Text as="span">
                                                                Secure AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                    <Checkbox value='pi' display={subAppDetails?.includes('pi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('pi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1" borderRadius="50px" src={PremiumAIBg} />
                                                            <Text as="span">
                                                                Premium AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                    <Checkbox value='di' display={subAppDetails?.includes('di') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('di') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1" borderRadius="50px" src={DocumentAiLightBg} />
                                                            <Text as="span">
                                                                Document AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                </SimpleGrid>
                                            </CheckboxGroup>
                                        </Flex>
                                    </>
                                )
                            }
                        </FormControl>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={handleSideBarClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' isDisabled={isUpdateDisabled} onClick={handleUpdateLink}>Update</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default UpdateLink