import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/card/Card";
import { Link as ChakraLink } from "@chakra-ui/react";
import humanai from "../../../assets/marketing/humanai.png";
import { Link as ReactRouterLink } from "react-router-dom";
import airobot from "../../../assets/marketing/airobot-small.png";
import lingo_logo from "../../../assets/landingPageImg/kb_logo.png";
import { Box, Text, SimpleGrid, Flex, Image, Center, Divider } from "@chakra-ui/react";

function DummyAppDemo() {
	const navigate = useNavigate();

	const launchPage = url => {
		navigate(url, {
			state: {
				sourcePage: "aiapp",
			},
		});
	};

	return (
		<Flex bg={"gray.800"} direction="column" pt={{ sm: "1px", lg: "1px" }}>
			<Card bg={"gray.800"} w={"100%"} h="100px" align={"center"} verticalAlign={"center"} alignContent={"center"}>
				<Box w={"100%"} align={"center"}>
					<Image src={lingo_logo} h={"60px"} w={"180px"} />
				</Box>
			</Card>
			<Card bg={"gray.800"} w={"100%"} mt={1} h="calc(100vh - 101px)" align={"center"} px={20}>
				<SimpleGrid w={"100%"} p={10} columns={2} gap="40px" mt={"20px"} mb="30px">
					<Box rounded={"md"} bgGradient="linear(to-r, teal.100, blue.600)" w={{ base: "600px", sm: "250px", md: "400px", xl: "600px" }} h={{ base: "400px", sm: "200px", md: "300px", xl: "400px" }} textAlign={"center"} align={"center"} p={10}>
						<Center>
							<Flex w={"100%"} direction="column">
								<Text fontWeight={800} fontSize={"3xl"}>
									Human Interpretation
								</Text>
								<Divider alignSelf={"center"} w={"20%"} mt={5} />
								<Image src={humanai} alignSelf={"center"} mt={10} w={{ base: "200px", sm: "100px", md: "200px", xl: "200px" }} h={{ base: "200px", sm: "100px", md: "200px", xl: "200px" }} />
							</Flex>
						</Center>
					</Box>
					<Box onClick={() => launchPage("")} rounded={"md"} bgGradient="linear(to-r, blue.300, blue.500)" w={{ base: "600px", sm: "250px", md: "400px", xl: "600px" }} h={{ base: "400px", sm: "200px", md: "300px", xl: "400px" }} textAlign={"center"} align={"center"} p={10}>
						<Center>
							<ChakraLink as={ReactRouterLink} isExternal to={"https://lingolet.onemeta.ai/"}>
								<Flex w={"100%"} direction="column">
									<Text fontWeight={800} fontSize={"3xl"}>
										Secure AI Interpretation
									</Text>
									<Divider alignSelf={"center"} w={"20%"} mt={5} />
									<Image src={airobot} alignSelf={"center"} mt={10} w={{ base: "200px", sm: "100px", md: "200px", xl: "200px" }} h={{ base: "200px", sm: "100px", md: "200px", xl: "200px" }} />
								</Flex>
							</ChakraLink>
						</Center>
					</Box>
				</SimpleGrid>
			</Card>
		</Flex>
	);
}

export default DummyAppDemo;
