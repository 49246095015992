import { Box, Button, Checkbox, FormControl, FormLabel, Input, NumberInput, NumberInputField, Radio, RadioGroup, Select, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { createFormData } from "../../../../services/adminServices";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

const DynamicForm = ({ form }) => {
    const { form_name, form_design } = form;
    const toast = useToast();
    const [submitLoading, setSubmitLoading] = useState(false);
  
    const [fieldValues, setFieldValues] = useState({});
  
    useEffect(() => {
      const initialValues = {};
      form_design.fields.forEach((field) => {
        initialValues[field.name] = "";
      });
      setFieldValues(initialValues);
    }, [form]);
  
    const handleFieldChange = (name, value) => {
      setFieldValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };
  
    const handleSubmitClick = () => {
      try {
        setSubmitLoading(true);
        const requestBody = {
          form_uuid: form.form_uuid,
          form_key: form.form_key,
          form_data: fieldValues
        }
  
        createFormData(requestBody).
          then((res) => {
            if (res?.status !== 'failure') {
              toast(toastFunctionToaster(res?.message, "success"));
              setFieldValues({});
            } else {
              toast(toastFunctionToaster(res?.data[0]?.error, "error"));
            }
          })
          .catch((err) => {
            toast(toastFunctionToaster("Failed to submit form data", "error"));
          }).finally(()=>{
            setSubmitLoading(false);
          })
      } catch (error) {
        toast(toastFunctionToaster("Failed to submit form data", "error"));
      }
    };
  
    return (
      <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {form_name}
        </Text>
        <Stack spacing={4}>
          {form_design.fields.map((field, index) => {
            const { type, name, design } = field;
  
            const {
              mandatory,
              readonly,
              disabled,
              hidden,
              min,
              max,
              minLength,
              maxLength,
              format,
              validation_message,
              is_checkkbox,
              is_radio,
              is_dropdown,
              enum: options,
              checkbox,
              radio,
              dropdown,
            } = design;
  
            if (hidden) return null;
  
            let fieldComponent = null;
  
            switch (type) {
              case "text":
                fieldComponent = (
                  <Input
                    type="text"
                    value={fieldValues[name]}
                    isReadOnly={readonly}
                    isDisabled={disabled}
                    minLength={minLength || undefined}
                    maxLength={maxLength || undefined}
                    placeholder={`Enter ${name}`}
                    onChange={(e) => handleFieldChange(name, e.target.value)}
                  />
                );
                break;
  
              case "numeric":
                fieldComponent = (
                  <NumberInput
                    value={fieldValues[name]}
                    isReadOnly={readonly}
                    isDisabled={disabled}
                    min={min || undefined}
                    max={max || undefined}
                    onChange={(valueString) => handleFieldChange(name, Number(valueString))}
                  >
                    <NumberInputField />
                  </NumberInput>
                );
                break;
  
              case "date":
                fieldComponent = (
                  <Input
                    type="date"
                    value={fieldValues[name]}
                    isReadOnly={readonly}
                    isDisabled={disabled}
                    placeholder="Select a date"
                    pattern={format || undefined}
                    onChange={(e) => handleFieldChange(name, e.target.value)}
                  />
                );
                break;
  
              case "boolean":
                fieldComponent = (
                  <Checkbox
                    isChecked={fieldValues[name]}
                    isReadOnly={readonly}
                    isDisabled={disabled}
                    onChange={(e) => handleFieldChange(name, e.target.checked)}
                  >
                    {name}
                  </Checkbox>
                );
                break;
  
              case "checkbox":
                if (is_checkkbox) {
                  fieldComponent = (
                    <Checkbox
                      isChecked={fieldValues[name]}
                      isReadOnly={readonly}
                      isDisabled={disabled}
                      onChange={(e) => handleFieldChange(name, e.target.checked)}
                    >
                      {name}
                    </Checkbox>
                  );
                }
                break;
  
              case "radio":
                if (is_radio) {
                  fieldComponent = (
                    <RadioGroup
                      value={fieldValues[name]}
                      onChange={(value) => handleFieldChange(name, value)}
                    >
                      <Stack direction={radio?.layout || "vertical"}>
                        {options?.map((opt, i) => (
                          <Radio key={i} value={opt} isDisabled={disabled}>
                            {opt}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  );
                }
                break;
  
              case "dropdown":
                if (is_dropdown) {
                  fieldComponent = (
                    <Select
                      value={fieldValues[name]}
                      placeholder={dropdown?.placeholder || "Select an option"}
                      isDisabled={disabled}
                      onChange={(e) => handleFieldChange(name, e.target.value)}
                    >
                      {options?.map((opt, i) => (
                        <option key={i} value={opt}>
                          {opt}
                        </option>
                      ))}
                    </Select>
                  );
                }
                break;
  
              default:
                fieldComponent = <Text>Unsupported field type: {type}</Text>;
            }
  
            return (
              <FormControl
                key={index}
                isRequired={mandatory}
              >
                <FormLabel>{name}</FormLabel>
                {fieldComponent}
              </FormControl>
            );
          })}
        </Stack>
        <Button mt={4} colorScheme="blue" onClick={handleSubmitClick} isLoading={submitLoading}>
          Submit
        </Button>
      </Box>
    );
  };

export default DynamicForm;