// React + Chakra Imports
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import React, { useState, useEffect, useMemo } from "react";
import { Flex, Button, Input, Text, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createLingoletIdContact, updateLingoletIdContact } from "../../../../../../services/accountServices";

const AddUpdateContact = React.memo(({ identity_uuid, passClientData, setclientData, updateClientDataCounter, idValue, indexValue }) => {
	const toast = useToast();
	const btnRef = React.useRef();
	const [error, setError] = useState({});
	const [counter, setCounter] = useState(0);
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [secondaryEmailTag, setSecondaryEmailTag] = useState([]);
	const [otherNumberTag, setOtherNumberTag] = useState([]);
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const [drawer, selectedDrawer] = useState("");

	const initialFormData = {
		id_contact_home_phone: "",
		id_contact_mobile_phone: "",
		id_contact_business_phone: "",
		id_contact_other_phone: [],
		id_contact_primary_email: "",
		id_contact_secondary_email: [],
	};
	const [formData, setFormData] = useState(initialFormData);

	// Todo:Update Form Details On Edit
	useEffect(() => {
		if (passClientData !== null || (passClientData !== undefined && passClientData?.id_contact_uuid)) {
			setFormData({
				id_contact_home_phone: passClientData?.id_contact_home_phone || "",
				id_contact_mobile_phone: passClientData?.id_contact_mobile_phone || "",
				id_contact_business_phone: passClientData?.id_contact_business_phone || "",
				id_contact_other_phone: passClientData?.id_contact_other_phone ? passClientData?.id_contact_other_phone?.split(",") : [],
				id_contact_primary_email: passClientData?.id_contact_primary_email || "",
				id_contact_secondary_email: passClientData?.id_contact_secondary_email ? passClientData?.id_contact_secondary_email?.split(",") : [],
			});
			setOtherNumberTag(passClientData?.id_contact_other_phone ? passClientData?.id_contact_other_phone?.split(",") : []);
			setSecondaryEmailTag(passClientData?.id_contact_secondary_email ? passClientData?.id_contact_secondary_email?.split(",") : []);
			if (idValue === indexValue) {
				onOpen();
			}
		} else {
			setFormData(initialFormData);
			setOtherNumberTag([]);
			setSecondaryEmailTag([]);
		}
	}, [passClientData, idValue, indexValue]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;

		if (!formData.id_contact_home_phone) {
			formIsValid = false;
			errors["contactHome"] = "*Number Cannot Be Empty.";
		}

		if (!formData.id_contact_mobile_phone) {
			formIsValid = false;
			errors["contactNumber"] = "*Number Cannot Be Empty.";
		}

		// Primary Email Validation
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (!formData.id_contact_primary_email) {
			formIsValid = false;
			errors["primaryEmail"] = "*Primary Email Cannot Be Empty.";
		} else if (!emailRegex.test(formData.id_contact_primary_email)) {
			formIsValid = false;
			errors["primaryEmail"] = "*Invalid Primary Email Address.";
		}

		// Secondary Email Validation
		if (formData.id_contact_secondary_email && formData.id_contact_secondary_email.length > 0) {
			formData.id_contact_secondary_email.forEach(email => {
				if (!emailRegex.test(email)) {
					formIsValid = false;
					errors["secondaryEmail"] = "*One or more secondary emails are invalid.";
				}
			});
		}

		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			formData["identity_uuid"] = identity_uuid;
			if (passClientData?.id_contact_uuid) {
				formData["identity_uuid"] = passClientData?.identity_uuid;
				formData["id_contact_uuid"] = passClientData?.id_contact_uuid;
				updateLingoletIdContact(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createLingoletIdContact(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	const handleTagChange = (newTags, field) => {
		const setterMap = {
			id_contact_other_phone: setOtherNumberTag,
			id_contact_secondary_email: setSecondaryEmailTag,
		};

		setterMap[field](newTags);
		setFormData(prevData => ({
			...prevData,
			[field]: newTags,
		}));
	};

	//Todo:Update Counter wjen data updated to refresh data
	useEffect(() => {
		updateClientDataCounter(counter);
	}, [counter]);

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setFormData(initialFormData);
		setOtherNumberTag([]);
		setSecondaryEmailTag([]);
		setError({});
		setclientData(null);
		onClose();
	}

	// Todo:Reset Form Data

	return (
		<>
			<Button
				ref={btnRef}
				w="100%"
				// onClick={onOpen}
				justifyContent={"start"}
				px="12px"
				fontWeight={"400"}
				_hover={{ bg: "blue.100", _active: "blue.100" }}
				sx={{
					bg: "blue.100",
					borderStyle: "dashed",
					borderTop: "none",
					borderWidth: "1px",
					borderColor: "black",
					color: "black",
					rounded: "sm",
				}}
				onClick={() => {
					onOpen();
					selectedDrawer();
				}}
			>
				Contact
			</Button>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{passClientData?.identity_uuid ? "Update" : "Create"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Contact
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* Home Phone */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Home Phone
								</FormLabel>
								<Input placeholder="Enter Home Phone Number" type="text" name="id_contact_home_phone" value={formData.id_contact_home_phone} onChange={handleChange} color={textColorPrimary} />
								{error.contactHome ? (
									<Text color="red" mb="2">
										{error.contactHome}
									</Text>
								) : null}
							</FormControl>

							{/* Mobile Number */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Mobile Number
								</FormLabel>
								<Input placeholder="Enter Mobile Number Number" type="text" name="id_contact_mobile_phone" value={formData.id_contact_mobile_phone} onChange={handleChange} color={textColorPrimary} />
								{error.contactNumber ? (
									<Text color="red" mb="2">
										{error.contactNumber}
									</Text>
								) : null}
							</FormControl>

							{/* Business Phone */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Business Phone
								</FormLabel>
								<Input placeholder="Enter Business Phone Number" type="text" name="id_contact_business_phone" value={formData.id_contact_business_phone} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							{/* Other Phone Number */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Other Number
								</FormLabel>
								<TagsInput
									borderRadis
									inputProps={{
										placeholder: "Add number...",
										style: {
											fontSize: "16px",
										},
									}}
									value={otherNumberTag}
									onChange={newTags => {
										handleTagChange(newTags, "id_contact_other_phone");
									}}
								/>
							</FormControl>

							{/* Primary Email */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Primary Email
								</FormLabel>
								<Input placeholder="Enter Primary Email" type="text" name="id_contact_primary_email" value={formData.id_contact_primary_email} onChange={handleChange} color={textColorPrimary} />
								{error.primaryEmail ? (
									<Text color="red" mb="2">
										{error.primaryEmail}
									</Text>
								) : null}
							</FormControl>

							{/* Secondary Email */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Secondary Email
								</FormLabel>
								<TagsInput
									inputProps={{
										placeholder: "Add email...",
										style: {
											fontSize: "16px",
											bg: "transparent",
										},
									}}
									value={secondaryEmailTag}
									onChange={newTags => {
										handleTagChange(newTags, "id_contact_secondary_email");
									}}
								/>
								{error.secondaryEmail ? (
									<Text color="red" mb="2">
										{error.secondaryEmail}
									</Text>
								) : null}
							</FormControl>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{passClientData?.identity_uuid ? "Update" : "Create"}
							</Button>
							<Button ml="20px" colorScheme="gray" variant="outline" onClick={handleCloseDrawer}>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
});
export default AddUpdateContact;
