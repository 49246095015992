import React from "react";
import { useNavigate } from "react-router-dom";
import { Link as SimpleGrid } from "@chakra-ui/react";
import { Heading, Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
// import theme from "./theme";

import Card from "../../components/card/Card";
import LingoletFooter from "../landinghome/LingoletFooter";

import logo from "../../assets/landingPageImg/kb_logo.png";

import avkashimg from "../../assets/marketing/avkash.png";
import frankimg from "../../assets/marketing/Frank.png";
import sawandeepimg from "../../assets/marketing/kaur.png";
import edimg from "../../assets/marketing/ed.png";
import wellsimg from "../../assets/marketing/wells.png";
import jerryimg from "../../assets/marketing/jerry.png";
import luzimg from "../../assets/marketing/luz.png";

function TeamPage() {
	const navigate = useNavigate();
	const btnRef = React.useRef();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const launchPage = url => {
		navigate(url, {
			state: {
				sourcePage: "teampage",
			},
		});
	};

	const contentArr = [
		{
			heading: "Jerry Song",
			subheading: "CEO",
			img: jerryimg,
		},
		{
			heading: "Avkash Chauhan",
			subheading: "CTO",
			img: avkashimg,
		},
		{
			heading: "Frank Wei",
			subheading: "Co-founder & Advisor",
			img: frankimg,
		},
		{
			heading: "Edward Varela",
			subheading: "CSO",
			img: edimg,
		},
		{
			heading: "Wells Tu",
			subheading: "Head of Product",
			img: wellsimg,
		},
		{
			heading: "Sawandeep Kaur",
			subheading: "Product Manager",
			img: sawandeepimg,
		},
		{
			heading: "Luz M. Sanchis",
			subheading: "Translation Advisor",
			img: luzimg,
		},
	];

	const renderContent = contentArr?.map((content, index) => (
		<Flex mb="16" flexDirection={index % 2 === 1 ? "row-reverse" : ""}>
			<Card _hover={{ bg: "gray.300" }} bg={"gray.200"}>
				<Box>
					<Heading
						fontSize={{
							base: "",
							md: "",
							xl: "",
							sm: "20px",
						}}
						fontWeight={"500"}
						mb="6"
					>
						{content?.heading}
					</Heading>
					<Text
						fontSize={{
							base: "14px",
							md: "14px",
							xl: "14px",
							sm: "14px",
						}}
						fontWeight={"500"}
					>
						{content?.subheading}
					</Text>
				</Box>
				<Box>
					<Image src={content?.img}></Image>
				</Box>
			</Card>
		</Flex>
	));

	return (
		<>
			<Box bg="#fff" color="#000">
				<Box bg="#fff">
					<Flex
						w={{
							base: "50%",
							md: "90%",
							xl: "50%",
							sm: "100%",
						}}
						mx="auto"
						justifyContent={"space-between"}
						alignItems={"center"}
						h="12vh"
					>
						<Box>
							<Image
								_hover={{ cursor: "pointer" }}
								onClick={() => launchPage("/")}
								w={{
									base: "50%",
									md: "50%",
									xl: "50%",
									sm: "50%",
								}}
								src={logo}
							></Image>
						</Box>
						<Flex
							w={"60%"}
							alignItems={"center"}
							fontSize={{
								base: "18px",
								md: "18px",
								xl: "18px",
								sm: "10px",
							}}
							bg="white"
						>
							<Text m="0" fontWeight={"bold"} cursor={"pointer"} color="#000">
								Lingolet Team driving the future of AI and Language.
							</Text>
						</Flex>
					</Flex>
				</Box>
				<Box
					w={{
						base: "80%",
						md: "90%",
						xl: "50%",
						sm: "90%",
					}}
					mx="auto"
					my="1"
					bg="#fff"
				>
					<Heading
						textAlign={"center"}
						my="50px"
						fontSize={{
							base: "20px",
							md: "20px",
							xl: "50px",
							sm: "20px",
						}}
						fontWeight={"normal"}
					>
						<Text as="span" fontWeight={"bold"}>
							The Lingolet Team
						</Text>
					</Heading>
					<Text mt={4}>We are an international team from different countries who speak different languages. Our team has more than 50 years of cumulative experience in technology and the language industry. Lingolet is all about languages. What we do is what we are.</Text>
					<Text></Text>
					<Box mt={5}>
						<SimpleGrid
							spacing={5}
							columns={{
								base: "2",
								md: "2",
								xl: "2",
								sm: "1",
							}}
						>
							{renderContent}
						</SimpleGrid>
					</Box>
				</Box>
				<LingoletFooter />
				<Box bg="#fff">
					<Text color="#000" textAlign={"center"} p="8">
						&copy; Copyright 2024 Lingolet AI, Inc. All rights reserved.
					</Text>
				</Box>
			</Box>
		</>
	);
}

export default TeamPage;
