import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { Document, Page, PDFDownloadLink, Text, View, StyleSheet } from '@react-pdf/renderer';
import React, { useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import moment from 'moment';
import { useRef } from 'react';
import { useEffect } from 'react';
import { toastFunctionToaster } from '../../../../utils/toastFunction';

const DownLoadPdfBtn = ({ loading, downloadPdfBtn, setPdfLoading, error }) => {
    useEffect(() => {
        if(error){
            toast(toastFunctionToaster("Error downloading PDF. Please try again.", "error"));
        }
        
        setPdfLoading(loading)
    }, [loading])
    const toast = useToast()
    return (
        <Button
            rounded={'lg'}
            size="sm"
            leftIcon={<AiOutlineFilePdf />}
            colorScheme="red"
            isLoading={loading}
            ref={downloadPdfBtn}
            onClick={()=>toast(toastFunctionToaster("PDF downloaded Successfully", "success"))}
        >
            PDF
        </Button>
    )
}

const BillingPdf = ({ data, selectedRange }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [Pdfloading, setPdfLoading] = useState(true);
    const downloadPdfBtn = useRef(null);
    const { onClose } = useDisclosure()

    function getFileName(selectedRange, prefix = "billing") {
        if (!selectedRange || selectedRange.length !== 2) {
            throw new Error("Invalid date range provided");
        }

        const [start, end] = selectedRange;
        const startDate = moment(start).format("MM-DD-YYYY");
        const endDate = moment(end).format("MM-DD-YYYY");

        const fileName = `${startDate} - ${endDate}_${prefix}.pdf`;
        return fileName;
    }

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#f2f8f9',
            padding: 20,
            fontFamily: 'Helvetica',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        tableHeader: {
            backgroundColor: '#AEE1E8',
            padding: 8,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 10,
        },
        tableCell: {
            padding: 8,
            textAlign: 'center',
            borderBottom: '1px solid #ddd',
            fontSize: 7,
            width: "13.9%",
        },
        tableRow: {
            width: "100%",
            flexDirection: 'row',
        },
    });

    const fileName = getFileName(selectedRange);
    useEffect(() => {
        if (isClicked && !Pdfloading && downloadPdfBtn.current) {
            downloadPdfBtn.current.click();
        }
    }, [isClicked, Pdfloading]);

    return (
        <>
            {isClicked ? (
                <PDFDownloadLink
                    document={
                        <Document>
                            <Page size="A4" style={styles.page}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>START TIME</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>END TIME</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>DURATION</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>PROVIDER</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>TARGET LANGUAGE</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>CALL TYPE</Text>
                                        <Text style={[styles.tableCell, styles.tableHeader]}>EMAIL</Text>
                                    </View>
                                    {data?.map(item => (
                                        <View style={styles.tableRow} key={item.id}>
                                            <Text style={styles.tableCell}>{moment(item?.start_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                                            <Text style={styles.tableCell}>{moment(item?.end_time).format("MMM DD,YYYY,HH:mm a")}</Text>
                                            <Text style={styles.tableCell}>{item?.duration}</Text>
                                            <Text style={styles.tableCell}>{item?.provider}</Text>
                                            <Text style={styles.tableCell}>{item?.target_language}</Text>
                                            <Text style={styles.tableCell}>{item?.call_type}</Text>
                                            <Text style={styles.tableCell}>{item?.subscriber || '...'}</Text>
                                        </View>
                                    ))}
                                </View>
                            </Page>
                        </Document>
                    }
                    fileName={fileName}
                >
                    {({ loading, error }) => (
                        <DownLoadPdfBtn downloadPdfBtn={downloadPdfBtn} loading={loading} setPdfLoading={setPdfLoading} error={error}/>
                    )}
                </PDFDownloadLink>
            ) :
                <Button
                    rounded={'lg'}
                    size="sm"
                    leftIcon={<AiOutlineFilePdf />}
                    colorScheme="red"
                    onClick={() => setIsClicked(true)}
                >
                    PDF
                </Button>
            }
            {(Pdfloading && downloadPdfBtn.current) &&
                <Modal isOpen={Pdfloading} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Generating PDF</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <Spinner thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl' />
                            <Text mt={4} fontSize="lg" color="gray.500">
                                Please wait, generating your PDF...
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default BillingPdf;
