// React Chakra Imports
import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { Box, Flex, Text, Menu, Grid, GridItem, MenuButton, MenuList, Button } from "@chakra-ui/react";

const IndustrySolutions = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Menu isOpen={isMenuOpen} onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
			<MenuButton as={Button} width="100%" bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }} ml="0">
				<Box 
					fontSize={'md'}
					display={"inline-flex"} alignItems={"center"} gap={1} cursor="pointer" 
					color={isMenuOpen ? "#259edc" : "#000"}>
					Industry Solutions {isMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
				</Box>
			</MenuButton>
			<MenuList bg={"white"} ml="" borderRadius={"0"}>
				<Flex h="20vh" w={"100vw"} p="5px" 
					//alignItems="center" justifyContent={"center"}
					>
					<Flex w={'100%'} direction={'column'} mx={10}>
						<Grid
							templateColumns={{
								sm: "repeat(1, 1fr)",
								md: "repeat(4, 1fr)",
								lg: "repeat(4, 1fr)",
							}}
							gap={10}
						>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
								 fontWeight={500}>Education (K12)</Text>
								<Text fontSize={'sm'}>Lingolet AI platform is designed to support various aspects of K-12 education system from students, parents and educators.</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Finance and Banking Services</Text>
								<Text fontSize={'sm'}>Various finance and banking systems can use Lingolet AI platform to provide interpretation and translation to their customers in most secure ways.</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Healthcare</Text>
								<Text fontSize={'sm'}>Providing healthcare services to everyone includes language understanding and for interpretation and translation, Lingolet AI platform provide various services.</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={'lg'} 
									pl={2} bg={'blue.100'} rounded={'lg'} mb={2}
									fontWeight={500}>Law Enforcement and Goverment</Text>
								<Text fontSize={'sm'}>Lingolet AI platform has various interpretation and translation functions to meet the need of Law Enforcement professionals.</Text>
							</GridItem>

						</Grid>
					</Flex>
				</Flex>

			</MenuList>
		</Menu>
	);
};

export default IndustrySolutions;
