import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HubspotForm from "react-hubspot-form";
import { Box, Text, VStack, Flex, Heading, useColorModeValue } from "@chakra-ui/react";

function EventFeedback() {
	const [showHeader, setShowHeader] = useState(true);

	return (
		<>
			<Box bg="gray.200" color="#000" p={5}>
				{/* <Box bg="#fff">
                <Flex
                w={{
                    base: "50%",
                    md: "90%",
                    xl: "50%",
                    sm: "100%",
                }}
                mx="auto"
                justifyContent={"space-between"}
                alignItems={"center"}
                h="12vh"
                >

                </Flex>
            </Box> */}
				<Box p={4}>
					<VStack spacing={4} align="left" target="_blank" p={2}>
						{showHeader ? (
							<Flex bg="gray.100" p={2} w={"100%"} direction={"column"}>
								<Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
									<Text as="" fontWeight={"600"} mr="1">
										FETC Event
									</Text>
									Feedback
								</Heading>
								<Text mt="3" fontSize={"17px"} color="gray.700">
									We do appreciate you take time and providing your valuable feedback.
									<br />
									Please answer the following 10 questions and also provide your contact information.
								</Text>
							</Flex>
						) : null}
						<Box w={"100%"} p={1} rounded={"md"}>
							<HubspotForm
								portalId="44706308"
								formId="cd1793ee-9380-47b4-a1e1-00978e74c1f9"
								onSubmit={() => setShowHeader(false)}
								onFormSubmitted={() => setShowHeader(false)}
								// onSubmit={() => console.log('Submit!')}
								// onReady={(form) => console.log('Form ready!')}
								loading={<div>Loading...</div>}
								formFieldGroups={[
									{
										fields: [
											{ name: "email", required: true, enabled: true, placeholder: "Email" },
											{ name: "first_name", required: true, enabled: true },
											{ name: "lastname", required: true, enabled: true },
											{ name: "companyname", required: true, enabled: true },
										],
									},
								]}
							/>
						</Box>
					</VStack>
				</Box>
			</Box>
		</>
	);
}

export default EventFeedback;
