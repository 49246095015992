// Chakra imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, FormControl, PinInput, PinInputField, Heading, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { verifyValidationCode } from "../../../services/sharedServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useState } from "react";

function RequestVerifyCode({ email, handleOnClose, getOtp, otpLoading }) {
	// Chakra color mode
	const textColorSecondary = "gray.400";
	const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
	const [loading, isLoading] = useState(false)
	const [pin, setPin] = useState(null)
	const toast = useToast()
	function verifyPin() {
			
			const paramObj = {
				signup_email: email,
				validation_code: pin
			}
			verifyValidationCode(paramObj).then(resp => {
				if (resp?.data[0]?.status) {
					if(resp?.data[0]?.access_verified){
						toast(toastFunctionToaster(resp?.message, "success"))
					}else{
						toast(toastFunctionToaster("Failed to verify code", "error"))
					}
					handleOnClose()
				} else {
					toast(toastFunctionToaster(resp?.message, "error"))
				}
			}).catch(e => {
				toast(toastFunctionToaster("Failed to verify code", "error"))
	
			}).finally(() => {
				isLoading(false)
			})
		}

		function handleGetOtp(){
			if(!otpLoading){
				getOtp()
			}
		}
	
	return (
		<Flex flexWrap={"wrap"} h="100%" alignContent={"center"}>
			<Box me="auto" mb="34px">
				<Heading color={"white"} fontSize="36px" mb="16px" mx={{ base: "auto", lg: "unset" }} textAlign={{ base: "center", lg: "left" }}>
					2-Step Verification
				</Heading>
				<Text color={textColorSecondary} fontSize="md" maxW={{ base: "95%", md: "100%" }} mx={{ base: "auto", lg: "unset" }} textAlign={{ base: "center", lg: "left" }}>
					Enter your 2-Step Verification email code to unlock!
				</Text>
			</Box>
			<Flex zIndex="2" direction="column" w={{ base: "100%", md: "425px" }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: "auto", lg: "unset" }} me="auto" mb={{ base: "20px", md: "auto" }}>
				<FormControl>
					<Flex justify="center">
						<PinInput mx="auto" otp onChange={(value) => setPin(value)}>
							<PinInputField fontSize="36px" color={"white"} borderRadius="16px" borderColor={borderColor} h={{ base: "55px", md: "87px" }} w={{ base: "55px", md: "87px" }} me="10px" />
							<PinInputField fontSize="36px" color={"white"} borderRadius="16px" borderColor={borderColor} h={{ base: "55px", md: "87px" }} w={{ base: "55px", md: "87px" }} me="10px" />
							<PinInputField fontSize="36px" color={"white"} borderRadius="16px" borderColor={borderColor} h={{ base: "55px", md: "87px" }} w={{ base: "55px", md: "87px" }} me="10px" />
							<PinInputField fontSize="36px" color={"white"} borderRadius="16px" borderColor={borderColor} h={{ base: "55px", md: "87px" }} w={{ base: "55px", md: "87px" }} />
						</PinInput>
					</Flex>

					<Button
						fontSize="14px"
						bg={"#259edc"}
						_hover={{ bg: "#259edc" }}
						color={"white"}
						borderRadius="16px"
						fontWeight="500"
						w="100%"
						h="50"
						mb="24px"
						mt="12px"
						onClick={() => {
							verifyPin()
						}}
						isLoading={loading}
					>
						Verify
					</Button>
				</FormControl>
				<Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
					<Text color={"white"} fontWeight="400" fontSize="14px" mx={{ base: "auto", lg: "unset" }} textAlign={{ base: "center", lg: "left" }}>
						Haven't received it?
						<Text color={"#259edc"} as="span" ms="5px" fontWeight="500" onClick={handleGetOtp}>
							Resend a new code
						</Text>
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default RequestVerifyCode;
