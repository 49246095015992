import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import moment from 'moment'
import TableComponent from "../accountBilling/TableComponent";

const ReportingTable = ({ onEdit, listData}) => {
  const columns = [
          {
              Header: 'Schedule Type',
              accessor: (row) =>row?.schedule_type ? row.schedule_type : "N/A",
              id: 'schedule_type',
              allowColorGrouping:true
          },
          {
            Header: 'Reporting Type',
            accessor: (row) =>row?.reporting_type ? row.reporting_type : "N/A",
            id: 'reporting_type',
            allowColorGrouping:true
          }, 
          {
            Header:"Recepients",
            accessor:(row)=>row.recipients?.length ? row?.recipients.join(",") : "N/A",
            id: 'recipients',
            allowColorGrouping:true
          },
          {
            Header:"Columns",
            accessor:(row)=>row?.columns?.length ? row?.columns.join(",") : "N/A",
            id: 'columns',
            allowColorGrouping:false
          },
          {
              Header: 'Status',
              accessor:(row)=>row.active ? "Active" : "Inactive",
              id: 'status',
              allowColorGrouping:true
          },
          {
            Header: 'Subject',
              accessor:(row)=>row?.subject ? row?.subject : "N/A",
              id: 'subject',
              allowColorGrouping:true
          },
          {
            Header: 'Format',
              accessor:(row)=>row?.format ? row?.format : "N/A",
              id: 'format',
              allowColorGrouping:true
          }
  
      ];

      const defaultSort = [
        { id: 'scheduleDate', desc: true },
    ];

  return (
   <TableComponent data={listData} columns={columns} defaultSort={defaultSort}/>
  );
};

export default ReportingTable;
