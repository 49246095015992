// React + Chakra Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Text, useColorModeValue, Alert, AlertIcon, Spinner, useToast, Icon, Tabs, TabList, TabPanels, Tab, TabPanel, Flex } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getLingoletIdAddressList } from "../../../../../../services/accountServices";

function AddressList({ identity_uuid, companyData, setclientData, idx, setIdxValue, reRenderContactList }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [bankDetailsList, setBankDetailsList] = useState([]);
	const shadowBlack = useColorModeValue("0px 3px 5px rgb(107 111 110)", "0px 3px 5px rgb(107 111 110);");

	const fields = [
		{
			label: "Address Type",
			key: "id_address_type",
		},
		{
			label: "Address Line 1",
			key: "id_address_line1",
		},
		{
			label: "Address Line 2",
			key: "id_address_line2",
		},
		{
			label: "City",
			key: "id_address_city",
		},
		{
			label: "State",
			key: "id_address_state",
		},
		{
			label: "ZIP Code",
			key: "id_address_zip",
		},
		{
			label: "Country",
			key: "id_address_country",
		},
		{
			label: "Region",
			key: "id_address_region",
		},
		{
			label: "Active Status",
			key: "id_address_active",
		},
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchAdressList();
	}, []);

	useEffect(() => {
		if (reRenderContactList !== null && reRenderContactList > 0) {
			//After update couter flags and function triggers
			fetchAdressList();
		}
	}, [reRenderContactList]);

	// Todo:Fetch Client Details
	const fetchAdressList = () => {
		isLoading(true);
		const obj = {
			identity_uuid: identity_uuid,
			id_company_uuid: companyData?.identity_company_uuid,
			id_address_active: "active",
		};

		getLingoletIdAddressList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setBankDetailsList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setBankDetailsList([]);
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderAddressDetails =
		bankDetailsList?.length > 0 ? (
			<Tabs variant="soft-rounded" borderRadius={"0px"} colorScheme="teal" w="100%">
				<TabList>
					{bankDetailsList.map((data, index) => (
						<Tab
							_selected={{
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: "#a5a5a5",
								fontWeight: "500",
								color: "black",
								borderBottom: "0px",
							}}
							sx={{
								bg: "green.100",
								color: "black",
								borderStyle: "solid",
								borderWidth: "1px",
								// borderColor: "grey.500",
								rounded: "md",
								borderBottomLeftRadius: "0px",
								borderBottomRightRadius: "0px",
							}}
							borderRadius={"0px"}
							key={data?.identity_uuid + index}
						>
							Address {index + 1}
						</Tab>
					))}
				</TabList>

				<TabPanels p="0px">
					{bankDetailsList.map((data, index) => (
						<TabPanel key={data?.identity_uuid + index} p="0px">
							<Flex flexWrap="wrap" p="4" borderWidth="1px" w="100%" alignItems="start" fontSize="15px" mb="4" borderRadius="10px" borderTopLeftRadius={"0px"} borderColor="#a5a5a5" position="relative" boxShadow="md">
								{fields.map(({ label, key }) => (
									<Flex mb="3" mr="3" key={key} w="47%">
										<Text fontWeight="bold" w="50%">
											{label}:
										</Text>
										<Text w="50%" ml="2">
											{data[key] !== undefined ? data[key]?.toString() : "No data found"}
										</Text>
									</Flex>
								))}
								<Flex
									rounded="md"
									p={2}
									alignItems="center"
									borderWidth="1px"
									borderColor="gray.400"
									align="end"
									cursor="pointer"
									borderRadius="50px"
									position="absolute"
									right="8px"
									onClick={() => {
										setclientData(data);
										setIdxValue(idx);
									}}
								>
									<Icon as={AiFillEdit} fontSize="14px" cursor="pointer" />
								</Flex>
							</Flex>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderAddressDetails}</>
			)}
		</Flex>
	);
}

export default AddressList;
