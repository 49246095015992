// React + Chakra Imports
import { AiFillPlusCircle } from "react-icons/ai";

import React, { useState, useEffect } from "react";
import { Flex, Button, Input, Icon, Text, Switch, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, Select, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createBookingAppoinmentServices, updateBookingAppoinmentServices } from "../../../../../../services/accountServices";

function AddUpdateAppoinment({ bookingId, bookingServiceId, passClientData, setAddressData, updateAppoinmentDataCounter, idValue, indexValue }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [counter, setCounter] = useState(0);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const initialFormData = {
		booking_uuid: bookingId,
		booking_service_uuid: bookingServiceId,
		booking_appointment_languages: "",
		booking_appointment_interpreters_count: "",
		booking_appointment_consumer_uuid: "",
		booking_appointment_interpreters_gender: "",
		booking_appointment_preferred_interpreter_uuid: "",
		booking_appointment_preferred_interpreter_email: "",
		booking_appointment_instructions: "",
	};
	const [formData, setFormData] = useState(initialFormData);

	// Todo:Update Form Details On Edit
	useEffect(() => {
		if (passClientData !== null || (passClientData !== undefined && passClientData?.booking_appointment_uuid)) {
			setFormData({
				booking_uuid: bookingId,
				booking_service_uuid: bookingServiceId,
				booking_appointment_languages: passClientData.booking_appointment_languages || "",
				booking_appointment_interpreters_count: passClientData.booking_appointment_interpreters_count || "",
				booking_appointment_consumer_uuid: passClientData.booking_appointment_consumer_uuid || "",
				booking_appointment_interpreters_gender: passClientData.booking_appointment_interpreters_gender || "",
				booking_appointment_preferred_interpreter_uuid: passClientData.booking_appointment_preferred_interpreter_uuid || "",
				booking_appointment_preferred_interpreter_email: passClientData.booking_appointment_preferred_interpreter_email || "",
				booking_appointment_instructions: passClientData.booking_appointment_instructions || "",
			});
			if (idValue === indexValue) {
				onOpen();
			}
		} else {
			setFormData(initialFormData);
		}
	}, [passClientData, idValue, indexValue]);

	// Todo:Update Data On Change
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" || type === "switch" ? checked : value,
		});
	};
	// Todo:Validate Form
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		if (!formData.booking_appointment_languages) {
			formIsValid = false;
			errors["booking_appointment_languages"] = "*Language Cannot Be Empty.";
		}
		if (!formData.booking_appointment_interpreters_count) {
			formIsValid = false;
			errors["booking_appointment_interpreters_count"] = "*Code Cannot Be Empty.";
		}

		setError(errors);
		return formIsValid;
	};

	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);

			if (passClientData?.booking_appointment_uuid) {
				formData["booking_appointment_uuid"] = passClientData?.booking_appointment_uuid;
				updateBookingAppoinmentServices(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			} else {
				createBookingAppoinmentServices(formData)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
						handleCloseDrawer();
					});
			}
		}
	};

	//Todo:Update Counter wjen data updated to refresh data
	useEffect(() => {
		updateAppoinmentDataCounter(counter);
	}, [counter]);

	// Todo:On Close Reset Data
	function handleCloseDrawer() {
		setFormData(initialFormData);
		setError({});
		onClose();
		setAddressData(null);
	}

	// Todo:Reset Form Data

	return (
		<>
			<Icon onClick={onOpen} as={AiFillPlusCircle} rounded={"md"} fontSize={"28px"} cursor={"pointer"} />
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{passClientData?.booking_appointment_uuid ? "Update" : "Add New"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Booking Appoinment
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							{/* booking_appointment_languages */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Booking Appoinment Language
								</FormLabel>
								<Input type="text" name="booking_appointment_languages" value={formData.booking_appointment_languages} onChange={handleChange} color={textColorPrimary} />
								{error.booking_appointment_languages ? (
									<Text color="red" mb="2">
										{error.booking_appointment_languages}
									</Text>
								) : null}
							</FormControl>
							{/*booking_appointment_interpreters_count */}
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Interpreter Count
								</FormLabel>
								<Input type="text" name="booking_appointment_interpreters_count" value={formData.booking_appointment_interpreters_count} onChange={handleChange} color={textColorPrimary} />
								{error.booking_appointment_interpreters_count ? (
									<Text color="red" mb="2">
										{error.booking_appointment_interpreters_count}
									</Text>
								) : null}
							</FormControl>
							{/* booking_appointment_consumer_uuid */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Consumer Id{" "}
								</FormLabel>
								<Input type="text" name="booking_appointment_consumer_uuid" value={formData.booking_appointment_consumer_uuid} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							{/* Gender */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Gender
								</FormLabel>

								<Select name="booking_appointment_interpreters_gender" value={formData.booking_appointment_interpreters_gender} onChange={handleChange} color={textColorPrimary}>
									<option value="male">Male</option>
									<option value="female">Female</option>
								</Select>
							</FormControl>
							{/* booking_appointment_preferred_interpreter_uuid */}
							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Interpreter UuId
								</FormLabel>
								<Input type="text" name="booking_appointment_preferred_interpreter_uuid" value={formData.booking_appointment_preferred_interpreter_uuid} onChange={handleChange} color={textColorPrimary} />
							</FormControl>
							<>
								{/* booking_appointment_preferred_interpreter_email */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Interpreter Email
									</FormLabel>
									<Input type="text" name="booking_appointment_preferred_interpreter_email" value={formData.booking_appointment_preferred_interpreter_email} onChange={handleChange} color={textColorPrimary} />
								</FormControl>

								{/* booking_appointment_instructions */}
								<FormControl>
									<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
										Instructions
									</FormLabel>
									<Input type="text" name="booking_appointment_instructions" value={formData.booking_appointment_instructions} color={textColorPrimary} onChange={handleChange} />
								</FormControl>
							</>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isDisabled={loading ? true : false} isLoading={loading ? true : false}>
								{passClientData?.booking_appointment_uuid ? "Update" : "Create"}
							</Button>
							<Button
								variant="outline"
								colorScheme="red"
								ml={3}
								onClick={() => {
									handleCloseDrawer();
								}}
							>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateAppoinment;
