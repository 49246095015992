import { Alert, AlertIcon, Box, Button, Flex, Icon, Spacer, Spinner, Text, useToast } from '@chakra-ui/react';
import React from 'react'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { useBrandBg, useTextColor } from '../../theme/globalColorTheme';
import { useState } from 'react';
import Card from '../../components/card/Card';
import { DocAiEmailActivity, DocAiEmailActivityList, DocAiEmailSyncList, DocAiMetrics, DocAiRework, DocAiWorkerHeartbeat } from '../../services/accountServices';
import { toastFunctionToaster } from '../../utils/toastFunction';
import { useEffect } from 'react';
import { DOC_AI_WORKER_MANAGEMENT, EXTERNAL_STORAGE } from './constants';
import MetricsDashboard from './components/MetricsDashboard';
import Pagination from '../../components/pagination/Pagination';
import { PiCheckCircleFill } from 'react-icons/pi';
import { HiExclamationCircle } from 'react-icons/hi';


const Management = () => {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(true);
    const [workerStatus, setWorkerStatus] = useState(null)
    const [workerStatusReqSent, setWorkerStatusReqSent] = useState(false)
    const toast = useToast()
    const [emailSyncLIst, setEmailSyncList] = useState([])
    const [metrics, setMetrics] = useState([])
    const [loading, setLoading] = useState(false)
    const [btnLoading, SetBtnLoading] = useState(false)
    const [tab, setActiveTab] = useState(DOC_AI_WORKER_MANAGEMENT.TABS.EMAIL_SYNC_LIST)
    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [activityList, setActivityList] = useState({})

    function checkHeartBeat() {
        setWorkerStatusReqSent(true)
        SetBtnLoading(btnLoading => ({ ...btnLoading, check_worker: true }))
        DocAiWorkerHeartbeat().then(resp => {
            if (resp.data[0]?.status) {
                toast(toastFunctionToaster("Worker online", "success"))
                return setWorkerStatus(true)
            }
            setWorkerStatus(false)
            toast(toastFunctionToaster("Worker offline", "error"))
        }).catch(() => {
            toast(toastFunctionToaster("Worker offline", "error"))
            setWorkerStatus(false)
        }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, check_worker: false }))
        })
    }

    function getDocAiMetrics() {
        setActiveTab(DOC_AI_WORKER_MANAGEMENT.TABS.METRICS)
        setLoading(true)
        DocAiMetrics().then(resp => {
            if (resp?.data[0]?.status) {
                setMetrics(resp?.data[0]?.metrics)
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to fetch metrics data", "error"))
        }).finally(() => {
            setLoading(false)
        })
    }

    function getDocAiEmailSyncList() {
        setActiveTab(DOC_AI_WORKER_MANAGEMENT.TABS.EMAIL_SYNC_LIST)
        setLoading(true)
        const paramObj = {
            "page_id": 1,
            "records_per_page": 10,
            "filter_key": "",
            "filter_value": ""
        }
        DocAiEmailSyncList(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                setEmailSyncList(resp?.data[0]?.data[0]?.records)
                setTotalPages(resp?.data[0]?.data[0]?.total_pages)
            } else {
                toast(toastFunctionToaster(resp?.message, "error"))
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    function getActivity(msg_uuid) {
        const paramObj = {
            "inbox_msg_uuid": msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, activity: msg_uuid }))
        DocAiEmailActivity(paramObj).then(resp => {
            if (resp.data[0]?.status) {
                console.log(resp.data[0])
            }
        }).catch(err => { }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, activity: null }))
        })
    }

    function getActivityList(msg_uuid) {
        if (activityList[msg_uuid]?.data) {
            setActivityList(activityList => ({ ...activityList, [msg_uuid]: { ...activityList[msg_uuid], showData: true } }))
            return;
        }
        const paramObj = {
            "page_id": 1,
            "records_per_page": 10,
            "filter_key": "",
            "filter_value": "",
            "email_sync_inbox_message_uuid":msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, activityList: msg_uuid }))
        DocAiEmailActivityList(paramObj).then(resp => {
            if (resp.data[0]?.status) {
                setActivityList(activityList => ({ ...activityList, [msg_uuid]: { data: resp?.data[0]?.data[0]?.records, showData: true } }))
            } else {
                setActivityList(activityList => ({ ...activityList, [msg_uuid]: { data: null, showData: false } }))
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            SetBtnLoading(btnLoading => ({ ...btnLoading, activityList: null }))
        })
    }
    function rework(msg_uuid) {
        const paramObj = {
            "inbox_msg_uuid": msg_uuid
        }
        SetBtnLoading(btnLoading => ({ ...btnLoading, rework: msg_uuid }))
        DocAiRework(paramObj).then(resp => {
            if (resp.data[0]?.status) {
                toast(toastFunctionToaster("Rerun request sent","success"))
            }else{
                toast(toastFunctionToaster("Failed to add Rerun request 1","error"))
            }
        }).catch(err => { }).finally(() => {
            toast(toastFunctionToaster("Failed to add Rerun request 2","error"))
        }).finally(()=>{
            SetBtnLoading(btnLoading => ({ ...btnLoading, rework: null }))
        })
    }

    useEffect(() => {
        getDocAiMetrics()
        checkHeartBeat()
    }, [])

    useEffect(() => {
        getDocAiEmailSyncList()
    }, [pageId, recordsPerPage])

    useState(() => {
        setPageId(1)
    }, [recordsPerPage])

    return (
        <Flex direction="column" pt={{ sm: "130px", lg: "80px" }} h="full">
            <Card p="1" h="full">
                <Flex h="full" overflow="hidden">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "80%"}
                        bg={brandBg}
                        p="2"
                        borderRadius={"10px"}
                        h="full"
                    >
                        {(workerStatusReqSent && workerStatus !== null) && <Box w="full" minH="5px" bg={workerStatus ? "green.300" : "red.300"} mb="2"></Box>}
                        {tab === DOC_AI_WORKER_MANAGEMENT.TABS.EMAIL_SYNC_LIST && <Flex h="full" overflow="hidden">
                            {
                                loading ? <Spinner /> :
                                    <>
                                        {emailSyncLIst.length > 0 ?
                                            <Flex h="full" direction="column" gap={2} w="full" overflow="hidden">
                                                <Flex maxH="90%" overflowY="auto" className='custom-scrollbar' direction="column" gap="2">
                                                    {
                                                        emailSyncLIst?.map((item, index) =>
                                                            <Card key={index} p="2" borderRadius="5px" w="full" borderColor={item?.action_success ? "green.200" : 'red.300'} borderWidth="1px" h="fit-content" borderLeftWidth="6px">

                                                                <Flex gap={1} alignItems={"center"} justifyContent={"space-between"} w="full">
                                                                    <Flex gap="1" color={useTextColor}>
                                                                        <Text fontWeight="bold">Target Email:</Text> {item.target_email}
                                                                    </Flex>
                                                                    <Flex gap="1">
                                                                        {/* <Button size="sm" variant="outline" colorScheme='green' onClick={() => getActivity(item?.inbox_msg_uuid)} isLoading={btnLoading?.activity === item?.inbox_msg_uuid}>Activity</Button> */}
                                                                        <Button size="sm" variant="outline" colorScheme='green' onClick={() => getActivityList(item?.inbox_msg_uuid)} isLoading={btnLoading?.activityList === item?.inbox_msg_uuid}>Activity List</Button>
                                                                        {!item?.action_success && <Button size="sm" 
                                                                            variant="outline" 
                                                                            colorScheme={item?.acted ? 'green' : 'red'}
                                                                            onClick={() => rework(item?.inbox_msg_uuid)} 
                                                                            isLoading={btnLoading?.rework === item?.inbox_msg_uuid}>ReRun Ai
                                                                        </Button>}
                                                                    </Flex>
                                                                </Flex>
                                                                <Flex gap={1} color={useTextColor}><Text fontWeight="bold">Subject:</Text> {item?.subject}</Flex>
                                                                <Flex gap={1} color={useTextColor}><Text fontWeight="bold">Body:</Text> {item?.body}</Flex>
                                                                <Flex gap={1} color={useTextColor}><Text fontWeight="bold">Attachments:</Text> {item?.attachments}</Flex>
                                                                {item?.action_result_jsonstring && <Flex gap={1} color={useTextColor}><Text fontWeight="bold">Action result:</Text><Text color={item?.action_success ? "green.200" : "red.300"}>{item?.action_result_jsonstring}</Text></Flex>}
                                                                {activityList[item?.inbox_msg_uuid]?.showData &&
                                                                    <Flex overflow="hidden" maxH="280px" direction="column" gap="2" p="2" borderRadius="5px" borderColor={item?.action_success ? "green.200" : 'red.300'} borderWidth="1px" mt="2">
                                                                        <Flex h="30px">
                                                                            <Text fontWeight="bold"> Activity List:</Text>
                                                                            <Spacer />
                                                                            <Button variant="outline" colorScheme='green' size="sm" onClick={() => setActivityList(activityList => ({ ...activityList, [item?.inbox_msg_uuid]: { ...activityList[item?.inbox_msg_uuid], showData: false } }))}>Hide List</Button>
                                                                        </Flex>
                                                                        <Flex h="250px" className='custom-scrollbar' overflow="auto" bg={brandBg} p="2" direction="column" gap="2">
                                                                            {
                                                                                activityList[item?.inbox_msg_uuid]?.data.length > 0 && <>
                                                                                    {
                                                                                        activityList[item?.inbox_msg_uuid]?.data?.map(activity => (
                                                                                            <Card p="1.5" borderRadius="5px">
                                                                                                <Flex flexDir="column">
                                                                                                    <Text fontWeight="bold" fontSize="md">Activity</Text>
                                                                                                    <Flex gap="4" pl="3">
                                                                                                        <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold">Acted:</Text>{activity?.acted ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                        <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold">Success:</Text>{activity?.action_success ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                    </Flex>
                                                                                                    <Flex pl="3" gap="2" flexDir="column" mt="2" overflowY="auto">
                                                                                                     {
                                                                                                      activity?.log_json.map((log,index) => (
                                                                                                        <Flex flexDir="row" key={index}  gap="2" >
                                                                                                        <Flex gap="1" justifyContent="center" alignItems="center"><Text fontWeight="bold"></Text>{log?.status ? <Icon as={PiCheckCircleFill} color="green.300" boxSize="5" /> : <Icon as={HiExclamationCircle} color="red.300" boxSize="5" />}</Flex>
                                                                                                        <Text whiteSpace="nowrap" fontSize="md">{log.timestamp}</Text>
                                                                                                        <Text whiteSpace="nowrap" fontSize="md">{log.log}</Text>
                                                                                                        </Flex>))
                                                                                                     } 
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            </Card>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </Flex>
                                                                    </Flex>
                                                                }
                                                            </Card>
                                                        )
                                                    }
                                                </Flex>
                                                {emailSyncLIst.length > 0 && <Pagination currentPage={pageId} recordsPerPage={recordsPerPage} setCurrentPage={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} />}
                                            </Flex> : <Alert status='info'>
                                                <AlertIcon />
                                                Email sync list is empty
                                            </Alert>
                                        }
                                    </>
                            }


                        </Flex>}
                        {tab === DOC_AI_WORKER_MANAGEMENT.TABS.METRICS && <MetricsDashboard metrics={metrics} loading={loading} />}
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="20%"
                            position="relative"
                            p="2"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="full" bg={brandBg}>
                                <Card h="full">
                                    <Flex direction="column" pt="2" gap="2">
                                        <Button onClick={checkHeartBeat} isLoading={btnLoading?.check_worker}>HeartBeat</Button>
                                        <Button onClick={getDocAiMetrics}>Metrics</Button>
                                        <Button onClick={getDocAiEmailSyncList}>Email Sync List</Button>
                                    </Flex>
                                </Card>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            minH="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Expand Management Settings
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default Management