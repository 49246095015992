import React, { useState, useEffect } from "react";
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, Input, Button, useDisclosure, useToast } from "@chakra-ui/react";
import RequestDemoDetails from "./RequestDemoDetails";
import RequestVerifyCode from "./RequestVerifyCode";
import { createSignUpType } from "../../../services/sharedServices";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function RequestDemo() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();
	const [valideCodeFlag, setValidateCodeFlag] = useState(false);
	const [loginForm, setLoginForm] = React.useState({
		email: "",
		firstName: "",
		lastName: "",
	});
	const [loading, isLoading] = useState(false)
	const [error, setError] = useState({});
	const toast = useToast()
	useEffect(() => {
		if (valideCodeFlag === null) {
			handleClose();
		}
	}, [valideCodeFlag]);

	const handleClose = () => {
		onClose();
		setValidateCodeFlag(false);
		setLoginForm({
			email: "",
			firstName: "",
			lastName: "",
		})
	};

	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		let fields = loginForm;
		let emailPattern = new RegExp(/^[a-zA-Z0-9._!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter email.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid email address.";
		}
		if (!fields["firstName"]) {
			formIsValid = false;
			errors["firstName"] = "*Please enter first name.";
		}
		if (!fields["lastName"]) {
			formIsValid = false;
			errors["lastName"] = "*Please enter last name.";
		}
		setError(errors);
		return formIsValid;
	};


	const handleSubmit = async () => {
		if (validateFieldForm()) {
			isLoading(true)
			const paramObj = {
				signup_email: loginForm?.email,
				first_name: loginForm?.firstName,
				last_name: loginForm?.lastName,
				signup_type: "demo"
			}
			createSignUpType(paramObj).then(resp => {
				if (resp?.data[0]?.status) {
					if (!resp?.data[0]?.access_verified) {
						setValidateCodeFlag(true);
					} else {
						handleClose()
					}
					toast(toastFunctionToaster(resp?.message, "success"))
				} else {
					toast(toastFunctionToaster(resp?.message, "error"))
				}

			}).catch(e => {
				toast(toastFunctionToaster(`Couldn't signup for demo`, "error"))
			}).finally(() => {
				isLoading(false)
			})
		}
	};

	return (
		<>
			<Button
				ref={btnRef}
				onClick={onOpen}
				borderRadius={"5px"}
				w="49%"
				fontWeight={"400"}
				fontSize={{
					sm: "14px",
					md: "20px",
					lg: "20px",
				}}
				colorScheme="red"
				p="25px"
			>
				Request Demo
			</Button>

			<Drawer onClose={handleClose} isOpen={isOpen} placement="right" size="sm" finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent bg="#100f12">
					<DrawerCloseButton color="red.400" />
					<DrawerBody>
					{valideCodeFlag ? 
					<RequestVerifyCode codeFlag={setValidateCodeFlag} handleOnClose={handleClose} email={loginForm?.email} getOtp={handleSubmit} otpLoading={loading}/>
					 : <RequestDemoDetails loginForm={loginForm} setLoginForm={setLoginForm} handleSubmit={handleSubmit} error={error} loading={loading}/>}
					 </DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default RequestDemo;
