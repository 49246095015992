// Chakra React Imports
import React, { useState, useEffect } from "react";
import {
	Box,
	Icon,
	Button,
	Flex,
	Spacer,
	Tab,
	Tabs,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";

import { AiOutlineBulb } from "react-icons/ai";

// Component Imports
import ReportingPanel from "../ReportingPanel";
import Card from "../../../../components/card/Card";
import { defaultThemeColor } from "../../../../constants";
import AccountBilling from "../accountBilling/AccountBillingTab";
import ReportingSetup from "../ReportingSetup";

function AccountFullBilling() {
	const [activeTab, setActiveTab] = useState(0);

	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};
	
	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="1" h="full">
			{/* <Card w={"100%"} rounded={"md"} p={1} mb={2} mx={3}>
				<Box textAlign="right">
					<ReportingPanel />
				</Box>
			</Card> */}
			<Card w={"100%"} h={'100%'} rounded={"md"} p={1} mx={3} >
				<Tabs
					w="100%"
					borderBottom={"0px"}
					mx="auto"
					onChange={handleTabChange}
					index={activeTab}
					h="full"
					display="flex"
					flexDirection="column"
				>
					<TabList border="0px" w="100%" h="5%" overflow="hidden">
						<Flex justifyContent={"end"} w="100%">
							<Flex> 
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Billing
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Analysis
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Report scheduling
								</Tab>
							</Flex>
						</Flex>
					</TabList>
					<TabPanels mt="1" display="flex" h="94%">
						<TabPanel p="0" h="100%" flex={1} w="100%">
							<AccountBilling />
						</TabPanel>
						<TabPanel p="0">

						</TabPanel>
						<TabPanel p="0" h="100%" w="full" display="flex">
							<ReportingSetup />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default AccountFullBilling;
