// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner, Select, Tr, Td, InputGroup, InputLeftAddon, TableContainer, Input, Table, Thead, Th, Tbody, Heading, Menu, MenuButton, Button, MenuList, MenuItem, Icon, useColorModeValue } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle, AiOutlineSearch } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useEffect } from "react";
import { format } from "date-fns";
import { memo } from "react";
import moment from 'moment'
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useBrandBg, useGreenBg, useNoDataBandBg, useRedBg, useTextColor } from "../../../../../theme/globalColorTheme";
import { fetchAccAdminEventsForSub, fetchAccEvents, fetchAccountEvents, fetchLocationsAndSubs } from "../../../../../services/accountServices";
import Card from "../../../../../components/card/Card";
import Pagination from "../../../../../components/pagination/Pagination";
import { ACC_EVENT_TAB } from "../constants";

function AccEventsTab() {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(true);

    const redBg = useRedBg()
    const greenBg = useGreenBg()
    const [loading, isLoading] = useState(false);
    const [eventValue, setEventValue] = useState("");
    const [subsId, setSubsId] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [cloneEventList, setCloneEventList] = useState([])
    const [eventLoader, setEventLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [currLocUuid, setCurrLocUuid] = useState('')

    const [emailColFilter, setEmailColFilter] = useState('')
    const [routeColFilter, setRouteColFilter] = useState('')
    const [statusColFilter, setStatusColFilter] = useState('')
    const [tableHeaders, setTableHeaders] = useState({ Email: [], Route: [], Status: [], Date: [] })
    const [filteredEventsData, setFilteredEventsData] = useState([])
    const [selectedDates, setSelectedDates] = useState([new Date("05-01-2024"), new Date()])
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
    const [duration, setDuration] = useState(ACC_EVENT_TAB.DURATION.TODAY)

    const blackWhiteColor = useTextColor()


    const handleUpdateColumnfilterOptions = () => {
        const newUniqueRouteTypes = new Set();
        const newUniqueEmails = new Set()
        const newUniqueStatus = new Set()

        filteredEventsData?.forEach(eventItem => {
            newUniqueRouteTypes.add(eventItem?.event_route);
            newUniqueEmails.add(eventItem?.event_caller)
            if (!eventItem.event_status) {
                newUniqueStatus.add('Fail')
            } else {
                newUniqueStatus.add('Success')
            }
        });
        setTableHeaders({ Email: [...newUniqueEmails], Route: [...newUniqueRouteTypes], Status: [...newUniqueStatus], Date: [] })
    }

    const handleUpdateFiltereEventdData = () => {
        const filteredEventData = eventsList?.filter(
            event => (
                (emailColFilter === '' || event?.event_caller.includes(emailColFilter))
                && (routeColFilter === '' || event?.event_route.includes(routeColFilter))
                && (statusColFilter === '' || String(event?.event_status) === statusColFilter)))
        setFilteredEventsData(
            filteredEventData
        )
    }

    const renderTableFilter = (placeholder, options) => {
        if (placeholder === "Date") {
            return null
        }
        if (options.length) {
            if (placeholder === 'Status') {
                return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                    {options?.map(option => <option key={option} value={option === 'Success'}>{option}</option>)}
                </Select>)
            }
            return (<Select defaultValue='' placeholder={'All'} onChange={(e) => handleColumnFilterChange(e.target.value, placeholder)}>
                {options?.map(option => <option key={option} value={option}>{option}</option>)}
            </Select>)
        }
    }

    const handleColumnFilterChange = (value, col) => {
        switch (col) {
            case "Email":
                setEmailColFilter(value)
                break;
            case "Route":
                setRouteColFilter(value)
                break
            case "Status":
                setStatusColFilter(value)
                break
            default:
                break;
        }
    }

    useEffect(() => {
        handleUpdateColumnfilterOptions()
    }, [filteredEventsData])

    useEffect(() => {
        handleUpdateFiltereEventdData()
    }, [emailColFilter, statusColFilter, routeColFilter, eventsList])


    useEffect(() => {
        collectEventsData(currLocUuid, subsId);
    }, [currentPage, recordsPerPage, currLocUuid, subsId]);



    const collectEventsData = () => {
        setEventLoader(true);
        const paramObj = {
            "start_date": format(new Date(selectedDates[0]), 'yyyy-MM-dd'),
            "end_date": format(new Date(selectedDates[1]), 'yyyy-MM-dd'),
            "page": currentPage,
            duration: duration,
            "records_per_page": recordsPerPage
        }
        fetchAccAdminEventsForSub(paramObj)
            .then(res => {
                setEventLoader(false)
                if (res?.data?.length) {
                    isLoading(false);
                    setEmailColFilter('')
                    setStatusColFilter('')
                    setRouteColFilter('')
                    setEventsList(res?.data[0]?.records);
                    setCloneEventList(res?.data[0]?.records)
                    setTotalPages(res?.data[0]?.total_pages)
                } else {
                    setEventsList([]);
                    setCloneEventList([]);
                }
            })
            .catch(err => {
                setEventLoader(false)
                setLocationList([]);
                setEventsList([]);
                setCloneEventList([]);
            });
    }

    useEffect(() => {
        if (eventValue?.length) {
            const filteredEvents = eventsList?.filter(event => {
                return Object.values(event).some(value =>
                    String(value).toLowerCase().includes(eventValue.toLowerCase())
                );
            });
            setCloneEventList(filteredEvents)
        }
        else {
            setCloneEventList(eventsList)
        }
    }, [eventValue])

    const RenderEventsList = memo(() => {
        return (
            filteredEventsData !== null && filteredEventsData?.length > 0 ? (
                filteredEventsData?.map((eventItem, index) => (
                    <Tr key={index + eventItem?.datetime_created}>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
                        <Td color='black' bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
                        <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
                    </Tr>
                ))
            ) : (
                null
            )
        )
    })

    // Todo:Render Location List

    return (
        <Flex h="full" overflow="hidden" w="full">
            <Card p="1" h="full" >
                <Flex h="full" overflow="hidden" >
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "78%"}
                        bg={brandBg}
                        p="2"
                        borderRadius={"10px"}
                        h="full"
                        className="custom-scrollbar"
                        overflowY="auto"
                    >
                        <Card p='2' h="full">
                            <Flex mb="3" alignItems="center" justifyContent={'space-between'}>
                                <Text fontWeight={'bold'} ml={1} >Events</Text>
                                <InputGroup w="40%">
                                    <InputLeftAddon><AiOutlineSearch /></InputLeftAddon>
                                    <Input w="95%" placeholder='Search...' value={eventValue} onChange={(e) => { setEventValue(e.target.value) }} color={blackWhiteColor} />
                                </InputGroup>

                            </Flex>
                            {eventLoader ? (
                                <Flex alignItems="center" justifyContent="center" h="50vh">
                                    <Spinner size="lg" />
                                </Flex>
                            ) :
                                cloneEventList?.length ? (
                                    <Flex direction="column" gap='2' h="full" overflow="hidden" position="relative">
                                        <TableContainer border="1px solid #d7d7d7" borderRadius="5px" h="83%" overflowY="auto" overflowX="auto" className="custom-scrollbar">
                                            <Table variant="simple">
                                                <Thead>
                                                    <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                        {Object.keys(tableHeaders).map(header => (
                                                            <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                                {header}
                                                            </Th>
                                                        ))}
                                                    </Tr>
                                                    <Tr w="full" borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                        {Object.entries(tableHeaders).map(([header, options]) => (
                                                            <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>
                                                                {renderTableFilter(header, options)}
                                                            </Th>
                                                        ))}
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <RenderEventsList />

                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                        {cloneEventList?.length ?
                                            <Flex position="absolute" bottom="0" w="full">
                                                <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setRecordsPerPage={setRecordsPerPage} recordsPerPage={recordsPerPage} />
                                            </Flex> : null}
                                    </Flex>
                                ) : <Flex w={"100%"} p={5} bg={useNoDataBandBg} borderLeftWidth={"10px"}
                                    borderLeftColor={"red.500"}>
                                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                        No Event Data based on selection..
                                    </Heading>
                                </Flex>}
                        </Card>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="22%"
                            position="relative"
                            p="2"
                            h="full"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                            overflow="hidden"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="full" overflow="hidden">
                                <Card h="full" overflow="auto" className="custom-scrollbar" p="2">
                                    <Flex direction="column" pt="5" gap="2" h="full" pb={2}>
                                        <Text fontSize='md' fontWeight="600" mb="2" p={5} borderBottom={'1px'} borderColor={'#d7d7d7'}>
                                            Account Events
                                        </Text>
                                        <Card h="full" w="full" p={1} overflow="hidden">
                                            <Flex flexDir="column" w="full" alignItems="center" h="90%" overflowY="auto">
                                                <Flex direction="column" w="100%" pt="1">
                                                    <Text fontWeight="bold" mb="3">Duration</Text>
                                                    <Flex alignItems="center" w="100%" justifyContent="center" flexDirection="column" gap="2">
                                                        <Menu w="80%" placement="bottom">
                                                            <MenuButton w="full" as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
                                                                {duration}
                                                            </MenuButton>
                                                            <MenuList>
                                                                {
                                                                    Object.entries(ACC_EVENT_TAB.DURATION).map(([key, value]) => (
                                                                        <MenuItem key={key} onClick={() => { setDuration(value) }}>{key.toLocaleLowerCase()}</MenuItem>
                                                                    ))
                                                                }
                                                            </MenuList>
                                                        </Menu>
                                                        {duration === ACC_EVENT_TAB.DURATION.CUSTOM &&
                                                            <Flex w="full" justifyContent={"center"}>
                                                                <RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />
                                                            </Flex>
                                                        }
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Button size={'sm'} ml={1}

                                                onClick={collectEventsData}
                                                _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                                bg={pillButtonBg}>Get History</Button>
                                        </Card>
                                    </Flex>
                                </Card>
                            </Box>
                        </Box>

                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            minH="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Expand Events Settings
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default AccEventsTab;
