import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineRobot, AiOutlineClose } from "react-icons/ai";
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Flex,
	FormLabel,
	Heading,
	Icon,
	Text,
	useDisclosure,
} from "@chakra-ui/react";

// Component Imports
// import BasicAIInAction from './BasicAIInAction';
import BasicAiLanguageList from "../../../../utils/BasicAILanguageList";
import BasicAiSourceLanguageList from "../../../../utils/BasicAiSourceLanguageList";

// Api Call Imports
import { accessibilityPageEx } from "../../../../services/dynamicPageSvc";
import MultichannelInAction from "./MultiChannelInAction";

const MultiChannelSettings = props => {
	const navigate = useNavigate();
	const [loading, isLoading] = useState(false);
	const langArr = BasicAiLanguageList;
	const sourceLanguageArr = BasicAiSourceLanguageList;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const launchKey = props.launchKey ? props.launchKey : null;
	const launchCode = props.launchCode ? props.launchCode : null;
	const sessionKey = props.sessionKey ? props.sessionKey : null;

	// Todo:Store Dropdown Data
	const [basicAIFlag, setBasicAIFlag] = useState(false);
	const [selectedValue, setSelectedValue] = useState("");
	const [selectedSettings, setSelectedSettings] = useState({});
	const [sourceLanguage, setSourceLanguage] = useState("English");
	const [targetLanguages, setTargetLanguages] = useState(["Hindi"]);

	useEffect(() => {
		if (selectedValue?.length) {
			renderSelectedValue();
		}
	}, [selectedValue]);

	const renderSelectedValue = () => {
		if (selectedValue === "Source") {
			renderLangugageData();
		}
	};

	useEffect(() => {
		let obj = {
			sourceLangCode: sourceLanguage,
			targetLangCode: targetLanguages,
		};
		setSelectedSettings(obj);
	}, [sourceLanguage, targetLanguages]);

	const renderBasicAISelectUI = () => {
		return renderSelectionFormData();
	};

	const renderSelectionFormData = () => {
		return (
			<Box p="2" borderWidth={"1px"} bg="#fff" borderRadius={"10px"} w="100%">
				<Flex w="100%" mb="4" alignItems={"center"} borderWidth={"1px"} borderRadius={"10px"} justifyContent={"space-between"} p="2">
					<FormLabel mb="0px" w="25%" color="gray.800">
						Source Language{" "}
					</FormLabel>
					<Text w="50%" bg="#e2e8f0" borderWidth="1px" p="2" borderRadius={"10px"} mr="3" color="gray.800">
						{sourceLanguage}
					</Text>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="gray.600"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Source");
						}}
					>
						...
					</Button>
				</Flex>
				{/* <Flex w="100%" mb="4" p="2" alignItems={"center"} borderWidth={"1px"} borderRadius={"10px"} justifyContent={"space-between"}>
					<FormLabel w="25%" mb="0px" color="gray.800">
						Target Languages{" "}
					</FormLabel>
					<Flex w="50%" flexWrap="wrap" borderWidth={"1px"} borderColor={"#d7d7d7"} p="2" borderRadius={"10px"}>
						{targetLanguages.map((lang, index) => (
							<Flex
								key={index}
								alignItems="center"
								justifyContent={"space-between"}
								bg="#e2e8f0"
								p="2"
								mr="3"
								mb="2"
								borderRadius={"10px"}
							>
								<Text borderWidth="1px" color="gray.800">
									{lang}
								</Text>
								{targetLanguages?.length > 1 ? (
									<Box
										as={AiOutlineClose}
										cursor="pointer"
										color="red.500"
										onClick={() => {
											const updatedLanguages = [...targetLanguages];
											updatedLanguages.splice(index, 1);
											setTargetLanguages(updatedLanguages);
										}}
									/>
								) : null}
							</Flex>
						))}
					</Flex>
					<Button
						size="md"
						bg="#e2e8f0"
						ml="2"
						color="grey"
						borderRadius={"10px"}
						onClick={() => {
							onOpen();
							setSelectedValue("Target");
						}}
					>
						...
					</Button>
				</Flex> */}

				<Flex justifyContent={"end"}>
					<Button
						type="button"
						bg="blue.200"
						borderRadius={"10px"}
						onClick={() => {
							setBasicAIFlag(true);
						}}
						_hover={{ bg: "blue.200" }}
						mr="2"
					>
						<Icon as={AiOutlineRobot} mr={1} />
						Start Multichannel AI
					</Button>
					<Button
						type="button"
						colorScheme="red"
						borderRadius={"10px"}
						onClick={() => {
							navigate(-1);
						}}
					>
						Back
					</Button>
				</Flex>
			</Box>
		);
	};

	const OptionItem = ({ data, onClick, isSelected, text }) => (
		<Text
			key={data.name}
			value={data.name}
			color="#000"
			w="31%"
			borderWidth="1px"
			p="2"
			borderRadius="10px"
			m="1%"
			onClick={() => onClick(data.name)}
			bg={isSelected ? "green.200" : "#e2e8f0"}
			cursor="pointer"
		>
			{text || data.name}
		</Text>
	);

	const renderOptions = (dataArray, onClickHandler, selectedItem) => {
		return dataArray.map((data, index) => (
			<OptionItem
				data={data}
				key={index}
				onClick={onClickHandler}
				isSelected={Array.isArray(selectedItem) ? selectedItem.includes(data.name) : data.name === selectedItem}
			/>
		));
	};

	const renderLangugageData = () => {
		return renderOptions(sourceLanguageArr, setSourceLanguage, sourceLanguage);
	};

	const toggleTargetLanguage = lang => {
		setTargetLanguages(prev => (prev.includes(lang) ? prev.filter(l => l !== lang) : [...prev, lang]));
	};

	const renderToLanguage = () => {
		return renderOptions(langArr, toggleTargetLanguage, targetLanguages);
	};

	const handlerCloser = () => {
		onClose();
	};

	return (
		<>
			<Box w={"100%"} h="100%" overflow={"auto"}>
				{launchKey != null && sessionKey != null ? (
					<>
						<Flex w="100%" h="100%" flexWrap={"wrap"}>
							{!basicAIFlag ? (
								<Flex
									w={{
										base: "100%",
										md: "100%",
										lg: "50%",
									}}
									p="10px"
									mx="auto"
									alignItems={"center"}
									justifyContent={"center"}
								>
									{renderBasicAISelectUI()}
								</Flex>
							) : (
								<Box w="100%" borderLeftWidth={"0px"}>									
									<MultichannelInAction
										launchKey={launchKey}
										launchCode={launchCode}
										sessionKey={sessionKey}
										selectedSettings={selectedSettings}
									></MultichannelInAction>
								</Box>
							)}
						</Flex>
					</>
				) : (
					<Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
						<Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
							Hmm....{" "}
							<Text as="span" fontWeight={"600"} mr="1">
								Something is not right!!
							</Text>
						</Heading>
						<Text mt="3" fontSize={"17px"} color="gray.700">
							Looks like, there is some issues with Human Translation...
						</Text>
					</Flex>
				)}
			</Box>
			{/* Drawer Ui */}
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					handlerCloser();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						Select {selectedValue} {selectedValue === "Service" ? "Type" : "Language"}{" "}
					</DrawerHeader>
					<DrawerBody>
						<Flex flexWrap={"wrap"}>
							{selectedValue === "Source" ? renderLangugageData() : selectedValue === "Target" ? renderToLanguage() : null}
						</Flex>
					</DrawerBody>

					<DrawerFooter>
						<Button
							variant="outline"
							size="sm"
							borderRadius="10px"
							mr={3}
							onClick={() => {
								handlerCloser();
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme="green"
							size="sm"
							borderRadius="10px"
							onClick={() => {
								handlerCloser();
							}}
						>
							Save
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default MultiChannelSettings;
