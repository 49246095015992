// React + Chakra Imports
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Text, useColorModeValue, Alert, AlertIcon, Spinner, useToast, Icon, Tabs, TabList, TabPanels, Tab, TabPanel, Flex } from "@chakra-ui/react";

// Service + Component Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getBookingAppoinmentList } from "../../../../../../services/accountServices";

function BookingAppoinmentList({ bookingId, bookingServiceId, setclientData, idx, setIdxValue, renderAppoinmentList }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [bookingAppoinmentList, setBookingAppoinmentList] = useState([]);

	const fields = [
		{
			label: "Booking Appointment Languages",
			key: "booking_appointment_languages",
		},
		{
			label: "Number of Interpreters",
			key: "booking_appointment_interpreters_count",
		},
		// {
		// 	label: "Consumer UUID",
		// 	key: "booking_appointment_consumer_uuid",
		// },
		{
			label: "Interpreter's Gender",
			key: "booking_appointment_interpreters_gender",
		},
		// {
		// 	label: "Preferred Interpreter UUID",
		// 	key: "booking_appointment_preferred_interpreter_uuid",
		// },
		{
			label: "Preferred Interpreter Email",
			key: "booking_appointment_preferred_interpreter_email",
		},
		{
			label: "Instructions",
			key: "booking_appointment_instructions",
		},
	];

	// Todo:Fetch Data
	useEffect(() => {
		fetchAdressList();
	}, []);

	useEffect(() => {
		if (renderAppoinmentList !== null && renderAppoinmentList > 0) {
			fetchAdressList();
		}
	}, [renderAppoinmentList]);

	// Todo:Fetch Client Details
	const fetchAdressList = () => {
		isLoading(true);
		const obj = {
			booking_service_uuid: bookingServiceId,
			filter_key: "",
			filter_value: "",
			page_id: 1,
			records_per_page: 10,
		};

		getBookingAppoinmentList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setBookingAppoinmentList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
				} else {
					setBookingAppoinmentList([]);
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderAppoinmentDetails =
		bookingAppoinmentList?.length > 0 ? (
			<Tabs variant="soft-rounded" borderRadius={"0px"} colorScheme="teal" w="100%">
				<TabList>
					{bookingAppoinmentList.map((data, index) => (
						<Tab
							_selected={{
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: "#a5a5a5",
								fontWeight: "500",
								color: "black",
								borderBottom: "0px",
							}}
							sx={{
								bg: "green.100",
								color: "black",
								borderStyle: "solid",
								borderWidth: "1px",
								// borderColor: "grey.500",
								rounded: "md",
								borderBottomLeftRadius: "0px",
								borderBottomRightRadius: "0px",
							}}
							borderRadius={"0px"}
							key={data?.identity_uuid + index}
						>
							Appoinment {index + 1}
						</Tab>
					))}
				</TabList>

				<TabPanels p="0px">
					{bookingAppoinmentList.map((data, index) => (
						<TabPanel key={data?.identity_uuid + index} p="0px">
							<Flex flexWrap="wrap" p="4" borderWidth="1px" w="100%" alignItems="start" fontSize="15px" mb="4" borderRadius="10px" borderTopLeftRadius={"0px"} borderColor="#a5a5a5" position="relative" boxShadow="md">
								{fields.map(({ label, key }) => (
									<Flex mb="3" mr="3" key={key} w="47%">
										<Text fontWeight="bold" w="50%">
											{label}:
										</Text>
										<Text w="50%" ml="2">
											{data[key] !== undefined ? data[key]?.toString() : "No data found"}
										</Text>
									</Flex>
								))}
								<Flex
									rounded="md"
									p={2}
									alignItems="center"
									borderWidth="1px"
									borderColor="gray.400"
									align="end"
									cursor="pointer"
									borderRadius="50px"
									position="absolute"
									right="8px"
									onClick={() => {
										setclientData(data);
										setIdxValue(idx);
									}}
								>
									<Icon as={AiFillEdit} fontSize="14px" cursor="pointer" />
								</Flex>
							</Flex>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No appoinment added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderAppoinmentDetails}</>
			)}
		</Flex>
	);
}

export default BookingAppoinmentList;
