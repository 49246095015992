import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, useToast, Fade, Flex } from '@chakra-ui/react';
import { UserAgent, Registerer, Inviter, SessionState } from 'sip.js';

import { toastFunctionToaster } from '../toastFunction';

import Dailer from './components/Dailer';
import AudioDeviceSelector from './components/AudioDeviceSelector';

const SipClient = () => {
    const [userAgent, setUserAgent] = useState(null);
    const [registerer, setRegisterer] = useState(null);
    const [session, setSession] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [isCallActive, setIsCallActive] = useState(false);
    const [logMessages, setLogMessages] = useState([]);
    const [inputDevice, setInputDevice] = useState(null);
    const [outputDevice, setOutputDevice] = useState(null);
    const [dtmfHistory, setDtmfHistory] = useState([]);
    const remoteAudio = useRef(null);
    const toast = useToast();

    useEffect(() => {
        console.log(inputDevice, outputDevice)

        initializeSIP();

        return () => {
            if (userAgent) {
                userAgent.stop();
            }
        };
    }, []);



    const initializeSIP = () => {

        const delegate = {
            // Handles successful registration
            onRegister: () => {
                console.log('SIP Registration Successful');
                setLogMessages((prev) => [...prev, 'SIP Registration Successful']);
                setIsRegistered(true);
            },
        
            // Handles deregistration (when disconnected or deregistered)
            onDisconnect: (error) => {
                if (error) {
                    console.error('Transport error on disconnect:', error);
                    setLogMessages((prev) => [...prev, `Transport error: ${error.message}`]);
                    toast(
                    toastFunctionToaster(
                        'Transport error, please check your connection settings.',
                        'error'
                        )
                    );
                }
                console.log('SIP Unregistered');
                setLogMessages((prev) => [...prev, 'SIP Unregistered']);
                setIsRegistered(false);
            },
        
            // Handles incoming invitation (e.g., incoming call)
            onInvite: async (invitation) => {
                console.log('Incoming Call Received');
                setLogMessages((prev) => [...prev, 'Incoming Call Received']);
                await handleIncomingCall(invitation);
            },
        
            // Handle registration failures
            onRegisterRequest: (response) => {
                console.error('Registration failed:', response);
                setLogMessages((prev) => [
                    ...prev,
                    `Registration failed: ${response.message || 'Unknown error'}`,
                ]);
                toast(
                    toastFunctionToaster(
                    'Registration failed, please check your credentials or network connection.',
                    'error'
                    )
                );
            },
        
            // Handles call invite failures
            onInviteRequest: (error) => {
            console.error('Call invite failed:', error);
            setLogMessages((prev) => [...prev, `Call invite failed: ${error.message}`]);
            toast(
                toastFunctionToaster(
                'Call invite failed, please try again.',
                'error'
                )
            );
            },
        };
              
        const uri = UserAgent.makeURI('sip:naveen1@sip.linphone.org');
        if (!uri) {
            console.error('Invalid URI', uri);
            toast(
                toastFunctionToaster(
                'Invalid URI.',
                'error'
                )
            );
            return;
        }

        const config = {
            uri: uri,
            transportOptions: {
                server: 'wss://sip.linphone.org/ws',
            },
            authorizationUsername: 'naveen1',
            authorizationPassword: '26Nav@1998',
            delegate: delegate
        };

        const ua = new UserAgent(config);

        const registerer = new Registerer(ua);
        
        // Start the UserAgent
        ua.start().then(() => {
            console.log('UserAgent started');
            setUserAgent(ua);
            toast(
                toastFunctionToaster(
                'SIP initialized.',
                'success'
                )
            );

            registerer.register();

        }).catch((error) => {
            console.error('Failed to start UserAgent:', error);
            setLogMessages((prev) => [...prev, 'Failed to initialize SIP']);
            toast(
                toastFunctionToaster(
                'Failed to initialize SIP.',
                'error'
                )
            );
        });               
    };

    const sendDTMF = (tone) => {
        if (session && isCallActive) {
            try {
                // Send DTMF tone
                const options = {
                    requestOptions: {
                        body: {
                            contentDisposition: "render",
                            contentType: "application/dtmf-relay",
                            content: `Signal=${tone}\r\nDuration=100\r\nInterToneGap=50`
                        }
                    }
                };

                session.info(options);
                setLogMessages((prev) => [...prev, `DTMF sent: ${tone}`]);
                toast(toastFunctionToaster(`DTMF tone sent: ${tone}`, 'success'));
            } catch (error) {
                console.error('Error sending DTMF:', error);
                setLogMessages((prev) => [...prev, `Error sending DTMF: ${error.message}`]);
                toast(toastFunctionToaster('Error sending DTMF tone.', 'error'));
            }
        } else {
            toast(toastFunctionToaster('No active session for DTMF.', 'error'));
        }
    };
    

    // Play DTMF tone locally
    const playDTMFTone = (tone) => {
        const audioContext = new window.AudioContext();

        // DTMF frequency pairs
        const dtmfFrequencies = {
        '1': [697, 1209], '2': [697, 1336], '3': [697, 1477],
        '4': [770, 1209], '5': [770, 1336], '6': [770, 1477],
        '7': [852, 1209], '8': [852, 1336], '9': [852, 1477],
        '*': [941, 1209], '0': [941, 1336], '#': [941, 1477]
        };

        const frequencies = dtmfFrequencies[tone];
        if (!frequencies) return;

        // Create and connect oscillators for both frequencies
        const osc1 = audioContext.createOscillator();
        const osc2 = audioContext.createOscillator();
        const gainNode1 = audioContext.createGain();
        const gainNode2 = audioContext.createGain();

        osc1.frequency.value = frequencies[0];
        osc2.frequency.value = frequencies[1];

        osc1.connect(gainNode1);
        osc2.connect(gainNode2);
        gainNode1.connect(audioContext.destination);
        gainNode2.connect(audioContext.destination);

        gainNode1.gain.value = 0.1;
        gainNode2.gain.value = 0.1;

        osc1.start();
        osc2.start();

        // Stop after 100ms
        setTimeout(() => {
        osc1.stop();
        osc2.stop();
        audioContext.close();
        }, 100);
    };

    const handleDTMF = (tone) => {
        if (session && isCallActive) {
            try {

                // Send DTMF tone
                sendDTMF(tone);
        
                // Play local DTMF tone
                playDTMFTone(tone);
        
                // Add to DTMF history
                setDtmfHistory(prev => [...prev, {
                    tone,
                    timestamp: new Date().toISOString()
                }]);
        
                console.log(`Sent DTMF tone: ${tone}`);
            } catch (error) {
                console.error('Error sending DTMF:', error);
            }
        }
    };

    const updateCallProgress = (session) => {

        session.on('stateChanged', () => {
            const state = session.state;
            console.log(`Session state changed to: ${state}`);

            if (state === SessionState.Establishing) {
                console.log('Call in progress');
                setLogMessages((prev) => [...prev, 'Call in progress']);

            } else if (state === SessionState.Established) {
                console.log('Call established');
                setIsCallActive(true);
                setLogMessages((prev) => [...prev, 'Call Connected']);

            } else if (state === SessionState.Terminating) {
                console.log('Call terminating');
                setLogMessages((prev) => [...prev, 'Call Terminating']);

            } else if (state === SessionState.Terminated) {
                console.log('Call terminated');
                setIsCallActive(false);
                setSession(null);
                setLogMessages((prev) => [...prev, 'Call Terminated']);
            }
        });

    };


    // Handle incoming calls
    const handleIncomingCall = async (incomingSession) => {

        setSession(incomingSession);

        updateCallProgress(incomingSession);

        // TODO: show UI prompt to accept or reject
        // Automatically answer call
        const options = {
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {
                        deviceId: inputDevice ? { exact: inputDevice } : undefined,
                    },
                    video: false
                }
            },
            media: {
                remote: {
                    audio: remoteAudio.current
                }
            }
        };
        
        try {
            
            await incomingSession.accept(options);

        } catch (error) {
            console.error('Error accepting call:', error);
            setLogMessages((prev) => [...prev, `Error accepting call: ${error.message}`]);
            toast(
                toastFunctionToaster(
                    'Error accepting call.',
                    'error'
                )
            );
        }
    };




    // Make outgoing call
    const makeCall =  async(destination) => {
        try {
            if (!userAgent) {
                toast(toastFunctionToaster('Agent not available.','error'));
                return;
            };

            const options = {
                sessionDescriptionHandlerOptions: {
                    constraints: {
                        audio: {
                            deviceId: inputDevice ? { exact: inputDevice } : undefined,
                        },
                        video: false 
                    }
                },
                media: {
                    remote: {
                        audio: remoteAudio.current
                    }
                }
            };

            const destinationUri = UserAgent.makeURI(destination);
            if (!destinationUri) {
                console.error('Invalid destination URI', destinationUri);
                toast(
                    toastFunctionToaster(
                    'Invalid URI.',
                    'error'
                    )
                );
                return;
            }

            const inviter = new Inviter(userAgent, destinationUri);
            const newSession = await inviter.invite(options);

            setLogMessages((prev) => [...prev, `Dialing ${destination}`]);

            updateCallProgress(newSession);
            setSession(newSession);
        } catch (error) {
            console.error('Error making call:', error);
            setLogMessages((prev) => [...prev, `Error making call: ${error.message}`]);
            toast(
                toastFunctionToaster(
                    'Error making call.',
                    'error'
                )
            );
        }
    };

    // Hang up call
    const hangUpCall = () => {
        try {

            if (session) {
                session.bye();
                setSession(null);
                setIsCallActive(false);
                setLogMessages((prev) => [...prev, 'Call Hangup Successful']);
                setDtmfHistory([]);
            }
        } catch (error) {
            console.error('Error hanging up call:', error);
            setLogMessages((prev) => [...prev, `Error hanging up call: ${error.message}`]);
            toast(
                toastFunctionToaster(
                    'Error hanging up call.',
                    'error'
                )
            );
        }
    };

    return (
        <Flex
        p={2}
        mx="auto"
        mt={4}
        bg="white"
        boxShadow="lg"
        borderRadius="lg"
        textAlign="center"
        transition="all 0.3s ease"
        pt={{ sm: "125px", lg: "75px" }}
        >        
            <Fade in={isRegistered}>
                <Text fontSize="lg" mb={4} color={isRegistered ? 'green.500' : 'red.500'}>
                    Status: {isRegistered ? 'Registered' : 'Not Registered'}
                </Text>
            </Fade>
    
            {/* Audio element for remote audio */}
            <audio ref={remoteAudio} autoPlay />
    
            {/* Layout for Dialer and Logs side by side */}
            <Flex direction="row" gap={4} mb={2}>
                {/* Device Selector */}
                <Flex>
                    <AudioDeviceSelector 
                        inputDevice={inputDevice}
                        setInputDevice={setInputDevice}
                        outputDevice={outputDevice}
                        setOutputDevice={setOutputDevice}
                    />
                </Flex>

                {/* Dialer component */}
                <Flex>
                    <Dailer onDial={makeCall} hangUpCall={hangUpCall} onDTMF={handleDTMF} disabled={isRegistered} isCallActive={isCallActive} />
                </Flex>

                {/* Vertical Separator */}
                <Box borderLeft="1px solid" borderColor="gray.300" h="100%" mx={4} />

                {/* Call Logs and DTMF History section (side by side) */}
                <Flex direction="column" gap={4}>
                    {/* Call Logs Section */}
                    <Flex direction="column" w="full">
                        <Text fontWeight="bold" mb={2}>Call Logs:</Text>
                        <Box
                            maxH={{ base: "120px", md: "200px", lg: "300px" }}
                            overflowY="auto"
                            p={2}
                            bg="gray.50"
                            borderRadius="md"
                            boxShadow="sm"
                            css={{
                                '&::-webkit-scrollbar': { width: '8px' },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.3s ease',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '4px',
                                },
                            }}
                        >
                            {logMessages.map((msg, index) => (
                                <Text key={index} fontSize="sm">{msg}</Text>
                            ))}
                        </Box>
                    </Flex>

                    {/* DTMF History Section */}
                    <Flex direction="column" w="full">
                        <Text fontWeight="bold" mb={2}>DTMF History:</Text>
                        <Box
                            maxH={{ base: "120px", md: "200px", lg: "300px" }}
                            overflowY="auto"
                            p={2}
                            bg="gray.50"
                            borderRadius="md"
                            boxShadow="sm"
                            css={{
                                '&::-webkit-scrollbar': { width: '8px' },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.3s ease',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '4px',
                                },
                            }}
                        >
                            {dtmfHistory.map((dtmf, index) => (
                                <Text key={index} fontSize="sm">
                                    {`Tone: ${dtmf.tone}, Time: ${dtmf.timestamp}`}
                                </Text>
                            ))}
                        </Box>
                    </Flex>
                </Flex>
            </Flex>

    
            {/* Call status */}
            <Fade in={isCallActive}>
                <Text fontSize="xl" fontWeight="bold" color="gray.600">
                    Call in progress...
                </Text>
            </Fade>
        </Flex>
    
    );
};

export default SipClient;