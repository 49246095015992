// React + Chakra Imports
import React, { useState, useEffect, useMemo } from "react";
import { Flex, Button, Input, Switch, Text, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useColorModeValue, useToast, FormLabel, SimpleGrid, FormControl } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { createLingoletIdTax, updateLingoletIdTax } from "../../../../../../services/accountServices";

function AddUpdateTaxDetails({ identity_uuid, passClientData, setclientData, updateClientDataCounter, idValue, indexValue }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [counter, setCounter] = useState(0);
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const initialFormData = {
		identity_tax_identifier: "",
		identity_tax_identifier_info: "",
		identity_tax_identifier_rate: "",
		identity_tax_identifier_country: "",
		identity_tax_identifier_state: "",
		identity_tax_active: true,
	};
	const [formData, setFormData] = useState(initialFormData);

	// Update form details on edit
	useMemo(() => {
		if (passClientData && passClientData.identity_tax_uuid) {
			setFormData({
				identity_tax_identifier: passClientData.identity_tax_identifier || "",
				identity_tax_identifier_info: passClientData.identity_tax_identifier_info || "",
				identity_tax_identifier_rate: passClientData.identity_tax_identifier_rate || "",
				identity_tax_identifier_country: passClientData.identity_tax_identifier_country || "",
				identity_tax_identifier_state: passClientData.identity_tax_identifier_state || "",
				identity_tax_active: passClientData.identity_tax_active || true,
			});
			if (idValue === indexValue) {
				onOpen();
			}
		} else {
			setFormData(initialFormData);
		}
	}, [passClientData, idValue, indexValue]);

	// Handle input changes
	const handleChange = e => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" || type === "switch" ? checked : value,
		});
	};

	// Validate form fields
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;

		if (!formData.identity_tax_identifier) {
			formIsValid = false;
			errors["identity_tax_identifier"] = "*Please enter your tax identifier number.";
		}

		if (!formData.identity_tax_identifier_info) {
			formIsValid = false;
			errors["identity_tax_identifier_info"] = "*Please enter your tax identifier info.";
		}

		if (!formData.identity_tax_identifier_rate) {
			formIsValid = false;
			errors["identity_tax_identifier_rate"] = "*Identifier rate is required";
		}

		setError(errors);
		return formIsValid;
	};

	// Handle form submission
	const handleSubmit = () => {
		if (validateFieldForm()) {
			setLoading(true);
			const payload = {
				...formData,
				identity_uuid,
			};

			if (passClientData?.identity_tax_uuid) {
				payload.identity_tax_uuid = passClientData.identity_tax_uuid;

				updateLingoletIdTax(payload)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
					});
			} else {
				createLingoletIdTax(payload)
					.then(res => {
						setLoading(false);
						setCounter(prev => prev + 1);
						toast(toastFunctionToaster(res?.message, "success"));
						handleCloseDrawer();
					})
					.catch(err => {
						setLoading(false);
						toast(toastFunctionToaster(err?.message, "error"));
					});
			}
		}
	};

	// Update counter when data is updated
	useEffect(() => {
		updateClientDataCounter(counter);
	}, [counter]);

	// Reset form data on drawer close
	const handleCloseDrawer = () => {
		setFormData(initialFormData);
		setError({});
		setclientData(null);
		onClose();
	};

	return (
		<>
			<Button
				ref={btnRef}
				w="100%"
				onClick={onOpen}
				justifyContent={"start"}
				px="12px"
				fontWeight={"400"}
				_hover={{ bg: "blue.100", _active: "blue.100" }}
				sx={{
					bg: "blue.100",
					borderStyle: "dashed",
					borderTop: "none",
					borderWidth: "1px",
					borderColor: "black",
					color: "black",
					rounded: "sm",
				}}
			>
				Tax
			</Button>
			<Drawer isOpen={isOpen} placement="right" size="md" onClose={handleCloseDrawer} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>
						{passClientData?.identity_tax_uuid ? "Update" : "Create New"}
						<Text as="span" display={"inline-block"} textTransform={"capitalize !important"} ml="1">
							Tax Details
						</Text>
					</DrawerHeader>

					<DrawerBody p="20px">
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "15px", xl: "15px" }}>
							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Tax Identifier
								</FormLabel>
								<Input disabled={!!passClientData?.identity_tax_uuid} type="text" name="identity_tax_identifier" value={formData.identity_tax_identifier} onChange={handleChange} color={textColorPrimary} />
								{error.identity_tax_identifier && (
									<Text color="red" mb="2">
										{error.identity_tax_identifier}
									</Text>
								)}
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Tax Identifier Info
								</FormLabel>
								<Input type="text" name="identity_tax_identifier_info" value={formData.identity_tax_identifier_info} onChange={handleChange} color={textColorPrimary} />
								{error.identity_tax_identifier_info && (
									<Text color="red" mb="2">
										{error.identity_tax_identifier_info}
									</Text>
								)}
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Tax Identifier Rate
								</FormLabel>
								<Input type="number" name="identity_tax_identifier_rate" value={formData.identity_tax_identifier_rate} onChange={handleChange} color={textColorPrimary} />
								{error.identity_tax_identifier_rate && (
									<Text color="red" mb="2">
										{error.identity_tax_identifier_rate}
									</Text>
								)}
							</FormControl>

							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Country
								</FormLabel>
								<Input type="text" name="identity_tax_identifier_country" value={formData.identity_tax_identifier_country} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									State
								</FormLabel>
								<Input type="text" name="identity_tax_identifier_state" value={formData.identity_tax_identifier_state} onChange={handleChange} color={textColorPrimary} />
							</FormControl>

							<FormControl>
								<FormLabel fontSize={"14px"} fontWeight={"600"} mb="0">
									Status
								</FormLabel>
								<Switch name="identity_tax_active" isChecked={formData.identity_tax_active} onChange={handleChange} />
							</FormControl>
						</SimpleGrid>
					</DrawerBody>
					<DrawerFooter borderTopWidth={"1px"}>
						<Flex justifyContent={"flex-end"} w="100%">
							<Button colorScheme="green" onClick={handleSubmit} isLoading={loading} isDisabled={loading}>
								{passClientData?.identity_tax_uuid ? "Update" : "Create"}
							</Button>
							<Button variant="outline" colorScheme="red" ml={3} onClick={handleCloseDrawer}>
								Cancel
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default AddUpdateTaxDetails;
