import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import moment from 'moment'
import { useState } from "react";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

const convertToCSV = (array) => {
    const headers = [
        "START TIME",
        "END TIME",
        "DURATION",
        "PROVIDER",
        "TARGET LANGUAGE",
        "CALL TYPE",
        "SUBSCRIBER EMAIL",
    ];

    // Generate rows
    const rows = array.map((item) => {
        return [
            item.start_time || "",
            item.end_time || "",
            item.duration || "",
            item.provider || "",
            item.target_language || "",
            item.call_type || "",
            item?.subscriber || "N/A",
        ].join(",");
    });

    return [headers.join(","), ...rows].join("\n");
};

function getFileName(selectedRange, prefix = "billing") {
    if (!selectedRange || selectedRange.length !== 2) {
        throw new Error("Invalid date range provided");
    }

    const [start, end] = selectedRange;
    const startDate = moment(start).format("MM-DD-YYYY");
    const endDate = moment(end).format("MM-DD-YYYY");

    const fileName = `${startDate} - ${endDate}_${prefix}.csv`;
    return fileName;
}

const BillingCSV = ({ data, selectedRange }) => {
    const [loading, setLoading] = useState(false)
    const { onClose } = useDisclosure()
    const toast = useToast()
    const handleDownload = () => {
        try {
            setLoading(true)
            const csvData = convertToCSV(data);
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = getFileName(selectedRange);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast(toastFunctionToaster("CSV downloaded successfully", "success"))
        } catch (e) {
            toast(toastFunctionToaster("Failed to download CSV", "error"))
        } finally{
            setLoading(false)
        }

    };

    return (
        <>
            <Button rounded={'lg'} size="sm" leftIcon={<AiOutlineFileText />} colorScheme="blue" onClick={handleDownload}>CSV</Button>
            {loading && <Modal isOpen={loading} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generating CSV</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <Spinner thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl' />
                        <Text mt={4} fontSize="lg" color="gray.500">
                            Please wait, generating your CSV...
                        </Text>
                    </ModalBody>
                </ModalContent>
            </Modal>}
        </>
    );
};

export default BillingCSV;
