import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  HStack,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { getAllSubscribersList } from "../../../../services/accountServices";

const ReportingForm = ({ onSubmit, editData }) => {
  const [subscriberList, setSubscriberList] = useState([]);
  const [form, setForm] = useState({
    reporting_type: "billing_records",
    reporting_info: {
      recipients: [],
      subject: "",
      body: "",
      format: "csv", // Default format
      columns: [],
      filters: { date_range: { start: "", end: "" }, subscriber_uuid: [] },
      frequency: "weekly",
    },
    schedule_type: "weekly",
    schedule_details: {
      daily: { times: [] },
      weekly: { days: [], times: [] },
      monthly: { dates: [], times: [] },
    },
  });

  const [weeklyDays, setWeeklyDays] = useState(
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  );

  function handleGetAllSubscriberList(){
    getAllSubscribersList().then(resp=>{
      if(resp.data[0]?.status){
        setSubscriberList(resp.data[0]?.data)
      }else{
        setSubscriberList([])
      }
    }).catch(e=>{
      setSubscriberList([])
    })
  }

  const [availableColumns, setAvailableColumns] = useState({
    provider_name: { 
        column: "call_provider", 
        dropdownName: "Provider name" },
    provider_code: { 
        column: "call_provider_code", 
        dropdownName: "Provider code" },
    type_of_call: { 
        column: "call_type", 
        dropdownName: "Type of call" },
    source_language: { 
        column: "call_source_language", 
        dropdownName: "Source language" },
    target_language: { 
        column: "call_target_language", 
        dropdownName: "Target language" },
    log_details: { 
        column: "call_log", 
        dropdownName: "Log details" },
    duration_in_seconds: { 
        column: "call_duration_seconds", 
        dropdownName: "Duration in seconds" },
    amount_billed: { 
        column: "billing_amount", 
        dropdownName: "Amount billed" },
    billing_information: { 
        column: "call_billing", 
        dropdownName: "Billing information" },
    agent_details: { 
        column: "call_agent_info", 
        dropdownName: "Agent details" },
    start_timestamp: { 
        column: "call_start_time", 
        dropdownName: "Start timestamp" },
    call_success_flag: { 
        column: "call_success", 
        dropdownName: "Call success flag" },
    call_tags: { 
        column: "call_tag", 
        dropdownName: "Call tags" },
    }
    );


  useEffect(() => {
    if (editData) {
      setForm(editData);
    }
  }, [editData]);
  useEffect(() => {
    handleGetAllSubscriberList()
  }, []);
  const handleChange = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const handleRemovePill = (column) => {
    const updatedColumns = form.reporting_info.columns.filter(
      (c) => c !== column
    );
    setForm((prevForm) => ({
      ...prevForm,
      reporting_info: {
        ...prevForm.reporting_info,
        columns: updatedColumns,
      },
    }));
  };

  const handleRemoveSubscriberPill = (subs) => {
    const updatedSubs = form.reporting_info.filters.subscriber_uuid.filter(
      (c) => c !== subs
    );
    setForm((prevForm) => ({
      ...prevForm,
      reporting_info: {
        ...prevForm.reporting_info,
        filters: {
          ...prevForm.reporting_info.filters,
          subscriber_uuid: updatedSubs,
        },
      },
    }));
  }

  const handleColumnSelection = (column) => {
    const updatedColumns = form.reporting_info.columns.includes(column)
      ? form.reporting_info.columns.filter((c) => c !== column)
      : [...form.reporting_info.columns, column];
    setForm((prevForm) => ({
      ...prevForm,
      reporting_info: {
        ...prevForm.reporting_info,
        columns: updatedColumns,
      },
    }));
  };

  const handleSubscriberSelection = (subs) => {
    const updatedSubs = form.reporting_info.filters.subscriber_uuid.includes(subs)
      ? form.reporting_info.filters.subscriber_uuid.filter((c) => c !== subs)
      : [...form.reporting_info.filters.subscriber_uuid, subs];
    setForm((prevForm) => ({
      ...prevForm,
      reporting_info: {
        ...prevForm.reporting_info,
        filters: {
          ...prevForm.reporting_info.filters,
          subscriber_uuid: updatedSubs,
        }
      },
    }));
  };

  const handleNestedChange = (path, value) => {
    const keys = path.split(".");
    const updatedForm = { ...form };
    let nested = updatedForm;    

    keys.slice(0, -1).forEach((key) => {
      nested = nested[key];      
    });

    nested[keys[keys.length - 1]] = value;    
    setForm(updatedForm);
  };

  const handleSubmit = () => {
    console.log(JSON.stringify(form));
    onSubmit(form);

    setForm({
      reporting_type: "billing_records",
      reporting_info: {
        recipients: [],
        subject: "",
        body: "",
        format: "csv",
        columns: [],
        filters: { date_range: { start: "", end: "" }, subscriber_uuid: [] },
        frequency: "weekly",
      },
      schedule_type: "weekly",
      schedule_details: {
        daily: { times: [] },
        weekly: { days: [], times: [] },
        monthly: { dates: [], times: [] },
      },
    });
  };

  return (
    <Box mb={6}>
      <VStack spacing={4} align="stretch">
        {/* Reporting Type */}
        <FormControl>
          <FormLabel>Reporting Type</FormLabel>
          <Select
            value={form.reporting_type}
            onChange={(e) => handleChange("reporting_type", e.target.value)}
          >
            <option value="billing_records">Billing Records</option>
          </Select>
        </FormControl>

        {/* Recipients */}
        <FormControl>
          <FormLabel>Recipients</FormLabel>
          <Input
            placeholder="Emails (comma-separated)"
            value={form.reporting_info.recipients.join(", ")}
            onChange={(e) =>
              handleNestedChange(
                "reporting_info.recipients",
                e.target.value.split(", ")
              )
            }
          />
        </FormControl>

        {/* Subject */}
        <FormControl>
          <FormLabel>Subject</FormLabel>
          <Input
            placeholder="Subject"
            value={form.reporting_info.subject}
            onChange={(e) =>
              handleNestedChange("reporting_info.subject", e.target.value)
            }
          />
        </FormControl>

        {/* Body */}
        <FormControl>
          <FormLabel>Body</FormLabel>
          <Textarea
            placeholder="Body"
            value={form.reporting_info.body}
            onChange={(e) =>
              handleNestedChange("reporting_info.body", e.target.value)
            }
          />
        </FormControl>

        {/* Format */}
        <FormControl>
          <FormLabel>Format</FormLabel>
          <RadioGroup
            value={form.reporting_info.format}
            onChange={(value) =>
              handleNestedChange("reporting_info.format", value)
            }
          >
            <Stack direction="row" spacing={4}>
              <Radio value="csv">CSV</Radio>
              <Radio value="pdf">PDF</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        {/* Columns */}
        <FormControl>
        <FormLabel>Columns</FormLabel>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} variant="outline">
            {form.reporting_info.columns.length === 0
              ? "Select Columns"
              : "Modify Selected Columns"}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup type="checkbox" value={form.reporting_info.columns}>
              {Object.entries(availableColumns).map(([key, value]) => (
                <MenuItemOption
                  key={key}
                  name={value["column"]}
                  value={value["column"]}
                  onClick={() => handleColumnSelection(value["column"])}
                  isChecked={form.reporting_info.columns.includes(value["column"])}
                >
                  {value["column"]}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <Flex mt={2} gap={2} flexWrap="wrap">
          {form.reporting_info.columns.map((column) => (
            <Tag
              size="md"
              key={column}
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{column}</TagLabel>
              <TagCloseButton onClick={() => handleRemovePill(column)} />
            </Tag>
          ))}
        </Flex>
        </FormControl>

        {/* Date Range */}
        <FormControl>
          <FormLabel>Date Range Start</FormLabel>
          <Input
            type="datetime-local"
            value={form.reporting_info.filters.date_range.start}
            onChange={(e) =>
              handleNestedChange(
                "reporting_info.filters.date_range.start",
                e.target.value
              )
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Date Range End</FormLabel>
          <Input
            type="datetime-local"
            value={form.reporting_info.filters.date_range.end}
            onChange={(e) =>
              handleNestedChange(
                "reporting_info.filters.date_range.end",
                e.target.value
              )
            }
          />
        </FormControl>

        {/* Subscriber UUID */}
        {/* <FormControl>
          <FormLabel>Subscriber UUID</FormLabel>
          <Input
            placeholder="Subscriber UUIDs (comma-separated)"
            value={form.reporting_info.filters.subscriber_uuid.join(", ")}
            onChange={(e) =>
              handleNestedChange(
                "reporting_info.filters.subscriber_uuid",
                e.target.value.split(", ")
              )
            }
          />
        </FormControl> */}

        <FormControl>
        <FormLabel>subscribers</FormLabel>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} variant="outline">
            {form.reporting_info.filters.subscriber_uuid.length === 0
              ? "Select Subscribers"
              : "Modify Selected Subscribers"}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup type="checkbox" value={form.reporting_info.filters.subscriber_uuid}>
              {subscriberList?.map(item => (
                <MenuItemOption
                  key={item?.user_uuid}
                  name={item?.email}
                  value={item?.user_uuid}
                  onClick={() => handleSubscriberSelection(item?.user_uuid)}
                  isChecked={form.reporting_info.filters.subscriber_uuid.includes(item?.user_uuid)}
                >
                  {item?.email}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <Flex mt={2} gap={2} flexWrap="wrap">
          {subscriberList?.map((subs) => (
            form.reporting_info.filters.subscriber_uuid.includes(subs?.user_uuid) ?
            <Tag
              size="md"
              key={subs?.user_uuid}
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{subs?.email}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveSubscriberPill(subs?.user_uuid)} />
            </Tag> : null
          ))}
        </Flex>
        </FormControl>

        {/* Schedule Details */}
        <FormControl>
          <FormLabel>Schedule Type</FormLabel>
          <Select
            value={form.schedule_type}
            onChange={(e) => handleChange("schedule_type", e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </FormControl>

        {form.schedule_type === "daily" && (
          <FormControl>
            <FormLabel>Times</FormLabel>
            <Input
              placeholder="Times (comma-separated - 09:00, 15:00, 21:00)"
              value={form.schedule_details.daily.times.join(", ")}
              onChange={(e) =>
                handleNestedChange(
                  "schedule_details.daily.times",
                  e.target.value.split(", ")
                )
              }
            />
          </FormControl>
        )}

        {form.schedule_type === "weekly" && (
          <>
            <FormControl>
              <FormLabel>Schedule Days</FormLabel>
              <CheckboxGroup
                value={form.schedule_details.weekly.days}
                onChange={(values) =>
                  handleNestedChange("schedule_details.weekly.days", values)
                }
              >
                <SimpleGrid columns={2}>
                    {
                        weeklyDays.map((day) => (
                            <Checkbox key={day} value={day}>{day}</Checkbox>
                        ))
                    }
                </SimpleGrid>
              </CheckboxGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Times</FormLabel>
              <Input
                placeholder="Times (comma-separated - 09:00, 15:00, 21:00)"
                value={form.schedule_details.weekly.times.join(", ")}
                onChange={(e) =>
                  handleNestedChange(
                    "schedule_details.weekly.times",
                    e.target.value.split(", ")
                  )
                }
              />
            </FormControl>
          </>
        )}

        {form.schedule_type === "monthly" && (
          <>
            <FormControl>
              <FormLabel>Dates</FormLabel>
              <Input
                placeholder="Dates (comma-separated - 1, 15)"
                value={form.schedule_details.monthly.dates.join(", ")}
                onChange={(e) =>
                  handleNestedChange(
                    "schedule_details.monthly.dates",
                    e.target.value.split(", ")
                  )
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Times</FormLabel>
              <Input
                placeholder="Times (comma-separated - 09:00, 15:00, 21:00)"
                value={form.schedule_details.monthly.times.join(", ")}
                onChange={(e) =>
                  handleNestedChange(
                    "schedule_details.monthly.times",
                    e.target.value.split(", ")
                  )
                }
              />
            </FormControl>
          </>
        )}

        {/* Submit Button */}
        <Button colorScheme="blue" onClick={handleSubmit}>
          {editData ? "Update" : "Create"} Report Setup
        </Button>
      </VStack>
    </Box>
  );
};

export default ReportingForm;
