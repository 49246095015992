import { Alert, AlertIcon, Box, Button, Flex, Spacer } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../../components/card/Card'
import { useBrandBg } from '../../../theme/globalColorTheme'
import DynamicTable from './components/DynamicTable'

const DynamicFormContentList = () => {
    const location = useLocation()
    const { data } = location?.state
    const subRoute = window.location.pathname.split('/')[1]
    const navigate = useNavigate()
    return (
        <Flex direction="column" pt={{ sm: "130px", lg: "80px" }} flex={1}>
            <Flex p="1">
                <Spacer />
                <Button colorScheme='blue' size="sm" onClick={() => {
                    navigate(`/${subRoute}/lingoforms`)
                }}>Back to Forms List</Button>
            </Flex>
            <Card p="1" flex={1}>
                <Flex minH="full" overflow="hidden" flex={1}>
                    <Box
                        w={"full"}
                        bg={useBrandBg}
                        p="2"
                        borderRadius={"10px"}
                        minH="full"
                        className="custom-scrollbar"
                        overflowY="auto"
                    >
                        <Card h="full">
                            {
                                data ? <DynamicTable data={data} /> : (
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        Form Not Found
                                    </Alert>
                                )
                            }
                        </Card>
                    </Box>
                </Flex>
            </Card>
        </Flex>
    )
}

export default DynamicFormContentList