// React Chakra Imports
import React from "react";
import { PiGear } from "react-icons/pi";
import { BsBank } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { VscBeaker } from "react-icons/vsc";
import { GiRadioTower } from "react-icons/gi";
import { FaBullhorn, FaLaptopCode } from "react-icons/fa6";
import { TiPlusOutline, TiShoppingCart } from "react-icons/ti";
import { FaLinkedin, FaFacebook, FaYoutube, FaUniversity } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight, MdCalendarMonth, MdLockClock, MdLocationOn } from "react-icons/md";
import { Heading, Image, Box, Flex, Button, Text, Grid, GridItem, Divider, Select, Input, Icon, VStack, Circle, useToast, HStack, PinInput, PinInputField, Spacer } from "@chakra-ui/react";

// Component Imports
import logo from "../../assets/landingPageImg/kb_logo_new1.png";
import contentBanner from "../../assets/landingPageImg/content-1.png";
import lingoletPlatformImg from "../../assets/landingPageImg/new_landing_img/lingolet-platform.png";
import AIEvents from "../../assets/landingPageImg/new_landing_img/AIEvents.png";
import waveFooterBg from "../../assets/landingPageImg/new_landing_img/waveFooterBg.png";
import PlatformCircle from "../../assets/landingPageImg/new_landing_img/PlatformCircle.png";
import RequestDemo from "./requestDemo/RequestDemo";
import Navbar from "./header/Navbar";
import { GoLaw } from "react-icons/go";
import { toastFunctionToaster } from "../../utils/toastFunction";
import { useState } from "react";
import { createSignUpType, verifyValidationCode } from "../../services/sharedServices";
import { LANDING_PAGE } from "./constants";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function LspLandingPageNew() {
	const toast = useToast()
	const [showPinInput, setShowPinInput] = useState(false)
	const [userInfo, setUserInfo] = useState({
		signup_email: null,
		last_name: null,
		first_name: null
	})
	const [pin, setPin] = useState("");
	const [signUpType, setSignUpType] = useState(LANDING_PAGE.SIGNUP_TYPES.NEWS_LETTER)
	const [btnLoading, setBtnLoading] = useState({
		signup: false,
		verify: false
	})
	const signupTypeSection = useRef()
	const navigate = useNavigate()

	const platformDetailsData = [
		{ title: "Interpretation with AI and Human", content: "Combining AI with Human to deliver interpretation to any device on any network is the core of Lingolet AI Platform." },
		{ title: "Translation with AI and PE", content: "AI supported translation framework with proofreading support is added to those who wants a document management solution integrated with translation." },
		{ title: "LingoLink", content: "LingoLink gives users access to on demand remote interpreters, AI interpretation, and document translation through a link that can be opened in any browser on any device." },
		{ title: "LingoCard", content: "LingoCard is a prepaid calling card service designed to connect you with professional interpreters over the phone within 30 seconds." },
		{ title: "LSP SaaS Platform", content: "Lingolet AI SaaS platform is designed for all sizes of LSP to bring their whole business management in cloud." },
		{ title: "Direct Interpretation", content: "A service available to all the business who wants to comsume human and AI supported interpretation for their businesses." },
		// { title: "", content: " " },
		// { title: "", content: " " },
	];

	const newsLetterData = [
		{
			title: "Role of AI at Lingolet AI",
			content: "Lingolet CTO will discuss how AI is playing important role to build our SaaS platform.",
			date: "January 22", time: "10 AM PST", type: "Virtual"
		},
		{
			title: "Lingolink and LingoCard",
			content: "Learn how to use LingoLink and LingoCard to deliver interpretaion services to your whole business.",
			date: "January 29", time: "10 AM PST", type: "Virtual"
		},
		{
			title: "Lingolet AI - Vision",
			content: "Learn about Lingolet AI vision from Lingolet leadership team.",
			date: "Feb 5", time: "10 AM PST", type: "Virtual"
		},
	];

	const industrySectionData = [
		{
			icon: FaUniversity,
			name: "Education (K12)",
		},
		{
			icon: VscBeaker,
			name: "Finance and Banking Services",
		},
		{
			icon: TiPlusOutline,
			name: "Healthcare & Life Sciences",
		},
		// {
		// 	icon: PiGear,
		// 	name: "Manufacturing",
		// },
		// {
		// 	icon: FaLaptopCode,
		// 	name: "Technology",
		// },
		// {
		// 	icon: BsBank,
		// 	name: "Public Sector",
		// },
		// {
		// 	icon: TiShoppingCart,
		// 	name: "Retail & Consumer Goods",
		// },
		{
			icon: GoLaw,
			name: "Law Enforcement and Goverment",
		},
	];

	const lspSectionData = [
		{
			icon: FaUniversity,
			name: "Cutting Edge AI",
			description: "Deliver AI to the customer with human interpretation"
		},
		{
			icon: VscBeaker,
			name: "Finance and Banking Services",
		},
		{
			icon: TiPlusOutline,
			name: "Healthcare & Life Sciences",
		},
		// {
		// 	icon: PiGear,
		// 	name: "Manufacturing",
		// },
		// {
		// 	icon: FaLaptopCode,
		// 	name: "Technology",
		// },
		// {
		// 	icon: BsBank,
		// 	name: "Public Sector",
		// },
		// {
		// 	icon: TiShoppingCart,
		// 	name: "Retail & Consumer Goods",
		// },
		{
			icon: GoLaw,
			name: "Law Enforcement and Goverment",
		},
	];


	const platformFeatures = [
		{
			icon: TiPlusOutline,
			position: 'left',
			name: "Distributed and Scalable Platform to run any cloud",
		},
		{
			icon: PiGear,
			position: 'right',
			name: "LingoLinks are shared to end users over SMS, email, QR Code..",
		},
		{
			icon: FaLaptopCode,
			position: 'left',
			name: "LingoLink contains LingoApps to run various translation and interpretation services.",
		},
		{
			icon: BsBank,
			position: 'right',
			name: "Multilevel customer management at Account and Subscriber level",
		},
		{
			icon: TiShoppingCart,
			position: 'left',
			name: "Special profiles for individual customers",
		},
		{
			icon: GiRadioTower,
			position: 'right',
			name: "Services and Quota Management",
		},
	];

	const contentData = [
		{
			count: "~8K",
			unit: "hours",
			heading: "AI Interpretation",
			duration: "Monthly"
		},
		{
			count: "~5K",
			unit: "hours",
			heading: "Human Interpretation",
			duration: "Monthly"
		},
		{
			count: "~2.5M",
			unit: "words",
			heading: "AI Translation",
			duration: "Monthly"
		},
		{
			count: "~180",
			unit: "",
			heading: "Global Languages",
			duration: "Supported"
		},

	];

	// Todo:Render Platform Data
	const renderPlatformData = platformDetailsData?.map((data, index) => {
		return (
			<GridItem w="100%" key={index}>
				<Text fontWeight={600}>{data?.title}</Text>
				<Text fontWeight={500} fontSize={"15px"} my="10px">
					{data?.content}
				</Text>
				<Flex alignItems={"center"}>
					<Text fontWeight={500} fontSize={"15px"} color="#259edc" mr="3">
						Learn More
					</Text>
					<MdOutlineKeyboardArrowRight color="#259edc" />
				</Flex>
			</GridItem>
		);
	});

	// Todo:Render Industry Section Data
	const renderPlatformFeaturesLeft = platformFeatures?.map((item, index) => {
		if (item?.position === 'left'){
			return (
				<GridItem w="100%" key={index}>
					<Box 
						_hover={{cursor:'pointer', boxShadow:"xl"}}
						key={index} p={7} rounded={"lg"} boxShadow={"md"} bg={"gray.200"} h="160px">
						<VStack justifyContent={"space-between"} h="100%">
							<Circle size={"50px"} bg={"#29b5e8"} color={"white"}>
								<Icon as={item.icon} />
							</Circle>
							<Text textAlign={"center"} fontWeight={"600"} fontSize="15px">
								{item.name}
							</Text>
						</VStack>
					</Box>
				</GridItem>
			);
		}
	});
	const renderPlatformFeaturesRight = platformFeatures?.map((item, index) => {
		if (item?.position === 'right'){
			return (
				<GridItem w="100%" key={index}>
					<Box 
						_hover={{cursor:'pointer', boxShadow:"xl"}}
						key={index} p={7} rounded={"lg"} boxShadow={"md"} bg={"gray.200"} h="160px">
						<VStack justifyContent={"space-between"} h="100%">
							<Circle size={"50px"} bg={"#29b5e8"} color={"white"}>
								<Icon as={item.icon} />
							</Circle>
							<Text textAlign={"center"} fontWeight={"600"} fontSize="15px">
								{item.name}
							</Text>
						</VStack>
					</Box>
				</GridItem>
			);
		}
	});

	// Todo:Render Newsletter Data
	const renderNewsLetterData = newsLetterData?.map((data, index) => {
		return (
			<GridItem w="100%" borderWidth={"1px"} borderBottomWidth={"5px"} borderBottomColor={"#259edc"} borderRadius={"5px"} p="20px" key={index}>
				<Flex flexWrap={"wrap"} h="100%" alignItems={"center"}>
					<Text fontWeight={600}>{data?.title}</Text>
					<Text fontWeight={500} fontSize={"15px"} mt="10px" mb="35px">
						{data?.content}
					</Text>
					<Grid templateColumns="repeat(3, 1fr)" gap={2}>
						<Flex alignItems={"center"}>
							<MdCalendarMonth color="#259edc" />
							<Text fontWeight={500} fontSize={"15px"} color="black" ml="2">
								{data?.date}
							</Text>
						</Flex>
						{data?.time ? (
							<Flex alignItems={"center"}>
								<MdLockClock color="#259edc" />
								<Text fontWeight={500} fontSize={"15px"} color="black" ml="2">
									{data?.time}
								</Text>
							</Flex>
						) : null}
						<Flex alignItems={"center"}>
							<MdLocationOn color="#259edc" />
							<Text fontWeight={500} fontSize={"15px"} color="black" ml="2">
								{data?.type}
							</Text>
						</Flex>
					</Grid>
				</Flex>
			</GridItem>
		);
	});

	// Todo:Render Industry Section Data
	const renderIndustrySectionData = industrySectionData?.map((item, index) => {
		return (
			<GridItem w="100%" key={index}>
				<Box 
					_hover={{cursor:'pointer', boxShadow:"2xl"}}
					key={index} p={7} rounded={"lg"} boxShadow={"lg"} bg={"white"} h="160px">
					<VStack justifyContent={"space-between"} h="100%">
						<Circle size={"50px"} bg={"red.200"} color={"gray.800"}>
							<Icon as={item.icon} />
						</Circle>
						<Text textAlign={"center"} fontWeight={"600"} fontSize="15px">
							{item.name}
						</Text>
					</VStack>
				</Box>
			</GridItem>
		);
	});

	// Todo:Render Industry Section Data
	const renderLSPData = lspSectionData?.map((item, index) => {
		return (
			<GridItem w="100%" key={index}>
				<Box 
					_hover={{cursor:'pointer', boxShadow:"2xl"}}
					key={index} p={7} rounded={"lg"} boxShadow={"lg"} bg={"white"} h="160px">
					<VStack justifyContent={"space-between"} h="100%">
						<Circle size={"50px"} bg={"red.200"} color={"gray.800"}>
							<Icon as={item.icon} />
						</Circle>
						<Text textAlign={"center"} fontWeight={"600"} fontSize="15px">
							{item.name}
						</Text>
					</VStack>
				</Box>
			</GridItem>
		);
	});

	// Todo:Render Content Data
	const renderContentData = contentData?.map((data, index) => {
		return (
			<GridItem w="100%" key={index} c>
				<Flex alignItems={"center"}>
					<Text fontSize={"3rem"} fontWeight={"600"} mr="3">
						{data?.count}
					</Text>
					<Flex w={'100%'} direction={'column'}>
						<Text fontSize={"14px"} textAlign={"left"} fontWeight={"500"} pr="10px">

						</Text>
						<Text fontSize={"24px"} textAlign={"left"} fontWeight={"500"} pr="10px">
							{data?.heading}
						</Text>
						<Text fontSize={"14px"} textAlign={"left"} fontWeight={"500"} pr="10px">
							{data?.unit}{" "}{data?.duration}
						</Text>
					</Flex>
				</Flex>
			</GridItem>
		);
	});

	function handleUserInfoChange(e) {
		const inputType = e.target.name
		const value = e.target.value

		setUserInfo(userInfo => ({ ...userInfo, [inputType]: value }))
	}

	function handleCreateSubscription(e) {
		e.preventDefault()
		const signup_type = e.target.elements.subscriptionType.value
		if (!userInfo?.signup_email) {
			toast(toastFunctionToaster("Email is required", "error"))
		}
		setBtnLoading(btnLoading => ({ ...btnLoading, signup: true }))
		const paramObj = {
			signup_email: userInfo?.signup_email,
			first_name: userInfo?.first_name,
			last_name: userInfo?.last_name,
			signup_type
		}
		createSignUpType(paramObj).then(resp => {
			if (resp?.data[0]?.status) {
				if (!resp?.data[0]?.access_verified) {
					setShowPinInput(true)
				}
				toast(toastFunctionToaster(resp?.message, "success"))
			} else {
				toast(toastFunctionToaster(resp?.message, "error"))
			}

		}).catch(e => {
			toast(toastFunctionToaster(`Couldn't signup for ${signup_type}`, "error"))
		}).finally(() => {
			setBtnLoading(btnLoading => ({ ...btnLoading, signup: false }))
		})
	}

	function resetdetails() {
		setUserInfo({
			signup_email: null,
			last_name: null,
			first_name: null
		})
		setPin(null)
		setShowPinInput(false)
		setSignUpType(LANDING_PAGE.SIGNUP_TYPES.NEWS_LETTER)
	}

	function verifyPin() {
		setBtnLoading(btnLoading => ({ ...btnLoading, 'verify': true }))
		const paramObj = {
			signup_email: userInfo.signup_email,
			validation_code: pin
		}
		verifyValidationCode(paramObj).then(resp => {
			if (resp?.data[0]?.status) {
				toast(toastFunctionToaster(resp?.message, "success"))
				resetdetails()
			} else {
				toast(toastFunctionToaster(resp?.message, "error"))
			}
		}).catch(e => {
			toast(toastFunctionToaster("Failed to verify code", "error"))

		}).finally(() => {
			setBtnLoading(btnLoading => ({ ...btnLoading, 'verify': false }))
		})
	}

	function handleShowWebinarSignup(){
		setSignUpType(LANDING_PAGE.SIGNUP_TYPES.WEBINAR)
		signupTypeSection.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	return (
		<Box>
			<Navbar />
			<Box
				backgroundImage={`url(${contentBanner})`}
				bgColor={"black"}
				backgroundSize="contain"
				backgroundPosition="right"
				h={{
					sm: "100%",
					md: "100vh",
					lg: "100vh",
				}}
				bgRepeat={"no-repeat"}
				p={{
					sm: "2rem",
					md: "3rem",
					lg: "3rem",
				}}
				position="relative"
			>
				{/* Hero Content */}
				<Flex h="100%" alignItem="center" flexWrap={"wrap"} alignItems={"center"}>
					<Box
						w={{
							sm: "100%",
							md: "600px",
							lg: "600px",
						}}
						textAlign={{
							sm: "center",
							md: "left",
							lg: "left",
						}}
						mt={{
							sm: "0px",
							md: "5rem",
							lg: "5rem",
						}}
					>
						<Heading
							color="white"
							fontSize={{
								sm: "2rem",
								md: "3rem",
								lg: "3rem",
							}}
							fontWeight="500"
							fontFamily={"DM Sans"}
						>
							A True AI Platform
						</Heading>
						<Text
							mt={5}
							color="white"
							fontFamily={"Longhand"}
							sx={{
								transform: "rotate(-15deg)",
								display: "inline-block",
							}}
						//mt="1rem"
						>
							Combining
						</Text>
						<Flex alignItems={"center"} mt={10}>
							<Heading
								fontSize={{
									sm: "2rem",
									md: "3rem",
									lg: "3rem",
								}}
								fontWeight="500"
								color="white"
								fontFamily={"DM Sans"}
								mb="5px"
							>
								AI + Human = {"  "}
							</Heading>
							<Image ml={3} src={logo} w="40%" />
						</Flex>
						<Flex justifyContent={"space-between"} alignItems={"center"} mt="3rem">
							{/* <Button
								borderRadius={"5px"}
								w="49%"
								fontWeight={"400"}
								fontSize={{
									sm: "14px",
									md: "20px",
									lg: "20px",
								}}
								colorScheme="red"
								p="25px"
							>
								Request Demo
							</Button> */}
							<RequestDemo />
							<Button
								borderRadius={"5px"}
								w="49%"
								fontWeight={"400"}
								fontSize={{
									sm: "14px",
									md: "20px",
									lg: "20px",
								}}
								ml="2"
								p="25px"
							>
								Introduction Video
							</Button>
						</Flex>
					</Box>
					<Flex
						display={{
							sm: "none",
							md: "flex",
							lg: "flex",
						}}
						mt="3rem"
						w="80%"
						mx="auto"
						justifyContent={"space-between"}
						alignItems={"center"}
						py="15px"
						px="40px"
						bg="#259edc"
						color="#fff"
						borderRadius={"10px"}
						h="maxContent"
					>
						<Grid w={'100%'} templateColumns="repeat(4, 1fr)" gap={10}>
							{renderContentData}
						</Grid>
					</Flex>
				</Flex>
			</Box>
			{/* 2nd Section */}
			<Box
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "10rem",
				}}
				pb="4rem"
				pt="4rem"
			>
				<Box textAlign={"center"}>
					<Text fontSize={"2.5rem"} fontWeight={"600"} color="#259edc">
						An Interpretation & Translation SaaS Platform
					</Text>
					<Text fontSize={"2.5rem"} mt={4} fontWeight={"600"} lineHeight={"30px"}>
						LIKE NO OTHER
					</Text>
					<Text mt="2rem" fontWeight="500">
						Lingolet's AI platform combines the power of human interpreters and AI
					</Text>
					<Text fontWeight="500">to seemlessly deliver Interpretation and Translations services to LSP and consumers worldwide</Text>
				</Box>
				<Grid
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(2, 1fr)",
						lg: "repeat(2, 1fr)",
					}}
					gap={10}
					mt="3rem"
				>
					<GridItem>
						<Grid
							templateColumns={{
								sm: "repeat(1, 1fr)",
								md: "repeat(2, 1fr)",
								lg: "repeat(2, 1fr)",
							}}
							gap={6}
						>
							{renderPlatformData}
						</Grid>
					</GridItem>
					<GridItem w="100%">
						<Flex justifyContent="center" alignItems="center" h="100%">
							<Image src={lingoletPlatformImg} w="100%" object />
						</Flex>
					</GridItem>
				</Grid>
			</Box>
			<Divider />

			{/* 3nd Section */}
			{/* 3th Industry Solution Section */}
			<Box
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "10rem",
				}}
				py="10rem"
				bg="#f7f9fa"
			>
				<Box textAlign={"center"}>
					<Text fontSize={"2.5rem"} fontWeight={"600"} color="#259edc">
						A Wide range of Solutions
					</Text>
					<Text fontSize={"2.5rem"} fontWeight={"600"} lineHeight={"30px"}>
						for Language Industry
					</Text>
					<Text mt="2rem" fontWeight="500">
						Lingolet AI platform provides language translation and interpretation solutions to various industries and verticals
					</Text>
				</Box>
				<Grid
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(4, 1fr)",
						lg: "repeat(4, 1fr)",
					}}
					gap={10}
					mt="3rem"
				>
					{renderIndustrySectionData}
				</Grid>
			</Box>
			<Divider />
			<Box
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "10rem",
				}}
				py="3rem"
				bg="#fff"
			>
				<Box textAlign={"center"}>
					<Text fontSize={"2.5rem"} fontWeight={"600"} color="#259edc">
						Lingolet AI Platform Features
					</Text>
					<Text fontSize={"2.5rem"} fontWeight={"600"} lineHeight={"30px"}>
						Designed for both Customers and LSP
					</Text>
					<Text mt="2rem" fontWeight="500">
						Lingolet AI platform is distributed SaaS platform which can run on any cloud to meet small to large LSP. 
					</Text>
					<Text fontWeight="500">LSP can choose Lingolet owned LSPcloud.ai platform or can run our Lingolet AI platform to their own data center.</Text>
				</Box>
				<Grid
					w={'100%'}
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(3, 1fr)",
						lg: "repeat(3, 1fr)",
					}}
					gap={10}
					mt="3rem"
				>
					<GridItem w={'100%'}>
						<Grid
							templateColumns={{
								sm: "repeat(1, 1fr)",
								md: "repeat(1, 1fr)",
								lg: "repeat(1, 1fr)",
							}}
							gap={6}
						>
							{renderPlatformFeaturesLeft}
						</Grid>
					</GridItem>
					<GridItem w="100%">
						<Flex justifyContent="center" alignItems="center" h="100%">
							<Image src={PlatformCircle} objectFit="contain" w="100%" />
						</Flex>
					</GridItem>
					<GridItem  w={'100%'}>
						<Grid
							templateColumns={{
								sm: "repeat(1, 1fr)",
								md: "repeat(1, 1fr)",
								lg: "repeat(1, 1fr)",
							}}
							gap={6}
						>
							{renderPlatformFeaturesRight}
						</Grid>
					</GridItem>
				</Grid>
			</Box>
			<Divider />
			{/* 3th LSP Special */}
			<Box
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "10rem",
				}}
				py="10rem"
				bg="#f7f9fa"
			>
				<Box textAlign={"center"}>
					<Text fontSize={"2.5rem"} fontWeight={"600"} color="#259edc">
						A SaaS Platform specifically developed for
					</Text>
					<Text fontSize={"2.5rem"} fontWeight={"600"} lineHeight={"30px"}>
						Language Service Providers (LSP)
					</Text>
					<Text mt="2rem" fontWeight="500">
						LSP can support language translation and interpretation solutions their customer using Lingolet AI platform and can manage their who business.
					</Text>
				</Box>
				<Grid
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(4, 1fr)",
						lg: "repeat(4, 1fr)",
					}}
					gap={10}
					mt="3rem"
				>
					{renderLSPData}
				</Grid>
			</Box>
			<Divider />
			{/* 5th  Lingolet AI Section */}
			<Box
				mb={10}
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "10rem",
				}}
				py="3rem"
			>
				<Grid
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(2, 1fr)",
						lg: "repeat(2, 1fr)",
					}}
					gap={6}
				>
					<GridItem>
						<Box>
							<Text fontSize={"2.5rem"} fontWeight={"600"} color="#259edc">
								Lingolet AI
							</Text>
							<Text fontSize={"2.5rem"} fontWeight={"600"} lineHeight={"30px"}>
								Webinars and Events
							</Text>
							<Text mt="1.5rem" fontWeight="500">
								Lingolet AI is working together with language industry leaders and business.
								In these events and webinars our objective is to learn how AI can transform language industry from language industry leaders worldwide.
							</Text>
							<Button mt="1rem" bg={"#259edc"} fontWeight={"400"} color="white" borderRadius={"50px"} _hover={{ bg: "#259edc", color: "white" }} fontSize={"14px"}>
								SEE All EVENTS
							</Button>
						</Box>
					</GridItem>
					<GridItem>
						<Flex borderWidth={"1px"} borderBottomWidth={"5px"} borderBottomColor={"#259edc"} borderRadius={"5px"} flexWrap={"wrap"}>
							<Box
								w={{
									sm: "100%",
									md: "50%",
									lg: "50%",
								}}
							>
								<Image src={AIEvents} />
							</Box>
							<Box
								w={{
									sm: "100%",
									md: "50%",
									lg: "50%",
								}}
								p="20px"
							>
								<Text fontSize={"20px"} fontWeight={"600"}>Learn AI with Lingolet AI</Text>
								<Text fontSize={"15px"} fontWeight={"500"} my="10px">
									With Lingolet AI you can learn various aspect of AI in both business and technical aspects.
									We will be adding those contents for anyone to consume free and take advantage of it.
								</Text>
								<Flex alignItems={"center"}>
									<Text fontWeight={500} fontSize={"15px"} color="#259edc" mr="3" onClick={handleShowWebinarSignup} cursor="pointer">
										Register Now
									</Text>
									<MdOutlineKeyboardArrowRight color="#259edc" />
								</Flex>
							</Box>
						</Flex>
					</GridItem>
				</Grid>
				<Grid
					templateColumns={{
						sm: "repeat(1, 1fr)",
						md: "repeat(3, 1fr)",
						lg: "repeat(3, 1fr)",
					}}
					gap={10}
					mt="3rem"
				>
					{renderNewsLetterData}
				</Grid>
			</Box>
			<Divider />
			{/* 6th Footer Section */}
			<Box
				as="footer"
				bg={"black"}
				px={{
					sm: "2rem",
					md: "3rem",
					lg: "3rem",
				}}
				py="3rem"
				color={"white"}
				backgroundImage={`url(${waveFooterBg})`}
				backgroundSize="cover"
			>
				<Box ref={signupTypeSection}>
					{showPinInput ?
						<Flex w="full" justifyContent="center" direction="column" alignItems="center" gap={4}>
							<HStack>
								<PinInput size="lg" onChange={(value) => setPin(value)} otp>
									<PinInputField color="white" outline="white" />
									<PinInputField color="white" />
									<PinInputField color="white" />
									<PinInputField color="white" />
								</PinInput>
							</HStack>
							<Flex gap="2">
								<Button size="md" onClick={() => setShowPinInput(false)} colorScheme="blue">Edit Details</Button>
								<Button size="md" colorScheme="green" isLoading={btnLoading?.verify} onClick={verifyPin}>verify</Button>
							</Flex>
						</Flex>
						: signUpType === LANDING_PAGE.SIGNUP_TYPES.NEWS_LETTER ?
							<Box py={10} borderTop={"1px"} borderTopColor={"#383838"} borderBottom={"1px"} borderBottomColor={"#383838"}>
								<form onSubmit={(e) => handleCreateSubscription(e)}>
									<Flex w={'100%'}>
										<Text fontSize="25px">Sign Up for Our Newsletter</Text>
									</Flex>
									<Grid
										templateColumns={{
											sm: "repeat(1, 1fr)",
											md: "repeat(2, 1fr)",
											lg: "repeat(4, 1fr)",
										}}
										gap={25}
										mb={5}
									>
										<GridItem>
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="Email Address*" _placeholder={{ color: "white" }} fontSize="15px" isRequired={true} name="signup_email" type="email" value={userInfo?.signup_email} onInput={handleUserInfoChange} />
										</GridItem>
										<GridItem>
											<Input hidden name="subscriptionType" type="text" value={"newsletter"} />
										</GridItem>
									</Grid>
									<Grid
										templateColumns={{
											sm: "repeat(1, 1fr)",
											md: "repeat(2, 1fr)",
											lg: "repeat(4, 1fr)",
										}}
										gap={25}
									>
										<GridItem>
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="First Name" _placeholder={{ color: "white" }} fontSize="15px" name="first_name" value={userInfo?.first_name} onInput={handleUserInfoChange} />
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="Last Name" _placeholder={{ color: "white" }} fontSize="15px" name="last_name" value={userInfo?.last_name} onInput={handleUserInfoChange} />
										</GridItem>
									</Grid>
									<Flex w={'100%'}>
										<Grid
											templateColumns={{
												sm: "repeat(1, 1fr)",
												md: "repeat(2, 1fr)",
												lg: "repeat(4, 1fr)",
											}}
											gap={25}
										>
											<GridItem>
											</GridItem>
											<GridItem>
												<Text fontSize="15px">
													By submitting this form, I understand Lingolet AI will process
													my personal information in accordance with their{" "}
													<Text as="span" fontWeight="bold" textDecoration="underline" fontSize="15px">
														Privacy Notice.
													</Text>
													{/* . Additionally, I consent to my information ening sahred with Event Partners in accordance with
									<Text as="span" fontWeight="bold" textDecoration="underline" fontSize="15px">
											Lingolet AI's Event Privacy Notice
									</Text>
									. I understnad that I may withdray my consent or update my presfrences{" "}
									<Text as="span" fontWeight="bold" textDecoration="underline" fontSize="15px">
										here
									</Text>{" "}
									at any time. */}
												</Text>
												<Button mt="1rem" bg={"#259edc"} fontWeight={"400"} color="white" borderRadius={"50px"} _hover={{ bg: "#259edc", color: "white" }} fontSize={"14px"} type="submit" isLoading={btnLoading?.signup}>
													SUBSCRIBE NOW
												</Button>
											</GridItem>
										</Grid>
									</Flex>
								</form>
							</Box>
							:
							<Box py={10} borderTop={"1px"} borderTopColor={"#383838"} borderBottom={"1px"} borderBottomColor={"#383838"}>
								<form onSubmit={(e) => handleCreateSubscription(e)}>
									<Flex w={'100%'}>
										<Text fontSize="25px">Sign Up for Our webinar</Text>
									</Flex>
									<Grid
										templateColumns={{
											sm: "repeat(1, 1fr)",
											md: "repeat(2, 1fr)",
											lg: "repeat(4, 1fr)",
										}}
										gap={25}
										mb={5}
									>
										<GridItem>
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="Email Address*" _placeholder={{ color: "white" }} fontSize="15px" isRequired={true} name="signup_email" type="email" value={userInfo?.signup_email} onInput={handleUserInfoChange} />
										</GridItem>
										<GridItem>
											<Input hidden name="subscriptionType" type="text" value={"webinar"} />
										</GridItem>
									</Grid>
									<Grid
										templateColumns={{
											sm: "repeat(1, 1fr)",
											md: "repeat(2, 1fr)",
											lg: "repeat(4, 1fr)",
										}}
										gap={25}
									>
										<GridItem>
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="First Name" _placeholder={{ color: "white" }} fontSize="15px" name="first_name" value={userInfo?.first_name} onInput={handleUserInfoChange} />
										</GridItem>
										<GridItem>
											<Input variant={"flushed"} color={"white"} placeholder="Last Name" _placeholder={{ color: "white" }} fontSize="15px" name="last_name" value={userInfo?.last_name} onInput={handleUserInfoChange} />
										</GridItem>
									</Grid>
									<Flex w={'100%'}>
										<Grid
											templateColumns={{
												sm: "repeat(1, 1fr)",
												md: "repeat(2, 1fr)",
												lg: "repeat(4, 1fr)",
											}}
											gap={25}
										>
											<GridItem>
											</GridItem>
											<GridItem>
												<Text fontSize="15px">
													By submitting this form, I understand Lingolet AI will process
													my personal information in accordance with their{" "}
													<Text as="span" fontWeight="bold" textDecoration="underline" fontSize="15px">
														Privacy Notice.
													</Text>

												</Text>
												<Flex w="full">
													<Button mt="1rem" bg={"#259edc"} fontWeight={"400"} color="white" borderRadius={"50px"} _hover={{ bg: "#259edc", color: "white" }} fontSize={"14px"} type="submit" isLoading={btnLoading?.signup}>
														SUBSCRIBE NOW
													</Button>
													<Spacer />
													<Button mt="1rem" bg={"#259edc"} fontWeight={"400"} color="white" borderRadius={"50px"} _hover={{ bg: "#259edc", color: "white" }} fontSize={"14px"} type="submit" onClick={() => setSignUpType(LANDING_PAGE.SIGNUP_TYPES.NEWS_LETTER)}>
														SUBSCRIBE TO OUR NEWSLETTER
													</Button>
												</Flex>
											</GridItem>
										</Grid>
									</Flex>
								</form>
							</Box>
					}
				</Box>
				<Box w={"full"} mt={10}>
					<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
						<Flex direction={{ lg: "row" }} flexWrap={"wrap"} wrap="wrap" gap={4} align={"flex-start"} fontSize="15px">
							<Text flexBasis={{ sm: "100%", lg: "auto" }}>&copy; 2025  Lingolet AI Inc. All Rights Reserved</Text>
							<Text flexBasis={{ sm: "100%", lg: "auto" }}>Privacy Notice</Text>
							<Text flexBasis={{ sm: "100%", lg: "auto" }}>Site Terms</Text>
							<Text flexBasis={{ sm: "100%", lg: "auto" }}>Cookie Settings</Text>
							{/* <Text flexBasis={{ sm: "100%", lg: "auto" }}>Do Not Share My Personal Information Legal</Text> */}
						</Flex>
						<Flex flexWrap={"wrap"}>
							<Box mr="3">
								<BsTwitterX color={'red.500'} fontSize={"2rem"} cursor={"pointer"} />
							</Box>
							<Box mr="3">
								<FaLinkedin fontSize={"2rem"} cursor={"pointer"} />
							</Box>
							<Box mr="3">
								<FaFacebook ml="6" fontSize={"2rem"} cursor={"pointer"} />
							</Box>

							<FaYoutube fontSize={"2rem"} cursor={"pointer"} />
						</Flex>
					</Flex>

					<Box mt={5}>
						<Text fontSize="15px">
							If you do not want to have any future communication with Lingolet AI,{" "}
							<Text as="span" fontWeight="normal" textDecoration="underline" cursor="pointer" onClick={()=> navigate("/public/preference/update")}>
								Please customize your communication prefrences here.
							</Text>
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default LspLandingPageNew;
