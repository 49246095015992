// React + Chakra Imports
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { Flex, Box, Text, Alert, AlertIcon, Spinner, useToast, Icon, useColorModeValue } from "@chakra-ui/react";

// Component + Services Imports
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { getLingoletIdContactList } from "../../../../../../services/accountServices";

function ContactList({ identity_uuid, setclientData, reRenderContactList, idx, setIdxValue }) {
	const toast = useToast();
	const [loading, isLoading] = useState(false);
	const [contactList, setContactList] = useState([]);
	const shadowBlack = useColorModeValue("0px 3px 5px rgb(107 111 110)", "0px 3px 5px rgb(107 111 110);");

	// Todo:Fetch Data
	useEffect(() => {
		fetchContactDetails();
	}, []);

	useEffect(() => {
		if (reRenderContactList !== null && reRenderContactList > 0) {
			//After update couter flags and function triggers
			fetchContactDetails();
		}
	}, [reRenderContactList]);

	// Todo:Fetch Client Details
	const fetchContactDetails = () => {
		isLoading(true);
		const obj = {
			identity_uuid: identity_uuid,
		};

		getLingoletIdContactList(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setContactList(res?.data?.[0]?.data?.length ? res?.data?.[0]?.data : []);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setContactList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderContactData =
		contactList?.length > 0 ? (
			contactList.map((data, index) => (
				<Flex p="4" key={data?.id_contact_uuid} borderWidth="1px" w="49%" alignItems={"start"} fontSize="15px" mb="4" borderRadius={"10px"} borderColor={"#a5a5a5"} boxShadow={shadowBlack}>
					<Box w="50%">
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Home Phone:
							</Text>
							<Text ml="2" w="50%">
								{data?.id_contact_home_phone ? data?.id_contact_home_phone : "No data found"}
							</Text>
						</Flex>
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Mobile Phone:
							</Text>
							<Text ml="2" w="50%">
								{data?.id_contact_mobile_phone ? data?.id_contact_mobile_phone : "No data found"}
							</Text>
						</Flex>
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Business Phone:
							</Text>
							<Text ml="2" w="50%">
								{data?.id_contact_business_phone ? data?.id_contact_business_phone : "No data found"}
							</Text>
						</Flex>
					</Box>
					<Box w="50%">
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Other Phone:
							</Text>
							<Text ml="2" w="50%">
								{data?.id_contact_other_phone ? data?.id_contact_other_phone : "No data found"}
							</Text>
						</Flex>
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Primary Email:
							</Text>
							<Text>{data?.id_contact_primary_email ? data?.id_contact_primary_email : "No data found"}</Text>
						</Flex>
						<Flex mb="3" mr="3">
							<Text fontWeight="bold" w="50%">
								Secondary Email:
							</Text>
							<Text ml="2" w="50%">
								{data?.id_contact_secondary_email ? data?.id_contact_secondary_email : "No data found"}
							</Text>
						</Flex>
					</Box>
					<Flex
						rounded={"md"}
						p={2}
						alignItems={"center"}
						borderWidth={"1px"}
						borderColor={"gray.400"}
						align={"end"}
						ml="4"
						cursor={"pointer"}
						onClick={() => {
							setclientData(data);
							setIdxValue(idx);
						}}
						borderRadius={"50px"}
					>
						<Icon as={AiFillEdit} rounded={"md"} fontSize={"14px"} cursor={"pointer"} />
					</Flex>
				</Flex>
			))
		) : (
			<Alert status="warning" mb="12px">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Flex justifyContent={"space-between"} borderWidth={"1px"} p="4" flexWrap={"wrap"} borderColor={"#a5a5a5"} borderRadius={"5px"} mt="4px" w="100%" pb="0">
			{loading ? (
				<Flex alignItems="center" justifyContent="center" p="10px" pt="0" h="30vh" w="100%">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>{renderContactData}</>
			)}
		</Flex>
	);
}

export default ContactList;
