// React Imports
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Input, Heading, Button, FormControl, FormLabel, useColorModeValue, useToast } from "@chakra-ui/react";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { createSignUpType } from "../../../services/sharedServices";

// Component Imports

function RequestDemoDetails({loginForm, setLoginForm, error,handleSubmit, loading }) {
	const toast = useToast();
	const brandStars = useColorModeValue("brand.500", "brand.400");

	// Todo:Set form value
	const onUpdateField = e => {
		const nextFormState = {
			...loginForm,
			[e.target.name]: e.target.value,
		};
		setLoginForm(nextFormState);
	};


	return (
		<Flex alignContent={"center"} justifyContent={"center"} h="100%" flexWrap={"wrap"}>
			<Box me="auto" w="100%">
				<Heading color={"white"} fontSize="26px" mb="10px">
					Request for an Demo!
				</Heading>
			</Box>
			<Flex zIndex="2" direction="column" w="100%" background="transparent" borderRadius="15px" mx={{ base: "auto", lg: "unset" }} me="auto" mb={{ base: "20px", md: "auto" }}>
				<FormControl>
					<FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={"white"} mb="8px">
						Email<Text color={brandStars}>*</Text>
					</FormLabel>
					<Input isRequired={true} variant="auth" fontSize="sm" ms={{ base: "0px", md: "0px" }} type="email" placeholder="email@businessdomain.com" mb="14px" fontWeight="500" size="lg" name="email" onChange={onUpdateField} value={loginForm.email} color="white" />
					<Text color="red" mb="2" _placeholder={{ color: "white" }}>
						{error?.email}
					</Text>

					<FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={"white"} mb="8px">
						First Name<Text color={brandStars}>*</Text>
					</FormLabel>
					<Input isRequired={true} variant="auth" fontSize="sm" ms={{ base: "0px", md: "0px" }} type="text" placeholder="Enter your first name" mb="14px" fontWeight="500" size="lg" name="firstName" onChange={onUpdateField} value={loginForm.firstName} color="white" _placeholder={{ color: "white" }} />
					<Text color="red" mb="2">
						{error?.firstName}
					</Text>

					<FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={"white"} mb="8px">
						Last Name<Text color={brandStars}>*</Text>
					</FormLabel>
					<Input isRequired={true} variant="auth" fontSize="sm" ms={{ base: "0px", md: "0px" }} type="text" placeholder="Enter your last name" mb="14px" fontWeight="500" size="lg" name="lastName" onChange={onUpdateField} value={loginForm.lastName} color="white" />
					<Text color="red" mb="2" _placeholder={{ color: "white" }}>
						{error?.lastName}
					</Text>

					<Button
						mt="2rem"
						fontSize="sm"
						bg={"#259edc"}
						_hover={{ bg: "#259edc" }}
						color={"white"}
						fontWeight="500"
						w="100%"
						h="50"
						mb="24px"
						onClick={() => {
							handleSubmit();
						}}
						isDisabled={loading ? true : false}
						isLoading={loading ? true : false}
					>
						Submit
					</Button>
				</FormControl>
			</Flex>
		</Flex>
	);
}

export default RequestDemoDetails;
