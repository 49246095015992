import { Box, Flex, Icon } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../../documentai/shareControl/SideBar";
import { AiOutlineDashboard } from "react-icons/ai";
import Documents from "../../documentai/shareControl/Documents";
import { useState } from "react";

const DocumentControlAccess = () => {
    const [userEmail, setUserEmail] = useState(null)

    const menuItems = [
        {
            name: "Files",
            path: "",
            icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
            component: <Documents setUserEmail={setUserEmail}/>,
        },

    ];

    return (
        <Flex height="100vh">
            <Sidebar menuItems={menuItems} userEmail={userEmail}/>
            <Box flex="1" overflow="auto">
                <Routes>
                    {menuItems.map((item) => (
                        <Route
                            key={item.name}
                            path={item.path}
                            element={item.component}
                        />
                    ))}
                </Routes>
            </Box>
        </Flex>
    );
};

export default DocumentControlAccess;
