import { FaSort } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTriangleInverted } from "react-icons/tb";
import { Flex, Button, Input, HStack, Box, Text, Tab, Tabs, TabList, TabPanel, TabPanels, MenuItem, MenuList, ButtonGroup, MenuButton, Menu, Circle, VStack, useColorModeValue, Alert, AlertIcon, Spinner, IconButton, useToast } from "@chakra-ui/react";

// Component + Services Imports
import { listBookServices } from "../../../../../services/accountServices";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import Pagination from "../../../../../components/pagination/Pagination";
import AddUpdateBookingService from "./bookingServices/AddUpdateBookingService";
import BookingServicesList from "./bookingServices/BookingServicesList";

function Active({ refresh }) {
	const tabBg = useColorModeValue("#eeeeee", "#707070");
	const toast = useToast();
	const [currPage, setCurrPage] = useState(1);
	const [loading, isLoading] = useState(false);
	const [activeBookingList, setActiveBookingList] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(null);

	const [editData, setEditData] = useState(null); // State to hold data to be edited
	const [refreshList, setRefreshList] = useState(false);

	const handleEdit = data => {
		setEditData(data); // Set the data to be edited
	};

	const handleUpdateComplete = () => {
		setRefreshList(prev => !prev); // Toggle refreshList to trigger ListComponent API call
	};

	useEffect(() => {
		fetchActiveBookingDetails();
	}, [refresh]);

	// Todo:Fetch Booking Details
	const fetchActiveBookingDetails = () => {
		isLoading(true);
		const obj = {
			page_id: currPage,
			records_per_page: 10,
		};

		listBookServices(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					setTotalPages(res?.data?.total_pages);
					setActiveBookingList(res?.data?.data?.length ? res?.data?.data : []);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setActiveBookingList([]);
					setTotalPages(0);
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isLoading(false);
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	// Todo: Render Client Data
	const renderActiveBookingData =
		activeBookingList?.length > 0 ? (
			activeBookingList.map((data, index) => (
				<Box mb="3" p="15px" borderWidth={"1px"} borderRadius={"10px"} key={data?.booking_uuid}>
					<Flex justifyContent={"space-between"} alignItems={"start"} size="md">
						<Box>
							<Text fontSize="16px" fontWeight="500">
								Client Name:- {data?.booking?.booking_client_name}
							</Text>
							<Text fontSize="16px" fontWeight="500">
								Customer Name:- {data?.booking?.booking_customer_name}
							</Text>
							<Text fontSize="16px" fontWeight="500">
								Booking Email:- {data?.booking?.booking_other_emails}
							</Text>
							<Text fontSize="16px" fontWeight="500">
								Requested Email:- {data?.booking?.booking_requested_by_email}
							</Text>
						</Box>
						<VStack gap={2} align={"flex-start"}>
							<Menu>
								<MenuButton as={Box}>
									<ButtonGroup
										isAttached
										spacing={0}
										borderRadius={"10px"}
										sx={{
											bg: "blue.100",
											borderStyle: "dashed",
											borderWidth: "1px",
											borderColor: "black",
											color: "black",
											rounded: "sm",
										}}
										size="sm"
									>
										<Button borderColor="black" bg="blue.100" rounded={"sm"} borderTopLeftRadius="10px" borderBottomLeftRadius="10px" fontWeight={"500"}>
											<Circle size={"10px"} bg={"limegreen"} mr={1} /> Action
										</Button>
										<Button borderTopRightRadius="10px" borderBottomRightRadius="10px" borderStyle="dashed" borderLeftWidth="1px" borderColor="black" bg="blue.100" rounded={"sm"} cursor={"pointer"}>
											<TbTriangleInverted />
										</Button>
									</ButtonGroup>
								</MenuButton>
								<MenuList
									sx={{
										p: 0,
										m: 0,
									}}
									zIndex={9}
								>
									{/* Booking Services */}
									<AddUpdateBookingService
										bookingId={data?.booking?.booking_uuid}
										isOpen={selectedDrawerIndex === index} // Only open if this
										onOpen={() => setSelectedDrawerIndex(index)} // Open this drawer
										onClose={() => setSelectedDrawerIndex(null)}
										editData={editData}
										onUpdateComplete={handleUpdateComplete}
										onEdit={handleEdit}
									/>
								</MenuList>
							</Menu>
						</VStack>
					</Flex>
					<BookingServicesList bookingId={data?.booking?.booking_uuid} refreshList={refreshList} onEdit={handleEdit} />
				</Box>
			))
		) : (
			<Alert status="warning" mt="4">
				<AlertIcon />
				<Text>No data added yet.</Text>
			</Alert>
		);

	return (
		<Box my={4}>
			<HStack gap={1} h={"full"} mb="3" justifyContent="space-between">
				<Box>
					<Input size={"sm"} bg={tabBg} w="300px" placeholder="Filter <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
					<Input size={"sm"} bg={tabBg} w="300px" placeholder="Search <>" borderRadius={0} rounded={"none"} borderColor={"gray.400"} mr="3" />
					<Button size={"sm"} bg={tabBg} borderRadius={0} rounded={"none"} borderWidth={"1px"} borderColor={"gray.400"} px={5} fontWeight="400" mb="1px">
						GO
					</Button>
				</Box>
				<Box alignItems={"center"}>
					<Menu>
						<MenuButton size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} as={Button} rightIcon={<FaSort />}>
							Quick Filter
						</MenuButton>
						<MenuList>
							<MenuItem p="0">
								<>
									<Button ml={5} size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0}>
										A
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										B
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										C
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										.
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										.
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										.
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										X
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important">
										Y
									</Button>
									<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={0} ml="2 !important" mr="2">
										Z
									</Button>
								</>
							</MenuItem>
						</MenuList>
					</Menu>
					<IconButton
						ml={2}
						size={"sm"}
						bg={tabBg}
						borderRadius={"5px"}
						borderWidth={"1px"}
						borderColor={"gray.800"}
						pr={2}
						rightIcon={<FiRefreshCw />}
						onClick={() => {
							fetchActiveBookingDetails();
						}}
					></IconButton>
					<Button ml={2} size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3}>
						...
					</Button>
					<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} ml="2 !important">
						...
					</Button>
					<Button size={"sm"} bg={tabBg} borderRadius={"5px"} borderWidth={"1px"} borderColor={"gray.800"} px={3} ml="2 !important">
						<FaEllipsisVertical />
					</Button>
				</Box>
				{/*  */}
			</HStack>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="lg" />
				</Flex>
			) : (
				<>
					{loading ? (
						<Flex alignItems="center" justifyContent="center" h="50vh">
							<Spinner size="lg" />
						</Flex>
					) : (
						<>
							{renderActiveBookingData}
							{activeBookingList.length ? <Pagination currentPage={currPage} recordsPerPage={recordsPerPage} setCurrentPage={setCurrPage} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} /> : null}
						</>
					)}
				</>
			)}
		</Box>
	);
}

export default Active;
